import * as Constants from "../constants/index";

const initialState = {
    data: [],
    isFetching: false,
    gotError: false,
    textError: false
};


export const allUsers = (state = initialState, action) => {
    switch (action.type) {
        case (Constants.RECEIVE_ALL_USERS):
            return {
                ...state,
                data: action.data,
                isFetching: false,
                getError: false,
                textError: ""
            };
        case (Constants.REQUEST_ALL_USERS):
            return {
                ...state,
                isFetching: true,
                getError: false,
                textError: ""
            };
        case (Constants.FAILED_FETCH_ALL_USERS):
            return {
                ...state,
                isFetching: false,
                getError: true,
                textError: action.err
            };
        default:
            return state
    }
};
