import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import {
  Button,
  // Modal,
  // ModalBody,
  // ModalHeader,
  FormCheckbox,
} from "shards-react";
import { IoIosAddCircleOutline } from "react-icons/io";
import { css } from "@emotion/core";
import { ScaleLoader } from "react-spinners";
import { fetchDecision, fetchUserSession } from "../../actions/index";
import { connect } from "react-redux";
import { API_URL } from "../../constants";
import { authHeader } from "../../actions/AuthHeader";
import Modal from "react-responsive-modal";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const columns = [
  {
    id: "0",
    label: "ID",
    align: "center",
  },
  {
    id: "1",
    label: "Decision Name",
    align: "center",
  },
  {
    id: "2",
    label: "Decision Deadline",
    align: "center",
  },
  {
    id: "3",
    label: "Status",
    align: "center",
  },
  {
    id: "4",
    label: "Creator",
    align: "center",
  },
  {
    id: "5",
    label: "More",
    align: "center",
  },
];

const rows = [
  { id: "services", numeric: "false" },
  { id: "requests", numeric: "true" },
  { id: "fat", numeric: "true" },
  { id: "carbs", numeric: "true" },
  { id: "protein", numeric: "true" },
];
/**
 * DecisionsComponent class renders current user's all decisions.
 */
class DecisionsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 1,
      page: 0,
      rowsPerPage: 10,
      isLoading: false,
      show_user_welcome_modal: false,
    };
    this.user_welcome_toggle = this.user_welcome_toggle.bind(this);
    this.status = {
      cl: ["Closed", "grey"],
      vt: ["Voting", "orange"],
      fi: ["Decided", "#32CD32"],
      dr: ["Draft", "#add8e6"],
    };
  }

  refactorProfileUrl = (url) => {
    if (url && String(url).substring(0, 4) === "http") {
      return url;
    } else {
      console.log(url);
      return `${API_URL}/uploads/${url["profile_pic"]}`;
    }
  };
  handleChangePage = (event, newPage) => {
    // setPage(newPage);
    this.setState({
      page: newPage,
    });
  };
  handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(+event.target.value);
    // setPage(0);

    this.setState((pre) => ({
      rowsPerPage: event.target.value,
      page: 0,
    }));
  };
  componentDidMount = async () => {
    this.setState({
      isLoading: true,
    });
    const { dispatch } = this.props;
    await dispatch(fetchUserSession());
    console.log("this.props.", this.props);
    if (this.props.sessionID !== undefined){
      await this.checkUserWelcome();

    await dispatch(
      fetchDecision(`${API_URL}/users/${this.props.sessionID}/polls/`)
    );
    this.setState({
      isLoading: false,
    });
    }
  };
  user_welcome_toggle() {
    this.setState({
      show_user_welcome_modal: !this.state.show_user_welcome_modal,
    });
  }
  checkUserWelcome = () => {
    const endpoint = `users/${this.props.sessionID}/check_user_welcome/`;
    const options = {
      method: "GET",
    };
    fetch(`${API_URL}/${endpoint}`, {
      ...options,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((data) => data.json())
      .then((res) => {
        // console.log("user_welcome", res);
        if (res.data === "show") {
          this.setState({
            show_user_welcome_modal: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  change_show_welcome = () => {
    const endpoint = `users/${this.props.sessionID}/change_show_welcome/`;

    fetch(`${API_URL}/${endpoint}`, {
      method: "POST",
      body: JSON.stringify({
        user: this.props.sessionID,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((data) => data.json())
      .then((res) => {
        console.log("user_welcome_change", res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onNextButtonClick = (apiUrl) => {
    const { dispatch } = this.props;
    dispatch(fetchDecision(apiUrl));
  };

  onPreviousButtonClick = (apiUrl) => {
    const { dispatch } = this.props;
    dispatch(fetchDecision(apiUrl));
  };

  _renderTableRow = () => {
    let data;
    const { page, rowsPerPage } = this.state;
    if (this.props.decisions && this.props.decisions.length > 0) {
      data = this.props.decisions.map((item, index) => {
        console.log("item", item);

        return (
          <div>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      console.log("asdasdd", value);
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </div>

          // <tr key={item["id"]}>
          //     <th scope="row" className={"text-center text-black"}>{index + 1}</th>
          //     <td>{item["name"]}</td>
          //     <td>
          //         <span>
          //             {item["finish_date"].substring(0, 10)}
          //         </span>
          //         <span className={"ml-2"}>
          //             {item["finish_date"].substring(11, 16)}
          //         </span>
          //     </td>
          //     <td>
          //         <div className={"d-flex"}>
          //             <div className={"status-circle ml-1"}
          //                  style={{"backgroundColor": this.status[item["state"]][1]}}>
          //             </div>
          //             <div>
          //                 {this.status[item["state"]][0]}
          //             </div>
          //         </div>
          //     </td>
          //     <td>{item["created_by"]}</td>
          //     <td className={"text-center"}>
          //         <Link to={`/decision/polls/${item['id']}`}>
          //             <Button theme={"success"}
          //                     size={"sm"}
          //                     className={"btn-success-custom"}>
          //                 Details
          //             </Button>
          //         </Link>
          //     </td>
          // </tr>
        );
      });
    }
    return data;
  };

  onIndexClick = (index) => {
    this.setState({
      activeItem: index,
    });
    const { dispatch } = this.props;
    dispatch(fetchDecision(`${API_URL}/polls/?page=${index}`));
  };

  _renderPagination = () => {
    let count = this.props.decisions["count"];
    count = Math.floor(count / 10) + 1;
    let next = this.props.decisions["next"];
    let prev = this.props.decisions["previous"];
    let nextClass = "page-item ";
    let prevClass = "page-item ";

    if (String(next) === "null") {
      nextClass += "disabled";
    }
    if (String(prev) === "null") {
      prevClass += "disabled";
    }

    let buttons = [];
    for (let i = 1; i <= count; i++) {
      buttons.push(i);
    }

    return (
      <div className={"pagination d-flex justify-content-center"}>
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className={prevClass}>
              <button
                className="page-link"
                tabIndex="-1"
                onClick={() => this.onPreviousButtonClick(prev)}
              >
                Previous
              </button>
            </li>
            {buttons.map((item) => {
              let isActive = this.state.activeItem === item ? "azure" : null;
              return (
                <li className="page-item" key={item}>
                  <button
                    className="page-link"
                    style={{ backgroundColor: isActive }}
                    onClick={() => this.onIndexClick(item)}
                  >
                    {item}
                  </button>
                </li>
              );
            })}
            <li className={nextClass}>
              <button
                className="page-link"
                onClick={() => this.onNextButtonClick(next)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    );
  };

  _renderTableOrSpinner = () => {
    const { page, rowsPerPage } = this.state;
    console.log("decision", this.props.decisions);
    if (this.state.isLoading) {
      return (
        <div className={"spinner-loader mt-5"}>
          <div className="sweet-loading">
            <ScaleLoader
              css={override}
              sizeUnit={"px"}
              size={150}
              color={"#FF8260"}
              loading={this.state.isLoading}
            />
          </div>
        </div>
      );
    } else if (this.props.getError) {
      return <div>{String(this.props.textError)}</div>;
    } else {
      return (
        // <div className={"decision-wrapper"}>
        //     <div className={"decision-table table-responsive"}>
        //         <table className="table table-borderless">
        //             <thead>
        //             <tr>
        //                 <th scope="col" className={"text-center"}>ID</th>
        //                 <th scope="col">Decision Name</th>
        //                 <th scope="col">Finish Date</th>
        //                 <th scope="col">Status</th>
        //                 <th scope="col">Creator</th>
        //                 <th scope="col" className={"text-center"}>More</th>
        //             </tr>
        //             </thead>
        //             <tbody>
        //             {this._renderTableRow()}
        //             </tbody>
        //         </table>
        //     </div>
        //     {/*{this._renderPagination()}*/}
        // </div>

        <Paper className="table_container">
          {this.props.decisions ? (
            console.log('pollll', this.props.decisions),
            <>
              <div className="report-table">
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{ minWidth: "340px" }}
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                    this.props.decisions.length>0?
                    this.props.decisions
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => {
                        // console.log("item", item);
                        return [
                          <TableRow key={item.id}>
                            <TableCell align="center">
                              {" "}
                              {index + page * rowsPerPage + 1}
                            </TableCell>
                            <TableCell align="center">{item.name}</TableCell>
                            <TableCell align="center">
                              {item["finish_date"].substring(0, 10)}

                              {/* {item["finish_date"].substring(11, 16)} */}
                            </TableCell>

                            <TableCell align="center">
                              <div className={"d-flex"}>
                                <div
                                  className={"status-circle ml-1"}
                                  style={{
                                    backgroundColor: this.status[
                                      item["state"]
                                    ][1],
                                  }}
                                ></div>
                                <div>{this.status[item["state"]][0]}</div>
                              </div>
                              {/* {" "}
                        {this.status[item["state"]][0]} */}
                            </TableCell>
                            <TableCell align="center">
                              {/* {item["created_by"]} */}
                              <div className={"ml-5 pl-5"}>
                                <Link
                                  to={`/people/users/${item["created_by"]}`}
                                  key={index}
                                  className={
                                    "m-0 poll-data-header-creator fw-300"
                                  }
                                >
                                  <span
                                    className={
                                      "nav-profile-avatar d-flex align-items-center"
                                    }
                                    style={{ fontWeight: "Normal" }}
                                  >
                                    <div
                                      style={{
                                        backgroundImage: `url(${this.refactorProfileUrl(
                                          item.creator["pic"]
                                        )})`,
                                      }}
                                      className={"avater-img mr-1"}
                                    ></div>
                                    {item.creator["first_name"] +
                                      " " +
                                      item.creator["last_name"]}
                                  </span>
                                </Link>
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              <Link to={`/decision/polls/${item["id"]}`}>
                                <Button
                                  theme={"success"}
                                  size={"sm"}
                                  className={"btn-success-custom"}
                                >
                                  Details
                                </Button>
                              </Link>
                            </TableCell>
                          </TableRow>,
                        ];
                      }):undefined
                    }
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={this.props.decisions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "previous page",
                }}
                nextIconButtonProps={{
                  "aria-label": "next page",
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                className="table-pagination"
              />
            </>
          ) : undefined}
        </Paper>
      );
    }
  };

  render() {
    return (
      <div
        className={"container"}
        style={{
          // height: "80%",
          backgroundColor: "white",
          marginTop: "70px",
          paddingBottom: "50px",
          border: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Modal
          animationDuration={100}
          open={this.state.show_user_welcome_modal}
          onClose={this.user_welcome_toggle}
          center
        >
          <h4>Welcome to MeriTa</h4>
          <hr />
          {/* <div style={{ width: "800px" }}></div> */}
          <div className="model ">
            <div className="modelContent">
              <div style={{ fontSize: 14, color: "black" }}>
                MeriTa allows you to make decisions with a group of experts.{" "}
                {/* <u style={{ color: "black" }}>
                  You need to first connect to your network.
                </u> */}
                <br />
                <br />
                Please use the "<u style={{ color: "black" }}>Invite Experts</u>
                " button to send invites to your colleagues.
                <div>
                  After They registered and activated their accounts, go to "
                  <u style={{ color: "black" }}>People</u>" page and{" "}
                </div>
                <u style={{ color: "black" }}>connect</u> to them.
                <br />
                <br />
                You need to connect to at least two people to create your first
                decision.
              </div>
              <br />
              <br />
              <br />
              <br />

              <FormCheckbox
                className={"ml-3"}
                inline
                name="new_comment"
                checked={this.state.not_show === true}
                onChange={() => {
                  this.change_show_welcome();
                  this.setState({
                    not_show: !this.state.not_show,
                  });
                }}
              >
                Do not show this again
              </FormCheckbox>
            </div>
          </div>
        </Modal>

        {/* <Modal
          open={this.state.show_user_welcome_modal}
          toggle={this.user_welcome_toggle}
          size="lg"
          position="mt"
          className="modal-dialog-welcome"
        >
          <ModalHeader>Welcome to MeriTa</ModalHeader>
          <ModalBody>
            <div style={{ fontSize: 14, color:'black' }}>
              MeriTa allows you to make decisions with a group of experts.{" "}
              <u style={{ color: "black" }}>
                You need to first connect to your network.
              </u>
              <br />
              <br/>
              Please use the "<u style={{ color: "black" }}>Invite Experts</u>" button above the page to send invites to your colleagues.
              <div>After the have registered and activated their account, go to "<u style={{ color: "black" }}>People</u>" page and </div>
              <u v>connect</u> to them.
            </div>
            <br />
            <br />
            <br />
            <br />
            
            <FormCheckbox
                        className={"ml-3"}
                        inline
                        name="new_comment"
                        checked={this.state.not_show === true}
                        onChange={() => {
                          this.change_show_welcome();
                        
                          this.setState({
                            not_show:!this.state.not_show
                          });
                        }}
                      >Do not show this again</FormCheckbox>
          </ModalBody>
        </Modal> */}
        <div className={""}>
          <div
            className={
              "py-5 d-flex ml-sm-2 ml-md-5 justify-content-between decision-wrapper"
            }
          >
            <div className={"d-flex ml-3 ml-md-0"}>
              <div className={"decision-icon-ui-title mr-1"}></div>
              <h2 className={"decisions-header-text"}>
                Decisions{" "}
                {this.props.decisions && this.props.decisions.length > 0 ? (
                  <span style={{ fontSize: 16 }}>
                    ({this.props.decisions.length})
                  </span>
                ) : (
                  ""
                )}
              </h2>
            </div>
            <div className={"py-2 mr-sm-2 mr-md-5 new-decision-button-wrapper"}>
              <Link to={"/decision/make"}>
                <Button
                  theme="success"
                  className={"new-decision-button btn-success-custom"}
                >
                  <IoIosAddCircleOutline className={"mr-2 font-size-18"} />
                  Make New Decision
                </Button>
              </Link>
            </div>
          </div>
        </div>
        {this.props.decisions ? this._renderTableOrSpinner() : undefined}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { decisions, isFetching, getError, textError } = state.decisions;
  console.log("user",state.decisions);
  return {
    decisions,
    isFetching,
    getError,
    textError,
    sessionID: state.session.data.username,
  };
};

export default withRouter(connect(mapStateToProps, null)(DecisionsComponent));
