import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { Button } from "shards-react";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { getAllUser, followUser } from "../../actions";
import { connect } from "react-redux";
import { css } from "@emotion/core";
import { PulseLoader } from "react-spinners";
import AutoCompleteAddFriend from "../../layout/UI/AutoCompleteAddFriend";

import { API_URL } from "../../constants";
import { authHeader } from "../../actions/AuthHeader";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

/**
 * AddFriendModal class renders an autocomplete input for selecting people to follow.
 * @prop {object} name
 */
class AddFriendModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      people: "",
      peopleList: [],
      loading: false,
      following: false,
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    if (this.props.user.trial)
      if (!this.props.user.trial.user_type.includes("Enter"))
        this.props
          .getAllUser()
          .then((res) => res.json())
          .then((data) =>
            this.setState({
              peopleList: data["results"],
              loading: false,
            })
          );
      else {
        const endpoint1 = `users/${this.props.sessionID}/org_users_connect/`;
        const options1 = {
          method: "GET",
        };
        await fetch(`${API_URL}/${endpoint1}`, {
          ...options1,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: authHeader(),
          },
        })
          .then((data) => data.json())
          .then((res) => {
            console.log("connection_list", res);
            this.setState({
              peopleList: res,
              loading: false,
            })
          })
          .catch((error) => {
            console.log(error);
          });
      }
  }

  showSuccessMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

  showErrorMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

  followUser = async () => {
    this.setState({
      following: true,
    });
    let follower = this.props.sessionID;
    let following = this.state.people;
    if (!following) {
      this.setState({
        following: false,
      });
      return this.showErrorMessage("Please select a username from list");
    }
    if (follower === following) {
      this.setState({
        following: false,
      });
      return this.showErrorMessage("You can not connect to yourself !");
    }
    await this.props.followUser(follower, following).then((res) => {
      if (res.status === 201) {
        return this.showSuccessMessage("User Connected Successfully");
      } else if (res.status === 300) {
        return this.showErrorMessage("This user is already followed.");
      } else {
        return this.showErrorMessage("Something went wrong");
      }
    });
    this.setState({
      following: false,
      people: "",
    });
    return this.props.onCloseModal();
  };

  handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      return this.followUser();
    }
  };

  onChangePeople = (event, { newValue }) => {
    this.setState({
      people: newValue,
    });
  };

  render() {
    return (
      <div onKeyDown={this.handleEnterKey}>
        <Modal
          animationDuration={100}
          open={this.props.show}
          onClose={this.props.onCloseModal}
          top
        >
          <h4>Connect to an expert</h4>
          <hr />

          <div className={"mb-3"}>
            <AutoCompleteAddFriend
              inputProps={{
                placeholder: "People",
                value: this.state.people,
                onChange: this.onChangePeople,
              }}
              data={this.state.peopleList}
            />
          </div>
          <div>
            <p>
              Start typing expert's Name
              <br />
              They should register & activate their account first.
              <br />
              you will be able to invite experts to decision after you connect
              to them.
            </p>
          </div>

          <div
            style={{ width: "500px" }}
            className={"d-flex justify-content-end"}
          >
            <Button
              disabled={this.state.following}
              onClick={this.followUser}
              className={"mr-1"}
            >
              {this.state.following ? (
                <div className="sweet-loading">
                  <PulseLoader
                    css={override}
                    sizeUnit={"px"}
                    color={"white"}
                    size={7}
                    loading={true}
                  />
                </div>
              ) : (
                "Connect"
              )}
            </Button>
            <Button onClick={this.props.onCloseModal} className={"ml-1"}>
              No
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ session }) => {
  console.log("session", session);
  return {
    sessionID: session.data.username,
    user: session.data,
  };
};

export default connect(mapStateToProps, { getAllUser, followUser })(
  AddFriendModal
);
