import React, {Component} from "react";
import {FaAt, FaLock, FaUser, FaUniversity} from "react-icons/fa";
import {withRouter} from 'react-router';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {PulseLoader} from 'react-spinners';
import {css} from '@emotion/core';
import {IoMdCheckmarkCircle, IoMdCloseCircle} from "react-icons/io";
import {setPassword} from "../../actions";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

/**
 * ConfirmAccount class renders inputs for changing user password without old password.
 */
class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            newPassword: "",
            newPasswordRepeat: "",
        }
    }


    showErrorMessage = (text) => toast.notify(
        <p className={"m-0 p-0 font-size-14"}>
            <span className={"mr-2 font-size-19"} style={{verticalAlign: "1px"}}>
                <IoMdCloseCircle style={{color: "#f5222d"}}/>
            </span>
            {text}
        </p>
        , {
            duration: 2000
        });

    showSuccessMessage = (text) => toast.notify(
        <p className={"m-0 p-0 font-size-14"}>
            <span className={"mr-2 font-size-19"} style={{verticalAlign: "1px"}}>
                    <IoMdCheckmarkCircle style={{color: "#52c41a"}}/>
            </span>
            {text}
        </p>
        , {
            duration: 2000
        });

    toggle = () => {
        this.setState({
            activeTab: !this.state.activeTab
        })
    };


    handleLoginKey = (e) => {
        if (e.keyCode === 13) {
            return this.handleOk()
        }
    };

    showRelatedError = (data) => {
        return Object.keys(data).map(item => {
            data[item].map(subItem => {
                this.showErrorMessage(subItem)
            })
        });
    };

    handleOk = () => {
        this.setState({
            isLoading: true
        });
        if (this.state.newPassword !== this.state.newPasswordRepeat) {
            this.setState({
                isLoading: false
            });
            return this.showErrorMessage("Password does not match!");
        };

        let token = this.props.match.params.token;
        let uid = this.props.match.params.uid;

        let data = {
            "uid": uid,
            "token": token,
            "new_password1": this.state.newPassword,
            "new_password2": this.state.newPasswordRepeat
        };
        return setPassword(uid, token, data)
            .then(res => {
                if (res.status === 404) {
                    this.setState({
                        gotError: true,
                        isLoading: false
                    });
                    res.json().then(data => {
                        return this.showRelatedError(data);
                    });
                } else if (res.status === 200) {
                    this.setState({
                        gotError: false,
                        isLoading: false
                    });
                    res.json().then(data => {
                        this.showSuccessMessage("Your password changed successfully");
                        this.props.history.push("/");
                    });
                }
            });
    };

    _renderLogin = () => {
        return (
            <div
                onKeyDown={this.handleLoginKey}
                className={"col-12 col-sm-12 col-md-8 col-lg-6"}>
                <div className="card">
                    <article className="card-body">
                        <h4 className="card-title text-center mb-4 mt-1">Reset Password</h4>
                        <hr/>
                        <form>

                            <div className="form-group">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <FaLock/>
                                                </span>
                                    </div>
                                    <input
                                        value={this.state.newPassword}
                                        onChange={(e) => this.setState({newPassword: e.target.value})}
                                        className="form-control" placeholder="New Password" type="password">

                                    </input>
                                </div>

                            </div>

                            <div className="form-group">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <FaLock/>
                                                </span>
                                    </div>
                                    <input
                                        value={this.state.newPasswordRepeat}
                                        onChange={(e) => this.setState({newPasswordRepeat: e.target.value})}
                                        className="form-control" placeholder="New Password Repeat" type="password">

                                    </input>
                                </div>

                            </div>

                            <div className="form-group">
                                <button
                                    type={"button"}
                                    onClick={this.handleOk}
                                    disabled={this.state.isLoading}
                                    className="btn btn-primary btn-block">{this.state.isLoading ? (
                                    <div className='sweet-loading'>
                                        <PulseLoader
                                            css={override}
                                            sizeUnit={"px"}
                                            color={"white"}
                                            size={7}
                                            loading={true}
                                        />
                                    </div>) : "Confirm"}
                                </button>
                            </div>
                        </form>
                    </article>
                </div>
            </div>
        )
    };


    render() {
        return (
            <div className={"container justify-content-center"} style={{height: "100%"}}>
                <div className={"row justify-content-center"}>
                    {this._renderLogin()}
                </div>
            </div>
        )
    }
}

export default withRouter(ResetPassword);

