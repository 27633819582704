import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import {
  Button,
  FormInput,
  FormTextarea,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormCheckbox,
  Col,
  Row,
  Container,
} from "shards-react";
import Dropzone from "react-dropzone";
import {
  IoIosClose,
  IoIosPerson,
  IoIosArrowDroprightCircle,
  IoIosArrowDropleftCircle,
  IoMdAttach,
} from "react-icons/io";
import Modal from "react-responsive-modal";

import { FaRegEdit } from "react-icons/fa";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/core";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import ChangeImage from "./ChangeImage";
import { setProfilePic } from "../../actions/index";
import { connect } from "react-redux";
import { API_URL } from "../../constants";
import { authHeader } from "../../actions/AuthHeader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

/**
 * Personal class is used to change current user profile details.
 */
class MeitaAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      firstName: "",
      lastName: "",
      bio: "",
      occupation: "",
      coreSkill: "",
      location: "",
      sendingData: false,
      showModal: false,
      modalSpinner: false,
      selectedSex: "",
      user_permission: [],
      user_permission_data: false,
      openAttachModal: false,
      files: [],
      deleteFiles: [],
      actionType: "add",
      email_notif: {
        new_vote_submitted_to_my_decision: false,
        decision_can_be_finalized: false,
        invited_to_a_decision_by_friend: false,
        decision_results_are_published: false,
        new_comment: false,
      },
      selected: "selected",
    };
  }

  componentDidMount = () => {
    console.log("ads", this.props);
  };

  // get_user_permissions = () => {
  //   const sessoin_id = this.props.sessionID;

  //   const endpoint = `users/${sessoin_id}/user_permission`;
  //   const options = {
  //     method: "GET",
  //   };
  //   fetch(`${API_URL}/${endpoint}`, {
  //     ...options,
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: authHeader(),
  //     },
  //   })
  //     .then((data) => data.json())
  //     .then((res) => {
  //       console.log("user_permission", res);
  //       res.permissions.map((item, id) => {
  //         let { email_notif } = this.state;

  //         email_notif[item.permission] = true;
  //       });
  //       // if (res.data === "voted") {
  //       this.setState({
  //         user_permission: res,
  //         user_permission_data: true,
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  sendData = () => {
    if (this.state.files.length > 0) {
      let formData = new FormData();

      const element = this.state.files[0];
      console.log(element);
      formData.append("file", element[0], element[0].name);

      let endpoint;

      if (this.state.actionType == "add")
        endpoint = `users/${this.props.data.username}/add_attr_by_file/`;
      else endpoint = `users/${this.props.data.username}/delete_attr_by_file/`;

      const options1 = {
        method: "POST",
        body: formData,
      };

      fetch(`${API_URL}/${endpoint}`, {
        ...options1,
        headers: {
          // 'Accept': 'application/json',
          // 'Content-Type': 'multipart/form-data',
          Authorization: authHeader(),
        },
      })
        .then((data) => data.json())
        .then((data) => {
          console.log(data);
          this.setState({
            files: [],
          });
        })
        .catch((error) => {
          console.log("asdasd", error);
        });
    } else {
      this.showErrorMessage("add file to send ");
    }
  };

  call_add_poll = () => {
    const sessoin_id = this.props.sessionID;

    const endpoint = `users/${sessoin_id}/set_exist_user_poll_copy`;
    const options = {
      method: "GET",
    };
    fetch(`${API_URL}/${endpoint}`, {
      ...options,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((data) => data.json())
      .then((res) => {
        this.showSuccessMessage('add poll is in progress')
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showErrorMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

  showSuccessMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

  refactorProfileUrl = (url) => {
    console.log("url", url);
    if (url && String(url).substring(0, 4) === "http") {
      return url;
    } else {
      console.log("url", url);
      return `${API_URL}/uploads/${url}`;
    }
  };
  componentDidMount() {
    this.setState({
      userName: this.props.data["username"],
      firstName: this.props.data["first_name"] || "",
      lastName: this.props.data["last_name"] || "",
      bio: this.props.data["bio"] || "",
      location: this.props.data["location"] || "",
      occupation: this.props.data["occupation"] || "",
      coreSkill: this.props.data["core_skill"] || "",
      selectedSex: this.props.data.sex,
    });
  }

  applyChange = () => {
    if (this.state.userName.length === 0) {
      this.showErrorMessage("Username may not be empty");
      return;
    }
    this.setState({
      sendingData: true,
    });
    if (this.state.firstName !== "" && this.state.lastName !== "") {
      let data = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        username: this.state.userName,
        bio: this.state.bio,
        location: this.state.location,
        occupation: this.state.occupation,
        core_skill: this.state.coreSkill,
        sex: this.state.selectedSex,
      };
      this.props.setUserProfileInfo(this.props.sessionID, data).then((data) => {
        this.setState({
          sendingData: false,
        });
        this.showSuccessMessage("Updated !");
      });
    } else {
      this.setState({
        sendingData: false,
      });
      this.showErrorMessage("please complete first Name and last Name field");
    }
  };

  onFocusInput = (e, labelElement) => {
    labelElement.style.color = "#008AFF";
  };

  onBlurInput = (e, labelElement) => {
    labelElement.style.color = "#5a6169";
  };

  showChangePictureModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  toggleAttachment = () => {
    this.setState({
      openAttachModal: !this.state.openAttachModal,
    });
  };
  removeAttachment = (text) => {
    let newAttachment = this.state.files.filter((item) => {
      return item[0]["name"] !== text;
    });
    this.setState({
      files: newAttachment,
    });
  };
  _renderAttachments = () => {
    let items = this.state.files.map((item, index) => {
      console.log("name", item);
      return (
        <div
          className={
            "select-attribute-item my-2 d-flex justify-content-between"
          }
          key={index}
        >
          <p
            className={"m-0 p-0 d-flex align-items-center"}
            style={{ fontWeight: "300" }}
          >
            <IoMdAttach className={"mr-2 text-orange font-size-20"} />
            {item[0]["name"]}
          </p>
          <Button
            outline
            pill
            theme="warning"
            size={"sm"}
            onClick={() => this.removeAttachment(item[0]["name"])}
            className={"btn btn-success-custom p-0 m-0"}
          >
            <IoIosClose className={"font-size-22"} />
          </Button>
        </div>
      );
    });
    return items;
  };

  addDataAttachment = () => {
    this.setState({
      // attachments: [
      //     ...this.state.attachments,
      //     this.state.attachmentTempText
      // ],
      openAttachModal: !this.state.openAttachModal,
    });
  };

  refactorUrl = (url) => {
    console.log("url", url);

    if (url) {
      return url["profile_pic"];
    }
    return null;
  };

  render() {
    console.log(this.state);
    return (
      <Fragment>
        <Modal
          open={this.state.openAttachModal}
          animationDuration={100}
          onClose={this.toggleAttachment}
          center
        >
          <div className={"modal-responsive"}>
            <h3>
              Add Attachment{" "}
              <span
                className={"ml-2"}
                style={{ color: "red", fontSize: "15px" }}
              >
                {" "}
                (Max:1 , 2MB)
              </span>
            </h3>
            <hr />
            <div>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  if (acceptedFiles.length === 0) {
                    return;
                  } else if (this.state.files.length > 0) {
                    // here i am checking on the number of files
                    return this.showErrorMessage("upload maximum one file "); // here i am using react toaster to get some notifications. don't need to use it
                  } else {
                    this.setState({
                      files: [...this.state.files, acceptedFiles],
                    });
                  }
                }}
                minSize={0}
                maxSize={2097152}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragReject,
                  rejectedFiles,
                }) => {
                  // console.log(getInputProps)
                  const isFileTooLarge =
                    rejectedFiles.length > 0 && rejectedFiles[0].size > 2097152;
                  return (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      {!isDragActive && "Click here or drop a file to upload!"}
                      {isDragActive &&
                        !isDragReject &&
                        "Drop it like it's hot!"}
                      {isDragReject && "File type not accepted, sorry!"}
                      {isFileTooLarge && (
                        <div className="text-danger mt-2">
                          File is too large.
                        </div>
                      )}
                    </div>
                  );
                }}
              </Dropzone>
            </div>
            <hr />
            <div>
              <Button theme={"primary"} onClick={this.addDataAttachment}>
                OK
              </Button>
            </div>
          </div>
        </Modal>
        <div
          className={"form-ui p-3"}
          style={{
            backgroundColor: "white",
            marginTop: "70px",
            paddingBottom: "50px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <hr />
          <Container>
            {" "}
            {this.props.data.trial ? (
              this.props.data.trial.user_type === "Free" ? (
                <Row>
                  <Col xs="12" sm="12" md="12" lg="12">
                    <div className={"  my-1"}>
                      <h3
                        className={"form-ui-label font-size-13 fw-600"}
                        ref={(element) => (this.elementFirstName = element)}
                      >
                        Your account type:
                        <span style={{ marginLeft: "10px" }}>
                          Free
                          <a
                            href="/pricing"
                            className={"orange"}
                            style={{
                              color: "orange",
                              fontSize: "15px",
                              marginLeft: "10px",
                            }}
                          >
                            Upgrade to Premium
                          </a>
                        </span>
                      </h3>
                    </div>
                  </Col>
                </Row>
              ) : this.props.data.trial.user_type === "Premium" ? (
                <Row>
                  <Col xs="12" sm="12" md="12" lg="12">
                    <div className={"  my-1"}>
                      <h3
                        className={"form-ui-label font-size-13 fw-600"}
                        ref={(element) => (this.elementFirstName = element)}
                      >
                        Your account type:
                        <span style={{ marginLeft: "10px" }}>Premium</span>
                        <br />
                        <h5
                          className={"fw-600 font-size-12"}
                          style={{ color: "red", marginTop: "5px" }}
                        >
                          {" "}
                          If you want to Pause/Cancel your Premium membership
                          please send email to:
                          <u style={{ color: "red" }}> info@myMeriTa.com</u>
                        </h5>
                      </h3>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs="12" sm="12" md="12" lg="12">
                    <div className={"  my-1"}>
                      <h3
                        className={"form-ui-label font-size-13 fw-600"}
                        ref={(element) => (this.elementFirstName = element)}
                      >
                        Your account type:
                        <span style={{ marginLeft: "10px" }}>
                          {this.props.data.trial.user_type}
                        </span>
                      </h3>

                      <h5
                        className={"font-size-14"}
                        style={{ marginTop: "5px" }}
                      >
                        Oraganization:{" "}
                        <span style={{ marginLeft: "10px" }}>
                          {this.props.data.org.org_name}.
                        </span>
                      </h5>
                      <h5
                        className={"fw-600 font-size-12"}
                        style={{ color: "red", marginTop: "2px" }}
                      >
                        {" "}
                        MeriTa admin in your organization is:
                      </h5>
                      <h5 className={" fw-600 font-size-12"} style={{}}>
                        {this.props.data.org.org_admin.first_name}{" "}
                        {this.props.data.org.org_admin.last_name}{" "}
                        <span style={{ marginLeft: "10px" }}>
                          @{this.props.data.org.org_admin.username}{" "}
                        </span>
                        <span
                          style={{
                            marginLeft: "20px",
                            textDecoration: "underline",
                          }}
                        >
                          {this.props.data.org.org_admin.email}
                        </span>
                        <span style={{ marginLeft: "10px" }}>
                          <Button>
                            <a
                              href={`/people/users/${this.props.data.org.org_admin.username}`}
                            >
                              Profile
                            </a>
                          </Button>
                        </span>
                      </h5>
                      <h5
                        className={"fw-600 font-size-12"}
                        style={{ color: "red", marginTop: "2px" }}
                      >
                        {" "}
                        If you have any queries please contact above person. If
                        you still cant't solve the problem you may contact:
                        <u style={{ color: "red" }}> info@myMeriTa.com</u>
                      </h5>
                    </div>
                  </Col>
                </Row>
              )
            ) : undefined}
            {this.props.data.is_superuser ? (
              <>
                <hr />
                <Row>
                  <Col xs="12" sm="12" md="12" lg="12">
                    <div className={"  my-1"}>
                      <Button>
                        <a href={`${API_URL}/export/csv`}>download users csv</a>
                      </Button>
                      <Button className={"ml-2"}>
                        <a href={`${API_URL}/export/csv/attr`}>
                          download attributes csv
                        </a>
                      </Button>
                      <Button className={"ml-2"} onClick={this.call_add_poll}>
                        add copy poll to exist user
                      </Button>
                    </div>
                  </Col>
                </Row>
              </>
            ) : undefined}
            {this.props.data.is_superuser ? (
              <>
                <hr />
                <div className={"row mb-3"}>
                  <div className={"col-12 col-md-6 my-1"}>
                    <label className={"form-ui-label font-size-13 fw-600"}>
                      action type
                    </label>
                    <InputGroup seamless>
                      <FormSelect
                        onChange={(e) =>
                          this.setState({ actionType: e.target.value })
                        }
                        value={this.state.actionType}
                      >
                        <option value="" disabled selected>
                          Select your Type
                        </option>
                        <option value="delete">delete attr</option>
                        <option value="add">add attr</option>
                      </FormSelect>
                    </InputGroup>
                  </div>
                </div>
                <div className={"select-attribute-wrapper"}>
                  <div className={"select-attribute-body"}>
                    {this.state.files.length > 0 ? null : (
                      <div className={"d-flex justify-content-center"}>
                        <p style={{ fontWeight: 100 }} className={"my-4"}>
                          Add your Attachments for add/delete attributes
                        </p>
                      </div>
                    )}
                    {this._renderAttachments()}
                  </div>
                  <div
                    className={
                      "select-attribute-footer mt-2 d-flex justify-content-end"
                    }
                  >
                    <Button theme="primary" onClick={this.toggleAttachment}>
                      Add
                    </Button>
                    <Button theme="primary" onClick={this.sendData}>
                      Send
                    </Button>
                  </div>
                </div>
              </>
            ) : undefined}
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(connect(null, { setProfilePic })(MeitaAccount));
