import * as Constant from "../constants/index";
import {combineReducers} from "redux";

const votingFirstStepInitialState = {
    data: [],
    isFetching: "",
    getError: "",
    errorText: ""
};

const setNewData = (state = [], action) => {
    let newState = state.map((item, index) => {
        return {...item, ...action["data"][index]}
    });
    return newState;
};

export const FirstStep = (state = votingFirstStepInitialState, action) => {
    switch (action.type) {
        case Constant.UPDATE_VOTING_STEP_1:
            return {
                ...state,
                isFetching: false,
                getError: false,
                errorText: "",
                data: setNewData(state.data, action)
            };
        case Constant.REQUEST_VOTING_INFO_STEP_1:
            return {
                ...state,
                isFetching: true,
                getError: false,
                errorText: ""
            };
        case Constant.RECEIVE_VOTING_INTO_STEP_1:
            return {
                ...state,
                isFetching: false,
                getError: false,
                errorText: false,
                data: action.data
            };
        case Constant.FAILED_FETCH_VOTING_INFO_STEP_1:
            return {
                ...state,
                isFetching: false,
                getError: true,
                errorText: action.err
            };
        default:
            return state
    }
};

const votingSecondStepInitialState = {
    data: [],
    isFetching: "",
    getError: "",
    errorText: ""
};

export const SecondStep = (state = votingSecondStepInitialState, action) => {
    switch (action.type) {
        case Constant.REQUEST_VOTING_INFO_STEP_2:
            return {
                ...state,
                isFetching: true,
                getError: false,
                errorText: ""
            };
        case Constant.RECEIVE_VOTING_INFO_STEP_2:
            return {
                ...state,
                isFetching: false,
                getError: false,
                errorText: false,
                data: action.data
            };
        case Constant.FAILED_FETCH_VOTING_INFO_STEP_2:
            return {
                ...state,
                isFetching: false,
                getError: true,
                errorText: action.err
            };
        default:
            return state
    }
};

const votingThirdStepInitialState = {
    data: [],
    isFetching: "",
    getError: "",
    errorText: ""
};

export const ThirdStep = (state = votingThirdStepInitialState, action) => {
    switch (action.type) {
        case Constant.REQUEST_VOTING_INFO_STEP_3:
            return {
                ...state,
                isFetching: true,
                getError: false,
                errorText: ""
            };
        case Constant.RECEIVE_VOTING_INFO_STEP_3:
            return {
                ...state,
                isFetching: false,
                getError: false,
                errorText: false,
                data: action.data
            };
        case Constant.FAILED_FETCH_VOTING_INFO_STEP_3:
            return {
                ...state,
                isFetching: false,
                getError: true,
                errorText: action.err
            };
        default:
            return state
    }
};

export default combineReducers({
    FirstStep,
    SecondStep,
    ThirdStep
})