import React, {Component} from "react";
import {withRouter, Switch, Route, Redirect} from "react-router";
import {connect} from "react-redux";
import Attr from "../components/Attribute/Attr"
import FavouritesAttr from '../components/Attribute/FavouritesAttr'
import OrgAttr from '../components/Attribute/OrgAttr'
/**
 * Attribute class renders Attributes.
 */
class Attribute extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={"/attribute"} render={() => <Redirect to="/attribute/t"/>}/>
                <Route exact path={"/attribute/t"} component={Attr}/>
                <Route exact path={"/attribute/favourites"} component={FavouritesAttr}/>
                <Route exact path={"/attribute/org"} component={OrgAttr}/>
            </Switch>
        )
    }
}

export default withRouter(connect()(Attribute));