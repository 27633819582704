import React, {Component} from "react";
import StepWizard from 'react-step-wizard';
import Nav from "../../layout/UI/StepNav";
import '../../layout/UI/StepNav/transition.css';
import FirstStep from "./VotingStep/FirstStep";
import SecondStep from "./VotingStep/SecondStep";
import ThirdStep from "./VotingStep/ThirdStep";
import {withRouter,} from 'react-router-dom';
import {Redirect} from "react-router";
import {connect} from "react-redux";


/**
 * VotingStep class renders voting steps.
 */
class VotingStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 1,
            transitions: {
                enterRight: 'animated enterRight',
                enterLeft: 'animated enterLeft',
                exitRight: 'animated exitRight',
                exitLeft: 'animated exitLeft}',
                intro: 'transitions.animated intro',
            },
        };
    }

    // Do something on step change
    onStepChange = (stats) => {
        this.setState({
            activeStep: stats.activeStep
        });

    };

    setInstance = SW => this.setState({SW});

    render() {
        if (!this.props.active_state) {
            return <Redirect to={`/decision/polls/${this.props.match.params["id"]}`}/>
        }
        return (
            <div className={"poll-wrapper mt-1"} style={{backgroundColor: "#fafafa"}}>
                <div className={"poll-data-wrapper container"}>
                    <StepWizard
                        onStepChange={this.onStepChange}
                        transitions={this.state.transitions}
                        nav={<Nav/>}
                        instance={this.setInstance}
                    >
                        {this.state.activeStep === 1 ? (
                            <FirstStep activeStep={this.state.activeStep}/>
                        ) : <div></div>}
                        {this.state.activeStep === 2 ? (
                            <SecondStep activeStep={this.state.activeStep}/>
                        ) : <div></div>}
                        {this.state.activeStep === 3 ? (
                            <ThirdStep activeStep={this.state.activeStep}/>
                        ) : <div></div>}

                    </StepWizard>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {...state.selectedDecision.data}
};

export default withRouter(connect(mapStateToProps, null)(VotingStep));