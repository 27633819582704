import * as Constants from "../constants/index";
import {authHeader} from "./AuthHeader";
import {API_URL} from '../constants'
// const API_URL = 'http://localhost:8000'

// --------------------------------- action for get decision by Id
const requestDecisionById = () => ({
    type: Constants.REQUEST_DECISION_BY_ID
});

const receiveDecisionById = (id, json) => ({
    type: Constants.RECEIVE_DECISION_BY_ID,
    id,
    decision: json,
    receivedAt: Date.now()
});

const failedFetchDecisionById = (err) => ({
    type: Constants.FAILED_FETCH_DECISION_BY_ID,
    err
});
/**
 * Fetch details of a decision by given id.
 * @param {integer} id - The id of decision
 */
export const fetchDecisionById = (id) => {
    return dispatch => {
        dispatch(requestDecisionById());
        return fetch(`${API_URL}/polls/${id}/`, {
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            })
        })
            .then(response => response.json())
            .then(date => {
                dispatch(receiveDecisionById(id, date))
            })
            .catch(err => dispatch(failedFetchDecisionById(err)))
    }
};

// --------------------------------- action for get alternatives decision

const requestDecisionAlternativesById = () => ({
    type: Constants.REQUEST_DECISION_ALTERNATIVES_BY_ID
});

const receiveDecisionAlternativesById = (data) => ({
    type: Constants.RECEIVE_DECISION_ALTERNATIVES_BY_ID,
    data
});

const failedDecisionAlternativesById = (err) => ({
    type: Constants.FAILED_DECISION_ALTERNATIVES_BY_ID,
    err
});
/**
 * Fetch alternatives of a decision by given id.
 * @param {integer} id - The id of decision
 */
export const fetchDecisionAlternativesById = (id) => {
    return dispatch => {
        dispatch(requestDecisionAlternativesById());
        return fetch(`${API_URL}/polls/${id}/alternatives/`, {
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            })
        })
            .then(response => response.json())
            .then(date => {
                dispatch(receiveDecisionAlternativesById(date))
            })
            .catch(err => dispatch(failedDecisionAlternativesById(err)))
    }
};

// --------------------------------- action for set Decision making form data
/**
 * add the data of form to store
 * @param {object} data - the data of form from <StepOne/>, <StepTwo/> components
 */
export const setFormData = (formData) => ({
    type: Constants.SET_FORM_DATA,
    formData
});

// --------------------------------- action for get all decision
const requestDecisions = () => ({
    type: Constants.REQUEST_ALL_DECISION,
});

const receiveAllDecision = (json) => ({
    type: Constants.RECEIVE_ALL_DECISION,
    decisions: json,
    receivedAt: Date.now()
});

const failedFetchDecision = (err) => ({
    type: Constants.FAILED_FETCH_DECISION,
    err
});
/**
 * fetch all decision
 * @param {string} apitUrl - url
 */
export const fetchDecision = (apiUrl) => {
    return dispatch => {
        dispatch(requestDecisions());
        return fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            },
        })
            .then(response => response.json())
            .then(data => {
                dispatch(receiveAllDecision(data))
            })
            .catch(err => failedFetchDecision(err))
    }
};


// --------------------------------- action for get Voting step 1 info
const requestVotingStep1 = () => ({
    type: Constants.REQUEST_VOTING_INFO_STEP_1,
});

const receiveVotingStep1 = (data) => ({
    type: Constants.RECEIVE_VOTING_INTO_STEP_1,
    data
});

const failedFetchVotingStep1 = (err) => ({
    type: Constants.FAILED_FETCH_VOTING_INFO_STEP_1,
    err
});
/**
 * fetch user normal votes of a decision.
 * also used for checking whether user normal votes of a decision is already voted or not.if decision is already voted, the fetched data must be shown.
 * @param {integer} id - id of decision
 */
export const fetchVotingStep1 = (id) => {
    return dispatch => {
        dispatch(requestVotingStep1());
        return fetch(`${API_URL}/polls/${id}/usernormalvotes/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            },
        })
            .then(res => res.json())
            .then(data => dispatch(receiveVotingStep1(data)))
            .catch(err => dispatch(failedFetchVotingStep1(err)))
    }
};

// --------------------------------- action for get Voting step 2 info
export const requestVotingStep2 = () => ({
    type: Constants.REQUEST_VOTING_INFO_STEP_2,
});
export const receiveVotingStep2 = (data) => ({
    type: Constants.RECEIVE_VOTING_INFO_STEP_2,
    data
});
export const failedFetchVotingStep2 = (err) => ({
    type: Constants.FAILED_FETCH_VOTING_INFO_STEP_2,
    err
});

/**
 * fetch user merit votes of a decision.
 * also used for checking whether user merit votes of decision is already voted or not.if decision is already voted, the fetched data must be shown.
 * @param {integer} id - id of decision
 */
export const fetchVotingStep2 = (id) => {
    return dispatch => {
        dispatch(requestVotingStep2());
        return fetch(`${API_URL}/polls/${id}/usermeritavotes/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            },
        })
            .then(res => res.json())
            .then(data => dispatch(receiveVotingStep2(data)))
            .catch(err => dispatch(failedFetchVotingStep2(err)))
    }
};

// --------------------------------- action for get Voting step 2 info
export const requestVotingStep3 = () => ({
    type: Constants.REQUEST_VOTING_INFO_STEP_3,
});
export const receiveVotingStep3 = (data) => ({
    type: Constants.RECEIVE_VOTING_INFO_STEP_3,
    data
});
export const failedFetchVotingStep3 = (err) => ({
    type: Constants.FAILED_FETCH_VOTING_INFO_STEP_3,
    err
});
/**
 * fetch user alternative votes of a decision.
 * also used for checking whether user alternative votes of decision is already voted or not.if decision is already voted, the fetched data must be shown.
 * @param {integer} id - id of decision
 */
export const fetchVotingStep3 = (id) => {
    return dispatch => {
        dispatch(requestVotingStep3());
        return fetch(`${API_URL}/polls/${id}/useralternativevotes/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            },
        })
            .then(res => res.json())
            .then(data => dispatch(receiveVotingStep3(data)))
            .catch(err => dispatch(failedFetchVotingStep3(err)))
    }
};


// updating voting form step 1

export const updateVotingFormFirstStep = (data) => ({
    type: Constants.UPDATE_VOTING_STEP_1,
    data
});

// GET USER PROFILE DETAILS

const requestUserProfile = () => ({
    type: Constants.REQUEST_USER_PROFILE
});

const receiveUserProfile = (data) => ({
    type: Constants.RECEIVE_USER_PROFILE,
    data
});

const failedFetchUserProfile = (err) => ({
    type: Constants.FAILED_FETCH_USER_PROFILE,
    err
});
/**
 * get user's profile details
 * @param {string} id - username
 */
export const fetchUserProfile = (username) => {
    return dispatch => {
        dispatch(requestUserProfile());
        return fetch(`${API_URL}/users/${username}/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            },
        })
            .then(res => res.json())
            .then(data => dispatch(receiveUserProfile(data)))
            .catch(err => dispatch(failedFetchUserProfile(err)))
    }
};

// --------------------------------- action for get all users

const requestAllUsers = () => ({
    type: Constants.REQUEST_ALL_USERS
});

const receiveAllUsers = (data) => ({
    type: Constants.RECEIVE_ALL_USERS,
    data
});

const failedFetchAllUsers = (err) => ({
    type: Constants.FAILED_FETCH_ALL_USERS,
    err
});
/**
 * get all users of project
 */
export const fetchAllUsers = () => {
    return dispatch => {
        dispatch(requestAllUsers());
        return fetch(`${API_URL}/users/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            },
        })
            .then(res => res.json())
            .then(data => dispatch(receiveAllUsers(data)))
            .catch(err => dispatch(failedFetchAllUsers(err)))
    }
};

// --------------------------------- action for get all attribute

const requestAllAttribute = () => ({
    type: Constants.REQUEST_ALL_ATTRIBUTES
});

const receiveAllAttribute = (data) => ({
    type: Constants.RECEIVE_ALL_ATTRIBUTES,
    data
});

const failedFetchAllAttribute = (err) => ({
    type: Constants.FAILED_FETCH_ALL_ATTRIBUTES,
    err
});
/**
 * get all attribute of project
 */
export const fetchAllAttribute = () => {
    return dispatch => {
        dispatch(requestAllAttribute());
        return fetch(`${API_URL}/attributes/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            },
        })
            .then(res => res.json())
            .then(data => dispatch(receiveAllAttribute(data)))
            .catch(err => dispatch(failedFetchAllAttribute(err)))
    }
};

// --------------------------------- action for get all attribute

const requestUserAttribute = () => ({
    type: Constants.REQUEST_USER_ATTRIBUTES
});

const receiveUserAttribute = (data) => ({
    type: Constants.RECEIVE_USER_ATTRIBUTES,
    data
});

const failedFetchUserAttribute = (err) => ({
    type: Constants.FAILED_FETCH_USER_ATTRIBUTES,
    err
});

/**
 * get user's top attributes
 * @param {string} id - username
 */
export const fetchUserAttribute = (id) => {
    return dispatch => {
        dispatch(requestUserAttribute());
        return fetch(`${API_URL}/users/${id}/attributes/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            },
        })
            .then(res => res.json())
            .then(data => dispatch(receiveUserAttribute(data)))
            .catch(err => dispatch(failedFetchUserAttribute(err)))
    }
};


// --------------------------------- action for get all attribute

const requestUserfollowings = () => ({
    type: Constants.REQUEST_USER_FOLLOWINGS
});

const receiveUserfollowings = (data) => ({
    type: Constants.RECEIVE_USER_FOLLOWINGS,
    data
});

const failedFetchUserfollowings = (err) => ({
    type: Constants.FAILED_FETCH_USER_FOLLOWINGS,
    err
});
/**
 * get user's following
 * @param {string} id - username
 */
export const fetchUserfollowings = (id) => {
    return dispatch => {
        dispatch(requestUserfollowings());
        return fetch(`${API_URL}/users/${id}/followings/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            },
        })
            .then(res => res.json())
            .then(data => dispatch(receiveUserfollowings(data)))
            .catch(err => dispatch(failedFetchUserfollowings(err)))
    }
};

const requestUserfollowers = () => ({
    type: Constants.REQUEST_USER_FOLLOWERS
});

const receiveUserfollowers = (data) => ({
    type: Constants.RECEIVE_USER_FOLLOWERS,
    data
});

const failedFetchUserfollowers = (err) => ({
    type: Constants.FAILED_FETCH_USER_FOLLOWERS,
    err
});

/**
 * get user's followers
 * @param {string} id - username
 */
export const fetchUserfollowers = (id) => {
    return dispatch => {
        dispatch(requestUserfollowers());
        return fetch(`${API_URL}/users/${id}/followers/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            },
        })
            .then(res => res.json())
            .then(data => dispatch(receiveUserfollowers(data)))
            .catch(err => dispatch(failedFetchUserfollowers(err)))
    }
};


// --------------------------------- action for get user session


const requestUserSession = () => ({
    type: Constants.REQUEST_USER_SESSION
});

const receiveUserSession = (data) => ({
    type: Constants.RECEIVE_USER_SESSION,
    data
});

const failedFetchUserSession = (err) => ({
    type: Constants.FAILED_FETCH_USER_SESSION,
    err
});

/**
 * fetch current user info
 */
export const fetchUserSession = () => {
    return dispatch => {
        dispatch(requestUserSession());
        return fetch(`${API_URL}/users/whoami/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            },
        })
            .then(res => res.json())
            .then(data => dispatch(receiveUserSession(data)))
            .catch(err => dispatch(failedFetchUserSession(err)))
    }
};


// --------------------------------- action for get user notification

const requestUserNotification = () => ({
    type: Constants.REQUEST_USER_NOTIFICATION
});

const receiveUserNotification = (data) => ({
    type: Constants.RECEIVE_USER_NOTIFICATION,
    data
});

const failedFetchUserNotification = (err) => ({
    type: Constants.FAILED_FETCH_USER_NOTIFICATION,
    err
});

/**
 * get all notification of a user
 * @param {string} id - username
 */
export const fetchUserNotification = (id) => {
    return dispatch => {
        dispatch(requestUserNotification());
        return fetch(`${API_URL}/users/${id}/notifications/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            },
        })
            .then(res => res.json())
            .then(data => dispatch(receiveUserNotification(data)))
            .catch(err => dispatch(failedFetchUserNotification(err)))
    }
};



/**
 * finalize a poll
 * @param {integer} pollID - the id of decision
 */
export const finalizeDecision = (pollID) => async (dispatch) =>
    new Promise((resolve, reject) => {
        const endpoint = `polls/${pollID}/finalize/`;
        const options = {
            method: 'GET',
        };
        fetch(`${API_URL}/${endpoint}`, {
            ...options,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            }
        })
            .then((data) => data.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });

/**
 * get alternatives of finalized poll
 * @param {integer} pollID - the id of decision
 */
export const getFinalizedPollAlternative = (pollID) => async (dispatch) =>
    new Promise((resolve, reject) => {
        const endpoint = `polls/${pollID}/final_alternatives/`;
        const options = {
            method: 'GET',
        };
        fetch(`${API_URL}/${endpoint}`, {
            ...options,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            }
        })
            .then((data) => data.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });


/**
 * get user profile info
 * @param {string} username - username
 */
export const getUserProfileInfo = (username) => async (dispatch) =>
    new Promise((resolve, reject) => {
        const endpoint = `users/${username}/`;
        const options = {
            method: 'GET',
        };
        fetch(`${API_URL}/${endpoint}`, {
            ...options,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            }
        })
            .then((data) => data.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });


/**
 * change user profile details
 * @param {string} username - username
 * @param {object} data - 
 */
export const setUserProfileInfo = (username, data) => async (dispatch) =>
    new Promise((resolve, reject) => {
        const endpoint = `users/${username}/`;
        const options = {
            method: 'PATCH',
            body: JSON.stringify(data)
        };
        fetch(`${API_URL}/${endpoint}`, {
            ...options,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            }
        })
            .then((data) => data.json())
            .then((data) => {
                console.log(data);
                dispatch({
                    type: Constants.CHANGE_CURRENT_USER_EMAIL,
                    email: data["email"]
                });
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });

/**
 * set attachment
 * @param {object} file - attachment
 * @param {integer} pollID - the id of decision
 */
export const setPollAttachment = (pollId, file) => async (dispatch) =>
    new Promise((resolve, reject) => {
        const endpoint = `polls/${pollId}/set_attachments/`;
        console.log("file",file)
        let formData = new FormData();
        formData.set("poll", "test");

        formData.append("attachment", file , file.name);
        console.log("formdata" . formData)
        const options = {
            method: 'POST',
            body: JSON.stringify(formData)
        };
        console.log("op" . options)

        fetch(`${API_URL}/${endpoint}`, {
            ...options,
            headers: {
                // 'Accept': 'application/json',
                // 'Content-Type': 'multipart/form-data',
                'Authorization': authHeader()
            }
        })
            .then((data) => data.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject("asdasd",error);
            });
    });


/**
 * delete an attribute from the project
 * @param {string} attr - attribute name
 */
export const deleteAttribute = (attr) => async (dispatch) =>
    new Promise((resolve, reject) => {
        const endpoint = `attributes/${attr}/`;
        const options = {
            method: 'DELETE',
        };
        fetch(`${API_URL}/${endpoint}`, {
            ...options,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            }
        })
            .then((data) => {
                if (data.status === 204) {
                    return dispatch({
                        type: Constants.DELETE_ATTRIBUTE,
                        attr
                    });
                }
                if (data.status === 424) {
                    return data.json().then(data => resolve(data))
                }
            })
            .catch((error) => {
                reject(error);
            });
    });


/**
 * create attribute
 * @param {object} info - attribute name and field
 */
export const createAttribute = (info) => async (dispatch) =>
    new Promise((resolve, reject) => {
        const endpoint = `attributes/`;
        const options = {
            method: 'POST',
            body: JSON.stringify(info)
        };
        fetch(`${API_URL}/${endpoint}`, {
            ...options,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            }
        })
            .then((res) => {
                if (res.status === 400) {
                    res.json().then(data => reject(data));
                } else {
                    res.json().then(data => {
                        dispatch({
                            type: Constants.CREATE_ATTRIBUTE,
                            info
                        });
                        resolve(data);
                    })
                }
            })
            .catch((error) => {
                reject(error);
            });
    });


/**
 * set profile pic
 * @param {string} photo - photo
 * @param {string} username - username
 */
export const setProfilePic = (photo, username) => async (dispatch) =>
    new Promise(async (resolve, reject) => {
        // let blob = await fetch(photo)
        //     .then(r => r.blob()).then(blobFile => new File([blobFile], "image", {type: "image/png"}));
        // console.log(blob);
        const endpoint = `users/${username}/set_pic/`;
        let formData = new FormData();
        formData.append("profile_pic ", photo);
        const options = {
            method: 'POST',
            body: formData,
        };
        fetch(`${API_URL}/${endpoint}`, {
            ...options,
            headers: {
                // Accept: 'application/json',
                // 'Content-Type': 'multipart/form-data',
                'Authorization': authHeader()
            }
        })
            .then((data) => data.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });


/**
 * add comment to poll in last step of voting
 * @param {object} info - 
 */
export const addCommentToPoll = (info) => async (dispatch) =>
    new Promise((resolve, reject) => {
        const endpoint = `comment/`;
        const options = {
            method: 'POST',
            body: JSON.stringify(info)
        };
        fetch(`${API_URL}/${endpoint}`, {
            ...options,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            }
        })
            .then((data) => data.json())
            .then((data) => {
                return resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });


/**
 * get all comments of specific decision
 * @param {integer} pollID - the id of decision
 */
export const getCommentById = (pollID) => async (dispatch) =>
    new Promise((resolve, reject) => {
        const endpoint = `/polls/${pollID}/comments/`;
        const options = {
            method: 'GET',
        };
        fetch(`${API_URL}/${endpoint}`, {
            ...options,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            }
        })
            .then((data) => data.json())
            .then((data) => {
                return resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });


/**
 * block a user
 * @param {object} data - followed, follower
 */
export const BlockUser = (data) => async () =>
    new Promise((resolve, reject) => {
        const endpoint = `followers/unfollow/`;
        const options = {
            body: JSON.stringify(data),
            method: 'POST',
        };
        fetch(`${API_URL}/${endpoint}`, {
            ...options,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            }
        })
            .then((data) => resolve(data))
            .catch((error) => {
                reject(error);
            });
    });


/**
 * change password with old password
 * @param {object} data- old password, new password , new password repeat
 */
export const changePassword = (data) => async () =>
    new Promise((resolve, reject) => {
        const endpoint = `rest-auth/password-change/`;
        const options = {
            body: JSON.stringify(data),
            method: 'POST',
        };
        fetch(`${API_URL}/${endpoint}`, {
            ...options,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            }
        })
            .then((res) => resolve(res))
            .catch((error) => {
                reject(error);
            });
    });


/**
 * creator of a decision closes the decision so that nobody can votes.
 * @param {integer} pollID- the id of decision
 */
export const closeDecision = (pollId) => async () =>
    new Promise((resolve, reject) => {
        const endpoint = `polls/${pollId}/close/`;
        const options = {
            method: 'GET',
        };
        fetch(`${API_URL}/${endpoint}`, {
            ...options,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            }
        })
            .then((res) => resolve(res))
            .catch((error) => {
                reject(error);
            });
    });

/**
 * creator of a decision send invtiation to participant
 * @param {integer} pollID- the id of decision
 */
export const sendInvitation = (pollId) => async () =>
    new Promise((resolve, reject) => {
        const endpoint = `polls/${pollId}/send_invites/`;
        const options = {
            method: 'GET',
        };
        fetch(`${API_URL}/${endpoint}`, {
            ...options,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            }
        })
            .then((res) => resolve(res))
            .catch((error) => {
                reject(error);
            });
    });


/**
 * reject a poll which a user is invited 
 * @param {integer} pollID- the id of decision
 */
export const rejectInvitation = (pollId) => async () =>
    new Promise((resolve, reject) => {
        const endpoint = `polls/${pollId}/reject/`;
        const options = {
            method: 'GET',
        };
        fetch(`${API_URL}/${endpoint}`, {
            ...options,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            }
        })
            .then((res) => resolve(res))
            .catch((error) => {
                reject(error);
            });
    });

/**
 * send reset password email
 * @param {string} email - the email of user
 */
export const resetPassword = (email) => {
    const endpoint = `rest-auth/password/reset/`;
    const options = {
        method: 'POST',
        body: JSON.stringify({
            "email": email
        })
    };
    return fetch(`${API_URL}/${endpoint}`, {
        ...options,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    })
};

/**
 * set new password for the user who has forgot his password.
 * @param {string} uid - uid is generated by rest api
 * @param {string} token - token is generated by rest api
 * @param {object} data - new password
 */
export const setPassword = (uid, token, data) => {
    const endpoint = `rest-auth/password-reset/confirm/${uid}/${token}/`;
    const options = {
        method: 'POST',
        body: JSON.stringify(data)
    };
    return fetch(`${API_URL}/${endpoint}`, {
        ...options,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    })
};
/**
 * delete a poll
 * @param {integer} pollID - the id of a decision
 */
export const deletePoll = (pollID) => {
    const endpoint = `polls/${pollID}/`;
    const options = {
        method: 'DELETE',
    };
    return fetch(`${API_URL}/${endpoint}`, {
        ...options,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    })
};
/**
 * checks if a decision can be finalized. 
 * @param {integer} pollID - the id of a decision
 */
export const checkFinalizeStatus = (pollId) => {
    const endpoint = `polls/${pollId}/finalize/`;
    const options = {
        method: 'GET',
    };
    return fetch(`${API_URL}/${endpoint}`, {
        ...options,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    })
};

export const partialResult = (pollId) => {
    const endpoint = `polls/${pollId}/partial/`;
    const options = {
        method: 'GET',
    };
    return fetch(`${API_URL}/${endpoint}`, {
        ...options,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    })
};
/**
 * confirm the account of a user who has just signed up
 * @param {string} key - the key is sent to email of user
 */
export const confirmEmail = (key) => {
    const endpoint = `rest-auth/registration/verify-email/`;
    const options = {
        method: 'POST',
        body: JSON.stringify({
            "key": key
        })
    };
    return fetch(`${API_URL}/${endpoint}`, {
        ...options,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    })
};

/**
 * send an invitatation email to a user which is not signed up in project.
 * @param {string} email - the email of user
 */

// export const sendInvitationEmail = (email) => {
//     const endpoint = `rest-invitations/create-and-send/`;
//     const options = {
//         method: 'POST',
//         body: JSON.stringify({
//             "email": email
//         })
//     };
//     return fetch(`${API_URL}/${endpoint}`, {
//         ...options,
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//             'Authorization': authHeader()
//         }
//     })
// };
export const sendInvitationEmail = (user,email) => {
    const endpoint = `users/${user}/user_invite/`;
    const options = {
        method: 'POST',
        body: JSON.stringify({
            "email": email
        })
    };
    return fetch(`${API_URL}/${endpoint}`, {
        ...options,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    })
};

/**
 * add notification token (device token) of a user
 * @param {string} token - the new token which is given by firebase
 */
export const postNotificationToken = (token) => {
    return dispatch => {
        const endpoint = `devices/`;
        const options = {
            method: 'POST',
            body: JSON.stringify({
                "active": true,
                "registration_id": token,
                "type": "web"
            })
        };
        return fetch(`${API_URL}/${endpoint}`, {
            ...options,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            }
        })
    };
};

/**
 * refresh notification token (device token) of a user
 * @param {string} preToken - previous token which is available in localStorage
 * @param {string} newToken - the new token which is given by firebase
 */
export const putNotificationToken = (preToken, newToken) => {
    return dispatch => {
        const endpoint = `devices/${preToken}/`;
        const options = {
            method: 'PUT',
            body: JSON.stringify({
                "active": true,
                "type": "web",
                "registration_id": newToken,
            })
        };
        return fetch(`${API_URL}/${endpoint}`, {
            ...options,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authHeader()
            }
        })
    }
};

/**
 * get all user of projects
 * used for auto complete 
 */

export const getAllUser = () => async () => {
    const endpoint = `users/`;
    const options = {
        method: 'GET',
    };
    return fetch(`${API_URL}/${endpoint}`, {
        ...options,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    })
};

/**
 * follow a user
 * @param {string} follower - the username of current user
 * @param {string} followed - the username we want to follow
 */
export const followUser = (follower, followed) => async () => {
    const endpoint = `followers/`;
    const body = {
        "follower": follower,
        "followed": followed
    };
    const options = {
        method: 'POST',
        body: JSON.stringify(body)
    };
    return fetch(`${API_URL}/${endpoint}`, {
        ...options,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    })
};