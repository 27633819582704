import * as Constants from "../constants/index";

const initialState = {
    data: [],
    isFetching: false,
    gotError: false,
    textError: false,
    notifications: [],
    notificationFetching: false
};


export const session = (state = initialState, action) => {
    switch (action.type) {
        case (Constants.CHANGE_CURRENT_USER_EMAIL):
            console.log(action);
            return {
                ...state,
                data: {
                    ...state.data,
                    email: action.email
                }
            };

        case (Constants.RECEIVE_USER_SESSION):
            return {
                ...state,
                data: action.data,
                isFetching: false,
                getError: false,
                textError: ""
            };
        case (Constants.REQUEST_USER_SESSION):
            return {
                ...state,
                isFetching: true,
                getError: false,
                textError: ""
            };
        case (Constants.FAILED_FETCH_USER_SESSION):
            return {
                ...state,
                isFetching: false,
                getError: true,
                textError: action.err
            };
        case (Constants.REQUEST_USER_NOTIFICATION):
            return {
                ...state,
                notificationFetching: true
            };
        case (Constants.RECEIVE_USER_NOTIFICATION):
            return {
                ...state,
                notifications: action.data,
                notificationFetching: false
            };
        default:
            return state
    }
};
