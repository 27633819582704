import React from 'react';
import "./StepNav/style.css";

const Nav = (props) => {
    const dots = [];

    for (let i = 1; i <= props.totalSteps; i += 1) {
        const isActive = props.currentStep === i;
        let a = isActive ? 'active' : '';
        dots.push((
            <span
                key={`step-${i}`}
                className={'dot ' + a}
                onClick={() => props.goToStep(i)}
            >&bull;</span>
        ));
    }

    return (
        <div className={"nav d-flex justify-content-center my-4"}>{dots}</div>
    );
};

export default Nav;

