import React, {Component} from "react";
import {withRouter, Switch, Route, Redirect} from "react-router";
import {connect} from "react-redux";
import Users from "./Users";

/**
 * People class renders a list of people I followed them and they followed me. 
 */
class People extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/people" render={() => <Redirect to="/people/users"/>}/>
                <Route path={"/people/users"} component={Users}/>
                {/*<Route exact path={"/people/add"} component={AddNewPeople}/>*/}
            </Switch>
        )
    }
}

export default withRouter(connect()(People));