import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import { ScaleLoader } from "react-spinners";
import { css } from "@emotion/core";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { confirmEmail } from "../../actions";
import { Link } from "react-router-dom";

import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn
} from "react-icons/fa";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

/**
 * ConfirmAccount class confirms account of user.
 */
class ConfirmAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      text: "",
      className: "header_area"
    };
  }

  componentDidMount() {
    let key = this.props.match.params.key;
    this.setState({
      isLoading: true
    });
    confirmEmail(key)
      .then(res => {
        if (res.status === 404) {
          return this.setState({
            isLoading: false,
            text: "Something went wrong. Please try again!"
          });
        } else {
          this.setState({
            isLoading: false,
            text: "Your Account has been verified successfully."
          });
        }
      })
      .then(data => console.log(data));
  }

  showErrorMessage = text =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000
      }
    );

  showSuccessMessage = text =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000
      }
    );

  render() {
    console.log(this.state);
    return (
      <Fragment>
        <header
          style={{ backgroundColor: "#3bacf0" }}
          className={this.state.className}
          id={"Home"}
        >
          <div className="main_menu">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div
                className="container box_1620"
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <a className="navbar-brand logo_h">
                  <img
                    src={require("../../assets/icon.png")}
                    height={45}
                    width={140}
                    alt=""
                  />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>

                <div
                  className="collapse navbar-collapse offset"
                  id="navbarSupportedContent"
                >
                  <ul className="nav navbar-nav menu_nav justify-content-center">
                    <li className="nav-item">
                      <a className="nav-link" href="/">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/#Story">
                        Our Story
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/#info">
                        How it works
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#info">
                        <Link to={"/pricing"} >
                        Pricing
                        </Link>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/#RayDalio">
                        Ray Dalio
                      </a>
                    </li>
                  </ul>
                  <ul className="nav navbar-nav navbar-right">
                    {this.state.isLoggedIn ? (
                      <span
                        className={
                          "nav-profile-avatar d-flex flex-column align-items-center my-2"
                        }
                      >
                        <Link to={"/decision"}>
                          <div
                            className={"avater-img"}
                            style={{
                              backgroundImage:
                                "url(" + this.props.session.data.pic + ")"
                            }}
                          ></div>
                        </Link>

                        {/*<span*/}
                        {/*className="text-white">{this.props.session.data.username}*/}
                        {/*</span>*/}
                      </span>
                    ) : (
                      <li className="nav-item">
                        <Link to={"/login"} className="tickets_btn">
                          Login / Sign up
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>
        <div
          className={"container justify-content-center "}
          style={{ height: "100%" }}
        >
          <div className={"row justify-content-center"}>
            <div className={"col-12 col-sm-12 col-md-8 col-lg-6"}>
              <div className="card">
                <article className="card-body">
                  <h4 className="card-title text-center mb-4 mt-1">
                    Confirming your email
                  </h4>
                  <hr />
                  {this.state.isLoading ? (
                    <div className="sweet-loading text-center">
                      <ScaleLoader
                        css={override}
                        sizeUnit={"px"}
                        size={150}
                        color={"#FF8260"}
                        loading={this.props.isFetching}
                      />
                    </div>
                  ) : (
                    <div>
                      <p className={"text-center"}>{this.state.text}</p>
                      <p className={"text-center"} style={{ color: "red" }}>
                        Now you can login using the botton on top of screen.
                      </p>
                    </div>
                  )}
                </article>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer_area p_120 mt-5">
          <div className="container">
            <div className="row footer_inner">
              <div className="col-lg-3 col-sm-6">
                <aside className="f_widget ab_widget">
                  <div className="f_title">
                    <h3>About Us</h3>
                  </div>
                  <p>MeriTa is a meritocratic decision support network</p>
                  <p>
                    Copyright
                    <span className={"mx-1"}>
                      &copy;
                      {new Date().getFullYear()}
                    </span>
                    All rights reserved
                  </p>
                </aside>
              </div>
              <div className="col-lg-3 col-sm-6">
                <aside className="f_widget news_widget">
                  <div className="f_title">
                    <h3>Newsletter</h3>
                  </div>
                  <p>Stay updated with us</p>
                  <div id="mc_embed_signup">
                    <form
                      className="subscribe_form relative"
                      onSubmit={e => e.preventDefault()}
                    >
                      <div className="input-group d-flex flex-row">
                        <input
                          name="EMAIL"
                          placeholder="Enter email address"
                          required=""
                          type="email"
                        />
                        <button className="btn sub-btn">
                          <span className="lnr lnr-arrow-right"></span>
                        </button>
                      </div>
                      <div className="mt-10 info"></div>
                    </form>
                  </div>
                </aside>
              </div>
              <div className="col-lg-3">
                <aside className="f_widget social_widget">
                  <div className="f_title">
                    <h3>Follow Us</h3>
                  </div>
                  <p>Let us be social</p>
                  <ul className="list">
                    <li>
                      <a href="#">
                        <FaFacebook />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                  <br/>
                  <a href="https://mymeritablog.wordpress.com/" target="_blank" className={"contact-us-email"}>
                    MeriTa Blog
                  </a>
                </aside>
              </div>
              <div className="col-lg-3">
                <aside className="f_widget social_widget">
                  <div className="f_title">
                    <h3>Contact us</h3>
                  </div>
                  <a
                    className={"contact-us-email"}
                    href={
                      "mailto: info@myMeriTa.com?subject=Feedback&body=Message"
                    }
                  >
                    info@myMeriTa.com
                  </a>
                  <br />

                  <a href="/privacy" className={"contact-us-email"}>
                    Privacy
                  </a>
                  <br />

                  <a href="/terms" className={"contact-us-email"}>Terms</a>
                </aside>
              </div>
            </div>
          </div>
        </footer>
      </Fragment>
    );
  }
}

export default withRouter(ConfirmAccount);
