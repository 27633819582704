import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Button, ButtonGroup, Tooltip } from "shards-react";
import { IoIosAddCircleOutline, IoMdRemoveCircleOutline } from "react-icons/io";
import { css } from "@emotion/core";
import { ScaleLoader, PulseLoader } from "react-spinners";
import {
  fetchUserfollowers,
  fetchUserfollowings,
  fetchUserSession,
  BlockUser,
} from "../../actions/index";
import { connect } from "react-redux";
import { authHeader } from "../../actions/AuthHeader";
import InvitationEmail from "../../containers/InvitationEmail";
import FriendModal from "./FriendModal";
import AddFriendModal from "./AddFriendModal";
import { API_URL } from "../../constants";
import InviteFriendModal from "./InviteFriendModal";
import { ic_info } from "react-icons-kit/md/ic_info";
import { Icon } from "react-icons-kit";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";

const columns = [
  {
    id: "0",
    label: "ID",
    align: "center",
  },
  {
    id: "1",
    label: "Name",
    align: "center",
  },
  {
    id: "2",
    label: "Top Attributes #1",
    align: "center",
  },
  {
    id: "3",
    label: "Top Attributes #2",
    align: "center",
  },
  {
    id: "4",
    label: "Top Attributes #3",
    align: "center",
  },
  {
    id: "5",
    label: "More",
    align: "center",
  },
];
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
/**
 * PeopleComponent class renders current user's friends.
 */
class PeopleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topAttribute: {},
      isLoading: false,
      showInvitationModal: false,
      isDeleting: false,
      showInvitation2Modal: false,

      selectedToDelete: "",
      showFriendModal: false,
      actionType: "",
      selectedUser: "",
      showAddFriendModal: false,
      page: 0,
      rowsPerPage: 10,
    };
  }

  tooltip_toggle(name, stat) {
    this.setState({
      [name]: !stat,
    });
  }
  handleChangePage = (event, newPage) => {
    // setPage(newPage);
    this.setState({
      page: newPage,
    });
  };
  handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(+event.target.value);
    // setPage(0);

    this.setState((pre) => ({
      rowsPerPage: event.target.value,
      page: 0,
    }));
  };
  componentDidMount() {
    return this.refresh();
  }

  refresh = async () => {
    const { dispatch } = this.props;
    this.setState({
      isLoading: true,
    });
    await dispatch(fetchUserSession());
    console.log("herere", this.props.sessionID);
    await dispatch(fetchUserfollowings(this.props.sessionID));
    await dispatch(fetchUserfollowers(this.props.sessionID));

    const { userFollowings } = this.props;
    let postData = {
      usernames: [],
    };
    userFollowings["data"].map((item) => {
      return postData.usernames.push(item["username"]);
    });

    userFollowings["followers"].map((item) => {
      return postData.usernames.push(item["username"]);
    });

    await fetch(`${API_URL}/users/many_attributes/`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          topAttribute: data,
        });
      });

    this.setState({
      isLoading: false,
    });
  };

  _renderTableRowFollowings = () => {
    const { page, rowsPerPage } = this.state;
    console.log("asdasdsdasdasddsa", this.props.userFollowings["data"]);

    let data;
    if (
      this.props.userFollowings["data"] &&
      this.props.userFollowings["data"].length > 0
    ) {
      data = this.props.userFollowings["data"]
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          let topAttr1 = "";
          let topAttr2 = "";
          let topAttr3 = "";
          if (this.state.topAttribute[item["username"]]) {
            if (this.state.topAttribute[item["username"]][0]) {
              topAttr1 = this.state.topAttribute[item["username"]][0]["attr"];
            }
            if (this.state.topAttribute[item["username"]][1]) {
              topAttr2 = this.state.topAttribute[item["username"]][1]["attr"];
            }
            if (this.state.topAttribute[item["username"]][2]) {
              topAttr3 = this.state.topAttribute[item["username"]][2]["attr"];
            }
          }
          return (
            <TableRow key={index}>
              <TableCell align="center">{index + 1}</TableCell>
              <TableCell align="center">
                {item["first_name"]} {item["last_name"]}
              </TableCell>
              <TableCell align="center">{topAttr1}</TableCell>

              <TableCell align="center">{topAttr2}</TableCell>
              <TableCell align="center">{topAttr3}</TableCell>
              <TableCell align="center">
                <ButtonGroup size="sm" className="mr-2">
                  <Button
                    onClick={() =>
                      this.setState({
                        showFriendModal: !this.state.showFriendModal,
                        actionType: "Unfollow",
                        selectedUser: item,
                      })
                    }
                    className={
                      "new-decision-button btn-success-custom btn btn-success"
                    }
                  >
                    disconnect
                  </Button>
                  <Button
                    onClick={() =>
                      this.props.history.push(
                        `/people/users/${item["username"]}`
                      )
                    }
                    className={
                      "new-decision-button btn-success-custom btn btn-success"
                    }
                  >
                    Profile
                  </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>

            //   <tr key={index}>
            //     <th scope="row" className={"text-center text-black"}>
            //       {index + 1}
            //     </th>
            //     <td>
            //       {item["first_name"]} {item["last_name"]}
            //     </td>
            //     <td>{topAttr1}</td>
            //     <td>{topAttr2}</td>
            //     <td>{topAttr3}</td>
            //     <td className={"text-center"}>
            //       <ButtonGroup size="sm" className="mr-2">
            //         <Button
            //           onClick={() =>
            //             this.setState({
            //               showFriendModal: !this.state.showFriendModal,
            //               actionType: "Unfollow",
            //               selectedUser: item
            //             })
            //           }
            //           className={
            //             "new-decision-button btn-success-custom btn btn-success"
            //           }
            //         >
            //           Unfollow
            //         </Button>
            //         <Button
            //           onClick={() =>
            //             this.props.history.push(`/people/users/${item["username"]}`)
            //           }
            //           className={
            //             "new-decision-button btn-success-custom btn btn-success"
            //           }
            //         >
            //           Profile
            //         </Button>
            //       </ButtonGroup>
            //     </td>
            //   </tr>
          );
        });
    }
    return data;
  };

  _renderTableRowFollowers = () => {
    console.log("num", this.props.userFollowings["data"].length);
    const { page, rowsPerPage } = this.state;
    console.log("asdasdsdasdasddsa", this.props.userFollowings["followers"]);

    let data;
    if (
      this.props.userFollowings["followers"] &&
      this.props.userFollowings["followers"].length > 0
    ) {
      data = this.props.userFollowings["followers"]
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          let topAttr1 = "";
          let topAttr2 = "";
          let topAttr3 = "";
          if (this.state.topAttribute[item["username"]]) {
            if (this.state.topAttribute[item["username"]][0]) {
              topAttr1 = this.state.topAttribute[item["username"]][0]["attr"];
            }
            if (this.state.topAttribute[item["username"]][1]) {
              topAttr2 = this.state.topAttribute[item["username"]][1]["attr"];
            }
            if (this.state.topAttribute[item["username"]][2]) {
              topAttr3 = this.state.topAttribute[item["username"]][2]["attr"];
            }
          }
          return (
            <TableRow key={index}>
              <TableCell align="center">{index + page * rowsPerPage}</TableCell>
              <TableCell align="center">
                {item["first_name"]} {item["last_name"]}
              </TableCell>
              <TableCell align="center">{topAttr1}</TableCell>

              <TableCell align="center">{topAttr2}</TableCell>
              <TableCell align="center">{topAttr3}</TableCell>
              <TableCell align="center">
                <ButtonGroup size="sm" className="mr-2">
                  <Button
                    onClick={() =>
                      this.setState({
                        showFriendModal: !this.state.showFriendModal,
                        actionType: "Block",
                        selectedUser: item,
                      })
                    }
                    className={
                      "new-decision-button btn-success-custom btn btn-success"
                    }
                  >
                    disconnect
                  </Button>
                  <Button
                    onClick={() =>
                      this.props.history.push(
                        `/people/users/${item["username"]}`
                      )
                    }
                    className={
                      "new-decision-button btn-success-custom btn btn-success"
                    }
                  >
                    Profile
                  </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
            // <tr key={index}>
            //   <th scope="row" className={"text-center text-black"}>
            //     {index + 1}
            //   </th>
            //   <td>
            //     {item["first_name"]} {item["last_name"]}
            //   </td>
            //   <td>{topAttr1}</td>
            //   <td>{topAttr2}</td>
            //   <td>{topAttr3}</td>
            //   <td className={"text-center"}>
            //     <ButtonGroup size="sm" className="mr-2">
            //       <Button
            //         onClick={() =>
            //           this.setState({
            //             showFriendModal: !this.state.showFriendModal,
            //             actionType: "Block",
            //             selectedUser: item
            //           })
            //         }
            //         className={
            //           "new-decision-button btn-success-custom btn btn-success"
            //         }
            //       >
            //         Block
            //       </Button>
            //       <Button
            //         onClick={() =>
            //           this.props.history.push(
            //             `/people/users/${item["username"]}`
            //           )
            //         }
            //         className={
            //           "new-decision-button btn-success-custom btn btn-success"
            //         }
            //       >
            //         Profile
            //       </Button>
            //     </ButtonGroup>
            //   </td>
            // </tr>
          );
        });
    }
    return data;
  };

  _renderTableOrSpinner = () => {
    const { page, rowsPerPage } = this.state;

    if (this.state.isLoading) {
      return (
        <div className={"spinner-loader mt-5"}>
          <div className="sweet-loading">
            <ScaleLoader
              css={override}
              sizeUnit={"px"}
              size={150}
              color={"#FF8260"}
              loading={this.state.isLoading}
            />
          </div>
        </div>
      );
    } else if (this.props.userFollowings.getError) {
      return <div>{String(this.props.userFollowings.textError)}</div>;
    } else {
      const { page, rowsPerPage } = this.state;

      return (
        // <div className={"people-wrapper"}>
        //     <div className={"people-table table-responsive"}>
        //         <table className="table table-borderless">
        //             <thead>
        //             <tr>
        //                 <th scope="col" className={"text-center"}>NO.</th>
        //                 <th scope="col">Name</th>
        //                 <th scope="col">Top Attributes-1</th>
        //                 <th scope="col">Top Attributes-2</th>
        //                 <th scope="col">Top Attributes-3</th>
        //                 <th scope="col" className={"text-center"}>More</th>
        //             </tr>
        //             </thead>
        //             <tbody>
        //             {this._renderTableRowFollowings()}
        //             {this._renderTableRowFollowers()}
        //             </tbody>
        //         </table>
        //     </div>
        //     {/*{this._renderPagination()}*/}
        // </div>

        <Paper className="table_container">
          <div className="report-table">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.userFollowings["data"] &&
                this.props.userFollowings["data"].length > 0
                  ? this.props.userFollowings.data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )

                      .map((item, index) => {
                        console.log("data", this.props.userFollowings.data);
                        let topAttr1 = "";
                        let topAttr2 = "";
                        let topAttr3 = "";
                        if (this.state.topAttribute[item["username"]]) {
                          if (this.state.topAttribute[item["username"]][0]) {
                            topAttr1 = this.state.topAttribute[
                              item["username"]
                            ][0]["attr"];
                          }
                          if (this.state.topAttribute[item["username"]][1]) {
                            topAttr2 = this.state.topAttribute[
                              item["username"]
                            ][1]["attr"];
                          }
                          if (this.state.topAttribute[item["username"]][2]) {
                            topAttr3 = this.state.topAttribute[
                              item["username"]
                            ][2]["attr"];
                          }
                        }
                        return [
                          <TableRow key={index}>
                            <TableCell align="center">
                              {index + page * rowsPerPage + 1}
                            </TableCell>
                            <TableCell align="center">
                              {item["first_name"]} {item["last_name"]}
                            </TableCell>
                            <TableCell align="center">{topAttr1}</TableCell>

                            <TableCell align="center">{topAttr2}</TableCell>
                            <TableCell align="center">{topAttr3}</TableCell>
                            <TableCell align="center">
                              <ButtonGroup size="sm" className="mr-2">
                                <Button
                                  onClick={() =>
                                    this.setState({
                                      showFriendModal: !this.state
                                        .showFriendModal,
                                      actionType: "Unfollow",
                                      selectedUser: item,
                                    })
                                  }
                                  className={
                                    "new-decision-button btn-success-custom btn btn-success"
                                  }
                                >
                                  disconnect
                                </Button>
                                <Button
                                  onClick={() =>
                                    this.props.history.push(
                                      `/people/users/${item["username"]}`
                                    )
                                  }
                                  className={
                                    "new-decision-button btn-success-custom btn btn-success"
                                  }
                                >
                                  Profile
                                </Button>
                              </ButtonGroup>
                            </TableCell>
                          </TableRow>,
                        ];
                      })
                  : null}
                {/* {this.props.userFollowings["followers"] &&
                this.props.userFollowings["followers"].length > 0
                  ? this.props.userFollowings["followers"]
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => {
                          console.log("follow",this.props.userFollowings.followers)
                        let topAttr1 = "";
                        let topAttr2 = "";
                        let topAttr3 = "";
                        if (this.state.topAttribute[item["username"]]) {
                          if (this.state.topAttribute[item["username"]][0]) {
                            topAttr1 = this.state.topAttribute[
                              item["username"]
                            ][0]["attr"];
                          }
                          if (this.state.topAttribute[item["username"]][1]) {
                            topAttr2 = this.state.topAttribute[
                              item["username"]
                            ][1]["attr"];
                          }
                          if (this.state.topAttribute[item["username"]][2]) {
                            topAttr3 = this.state.topAttribute[
                              item["username"]
                            ][2]["attr"];
                          }
                        }
                        return (
                          <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">
                              {item["first_name"]} {item["last_name"]}
                            </TableCell>
                            <TableCell align="center">{topAttr1}</TableCell>

                            <TableCell align="center">{topAttr2}</TableCell>
                            <TableCell align="center">{topAttr3}</TableCell>
                            <TableCell align="center">
                              <ButtonGroup size="sm" className="mr-2">
                                <Button
                                  onClick={() =>
                                    this.setState({
                                      showFriendModal: !this.state
                                        .showFriendModal,
                                      actionType: "Block",
                                      selectedUser: item
                                    })
                                  }
                                  className={
                                    "new-decision-button btn-success-custom btn btn-success"
                                  }
                                >
                                  disconnect
                                </Button>
                                <Button
                                  onClick={() =>
                                    this.props.history.push(
                                      `/people/users/${item["username"]}`
                                    )
                                  }
                                  className={
                                    "new-decision-button btn-success-custom btn btn-success"
                                  }
                                >
                                  Profile
                                </Button>
                              </ButtonGroup>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : null} */}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={this.props.userFollowings.data.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            className="table-pagination"
          />
        </Paper>
      );
    }
  };

  toggleInvitationModal = () => {
    return this.setState({
      showInvitationModal: !this.state.showInvitationModal,
    });
  };
  toggleInvitation2Modal = () => {
    return this.setState({
      showInvitation2Modal: !this.state.showInvitation2Modal,
    });
  };

  toggleFriendModal = () => {
    return this.setState({
      showFriendModal: !this.state.showFriendModal,
    });
  };

  toggleAddFriendModalModal = () => {
    if (this.state.showAddFriendModal) {
      this.refresh();
    }
    return this.setState({
      showAddFriendModal: !this.state.showAddFriendModal,
    });
  };

  render() {
    return (
      <div
        className={"container"}
        style={{
          // height: "80%",
          backgroundColor: "white",
          marginTop: "70px",
          paddingBottom: "50px",
          border: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <FriendModal
          show={this.state.showFriendModal}
          refresh={this.refresh}
          user={this.state.selectedUser}
          currentUser={this.props.sessionID}
          onCloseModal={this.toggleFriendModal}
          actionType=""
          // actionType={this.state.actionType}
        />
        <InvitationEmail
          show={this.state.showInvitationModal}
          onCloseModal={this.toggleInvitationModal}
        />
        <AddFriendModal
          show={this.state.showAddFriendModal}
          onCloseModal={this.toggleAddFriendModalModal}
        />
        <div className={""}>
          <div
            className={
              "py-5 d-flex ml-sm-2 ml-md-5 justify-content-between people-wrapper"
            }
          >
            <div className={"d-flex ml-3 ml-md-0"}>
              <div className={"profile-icon-ui-title"}></div>
              <h2 className={"people-header-text"}>
                People{" "}
                {this.props.userFollowings.data.length &&
                this.props.userFollowings.data.length > 0 ? (
                  <span style={{ fontSize: 16 }}>
                    ({this.props.userFollowings.data.length})
                  </span>
                ) : (
                  ""
                )}
              </h2>
            </div>
            <div className={"py-2 mr-sm-2 mr-md-5 new-decision-button-wrapper"}>
              {this.props.user.org ? (
                this.props.user.org.is_admin ||
                !this.props.user.is_enterprise ? (
                  <Button
                    onClick={this.toggleInvitationModal}
                    theme="light"
                    className={"mr-2"}
                  >
                    Invite Experts
                  </Button>
                ) : undefined
              ) : undefined}

              {!this.props.user.is_enterprise ? (
                <Button
                  onClick={this.toggleInvitationModal}
                  theme="light"
                  className={"mr-2"}
                >
                  Invite Experts
                </Button>
              ) : undefined}

              <Button
                onClick={this.toggleAddFriendModalModal}
                theme="success"
                className={"new-decision-button btn-success-custom"}
              >
                <IoIosAddCircleOutline className={"mr-2 font-size-18"} />
                Connect
                <Icon
                  id="TooltipConnectPeople"
                  className={"ml-2"}
                  size={20}
                  icon={ic_info}
                />
                <Tooltip
                  open={this.state.connect_people_tooltip}
                  target="#TooltipConnectPeople"
                  toggle={() =>
                    this.tooltip_toggle(
                      "connect_people_tooltip",
                      this.state.connect_people_tooltip
                    )
                  }
                >
                  You can add/remove people to this page, but first they should
                  register and activate their account. Once connected to a
                  person, you'll be able to invite them to decisions.
                </Tooltip>
              </Button>

              <InviteFriendModal
                show={this.state.showInvitation2Modal}
                onCloseModal={this.toggleInvitation2Modal}
              />
            </div>
          </div>
        </div>
        <h5 className={"mt-5 mb-5 font-size-16"}>
          You can ​invite​ your colleagues and friends to MeriTa. After they
          activate their account you can connect to them on this page. After
          connection you'll be able to add them to decisions as participants.
        </h5>
        {this._renderTableOrSpinner()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userFollowings: state.userFollowings,
    sessionID: state.session.data.username,
    user:state.session.data
  };
};

export default withRouter(connect(mapStateToProps, null)(PeopleComponent));
