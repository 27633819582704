import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import {
  Button,
  FormInput,
  FormTextarea,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormCheckbox,
  Col,
  Row,
  Container
} from "shards-react";
import { FaRegEdit } from "react-icons/fa";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/core";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import ChangeImage from "./ChangeImage";
import { setProfilePic } from "../../actions/index";
import { connect } from "react-redux";
import { API_URL } from "../../constants";
import { authHeader } from "../../actions/AuthHeader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

/**
 * Personal class is used to change current user profile details.
 */
class EmailNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      firstName: "",
      lastName: "",
      bio: "",
      occupation: "",
      coreSkill: "",
      location: "",
      sendingData: false,
      showModal: false,
      modalSpinner: false,
      selectedSex: "",
      user_permission: [],
      user_permission_data: false,
      email_notif: {
        new_vote_submitted_to_my_decision: false,
        decision_can_be_finalized: false,
        invited_to_a_decision_by_friend: false,
        decision_results_are_published: false,
        new_comment: false
      },
      selected: "selected"
    };
  }

  componentDidMount = () => {
    this.get_user_permissions();
  };
  remove_permission = permission => {
    const sessoin_id = this.props.sessionID;

    const endpoint = `users/${sessoin_id}/user_permission_remove`;

    fetch(`${API_URL}/${endpoint}/`, {
      method: "POST",
      body: JSON.stringify({
        permission: permission,
        user: this.props.sessionID
      }),
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader()
      })
    })
      .then(data => data.json())
      .then(res => {
        console.log("user_permissions_remove", res);
        // this.setState(
        //   {
        //     all_comments_data: false
        //   }
        // );

        return this.showSuccessMessage("Updated !");
      });
  };

  get_user_permissions = () => {
    const sessoin_id = this.props.sessionID;

    const endpoint = `users/${sessoin_id}/user_permission`;
    const options = {
      method: "GET"
    };
    fetch(`${API_URL}/${endpoint}`, {
      ...options,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader()
      }
    })
      .then(data => data.json())
      .then(res => {
        console.log("user_permission", res);
        res.permissions.map((item, id) => {
          let { email_notif } = this.state;

          email_notif[item.permission] = true;
        });
        // if (res.data === "voted") {
        this.setState({
          user_permission: res,
          user_permission_data: true
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
  showErrorMessage = text =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000
      }
    );

  showSuccessMessage = text =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000
      }
    );

  refactorProfileUrl = url => {
    console.log("url", url);
    if (url && String(url).substring(0, 4) === "http") {
      return url;
    } else {
      console.log("url", url);
      return `${API_URL}/uploads/${url}`;
    }
  };
  componentDidMount() {
    this.setState({
      userName: this.props.data["username"],
      firstName: this.props.data["first_name"] || "",
      lastName: this.props.data["last_name"] || "",
      bio: this.props.data["bio"] || "",
      location: this.props.data["location"] || "",
      occupation: this.props.data["occupation"] || "",
      coreSkill: this.props.data["core_skill"] || "",
      selectedSex: this.props.data.sex
    });
  }

  applyChange = () => {
    if (this.state.userName.length === 0) {
      this.showErrorMessage("Username may not be empty");
      return;
    }
    this.setState({
      sendingData: true
    });
    if (this.state.firstName !== "" && this.state.lastName !== "") {
      let data = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        username: this.state.userName,
        bio: this.state.bio,
        location: this.state.location,
        occupation: this.state.occupation,
        core_skill: this.state.coreSkill,
        sex: this.state.selectedSex
      };
      this.props.setUserProfileInfo(this.props.sessionID, data).then(data => {
        this.setState({
          sendingData: false
        });
        this.showSuccessMessage("Updated !");
      });
    } else {
      this.setState({
        sendingData: false
      });
      this.showErrorMessage("please complete first Name and last Name field");
    }
  };

  onFocusInput = (e, labelElement) => {
    labelElement.style.color = "#008AFF";
  };

  onBlurInput = (e, labelElement) => {
    labelElement.style.color = "#5a6169";
  };

  showChangePictureModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  refactorUrl = url => {
    console.log("url", url);

    if (url) {
      return url["profile_pic"];
    }
    return null;
  };

  render() {
    console.log(this.state);
    return (
      <Fragment>
        <ChangeImage
          showModal={this.state.showModal}
          uploadImage={this.props.setProfilePic}
          username={this.state.userName}
          toggle={this.showChangePictureModal}
        />

        <div
          className={"form-ui p-3"}
          style={{
            backgroundColor: "white",
            marginTop: "70px",
            paddingBottom: "50px",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          }}
        >
          <form>
            <h3>Notifications</h3>
            <hr />
            <Container>
              <Row>
                <Col xs="6" sm="5" md="6" lg="4">
                  <div className={"  my-1"}>
                    <label
                      style={{ textDecoration: "underline" }}
                      className={"form-ui-label font-size-13 fw-600"}
                      ref={element => (this.elementFirstName = element)}
                    >
                      General:
                    </label>
                    <div className="form-group d-flex m-0">
                      <label
                        className={"form-ui-label font-size-13 fw-600"}
                        ref={element => (this.elementFirstName = element)}
                      >
                        New Comment
                      </label>
                    </div>
                  </div>
                </Col>
                <Col xs="4" sm="4" md="3" lg="4">
                  <div className={""}>
                    <label
                      className={"form-ui-label font-size-13 fw-600"}
                      ref={element => (this.elementLastName = element)}
                    >
                      {" "}
                      MeriTa Notification
                    </label>
                    <div className="form-group d-flex m-0">
                      <FormCheckbox
                        className={"ml-5"}
                        inline
                        name="new_comment_notif"
                        checked={this.state.selected === "selected"}
                      ></FormCheckbox>
                    </div>
                  </div>
                </Col>
                <Col xs="2" sm="3" md="3" lg="4">
                  <div className={""}>
                    <label
                      className={"form-ui-label font-size-13 fw-600"}
                      ref={element => (this.elementLastName = element)}
                    >
                      Email
                    </label>
                    <div className="form-group d-flex m-0">
                      <FormCheckbox
                        className={"ml-3"}
                        inline
                        name="new_comment"
                        checked={this.state.email_notif["new_comment"] === true}
                        onChange={() => {
                          this.remove_permission("new_comment");
                          const { email_notif } = this.state;
                          email_notif["new_comment"] = !email_notif[
                            "new_comment"
                          ];
                          this.setState({
                            email_notif
                          });
                        }}
                      ></FormCheckbox>
                    </div>
                  </div>{" "}
                </Col>
              </Row>

              <Row>
                <Col xs="6" sm="5" md="6" lg="4">
                  <div className={"my-1"}>
                    <label
                      style={{ textDecoration: "underline" }}
                      className={"form-ui-label font-size-13 fw-600"}
                      ref={element => (this.elementFirstName = element)}
                    >
                      Decision Created by me:
                    </label>
                    <div className="form-group d-flex m-0">
                      <label
                        className={"form-ui-label font-size-13 fw-600"}
                        ref={element => (this.elementFirstName = element)}
                      >
                        New vote submitted to my decision
                      </label>
                    </div>
                    <div className="form-group d-flex m-0 mt-1">
                      <label
                        className={"form-ui-label font-size-13 fw-600"}
                        ref={element => (this.elementFirstName = element)}
                      >
                        Decision can be finalized
                      </label>
                    </div>
                  </div>
                </Col>
                <Col xs="4" sm="4" md="3" lg="4">
                  <div className={"my-1"}>
                    <label
                      className={"form-ui-label font-size-13 fw-600"}
                      ref={element => (this.elementLastName = element)}
                    ></label>
                    <div className="form-group d-flex m-0">
                      <FormCheckbox
                        className={"ml-5"}
                        inline
                        name="new_vote_submitted_notif"
                        checked={this.state.selected === "selected"}
                      ></FormCheckbox>
                    </div>
                    <div className="form-group d-flex m-0">
                      <FormCheckbox
                        className={"ml-5"}
                        inline
                        name="decision_can_be_finalized_notif"
                        checked={this.state.selected === "selected"}
                      ></FormCheckbox>
                    </div>
                  </div>
                </Col>
                <Col xs="2" sm="3" md="3" lg="4">
                  <div className={" my-1"}>
                    <label
                      className={"form-ui-label font-size-13 fw-600"}
                      ref={element => (this.elementLastName = element)}
                    ></label>
                    <div className="form-group d-flex m-0">
                      <FormCheckbox
                        className={"ml-3"}
                        inline
                        name="new_vote_submitted_email"
                        checked={
                          this.state.email_notif[
                            "new_vote_submitted_to_my_decision"
                          ] === true
                        }
                        onChange={() => {
                          this.remove_permission(
                            "new_vote_submitted_to_my_decision"
                          );
                          const { email_notif } = this.state;
                          email_notif[
                            "new_vote_submitted_to_my_decision"
                          ] = !email_notif["new_vote_submitted_to_my_decision"];
                          this.setState({
                            email_notif
                          });
                        }}
                      ></FormCheckbox>
                    </div>
                    <div className="form-group d-flex m-0">
                      <FormCheckbox
                        className={"ml-3"}
                        inline
                        name="decision_can_finalized_email"
                        checked={
                          this.state.email_notif[
                            "decision_can_be_finalized"
                          ] === true
                        }
                        onChange={() => {
                          this.remove_permission("decision_can_be_finalized");

                          const { email_notif } = this.state;
                          email_notif[
                            "decision_can_be_finalized"
                          ] = !email_notif["decision_can_be_finalized"];
                          this.setState({
                            email_notif
                          });
                        }}
                      ></FormCheckbox>
                    </div>
                  </div>
                </Col>
              </Row>
              
              <Row>
                <Col xs="6" sm="5" md="6" lg="4">
                  <div className={" my-1"}>
                    <label
                      className={"form-ui-label font-size-13 fw-600"}
                      ref={element => (this.elementFirstName = element)}
                      style={{ textDecoration: "underline" }}
                    >
                      Decision I'm invited to:
                    </label>
                    <div className="form-group d-flex m-0">
                      <label
                        className={"form-ui-label font-size-13 fw-600"}
                        ref={element => (this.elementFirstName = element)}
                      >
                        Invited to a decision by friend
                      </label>
                    </div>

                    <div className="form-group d-flex m-0 mt-1">
                      <label
                        className={"form-ui-label font-size-13 fw-600"}
                        ref={element => (this.elementFirstName = element)}
                      >
                        Decision results are published
                      </label>
                    </div>
                  </div>
                </Col>
                <Col xs="4" sm="4" md="3" lg="4">
                  <div className={" my-1"}>
                    <label
                      className={"form-ui-label font-size-13 fw-600"}
                      ref={element => (this.elementLastName = element)}
                    ></label>
                    <div className="form-group d-flex m-0">
                      <FormCheckbox
                        className={"ml-5"}
                        inline
                        name="invite_to_decision_notif"
                        checked={this.state.selected === "selected"}
                      ></FormCheckbox>
                    </div>
                    <div className="form-group d-flex m-0">
                      <FormCheckbox
                        className={"ml-5"}
                        inline
                        name="decision_results_published_notif"
                        checked={this.state.selected === "selected"}
                      ></FormCheckbox>
                    </div>
                  </div>
                </Col>
                <Col xs="2" sm="3" md="3" lg="4">
                  <div className={"my-1"}>
                    <label
                      className={"form-ui-label font-size-13 fw-600"}
                      ref={element => (this.elementLastName = element)}
                    ></label>
                    <div className="form-group d-flex m-0">
                      <FormCheckbox
                        className={"ml-3"}
                        inline
                        name="invited_to_decision_email"
                        checked={
                          this.state.email_notif[
                            "invited_to_a_decision_by_friend"
                          ] === true
                        }
                        onChange={() => {
                          this.remove_permission(
                            "invited_to_a_decision_by_friend"
                          );
                          const { email_notif } = this.state;
                          email_notif[
                            "invited_to_a_decision_by_friend"
                          ] = !email_notif["invited_to_a_decision_by_friend"];
                          this.setState({
                            email_notif
                          });
                        }}
                      ></FormCheckbox>
                    </div>
                    <div className="form-group d-flex m-0">
                      <FormCheckbox
                        className={"ml-3"}
                        inline
                        name="decision_results_published_email"
                        checked={
                          this.state.email_notif[
                            "decision_results_are_published"
                          ] === true
                        }
                        onChange={() => {
                          this.remove_permission(
                            "decision_results_are_published"
                          );

                          const { email_notif } = this.state;
                          email_notif[
                            "decision_results_are_published"
                          ] = !email_notif["decision_results_are_published"];
                          this.setState({
                            email_notif
                          });
                        }}
                      ></FormCheckbox>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(connect(null, { setProfilePic })(EmailNotification));
