import React, {Component} from "react";
import {withRouter} from "react-router";
import {NavLink} from "react-router-dom";

class SubmenuPeople extends Component {
    render() {
        return (
            <div className={"submenu-items font-size-13"}>
                <NavLink className={"submenu-item hvr-sweep-to-top"}
                         activeClassName={"submenu-item-active"}
                         to={"/people/users"}>
                    People
                </NavLink>
                {/*<NavLink className={"submenu-item hvr-sweep-to-top"}*/}
                         {/*activeClassName={"submenu-item-active"}*/}
                         {/*to={"/people/add"} exact>*/}
                    {/*Add New People*/}
                {/*</NavLink>*/}

            </div>
        )
    }
}


export default withRouter(SubmenuPeople);