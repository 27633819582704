import React from "react";
import MaterialTable from "material-table";
import MUIDataTable from "mui-datatables";

const OrganizationAttrs = (props) => {
  const org_attr_table_rows_name = [
    {
      
      title: 'ID',
      render: rowData => rowData.tableData.id+1
    },
    { title: "Name", field: "name" }
   
   
  ];

  const columns = [
    {
     name: "first_name",
     label: "Name",
     options: {
      filter: true,
      sort: true,
     }
    }
   ];
  const options = {
    filterType: "checkbox",
  };
  return (
    <>
      <MaterialTable
        title="Organization Attributes"
        columns={org_attr_table_rows_name}
        data={props.data}
        localization={{
          header: {
            actions: "More",
          },
        }}
        actions={[
          
          (rowData) => ({
            icon: "delete",
            tooltip: "Remove Attribute",
            onClick: (event, rowData) => {
              props.setshowRemoveToggle((before) => !before);
              props.setOnRemoveAttr(rowData.name);
            },
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
        //   sorting: true,
        //   filtering: true,
        }}
      />

      {/* <MUIDataTable
        title={"Employee List"}
        data={props.data}
        columns={columns}
        options={options}
      /> */}
    </>
  );
};

export default OrganizationAttrs;
