import React ,{useEffect} from 'react';
import useAxios from "@use-hooks/axios";
import { API_URL } from "../constants";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import {
  IoMdCheckmarkCircle,
  IoMdCloseCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
const ActivateEnterprise =(props)=>{


  const showErrorMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

  const showSuccessMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

    const activateEnt = useAxios({
        url: `${API_URL}/activate_enterprise/`,
        method: "POST",
        options: {
          data: {
            token: props.match.params.token,
          },
        },
        // trigger: [],
        customHandler: (err, res) => {
          if (res) {
           
            console.log("res", res);
            const message = res.data.data;
            console.log(message);
            if (message == "token_is_not_exist") {
              console.log("there");
              showErrorMessage("your token is not valid");
              props.history.push('/')
            } else if (message === "user_added") {
              showSuccessMessage("You are add to Organizatoin");
              props.history.push('/')
            }
           
          }
        },
      });
    useEffect(()=>{
        // console.log(props.match.params.token)
        activateEnt.reFetch()
    },[])
    
    return(
        <div></div>
    )
}


export default ActivateEnterprise;