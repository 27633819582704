import React, {Component} from "react";
import {Redirect, Route, Switch, withRouter} from "react-router";
import {connect} from "react-redux";
import DecisionsComponent from "../components/Decisions/DecisionsComponent";
import Decision from "./Decision";
import Voting from "./Voting";
import MakeDecision from "../components/Decisions/MakeDecision";
import Finalize from "./Finalize";


/**
 * Decisions class renders a component With respect to the browser url.
 */
class Decisions extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/decision" render={() => <Redirect to="/decision/polls"/>}/>
                <Route exact path={"/decision/polls"} component={DecisionsComponent}/>
                <Route exact path={"/decision/polls/:id"} component={Decision}/>
                <Route exact path={"/decision/polls/:id/voting"} component={Voting}/>
                <Route exact path={"/decision/finalize/:id/:partial?"} component={Finalize}/>
                <Route exact path={"/decision/make"} component={MakeDecision}/>
                <Route exact path={"/decision/make/:id"} component={MakeDecision}/>
            </Switch>
        )
    }
}


export default withRouter(connect()(Decisions));