import React, {Component} from "react";
import {withRouter, Switch, Route} from "react-router";
import SubmenuDecision from "./SubmenuItems/SubmenuDecision";
import SubmenuPeople from "./SubmenuItems/SubmenuPeople";
import SubmenuAttribute from "./SubmenuItems/SubmenuAttribute";
/**
 * SubmenuUI class renders submenu of navbar.
 */
class SubmenuUI extends Component {
    render() {
        return (
            <div className={"submenu-wrapper"}>
                <Switch>
                    <Route path={"/decision"} component={SubmenuDecision}/>
                    <Route path={"/attribute"} component={SubmenuAttribute}/>
                    <Route path={"/people"} component={SubmenuPeople}/>
                </Switch>
            </div>
        )
    }
}


export default withRouter(SubmenuUI);