import React, {Component} from "react";
import Modal from 'react-responsive-modal';
import {Button} from "shards-react";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {IoMdCheckmarkCircle, IoMdCloseCircle} from "react-icons/io";
import {BlockUser} from "../../actions";
import {connect} from "react-redux";
import {css} from '@emotion/core';
import {PulseLoader} from 'react-spinners';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

/**
 * FriendModal class renders a modal which used to confirm block or unfollow people.
 */
class FriendModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            emailValue: "",
        }
    }

    showSuccessMessage = (text) => toast.notify(
        <p className={"m-0 p-0 font-size-14"}>
            <span className={"mr-2 font-size-19"} style={{verticalAlign: "1px"}}>
                    <IoMdCheckmarkCircle style={{color: "#52c41a"}}/>
            </span>
            {text}
        </p>
        , {
            duration: 2000
        });


    showErrorMessage = (text) => toast.notify(
        <p className={"m-0 p-0 font-size-14"}>
            <span className={"mr-2 font-size-19"} style={{verticalAlign: "1px"}}>
                <IoMdCloseCircle style={{color: "#f5222d"}}/>
            </span>
            {text}
        </p>
        , {
            duration: 2000
        });


    handleOkButton = () => {
        if (this.props.actionType === "Block") {
            return this.BlockUserFunc(this.props.user["username"]);
        } else {
            return this.unFollowUser(this.props.user["username"]);
        }
    };


    BlockUserFunc = async (user) => {
        this.setState({
            isDeleting: true,
        });
        let currentUser = this.props.currentUser;
        let data = {
            "follower": user,
            "followed": currentUser
        };
        await this.props.BlockUser(data);
        this.setState({
            isDeleting: false,
        });
        this.props.onCloseModal();
        return this.props.refresh()
    };

    unFollowUser = async (user) => {
        this.setState({
            isDeleting: true,
        });
        let currentUser = this.props.currentUser;
        let data = {
            "follower": currentUser,
            "followed": user
        };
        await this.props.BlockUser(data);
        this.setState({
            isDeleting: false,
        });
        this.props.onCloseModal();
        return this.props.refresh()
    };


    handleEnterKey = (e) => {
        if (e.keyCode === 13) {
            return this.sendEmail()
        }
    };

    render() {
        console.log(this.props);
        return (
            <div onKeyDown={this.handleEnterKey}>
                <Modal
                    animationDuration={100}
                    open={this.props.show} onClose={this.props.onCloseModal} top>
                    <h4>Are you sure {this.props.actionType}?</h4>
                    <hr/>
                    <div style={{width: "500px"}} className={"d-flex justify-content-end"}>
                        <Button
                            onClick={this.handleOkButton}
                            className={"mr-1"}>
                            {this.state.isDeleting ? (
                                <div className='sweet-loading'>
                                    <PulseLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        color={"white"}
                                        size={7}
                                        loading={true}
                                    />
                                </div>) : "Yes"}
                        </Button>
                        <Button
                            onClick={this.props.onCloseModal}
                            className={"ml-1"}>
                            No
                        </Button>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default connect(null, {BlockUser})(FriendModal);