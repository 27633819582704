import React, {
  Component,
  Fragment,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import { withRouter } from "react-router";
import { MdEmail } from "react-icons/md";
import ImageUploader from "react-images-upload";

import {
  Row,
  Col,
  Container,
  Button,
  FormInput,
  InputGroup,
  FormSelect,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";
import { connect } from "react-redux";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import { API_URL } from "../../constants";
import { authHeader } from "../../actions/AuthHeader";
import useAxios from "@use-hooks/axios";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { PulseLoader, ScaleLoader } from "react-spinners";
import { css } from "@emotion/core";
import isEmail from "validator/lib/isEmail";
import ChangeImage from "./ChangeLogo";
import { HOST_URL } from "../../constants";

import AutoComplete from "../../layout/UI/AutoComplete";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  left: 50%;
`;
const AdminAddOrgLogo = (props) => {
  const [AllUsers, setAllUsers] = useState(null);
  const [isPosting, setisPosting] = useState(false);
  const [ShowModal, setShowModal] = useState(false);
  const [NewUser, setNewUser] = useState("");
  const [EnterpriseType, setEnterpriseType] = useState("");
  const [OrgName, setOrgName] = useState("");
  const [formData, setformData] = useState();
  const [image, setImage] = useState([]);

  const getAllUser = useAxios({
    url: `${API_URL}/users/${props.sessionID}/enterprise_users/`,
    options: {
      headers: {
        Authorization: authHeader(),
      },
    },
    trigger: [],
    customHandler: (err, res) => res && setAllUsers(res.data.data),
  });

  const getOrgPic = useAxios({
    url: `${API_URL}/users/${props.sessionID}/get_org_pic/`,
    method: "POST",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: {
        user_email: NewUser,
      },
    },
    trigger: NewUser,
    // customHandler: (err, res) => res && setAllUsers(res.data.data),
  });

  const setOrgPic = useAxios({
    url: `${API_URL}/users/${props.sessionID}/set_org_pic/`,
    method: "POST",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: formData,
    },
    // trigger: NewUser,
    customHandler: (err, res) => {
      if (res) {
        // console.lof("set_image", res);
        window.location.replace(HOST_URL + "/setting/admin/addOrgLogo");
      }
    },
  });

  // if (getAllUser.response) console.log(getAllUser.response.data.data);
  if (getOrgPic.response) console.log("test", getOrgPic.response.data.data);

  const showErrorMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

  const onDrop = (pictureFiles, pictureDataURLs) => {
    // this.setState({
    //   pictures: pictureFiles,
    // });
    if (pictureFiles.length > 0) {
      setisPosting(true);
      setImage(pictureFiles);
      setInterval(console.log("ty"), 3000);

      console.log("asd", pictureFiles);
      const formdata = new FormData();
      formdata.append("profile_pic ", pictureFiles[0]);
      formdata.append("user_email ", NewUser);
      console.log("formData", formdata);
      setformData(formdata);
      setOrgPic.reFetch();
    }
  };

  const showSuccessMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

  const onAddNewUser = (event, { newValue }) => {
    setNewUser(newValue);
  };

  const refactorProfileUrl = (url) => {
    console.log("url", url);
    if (url && String(url).substring(0, 4) === "http") {
      return url;
    } else {
      console.log("url", url);
      return `${API_URL}/uploads/${url}`;
    }
  };

  const showChangePictureModal = () => {
    setShowModal((before) => !before);
  };

  const setOrgLogo = (photo, username) => {
    console.log("photo", photo, username);

    const formdata = new FormData();
    formdata.append("profile_pic ", photo);
    formdata.append("user_email ", username);
    console.log("formData", formdata);
    setformData(formdata);
    setOrgPic.reFetch();

    // return setOrgPic.response?.data.data
  };

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    console.log("here");

    setisPosting(true);
  }, [formData]);

  return (
    <div>
      <Fragment>
        <ChangeImage
          showModal={ShowModal}
          uploadImage={setOrgLogo}
          username={NewUser}
          toggle={showChangePictureModal}
        />
        <div
          className={"p-3"}
          style={{
            backgroundColor: "white",
            marginTop: "70px",
            paddingBottom: "50px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <h3>Add Organization Logo</h3>
          <hr />
          <div className={"row mb-3"}>
            <div className={"col-12 col-md-6 my-1"}>
              <label className={"form-ui-label font-size-13 fw-600"}>
                username
              </label>
              <InputGroup seamless>
                {getAllUser.response ? (
                  <AutoComplete
                    inputProps={{
                      placeholder: "",
                      value: NewUser,
                      onChange: onAddNewUser,
                    }}
                    data={getAllUser.response?.data.data}
                  />
                ) : undefined}
              </InputGroup>
            </div>
          </div>

          {NewUser ? (
            // <div className="nav-profile-avatar d-flex align-items-center">
            //   <span
            //     className={"nav-profile-avatar d-flex align-items-center"}
            //     style={{ fontWeight: "Normal" }}
            //   >
            //     <div
            //       style={{
            //         backgroundImage: `url(${refactorProfileUrl(
            //           getOrgPic.response?.data.data
            //         )})`,
            //       }}
            //       className={"avater-img mr-1"}
            //     ></div>
            //   </span>
            //   <Button
            //     onClick={showChangePictureModal}
            //     theme={"primary"}
            //     className={"ml-2"}
            //     size={"sm"}
            //   >
            //     Change Image
            //   </Button>
            // </div>
            <>
              {" "}
              <img
                src={getOrgPic.response?.data.data}
                width={120}
                height={47}
                style={{
                  borderRadius: "10px",
                }}
              />
              {isPosting ? (
                <div className={"poll-sppiner-wrapper"}>
                  <div className="sweet-loading">
                    <ScaleLoader
                      css={override}
                      sizeUnit={"px"}
                      size={150}
                      color={"#FF8260"}
                      loading={isPosting}
                    />
                  </div>
                </div>
              ) : (
                <ImageUploader
                  withIcon={true}
                  buttonText="Choose image"
                  onChange={onDrop}
                  imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                  maxFileSize={1242880}
                >
                  {/* <div
                  style={{
                    backgroundImage: `url(${refactorProfileUrl(
                      getOrgPic.response?.data.data
                    )})`,
                  }}
                  className={"avater-img mr-1"}
                ></div> */}
                </ImageUploader>
              )}
            </>
          ) : undefined}
        </div>
      </Fragment>
    </div>
  );
};

export default withRouter(connect(null, {})(AdminAddOrgLogo));
