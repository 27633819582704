import React from "react";
import MaterialTable from "material-table";
import MUIDataTable from "mui-datatables";

const OrganizationUsers = (props) => {
  const org_user_table_rows_name = [
    {
      title: "ID",
      render: (rowData) => rowData.tableData.id + 1,
    },
    { title: "Name", field: "first_name" },
    { title: "Family", field: "last_name" },
    { title: "Username", field: "username" },
    {
      title: "Email Address",
      field: "email",
    },
  ];


  return (
    <>
      <MaterialTable
        title="Organization Users"
        columns={org_user_table_rows_name}
        data={props.data}
        localization={{
          header: {
            actions: "More",
          },
        }}
        actions={[
          {
            icon: "account_circle",
            tooltip: "User Profile",
            onClick: (event, rowData) =>
              props.history.push(`/people/users/${rowData.username}`),
          },
          (rowData) => ({
            icon: "delete",
            tooltip: "Remove User",
            onClick: (event, rowData) => {
              props.setshowRemoveToggle((before) => !before);
              props.setOnRemoveUser(rowData.email);
            },
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          //   sorting: true,
          //   filtering: true,
        }}
      />

      {/* <MUIDataTable
        title={"Employee List"}
        data={props.data}
        columns={columns}
        options={options}
      /> */}
    </>
  );
};

export default OrganizationUsers;
