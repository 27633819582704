import React, {Component} from "react";
import Autosuggest from 'react-autosuggest';

export default class AutoCompleteAttribute extends Component {
    constructor() {
        super();
        this.state = {
            suggestions: []
        };
    }

    componentDidMount() {
        this.setState({
            suggestions: this.props.data
        })
    }

    shouldRenderSuggestions = (value) => {
        return value.trim().length >= 0;
    };


    getSuggestions = value => {

        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? this.props.data : this.props.data.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };


    getSuggestionValue = suggestion => suggestion.name;


    renderSuggestion = suggestion => (
        <div className={"react-auto-suggest-item"}>
            {suggestion.name}
            <span className={"ml-1 text-muted font-size-10"}>{suggestion["field"]}</span>
        </div>
    );

    onSuggestionsFetchRequested = ({value}) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const {suggestions} = this.state;
        const inputProps = this.props.inputProps;

        return (
            <Autosuggest
                suggestions={suggestions}
                className={"form-control"}
                shouldRenderSuggestions={this.shouldRenderSuggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
            />
        );
    }
}