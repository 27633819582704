import React, {Component} from "react";
import Modal from 'react-responsive-modal';
import {Button} from "shards-react";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {IoMdCheckmarkCircle, IoMdCloseCircle} from "react-icons/io";
import {getAllUser, followUser} from "../../actions";
import {connect} from "react-redux";
import {css} from '@emotion/core';
import {PulseLoader} from 'react-spinners';
import AutoCompleteAddFriend from "../../layout/UI/AutoCompleteAddFriend";


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

/**
 * AddFriendModal class renders an autocomplete input for selecting people to follow.
 * @prop {object} name
 */
class InviteFriendModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            people: "",
            peopleList: [],
            loading: false,
            following: false
        }
    }

    componentDidMount() {
        this.setState({
            loading: true
        });
        this.props.getAllUser()
            .then(res => res.json())
            .then(data => this.setState({
                peopleList: data["results"],
                loading: false
            }))
    }

    showSuccessMessage = (text) => toast.notify(
        <p className={"m-0 p-0 font-size-14"}>
            <span className={"mr-2 font-size-19"} style={{verticalAlign: "1px"}}>
                    <IoMdCheckmarkCircle style={{color: "#52c41a"}}/>
            </span>
            {text}
        </p>
        , {
            duration: 2000
        });


    showErrorMessage = (text) => toast.notify(
        <p className={"m-0 p-0 font-size-14"}>
            <span className={"mr-2 font-size-19"} style={{verticalAlign: "1px"}}>
                <IoMdCloseCircle style={{color: "#f5222d"}}/>
            </span>
            {text}
        </p>
        , {
            duration: 2000
        });

    followUser = async () => {
        this.setState({
            following: true
        });
        let follower = this.props.sessionID;
        let following = this.state.people;
        if (!following) {
            this.setState({
                following: false
            });
            return this.showErrorMessage("Please select a username from list");
        }
        if (follower === following) {
            this.setState({
                following: false
            });
            return this.showErrorMessage("You can not connect to yourself !");
        }
        await this.props.followUser(follower, following)
            .then(res => {
                if (res.status === 201) {
                    return this.showSuccessMessage("User Connected Successfully");
                } 
                else if(res.status === 300){
                    return this.showErrorMessage("This user is already followed.");
                }else {
                    return this.showErrorMessage("Something went wrong");
                }
            });
        this.setState({
            following: false,
            people: ""
        });
        return this.props.onCloseModal()
    };

    handleEnterKey = (e) => {
        if (e.keyCode === 13) {
            return this.followUser()
        }
    };

    onChangePeople = (event, {newValue}) => {
        this.setState({
            people: newValue
        });
    };

    render() {
        return (
            <div onKeyDown={this.handleEnterKey}>
                <Modal
                    animationDuration={100}
                    open={this.props.show} onClose={this.props.onCloseModal} top>
                    <h4>Invite Experts</h4>
                    <hr/>

                    <div className={"mb-3"}>
                        <AutoCompleteAddFriend
                            inputProps={{
                                placeholder: 'Email',
                                value: this.state.people,
                                onChange: this.onChangePeople
                            }}
                            data={this.state.peopleList}/>

                    </div>

                    <div style={{width: "500px"}} className={"d-flex justify-content-end"}>
                        <Button
                            disabled={this.state.following}
                            onClick={this.followUser}
                            className={"mr-1"}>
                            {this.state.following ? (
                                <div className='sweet-loading'>
                                    <PulseLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        color={"white"}
                                        size={7}
                                        loading={true}
                                    />
                                </div>) : "Invite"}
                        </Button>
                        <Button
                            onClick={this.props.onCloseModal}
                            className={"ml-1"}>
                            Cancel
                        </Button>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = ({session}) => {
    return {
        sessionID: session.data.username
    }
};

export default connect(mapStateToProps, {getAllUser, followUser})(InviteFriendModal);