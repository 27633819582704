import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Badge, Slider } from "shards-react";
import { withRouter } from "react-router";
import { PulseLoader, ScaleLoader } from "react-spinners";
import { css } from "@emotion/core";
import { fetchVotingStep2 } from "../../../actions/index";
import { authHeader } from "../../../actions/AuthHeader";
import { API_URL } from "../../../constants";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

/**
 * SecondStep class renders sliders for voting on people.
 */
class SecondStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 2,
      value: {},
      isPosting: false,
      isVoted: false,
      gotError: false,
      loadingSlider: false
    };
  }

  async componentDidMount() {
    console.log(this.props , this.state)
    this.setState({
      loadingSlider: true
    });
    const { dispatch, match } = this.props;
    // await dispatch(fetchDecisionById(match.params["id"]));
    await dispatch(fetchVotingStep2(match.params["id"]));
    const { attributes, participants } = this.props.data;
    const votedList = this.props.SecondStep["data"];

    if (votedList.length > 0) {
      this.setState({
        isVoted: true
      });
    }
    let a = {};

    attributes.map(item => (a[item] = {}));
    for (let i = 0; i < attributes.length; i++) {
      for (let j = 0; j < participants.length; j++) {
        if (participants[j]["username"] !== this.props.sessionId) {
          a[attributes[i]][participants[j]["username"]] = 5;
        }
      }
    }
    if (this.state.isVoted) {
      for (let i = 0; i < votedList.length; i++) {
        if (votedList[i]["rate"]) {
          a[votedList[i]["attr"]][votedList[i]["user"]] = votedList[i]["rate"];
        }
      }
    }
    // console.log("asd", a , votedList);

    this.setState({
      value: a,
      loadingSlider: false
    });
  }

  operation = (list1, list2, isUnion = false) =>
    list1.filter(
      a => isUnion === list2.some(b => a.attr === b.attr && a.user === b.user)
    );

  getDiff = (list1, list2) => {
    return list1.filter(item => {
      return list2.filter(item2 => {
        return item["attr"] !== item2["attr"] && item["user"] !== item2["user"];
      });
    });
  };

  submitVote = async () => {
    this.setState({
      gotError: false,
      isPosting: true
    });

    // putting data which are voted before
    let putData = this.props.SecondStep["data"].map(item => {
      return {
        attr: item["attr"],
        user: item["user"],
        rate: this.state.value[item["attr"]][item["user"]],
        poll:parseInt(this.props.match.params['id'])
      };
    });
    console.log("putData",putData)
    for (let i = 0; i < this.props.SecondStep.data.length; i++) {
      let id = this.props.SecondStep.data[i]["id"];
      await fetch(`${API_URL}/userskillvotes/${id}/`, {
        method: "PUT",
        body: JSON.stringify(putData[i]),
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: authHeader()
        })
      }).then(res => {
        if (res.status === 400) {
          this.setState({ gotError: true });
        }
      });
    }
    // finish putting

    let postData;
    postData = Object.keys(this.state.value).map(item => {
      return Object.keys(this.state.value[item]).map(subItem => {
        return {
          rate: this.state.value[item][subItem],
          attr: item,
          user: subItem,
          poll:parseInt(this.props.match.params['id'])
        };
      });
    });
    let newPostData = [];
    for (let i = 0; i < postData.length; i++) {
      newPostData.push(...postData[i]);
    }

    let finalPostData = this.operation(newPostData, putData);
    console.log("final",finalPostData)
    await fetch(`${API_URL}/userskillvotes/`, {
      method: "POST",
      body: JSON.stringify(finalPostData),
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader()
      })
    }).then(res => {
      if (res.status === 400) {
        this.setState({ gotError: true });
      }
    });

    this.setState({
      isPosting: false
    });
    if (this.state.gotError) {
      return null;
    }
    return this.props.nextStep();
  };

  _renderContinueBtn = () => {
    return (
      <button
        className="new-decision-button btn-success-custom btn btn-success mx-2"
        onClick={this.submitVote}
      >
        {this.state.isPosting ? (
          <div className="sweet-loading">
            <PulseLoader
              css={override}
              sizeUnit={"px"}
              color={"white"}
              size={7}
              loading={true}
            />
          </div>
        ) : (
          "Continue"
        )}
      </button>
    );
  };

  render() {
    const { attributes, participants } = this.props.data;
    return (
      <Fragment>
        {attributes !== undefined && participants !== undefined ? (
          <div className={"mx-3 mx-md-5"}>
            <h3 className={"fw-600"}>Merit Matrix</h3>
            <h6 className={"fw-600"}>
              Expertise level of participants in each attribute. If you don’t know a participant leave the rating at
                5.{" "}
              <br/>
              <pre style={{color:'red'}}>
                (1:Low expertise  5:Average expertise  9:High
                Expertise) 
              </pre>
            </h6>

            <hr />
            {!this.state.loadingSlider ? (
              attributes.map((item, index) => {
                if (this.state.value[item] !== undefined) {
                  return (
                    <div key={index}>
                      <h5 className={"fw-600 m-0 p-0"}>
                        Rate participants in attribute:
                        <Badge pill className={"ml-2"}>
                          {item}
                        </Badge>
                      </h5>
                      {participants.map((subItem, index) => {
                        if (this.props.sessionId !== subItem["username"]) {
                          return (
                            <div
                              className={"row align-items-center"}
                              key={index}
                            >
                              <div className={"col-12 col-md-3"}>
                                <h6 className={"fw-600 font-size-13 m-0 p-0"}>
                                  {subItem["username"]}
                                </h6>
                              </div>
                              <div className={"col-12 col-md-8"}>
                                <Slider
                                  onSlide={e => {
                                    let newValue = { ...this.state.value };
                                    console.log(newValue);
                                    newValue[item][
                                      subItem["username"]
                                    ] = parseFloat(e[0]);
                                    this.setState({
                                      value: newValue
                                    });
                                  }}
                                  connect={[true, false]}
                                  step={1}
                                  start={[
                                    this.state.value[item][subItem["username"]]
                                  ]}
                                  range={{ min: 1, max: 9 }}
                                />
                              </div>
                              <div className={"col-12 col-md-1"}>
                                <h6 className={"fw-600 font-size-13 m-0 p-0"}>
                                  {this.state.value[item][subItem["username"]]}{" "}
                                  / 9
                                </h6>
                              </div>
                            </div>
                          );
                        }
                      })}
                      <hr />
                    </div>
                  );
                }
                return null;
              })
            ) : (
              <div className={"d-flex justify-content-center"}>
                <div className="sweet-loading">
                  <ScaleLoader
                    css={override}
                    sizeUnit={"px"}
                    size={150}
                    color={"#FF8260"}
                    loading={true}
                  />
                </div>
              </div>
            )}

            <div>
              <div className={"d-flex justify-content-end my-3"}>
                {this.state.step > 1 && (
                  <button
                    className="new-decision-button btn-success-custom btn btn-success mx-2"
                    onClick={this.props.previousStep}
                  >
                    Go Back
                  </button>
                )}
                {this.state.step < this.props.totalSteps ? (
                  this._renderContinueBtn()
                ) : (
                  <button
                    className="new-decision-button btn-success-custom btn btn-success mx-2"
                    onClick={this.props.nextStep}
                  >
                    Finish
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  // console.log("state",state)
  return {
    ...state.selectedDecision,
    SecondStep: state.votingStep.SecondStep,
    sessionId: state.session.data.username
  };
};

export default withRouter(connect(mapStateToProps, null)(SecondStep));
