import React, {Component, Fragment} from "react";
import {withRouter} from "react-router";
import {Button, FormInput, InputGroup, InputGroupAddon, InputGroupText} from "shards-react";
import {FaRegEdit} from "react-icons/fa";
import {IoMdCheckmarkCircle, IoMdCloseCircle} from "react-icons/io";
import {css} from '@emotion/core';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {PulseLoader} from 'react-spinners';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

/**
 * Email class is used to change current user email.
 */
class Email extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            invalidEmail: false,
            sendingData: false,
        }
    }

    validateEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    showErrorMessage = (text) => toast.notify(
        <p className={"m-0 p-0 font-size-14"}>
            <span className={"mr-2 font-size-19"} style={{verticalAlign: "1px"}}>
                <IoMdCloseCircle style={{color: "#f5222d"}}/>
            </span>
            {text}
        </p>
        , {
            duration: 2000
        });

    showSuccessMessage = (text) => toast.notify(
        <p className={"m-0 p-0 font-size-14"}>
            <span className={"mr-2 font-size-19"} style={{verticalAlign: "1px"}}>
                    <IoMdCheckmarkCircle style={{color: "#52c41a"}}/>
            </span>
            {text}
        </p>
        , {
            duration: 2000
        });


    componentDidMount() {
        this.setState({
            email: this.props.data["email"],
        })
    }

    applyChange = () => {
        if (!this.validateEmail(this.state.email)) {
            this.showErrorMessage("Email is not in correct form !");
            this.setState({
                invalidEmail: true
            });
            return;
        }
        this.setState({
            sendingData: true
        });
        let data = {
            "email": this.state.email,
        };
        this.props.setUserProfileInfo(this.props.sessionID, data)
            .then(data => {
                this.setState({
                    sendingData: false
                });
                this.showSuccessMessage("Updated !")
            })
    };


    onFocusInput = (e, labelElement) => {
        labelElement.style.color = "#008AFF"

    };

    onBlurInput = (e, labelElement) => {
        labelElement.style.color = "#5a6169"
    };

    render() {
        return (
            <Fragment>
                <div className={"p-3"}
                     style={{
                         backgroundColor: "white",
                         marginTop: "70px",
                         paddingBottom: "50px",
                         border: "1px solid rgba(0, 0, 0, 0.1)"
                     }}
                >
                    <form>
                        <h3>Email Addresses</h3>
                        <hr/>
                        <div className={"row mb-3"}>
                            <div className={"col-12 col-md-6 my-1"}>
                                <label className={"form-ui-label font-size-13 fw-600"}
                                       ref={(element) => this.elementEmail = element}>
                                    Email
                                </label>
                                <InputGroup seamless>
                                    <InputGroupAddon type="prepend">
                                        <InputGroupText>
                                            <FaRegEdit/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <FormInput
                                        invalid={this.state.invalidEmail}
                                        onChange={(e) => this.setState({
                                            email: e.target.value,
                                            invalidEmail: false
                                        })}
                                        value={this.state.email}
                                        disabled
                                        onFocus={(e) => this.onFocusInput(e, this.elementEmail)}
                                        onBlur={(e) => this.onBlurInput(e, this.elementEmail)}/>
                                </InputGroup>
                            </div>
                        </div>

                    </form>
                    {/* <Button theme={"primary"}
                            onClick={this.applyChange}
                    >
                        {this.state.sendingData ? (
                            <div className='sweet-loading'>
                                <PulseLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    color={"white"}
                                    size={7}
                                    loading={true}
                                />
                            </div>) : "Save"}
                        <IoMdCheckmarkCircle className={"ml-2 font-size-19"}/>
                    </Button> */}
                </div>
            </Fragment>
        )
    }
}

export default withRouter(Email)