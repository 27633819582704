import * as Constant from "../constants/index";


const initialState = {
    data: [],
    isFetching: false,
    gotError: false,
    textError: ""
};

export const profile = (state = initialState, action) => {
    switch (action.type) {
        case Constant.REQUEST_USER_PROFILE:
            return {
                ...state,
                isFetching: true,
                gotError: false,
            };
        case Constant.RECEIVE_USER_PROFILE:
            return {
                ...state,
                isFetching: false,
                gotError: false,
                data: action.data
            };
        case Constant.FAILED_FETCH_USER_PROFILE:
            return {
                ...state,
                isFetching: false,
                gotError: true,
                textError: action.err
            };
        default:
            return state
    }
};