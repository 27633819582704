import React, { Component } from "react";
import { Button, Form, FormGroup, FormInput } from "shards-react";
import "react-quill/dist/quill.snow.css";
import Modal from "react-responsive-modal";
import { css } from "@emotion/core";
import { PulseLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
/**
 * AddAttribute class renders a input for attribute name and creates an attribute in project.
 */
class AddAttribute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      field: "",
      isLoading: false,
      invalidName: false
    };
  }

  validate = async () => {
    await this.setState({
      invalidName: this.state.name.length === 0,
      invalidField: this.state.field.length === 0
    });
    if (this.state.invalidName) {
      return;
    }
    let data = {
      field: this.state.field,
      name: this.state.name
    };
    this.setState({
      name: "",
      field: ""
    });
    return this.props.createAttribute(data);
  };

  handleEnterKey = e => {
    if (e.keyCode === 13) {
      return this.validate();
    }
  };

  render() {
    return (
      <Modal open={this.props.showModal} onClose={this.props.toggle} center>
        <div onKeyDown={this.handleEnterKey}>
          <h3>
            Create Attribute{" "}
            <span style={{ color: "red", fontSize: 12 }}>
              maximum 20 character{" "}
              {this.state.name.length > 0
                ? "(now " + this.state.name.length + ")"
                : ""}
            </span>
          </h3>
          <hr />
          <div style={{ width: "500px" }}>
            <Form onSubmit={e => e.preventDefault()}>
              <FormGroup>
                <label htmlFor="#AttributeName">Attribute Name</label>
                <FormInput
                  id="#AttributeName"
                  value={this.state.name}
                  invalid={this.state.invalidName}
                  onChange={e => {
                    if (this.state.name.length<20 || e.target.value.length < 20)
                      this.setState({
                        name: e.target.value,
                        invalidName: false
                      });
                  }}
                  placeholder="Attribute Name"
                />
              </FormGroup>
              {/*<FormGroup>*/}
              {/*<label htmlFor="#Field">Field</label>*/}
              {/*<FormInput id="#Field"*/}
              {/*value={this.state.field}*/}
              {/*onChange={(e) => this.setState({field: e.target.value, invalidField: false})}*/}
              {/*placeholder="Field"/>*/}
              {/*</FormGroup>*/}
            </Form>
          </div>
          <hr />
          <div>
            <Button theme={"primary"} onClick={this.validate}>
              {this.props.showSpinner ? (
                <div className="sweet-loading">
                  <PulseLoader
                    css={override}
                    sizeUnit={"px"}
                    color={"white"}
                    size={7}
                    loading={true}
                  />
                </div>
              ) : (
                "Create"
              )}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default AddAttribute;
