import React, { Component } from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

class SubmenuAttribute extends Component {
  render() {
    return (
      <div className={"submenu-items font-size-13"}>
        <NavLink
          className={"submenu-item hvr-sweep-to-top"}
          activeClassName={"submenu-item-active"}
          to={"/attribute/t"}
        >
          All Attributes
        </NavLink>
      <NavLink
          className={"submenu-item hvr-sweep-to-top"}
          activeClassName={"submenu-item-active"}
          to={"/attribute/favourites"}
        >
          Favourites
        </NavLink>
        {this.props.user ? (
          this.props.user.is_enterprise ? (
            <NavLink
              className={"submenu-item hvr-sweep-to-top"}
              activeClassName={"submenu-item-active"}
              to={"/attribute/org"}
            >
              Org. Attributes
            </NavLink>
          ) : undefined
        ) : undefined}

      </div>
    );
  }
}
const mapStateToProps = (state) => {
  console.log("sub menu state", state);

  return {
    user: state.session.data,
    sessionID: state.session.data.username,
  };
};

export default withRouter(connect(mapStateToProps, {})(SubmenuAttribute));
