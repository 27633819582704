import React, { useState, useEffect, useRef, useLayoutEffect } from "react";

import { Slider, Badge } from "shards-react";
import { css } from "@emotion/core";
import { API_URL } from "../../../constants";
import { authHeader } from "../../../actions/AuthHeader";
import useAxios from "@use-hooks/axios";
import { Container } from "shards-react";

const CopyFirstStep = (props) => {
  const [attr, setAttr] = useState([]);
  const [a, setA] = useState({});
  const [value, setValue] = useState({});
  const [step, setStep] = useState(1);
  const [postValues, setPostValues] = useState("");
  const [putValues, setPutValues] = useState("");
  const [isVoted, setIsVoted] = useState(false);
  const [votedData, setVotedData] = useState(false);
  const [putIdData, setPutIdData] = useState();
  const [updateComplete, setUpdateComplete] = useState(false);

  const checkIsVote = useAxios({
    url: `${API_URL}/pollCopy/${props.poll.id}/check_status/`,
    method: "POST",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: {
        user: props.user,
      },
    },
    trigger: [],
    customHandler: (err, res) => {
      if (res) {
        if (res.data.length > 0) {
          console.log("status", res.data);
          setIsVoted(true);
          setVotedData(res.data);
        }
      }
    },
  });

  const addPollCopyVote = useAxios({
    url: `${API_URL}/voteCopyService/`,
    method: "POST",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: postValues,
    },
    trigger: postValues,

    forceDispatchEffect: () => !!postValues,
    customHandler: (err, res) => {
      if (res) {
        console.log("res", res);
        props.nextStep();
      }
    },
  });

  const putCopyVote = useAxios({
    url: `${API_URL}/voteCopyService/${putIdData}/`,
    method: "PUT",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: putValues,
    },
    // trigger: putIdData,

    // forceDispatchEffect: () => !!putIdData,
    customHandler: (err, res) => {
      if (res) {
        console.log("Put res", res);
        props.nextStep();
      }
    },
  });

  const submitVote = async () => {
    if (!isVoted) {
      let postData;
      postData = Object.keys(value).map((item) => {
        return {
          rate: value[item],
          attribute: item,
          poll: props.poll.id,
          voted_by: props.user,
        };
      });

      setPostValues(postData);
    } else {
      let putData;
      putData = Object.keys(value).map((item) => {
        return {
          rate: value[item],
          attribute: item,
          poll: props.poll.id,
          voted_by: props.user,
        };
      });

      for (let i = 0; i < votedData.length; i++) {
        let id = votedData[i]["id"];
        console.log(id);
        setPutValues(putData[i]);
        // setPutIndex(i)
        await setPutIdData(id);
        await putCopyVote.reFetch();
      }
    }
  };

  const _renderContinueBtn = () => {
    return (
      <button
        className="new-decision-button btn-success-custom btn btn-success mx-2"
        onClick={submitVote}
      >
        Continue
      </button>
    );
  };

  useEffect(() => {
    console.log("props first ", props);

    setAttr(props.poll["attributes"]);

    props.poll["attributes"].map((item) => {
      return (a[item] = 5);
    });

    setValue(a);

    return () => {
      console.log('unmount')
    };
  }, []);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    console.log("here");
    votedData.map((item) => {
      return (a[item.attribute] = item.rate);
    });

    setValue(a);
  }, [votedData]);

  // const Update = useRef(true);
  // useLayoutEffect(() => {
  //   if (Update.current) {
  //     Update.current = false;
  //     return;
  //   }
  //   console.log("on update");
  //   props.nextStep();
  // }, [updateComplete]);

  return (
    <Container>
      <div className={"mx-3 mx-md-5"}>
        <div className={"setp-1-wrapper"}>
          <h3 className={"fw-600"}>Importance Matrix</h3>
          <h6 className={"fw-600"}>
            Vote for importance of each attribute in this decision
            <br />
            <pre style={{ color: "red" }}>
              (1: Not Important 5: Neutral 9: Very Important)
            </pre>
          </h6>
          <hr />
          <div className={"row align-items-center"}>
            <div className={"col-12 col-md-3"}>
              <h6 className={"text-muted fw-600 font-size-13 m-0 p-0"}>
                Attributes
              </h6>
            </div>
          </div>
          <div className={"slider-wrapper"}>
            {/* {!this.props.FirstStep["isFetching"] ? ( */}
            {attr &&
              attr.map((item, index) => {
                return (
                  <div className={"row align-items-center"} key={index}>
                    <div className={"col-12 col-md-12"}>
                      <h6 className={"fw-600 font-size-18 m-0 p-0"}>
                        <Badge pill>{item}</Badge>
                      </h6>
                    </div>
                    <div className={"col-12 col-md-11 p-0 m-0"}>
                      <Slider
                        onSlide={(e) => {
                          let newValue = {};
                          Object.keys(value).map((subItem) => {
                            return (newValue[subItem] = value[subItem]);
                          });
                          newValue[item] = parseFloat(e[0]);
                          setValue(newValue);
                        }}
                        connect={[true, false]}
                        step={1}
                        start={[value[item]]}
                        range={{ min: 1, max: 9 }}
                      />
                    </div>
                    <div className={"col-12 col-md-1"}>
                      <h6 className={"fw-600 font-size-13 m-0 p-0"}>
                        {value[item]} / 9
                      </h6>
                    </div>
                  </div>
                );
              })}
          </div>
          <hr />
          <div>
            <div className={"d-flex justify-content-end my-3"}>
              {step > 1 && (
                <button
                  className="new-decision-button btn-success-custom btn btn-success mx-2"
                  onClick={props.previousStep}
                >
                  Go Back
                </button>
              )}
              {step < props.totalSteps ? (
                _renderContinueBtn()
              ) : (
                <button
                  className="new-decision-button btn-success-custom btn btn-success mx-2"
                  onClick={props.nextStep}
                >
                  Finish
                </button>
              )}
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    </Container>
    // </div>
  );
};

export default CopyFirstStep;
