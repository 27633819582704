import React, { Component, Fragment, useState, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import { MdEmail } from "react-icons/md";

import {
  Row,
  Col,
  Container,
  Button,
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";
import { connect } from "react-redux";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import { API_URL } from "../../constants";
import { authHeader } from "../../actions/AuthHeader";
import useAxios from "@use-hooks/axios";
import {
  IoMdCheckmarkCircle,
  IoMdCloseCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import MaterialTable from "material-table";
import { PulseLoader, ScaleLoader } from "react-spinners";
import Modal from "react-responsive-modal";
import { css } from "@emotion/core";
import OrganizationUsers from "./adminAccount/OrganizationUsers";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const AdminAccountUsers = (props) => {
  const [orgUsers, setOrgUsers] = useState(null);
  const [showRemoveToggle, setshowRemoveToggle] = useState(false);
  const [showAddToggle, setshowAddToggle] = useState(false);

  const [isPosting, setisPosting] = useState(false);
  const [OnremoveUser, setOnRemoveUser] = useState("");
  const [OnAddUser, setOnAddUser] = useState("");

  const getOrganizationUser = useAxios({
    url: `${API_URL}/users/${props.sessionID}/org_users/`,
    options: {
      headers: {
        Authorization: authHeader(),
      },
    },
    trigger: [],
    customHandler: (err, res) => res && setOrgUsers(res.data.data),
    // setOrgUsers(res.data.data),
  });

  const validateEmail = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const createOrgUser = useAxios({
    url: `${API_URL}/users/${props.sessionID}/org_add_users/`,
    method: "POST",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: {
        email: OnAddUser,
      },
    },
    // trigger: [],
    customHandler: (err, res) => {
      if (res) {
        setisPosting(false);

        setshowAddToggle((before) => !before);
        setOnAddUser("");
        console.log("res", res);
        const message = res.data.data;
        console.log(message);
        if (message == "user_invite_exists") {
          console.log("there");
          showErrorMessage("user invitation exists");
        } else if (message === "user_is_in_org") {
          showErrorMessage("user is in organization");
        } else if (message === "invitation_is_send") {
          showSuccessMessage("Invitation sent");
        } else if (message === "your_plan_user_is_completed") {
          showErrorMessage("your plan user is completed");
        }
      }
    },
  });

  const removeOrgUser = useAxios({
    url: `${API_URL}/users/${props.sessionID}/org_remove_users/`,
    method: "POST",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: {
        email: OnremoveUser,
      },
    },
    // trigger: [],
    customHandler: (err, res) => {
      if (res) {
        setisPosting(false);

        console.log("res", res);
        const message = res.data.data;
        console.log(message);
        if (message == "not_found") {
          console.log("there");
          showErrorMessage("user not found");
        } else if (message === "user_removed") {
          showSuccessMessage("user is removed");
        } else if (message === "you_are_owner") {
          showErrorMessage("you cant remove yourself !");
        }
        setshowRemoveToggle((before) => !before);
        getOrganizationUser.reFetch();
      }
    },
  });

  const showErrorMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

  const showSuccessMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );
  useEffect(() => {
    console.log("asd");
  }, []);

  const removeUser = () => {
    // ! add isposting
    // setisPosting(true)
    removeOrgUser.reFetch();
  };

  const addUser = () => {
    if (!validateEmail(OnAddUser)) {
      return showErrorMessage("Email is not in correct form !");
    }
    setisPosting(true);
    createOrgUser.reFetch();
  };

  const handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      return addUser();
    }
  };

  // console.log("onRemove", OnremoveUser);

  return (
    <div>
      <Fragment>
        <Modal
          animationDuration={100}
          open={showRemoveToggle}
          onClose={() => setshowRemoveToggle((before) => !before)}
          top
        >
          <h5>
            Your user will be remove.
            <br />
            Are you sure? ‬
          </h5>
          <hr />

          <div
            style={{ width: "500px" }}
            className={"d-flex justify-content-end"}
          >
            <Button onClick={removeUser} className={"mr-1"}>
              {isPosting ? (
                <div className="sweet-loading">
                  <PulseLoader
                    css={override}
                    sizeUnit={"px"}
                    color={"white"}
                    size={7}
                    loading={true}
                  />
                </div>
              ) : (
                "Yes"
              )}
            </Button>
            <Button
              onClick={() => {
                setshowRemoveToggle((before) => !before);
                setOnRemoveUser("");
              }}
              className={"ml-1"}
            >
              No
            </Button>
          </div>
        </Modal>

        <div onKeyDown={handleEnterKey}>
          <Modal
            animationDuration={100}
            open={showAddToggle}
            onClose={() => {
              setshowAddToggle((before) => !before);
            }}
            center
          >
            <h4>Invite a friend or colleague to MeriTa:</h4>
            <hr />
            <div style={{ width: "500px" }}></div>
            <InputGroup>
              <InputGroupAddon type="prepend">
                <InputGroupText>
                  <MdEmail />
                </InputGroupText>
              </InputGroupAddon>
              <FormInput
                value={OnAddUser}
                onChange={(e) => setOnAddUser(e.target.value)}
              />
              <InputGroupAddon type="append">
                <Button
                  // disabled={this.state.sending}
                  onClick={addUser}
                  theme="primary"
                >
                  {isPosting ? (
                    <div className="sweet-loading">
                      <PulseLoader
                        css={override}
                        sizeUnit={"px"}
                        color={"white"}
                        size={7}
                        loading={true}
                      />
                    </div>
                  ) : (
                    "Send"
                  )}
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Modal>
        </div>
        <div
          className={"form-ui p-3"}
          style={{
            backgroundColor: "white",
            marginTop: "70px",
            paddingBottom: "50px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          {props.data.org ? (
            <Container>
              <Row>
                <Col xs="12" sm="12" md="12" lg="12">
                  <div className={"  my-1"}>
                    <h3 className={"form-ui-label font-size-13 fw-600"}>
                      My Organization:
                      <span style={{ marginLeft: "10px" }}>
                        {props.data.org.org_name}
                      </span>
                      <br />
                      <h5
                        className={"form-ui-label font-size-13 fw-600"}
                        style={{ marginTop: "5px" }}
                      >
                        No. of Users:
                        <span style={{ marginLeft: "10px" }}>
                          {getOrganizationUser.response?.data.data.length}
                        </span>
                      </h5>
                      <h5
                        className={"form-ui-label font-size-13 fw-600"}
                        style={{ marginTop: "5px" }}
                      >
                        No. of Decisions:
                        <span style={{ marginLeft: "10px" }}>
                          {props.data.org.org_poll}
                        </span>
                      </h5>
                    </h3>
                  </div>
                </Col>
              </Row>
              <button
                className="btn btn-success-custom align-self-end font-size-13 fw-600 mb-4"
                onClick={() => setshowAddToggle((before) => !before)}
              >
                Invite to my Organizatin
              </button>
              {getOrganizationUser.response ? (
                <OrganizationUsers
                  history={props.history}
                  data={orgUsers}
                  setOnRemoveUser={setOnRemoveUser}
                  setshowRemoveToggle={setshowRemoveToggle}
                />
              ) : undefined}
            </Container>
          ) : undefined}
        </div>
      </Fragment>
    </div>
  );
};

export default withRouter(connect(null, {})(AdminAccountUsers));
