import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
// import toast from "toasted-notes";
// import "toasted-notes/src/styles.css";
// import { ScaleLoader } from "react-spinners";
import { css } from "@emotion/core";
// import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
// import { confirmEmail } from "../../actions";
import { Link } from "react-router-dom";

import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn
} from "react-icons/fa";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

/**
 * ConfirmAccount class confirms account of user.
 */
class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      text: "",
      className: "header_area"
    };
  }

  //   componentDidMount() {
  //     let key = this.props.match.params.key;
  //     this.setState({
  //       isLoading: true
  //     });
  //     confirmEmail(key)
  //       .then(res => {
  //         if (res.status === 404) {
  //           return this.setState({
  //             isLoading: false,
  //             text: "Something went wrong. Please try again!"
  //           });
  //         } else {
  //           this.setState({
  //             isLoading: false,
  //             text: "Your Account has been verified successfully."
  //           });
  //         }
  //       })
  //       .then(data => console.log(data));
  //   }

  //   showErrorMessage = text =>
  //     toast.notify(
  //       <p className={"m-0 p-0 font-size-14"}>
  //         <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
  //           <IoMdCloseCircle style={{ color: "#f5222d" }} />
  //         </span>
  //         {text}
  //       </p>,
  //       {
  //         duration: 2000
  //       }
  //     );

  //   showSuccessMessage = text =>
  //     toast.notify(
  //       <p className={"m-0 p-0 font-size-14"}>
  //         <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
  //           <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
  //         </span>
  //         {text}
  //       </p>,
  //       {
  //         duration: 2000
  //       }
  // );

  render() {
    // console.log(this.state);
    return (
      <Fragment>
        <header
          style={{ backgroundColor: "#3bacf0" }}
          className={this.state.className}
          id={"Home"}
        >
          <div className="main_menu">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div
                className="container box_1620"
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <a className="navbar-brand logo_h">
                  <img
                    src={require("../assets/icon.png")}
                    height={45}
                    width={140}
                    alt=""
                  />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>

                <div
                  className="collapse navbar-collapse offset"
                  id="navbarSupportedContent"
                >
                  <ul className="nav navbar-nav menu_nav justify-content-center">
                    <li className="nav-item">
                      <a className="nav-link" href="/">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/#Story">
                        Our Story
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/#info">
                        How it works
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#info">
                        <Link to={"/pricing"} >
                        Pricing
                        </Link>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/#RayDalio">
                        Ray Dalio
                      </a>
                    </li>
                  </ul>
                  <ul className="nav navbar-nav navbar-right">
                    {this.state.isLoggedIn ? (
                      <span
                        className={
                          "nav-profile-avatar d-flex flex-column align-items-center my-2"
                        }
                      >
                        <Link to={"/decision"}>
                          <div
                            className={"avater-img"}
                            style={{
                              backgroundImage:
                                "url(" + this.props.session.data.pic + ")"
                            }}
                          ></div>
                        </Link>

                        {/*<span*/}
                        {/*className="text-white">{this.props.session.data.username}*/}
                        {/*</span>*/}
                      </span>
                    ) : (
                      <li className="nav-item">
                        <Link to={"/login"} className="tickets_btn">
                          Login / Sign up
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>

        {/* <div className={"row justify-content-center"}>
            <div className={"col-12 col-sm-12 col-md-8 col-lg-6"}>
              <div className="card">
                <article className="card-body">
                  <h4 className="card-title text-center mb-4 mt-1">
                    Confirming your email
                  </h4>
                  <hr />
                  {this.state.isLoading ? (
                    <div className="sweet-loading text-center">
                      <ScaleLoader
                        css={override}
                        sizeUnit={"px"}
                        size={150}
                        color={"#FF8260"}
                        loading={this.props.isFetching}
                      />
                    </div>
                  ) : (
                    <div>
                      <p className={"text-center"}>{this.state.text}</p>
                      <p className={"text-center"} style={{ color: "red" }}>
                        Now you can login using the botton on top of screen.
                      </p>
                    </div>
                  )}
                </article>
              </div>
            </div>
          </div> */}
        <Fragment>
          <section className="made_life_area p_120" id={"Story"}>
            <div className="container">
              <div className="made_life_inner">
                <div className="row made_life_text">
                  <div className="col-lg-11">
                    <div className="left_side_text">
                      <h3>
                        MeriTa Privacy Policy
                        <br />
                      </h3>
                      <p className={"text-black"}>
                        Last updated: Jan 6, 2020
                        <br />
                        Thank you for using MeriTa. We are committed to
                        protecting your privacy and, for that reason, we have
                        adopted this Privacy Policy to memorialize our data
                        collection and disclosure practices for the MeriTa
                        website and web applications, and their related tools
                        and services (collectively, the “Services”). The
                        Services are owned and operated by MeriTa, Inc., a
                        Melbourne company (“we”, “us” or “our”). This Privacy
                        Policy applies to information collected through the
                        Services, as well as other information provided to us
                        online or offline by customers or users of our MeriTa;
                        however, it does not apply to information collected from
                        our employees, contractors or vendors. This Privacy
                        Policy describes, among other things:
                        <ul>
                          <li>
                            {" "}
                            Personal and other information we collect about you
                          </li>
                          <li>
                            Personal and other information we collect about you
                          </li>
                          <li>How we use your information</li>
                          <li>
                            How we may share your information with third parties
                          </li>
                          <li>
                            Your choices regarding the personal information we
                            collect about you
                          </li>
                        </ul>
                        MeriTa is conscious of the gravity of decisions in
                        organizations and the sensitivity of information you’re
                        sharing, hence{" "}
                        <u style={{ color: "black" }}>we guarantee</u>:
                        <ul>
                          <li>
                            Your decisions won’t be accessible to anyone except
                            yourself.
                          </li>
                          <li>
                            MeriTa only to collect rudimentary statistics to
                            enhance your decision making experience.
                          </li>
                          <li>
                            MeriTa will take all reasonably possible security
                            measures to safeguard all information from
                            unauthorized access.
                          </li>
                        </ul>
                        We can run MeriTa on your own servers or internal
                        networks(Intranet) where only your staff can access
                        information. If this is something you consider please
                        send an email to{" "}
                        <u style={{ color: "black" }}>info@myMeriTa.com</u>.
                        <br />
                        <br /> 1.Consent
                        <br />
                        <br />
                        By using the Services, you consent to this Privacy
                        Policy. If you do not agree with this Privacy Policy,
                        please do not use the Services or MeriTa. Information
                        gathered through the Services may be transferred, used,
                        and stored in Australia or in other countries where our
                        service providers or we are located. If you use the
                        Services, you agree to the transfer, use and storage of
                        your Personal Information (as defined below) in those
                        countries. The data protection and other laws of
                        Australia and other countries might not be as
                        comprehensive as those in your country. You agree that
                        all transactions relating to the Services or MeriTa are
                        deemed to occur in Australia, where our servers are
                        located. This Privacy Policy is intended to comply with
                        all applicable laws and regulations, including but not
                        limited to the General Data Protection Regulation
                        (“GDPR”) of the European Union.
                        <br />
                        <br />
                        2.Collection of Your Personal and Other Information{" "}
                        <br />
                        <br />
                        When you use our Services, we collect Personal
                        Information. By “Personal Information” we mean
                        information that can identify you, such as:
                        <ul>
                          <li>Name</li>
                          <li>Email address</li>
                          <li>
                            Information contained in any image, photograph or
                            profile you choose to submit to us
                          </li>
                        </ul>
                        Additionally, if your account requires billing
                        information, we will also ask for:
                        <ul>
                          <li>Personal or business address, and</li>
                          <li>
                            Credit card information (which you submit for
                            payment purposes)
                          </li>
                          <li>
                            We do not collect any "special categories of
                            personal data," as defined in the GDPR.
                          </li>
                        </ul>
                        Our Services integrate with internet platforms which
                        provide single sign-on functionality, including Slack,
                        and if you choose to connect such an account to MeriTa,
                        then we will collect authentication information that
                        permits us to connect to the account. We will also
                        collect information from the account (such as your name
                        and email address). Certain aspects of the Services will
                        also access certain features of your mobile device,
                        including its camera, and collect information from those
                        features, such as photographs. You may always choose not
                        to provide Personal Information or prevent MeriTa from
                        accessing certain features of your mobile device, but
                        this may prevent you from receiving certain Services. We
                        also collect non-Personal Information, that is,
                        information that does not personally identify an
                        individual. The non-Personal Information we collect
                        includes how you interact with the Services, information
                        generally collected or “logged” by Internet websites or
                        Internet services when accessed or used by users, and
                        information about your web browser or device accessing
                        or using the Services. Examples of the non-Personal
                        Information we collect are:
                        <ul>
                          <li>Your Internet Protocol (IP address),</li>
                          <li>
                            Information about your operating system and browser,
                            such as its maker or version
                          </li>

                          <li>
                            The pages of our website that you viewed during a
                            visit or the features of the app you use
                          </li>
                          <li>
                            The city and state in which you are located (but not
                            your precise geographic location), and
                          </li>
                          <li>
                            Unique Identifiers, meaning arbitrary codes or
                            series of characters we create to identify unique
                            users without Personal Information.
                          </li>
                        </ul>
                        We will not use non-Personal Information to try to
                        identify you, and if we associate any non-Personal
                        Information with information that personally identifies
                        you, then we will treat it as Personal Information. As
                        discussed in more detail below, we sometimes use cookies
                        and other automatic information gathering technologies
                        to gather non-Personal Information. Information
                        collected by the Services may be collected by us or one
                        of our service providers, but in either case, this
                        Privacy Policy will govern the collection, use and
                        sharing of the information.
                        <br />
                        <br />
                        3. Use of Your Information
                        <br />
                        <br />
                        We use the information we collect to:
                        <ul>
                          <li>Assist us in providing the Services</li>
                          <li>Set up accounts</li>
                          <li>Improve online operations</li>
                          <li>
                            Process transactions (credit card or other payment
                            account information will only be used for this
                            purpose)
                          </li>
                          <li>Provide customer service</li>
                          <li>
                            Provide you with newsletters, notifications and/or
                            other communications or services which you have
                            signed up for or otherwise agreed to receive
                          </li>
                          <li>
                            Send account- or transaction-related communications,
                            such as welcome letters or notifications
                          </li>
                          <li>
                            Perform research and analysis aimed at improving our
                            products and services, and
                          </li>
                          <li>Manage our systems</li>
                        </ul>
                        We will only use your Personal Information in the way we
                        specified when it was collected (including as described
                        in this Privacy Policy). We do not use personal
                        information to make automated decisions.
                        <br />
                        <br />
                        4. Disclosure of Your Information
                        <br />
                        <br />
                        We will not disclose your Personal Information to third
                        parties except as described below. We also will not sell
                        or disclose your Personal Information to third parties
                        for their own marketing purposes unless you have
                        explicitly and affirmatively granted us permission to do
                        so. We will disclose Personal Information to provide the
                        Services, which will be apparent when you use or access
                        MeriTa or when you authorize or instruct us to do so,
                        for example when you choose to use the Services to
                        transmit messages that are viewable by other users of
                        the Services. We may also disclose Personal Information
                        to companies, agents, contractors, service providers or
                        others engaged to perform functions on our behalf (such
                        as processing of payments, provision of data storage,
                        hosting of our website, marketing of our products and
                        services, conducting audits, and performing web
                        analytics). We may also disclose your Personal
                        Information to third parties when we believe, in good
                        faith and in our sole discretion, that such disclosure
                        is reasonably necessary to (a) enforce or apply the
                        terms and conditions of the Services, including
                        investigation of potential violations thereof, (b)
                        comply with legal or regulatory requirements or an
                        enforceable governmental request, (c) protect the
                        rights, property or safety of us, our users or other
                        third parties, (d) prevent a crime or protect national
                        security, or (e) detect, prevent or otherwise address
                        fraud, security or technical issues. Finally, we reserve
                        the right to transfer information (including your
                        Personal Information) to a third party in the event of a
                        sale, merger, or transfer of all or substantially all of
                        the assets of our company relating to MeriTa, or in the
                        unlikely event of a bankruptcy, liquidation or
                        receivership of our business. You will be notified via
                        email or prominent notice on our website for 30 days of
                        any such change in ownership or control of your Personal
                        Information. Lastly, we may also disclose non-Personal
                        Information, anonymously aggregated with information
                        about our other users, to our clients, business
                        partners, merchants, advertisers, investors, potential
                        buyers and other third parties if we deem such
                        disclosure, in our sole discretion, to have sound
                        business reasons or justifications.
                        <br />
                        <br />
                        5. Cookies and Automatic Information Gathering
                        Technologies
                        <br />
                        <br />
                        Every time you access our Services, or one of its web
                        pages, we collect Non-Personal Information (discussed
                        above in Section 2). To assist the Services in
                        collecting and storing Non-Personal Information, we may
                        a variety of technologies, including “Cookies,” local
                        browser storage and “Web Beacons.” A “Cookie” is a small
                        amount of data a website may store in your web browser
                        that it can access when you visit it. A cookie also
                        refers to web-browser-based storage provided by Adobe’s
                        Flash plugin (a “Flash Cookie”). Cookies may contain
                        user preferences, unique identifiers and other
                        non-Personal Information. A “Web Beacon” is a small,
                        usually-transparent image placed on a web page that
                        allows us or our partners to collect non-Personal
                        Information. You can erase information stored in
                        cookies, flash cookies, and local browser storage at any
                        time, but you may be forced to login to the website
                        again and you may lose some preferences or settings. You
                        can also set your browser to refuse all website storage
                        or to indicate when it is permitted, but some features
                        of our Services may not function properly without it. We
                        do not use Cookies, Web Beacons or similar technologies
                        to store Personal Information. Every time you open or
                        use MeriTa, we collect non-Personal Information
                        (discussed above in Section 2). For example, using the
                        Services may trigger the transmission of information
                        between the app and our servers, and each time that
                        occurs, our servers collect non-Personal Information.
                        MeriTa also automatically collects information about how
                        people use the Services. For example, to improve our
                        Services, we collect how, when, and which parts of the
                        app or its features you use, which internet services you
                        connect to the app, and when, how, and what you post to
                        MeriTa through those services. To help us collect this
                        information, we use third-party software and services.
                        Also, we may use your device’s unique identifier (UDID),
                        media access control address (MAC Address), or other
                        unique identifiers to assist us in collecting and
                        analyzing this data.
                        <br />
                        <br />
                        6. Transparency and Choice
                        <br />
                        <br />
                        When you use our Services, we make good faith efforts to
                        provide you with access to your Personal Information and
                        either to correct this data if it is inaccurate or to
                        delete such data at your request, in either case if it
                        is not otherwise required to be retained by law or for
                        legitimate business purposes. We ask individual users to
                        identify themselves and the information requested to be
                        accessed, corrected or removed before processing such
                        requests, and we may decline to process requests that
                        are unreasonably repetitive or systematic, require
                        disproportionate technical effort, jeopardize the
                        privacy of others, or would be extremely impractical
                        (for instance, requests concerning information residing
                        on backup), or for which access is not otherwise
                        required. In any case where we provide information
                        access and correction, we perform this service free of
                        charge, except if doing so would require a
                        disproportionate effort. Please email us at{" "}
                        <u style={{ color: "black" }}>info@myMeriTa.com</u>.
                         with any questions, if you need assistance accessing or
                        changing your Personal Information, or if you would like
                        to have your Personal Information deleted. Please be
                        aware that if you delete your Personal Information, you
                        may not be able to continue to use MeriTa or the
                        Services. Also, even if you request that we delete your
                        Personal Information, we may need to retain certain
                        information for a limited period of time to satisfy our
                        legal, audit and/or dispute resolution requirements. We
                        do not use, or allow third party advertising networks to
                        use, information about web browsing activity collected
                        through the Services for targeted or behavioral
                        advertising. MeriTa supports the development and
                        implementation of a standard "do not track" browser
                        feature that provides customers with control over the
                        collection and use of information about their
                        web-browsing activities. Once a standardized "do not
                        track" feature is released, we intend to adhere to the
                        browser settings accordingly. You can opt out of
                        receiving marketing emails from us by clicking on the
                        “unsubscribe” link in the e-mails. Please note that it
                        may take up to ten (10) business days for your opt-out
                        request to be processed. Also, even if you opt of
                        marketing emails, we may continue to send you certain
                        account-related emails, such as notices about your
                        account and confirmations of transactions you have
                        requested.
                        <br />
                        <br />
                        7.Information Security <br />
                        <br />
                        We utilize reasonable information security measures to
                        safeguard your Personal Information. For example, we
                        utilize Secure Socket Layer (SSL) encryption technology
                        when sensitive data is transmitted over the Internet,
                        and use firewalls to help prevent external access into
                        our network. Unfortunately, however, no data
                        transmission over the Internet and no method of data
                        storage can be guaranteed to be 100% secure. Therefore,
                        while we strive to use commercially acceptable means to
                        protect your Personal Information, we cannot guarantee
                        its security. We restrict access to Personal Information
                        to our employees, contractors, service providers and
                        agents who need to know that information in order to
                        operate, develop or improve our Services. These
                        individuals are bound by confidentiality obligations and
                        may be subject to discipline, including termination and
                        criminal prosecution, if they fail to meet these
                        obligations.
                        <br />
                        <br />
                        8.Third Party Websites <br />
                        <br />
                        <br />
                        The Services may link or integrate with third party
                        sites, services or apps.   We are not responsible for
                        the privacy or security policies or practices or the
                        content of such third parties. Accordingly, we encourage
                        you to review the privacy and security policies and
                        terms of service of those third parties so that you
                        understand how those websites collect, use, share and
                        protect your information.
                        <br />
                        <br />
                        9. Changes to this Policy
                        <br />
                        <br />
                        We may modify or update this Privacy Policy periodically
                        with or without prior notice by posting the updated
                        policy on this page. You can always check the “Last
                        Updated” date at the top of this document to see when
                        the Privacy Policy was last changed. If we make any
                        material changes to this Privacy Policy, we will notify
                        you by email or post a notice of the changes on our
                        website or through our mobile app prior to the changes
                        becoming effective. We encourage you to check this
                        Privacy Policy from time to time. IF YOU DO NOT AGREE TO
                        FUTURE CHANGES TO THIS PRIVACY POLICY, YOU MUST STOP
                        USING MERITA AND SERVICES AFTER THE EFFECT
                        <br />
                        <br />
                        10. Data Protection Officer
                        <br />
                        <br />
                        MeriTa has appointed an internal data protection officer
                        for you to contact if you have any questions or concerns
                        about our personal data policies or practices. Our data
                        protection officer’s name and contact information are as
                        follows:
                        <br /> Yaser Rashidi
                        <br /> MeriTa, Inc.
                        <br /> 60 Hilltop Cres. Burwood East, VIC 3151
                        <br /> Email:{" "}
                        <u style={{ color: "black" }}>info@myMeriTa.com</u>{" "}
                        <br />
                        To ask questions about our Privacy Policy, submit a user
                        access request (i.e., a Subject Access Request, or
                        "SAR"), or to lodge a complaint, contact us at the above
                        email address.
                      </p>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Fragment>
        <footer className="footer_area p_120 mt-5">
          <div className="container">
            <div className="row footer_inner">
              <div className="col-lg-3 col-sm-6">
                <aside className="f_widget ab_widget">
                  <div className="f_title">
                    <h3>About Us</h3>
                  </div>
                  <p>MeriTa is a meritocratic decision support network</p>
                  <p>
                    Copyright
                    <span className={"mx-1"}>
                      &copy;
                      {new Date().getFullYear()}
                    </span>
                    All rights reserved
                  </p>
                </aside>
              </div>
              <div className="col-lg-3 col-sm-6">
                <aside className="f_widget news_widget">
                  <div className="f_title">
                    <h3>Newsletter</h3>
                  </div>
                  <p>Stay updated with us</p>
                  <div id="mc_embed_signup">
                    <form
                      className="subscribe_form relative"
                      onSubmit={e => e.preventDefault()}
                    >
                      <div className="input-group d-flex flex-row">
                        <input
                          name="EMAIL"
                          placeholder="Enter email address"
                          required=""
                          type="email"
                        />
                        <button className="btn sub-btn">
                          <span className="lnr lnr-arrow-right"></span>
                        </button>
                      </div>
                      <div className="mt-10 info"></div>
                    </form>
                  </div>
                </aside>
              </div>
              <div className="col-lg-3">
                <aside className="f_widget social_widget">
                  <div className="f_title">
                    <h3>Follow Us</h3>
                  </div>
                  <p>Let us be social</p>
                  <ul className="list">
                    <li>
                      <a href="#">
                        <FaFacebook />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                  <br/>
                  <a href="https://mymeritablog.wordpress.com/"  target="_blank" className={"contact-us-email"}>
                    MeriTa Blog
                  </a>
                </aside>
              </div>
              <div className="col-lg-3">
                <aside className="f_widget social_widget">
                  <div className="f_title">
                    <h3>Contact us</h3>
                  </div>
                  <a
                    className={"contact-us-email"}
                    href={
                      "mailto: info@myMeriTa.com?subject=Feedback&body=Message"
                    }
                  >
                    info@myMeriTa.com
                  </a>
                  <br />

                  <a href="/privacy" className={"contact-us-email"}>
                    Privacy
                  </a>
                  <br />
 
                  <a href="/terms" className={"contact-us-email"}>Terms</a>
                </aside>
              </div>
            </div>
          </div>
        </footer>
      </Fragment>
    );
  }
}

export default withRouter(Privacy);
