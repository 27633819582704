import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { MdEmail } from "react-icons/md";
import {
  Button,
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { sendInvitationEmail } from "../actions";
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

/**
 * InvitationEmail class(Modal) renders a form which can be used for inviting people to platform.
 */
class InvitationEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: ""
    };
  }

  validateEmail = email => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  showSuccessMessage = text =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000
      }
    );

  showErrorMessage = text =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000
      }
    );

  sendEmail = async () => {
    this.setState({
      sending: true
    });
    if (!this.validateEmail(this.state.emailValue)) {
      return this.showErrorMessage("Email is not in correct form !");
    }

    await sendInvitationEmail(this.props.sessionID,this.state.emailValue)
      .then(re => re.json())
      .then(res => {
        console.log(res.email);
        // if (res.status === 400) {
        console.log("invite",res);
        try {
            if (res.email[0] === "An active user is using this e-mail address") {
                return this.showErrorMessage(
                  "An active user is using this e-mail address"
                );
              } 
        } catch (error) {
            return this.showSuccessMessage("Invitation email has been sent successfully!")
        }
        
        // }
        //  else {
        //     return this.showSuccessMessage("Invitation email has been sent successfully!")
        // }
      });

    this.setState({
      sending: false
    });
    return this.props.onCloseModal();
  };

  handleEnterKey = e => {
    if (e.keyCode === 13) {
      return this.sendEmail();
    }
  };

  render() {
    return (
      <div onKeyDown={this.handleEnterKey}>
        <Modal
          animationDuration={100}
          open={this.props.show}
          onClose={this.props.onCloseModal}
          center
        >
          <h4>Invite a friend or colleague to MeriTa:</h4>
          <hr />
          <div style={{ width: "500px" }}></div>
          <InputGroup>
            <InputGroupAddon type="prepend">
              <InputGroupText>
                <MdEmail />
              </InputGroupText>
            </InputGroupAddon>
            <FormInput
              value={this.state.emailValue}
              onChange={e =>
                this.setState({
                  emailValue: e.target.value
                })
              }
            />
            <InputGroupAddon type="append">
              <Button
                disabled={this.state.sending}
                onClick={this.sendEmail}
                theme="primary"
              >
                {this.state.sending ? (
                  <div className="sweet-loading">
                    <PulseLoader
                      css={override}
                      sizeUnit={"px"}
                      color={"white"}
                      size={7}
                      loading={true}
                    />
                  </div>
                ) : (
                  "Send"
                )}
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Modal>
      </div>
    );
  }
}

export default InvitationEmail;
