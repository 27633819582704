import React, { Component } from "react";
import { connect } from "react-redux";
import { Slider, Badge } from "shards-react";
import {
  fetchDecisionById,
  fetchVotingStep1,
  updateVotingFormFirstStep
} from "../../../actions/index";
import { withRouter } from "react-router";
import { ScaleLoader, PulseLoader } from "react-spinners";
import { css } from "@emotion/core";
import { authHeader } from "../../../actions/AuthHeader";
import { API_URL } from "../../../constants";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

/**
 * FirstStep class renders sliders for voting on attributes.
 */
class FirstStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: {},
      step: 1,
      isPosting: false,
      gotError: false,
      isVoted: false,
      loadingSlider: false
    };
  }

  async componentDidMount() {
    this.setState({
      loadingSlider: true
    });
    const { dispatch, match } = this.props;

    if (this.props.selectedDecision.data["attributes"] === undefined) {
      await dispatch(fetchDecisionById(match.params["id"]));
    }
    await dispatch(fetchVotingStep1(match.params["id"]));

    let a = {};
    if (this.props.selectedDecision.data["attributes"]) {
      this.props.selectedDecision.data["attributes"].map(item => {
        return (a[item] = 5);
      });
    }

    if (this.props.FirstStep["data"].length > 0) {
      this.setState({
        isVoted: true
      });
    }

    if (this.state.isVoted) {
      this.props.selectedDecision.data["attributes"].map(
        (item, index) =>
          (a[
            this.props.FirstStep.data[index]["attribute"]
          ] = this.props.FirstStep.data[index]["rate"])
      );
    }

    this.setState({
      value: a,
      loadingSlider: false
    });
  }

  submitVote = async () => {
    const { dispatch } = this.props;
    this.setState({
      gotError: false
    });

    if (this.state.isVoted) {
      this.setState({
        isPosting: true
      });
      let postData;
      postData = Object.keys(this.state.value).map(item => {
        return {
          rate: this.state.value[item],
          attribute: item,
          poll: this.props.match.params["id"]
        };
      });
      dispatch(updateVotingFormFirstStep(postData));

      for (let i = 0; i < this.props.FirstStep.data.length; i++) {
        let id = this.props.FirstStep.data[i]["id"];
        await fetch(`${API_URL}/votes/${id}/`, {
          method: "PUT",
          body: JSON.stringify(postData[i]),
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: authHeader()
          })
        }).then(res => {
          if (res.status === 400) {
            this.setState({ gotError: true });
          }
        });
      }
      this.setState({
        isPosting: false
      });
      if (this.state.gotError) {
        return null;
      }
      return this.props.nextStep();
    }

    this.setState({
      isPosting: true
    });
    let postData;
    postData = Object.keys(this.state.value).map(item => {
      return {
        rate: this.state.value[item],
        attribute: item,
        poll: this.props.match.params["id"]
      };
    });
    dispatch(updateVotingFormFirstStep(postData));
    await fetch(`${API_URL}/votes/`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader()
      })
    }).then(res => {
      if (res.status === 400) {
        this.setState({ gotError: true });
      }
    });

    this.setState({
      isPosting: false
    });
    if (this.state.gotError) {
      return null;
    }
    return this.props.nextStep();
  };

  _renderContinueBtn = () => {
    return (
      <button
        className="new-decision-button btn-success-custom btn btn-success mx-2"
        onClick={this.submitVote}
      >
        {this.state.isPosting ? (
          <div className="sweet-loading">
            <PulseLoader
              css={override}
              sizeUnit={"px"}
              color={"white"}
              size={7}
              loading={true}
            />
          </div>
        ) : (
          "Continue"
        )}
      </button>
    );
  };

  render() {
    let attr = this.props.selectedDecision.data["attributes"] || [];
    return (
      <div className={"mx-3 mx-md-5"}>
        {this.props.isFetching ? (
          <div className={"d-flex justify-content-center"}>
            <div className="sweet-loading">
              <ScaleLoader
                css={override}
                sizeUnit={"px"}
                size={150}
                color={"#FF8260"}
                loading={this.props.isFetching}
              />
            </div>
          </div>
        ) : (
          <div className={"setp-1-wrapper"}>
            <h3 className={"fw-600"}>Importance Matrix</h3>
            <h6 className={"fw-600"}>
              Vote for importance of each attribute in this decision
              <br />
              <pre style={{ color: "red" }}>
                (1: Not Important 5: Neutral 9: Very Important)
              </pre>
            </h6>
            <hr />
            <div className={"row align-items-center"}>
              <div className={"col-12 col-md-3"}>
                <h6 className={"text-muted fw-600 font-size-13 m-0 p-0"}>
                  Attributes
                </h6>
              </div>
            </div>
            <div className={"slider-wrapper"}>
              {!this.props.FirstStep["isFetching"] ? (
                attr.map((item, index) => {
                  return (
                    <div className={"row align-items-center"} key={index}>
                      <div className={"col-12 col-md-12"}>
                        <h6 className={"fw-600 font-size-18 m-0 p-0"}>
                          <Badge pill>{item}</Badge>
                        </h6>
                      </div>
                      <div className={"col-12 col-md-11 p-0 m-0"}>
                        <Slider
                          onSlide={e => {
                            let newValue = {};
                            Object.keys(this.state.value).map(subItem => {
                              return (newValue[subItem] = this.state.value[
                                subItem
                              ]);
                            });
                            newValue[item] = parseFloat(e[0]);
                            this.setState({
                              value: newValue
                            });
                          }}
                          connect={[true, false]}
                          step={1}
                          start={[this.state.value[item]]}
                          range={{ min: 1, max: 9 }}
                        />
                      </div>
                      <div className={"col-12 col-md-1"}>
                        <h6 className={"fw-600 font-size-13 m-0 p-0"}>
                          {this.state.value[item]} / 9
                        </h6>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className={"d-flex justify-content-center"}>
                  <div className="sweet-loading">
                    <ScaleLoader
                      css={override}
                      sizeUnit={"px"}
                      size={150}
                      color={"#FF8260"}
                      loading={true}
                    />
                  </div>
                </div>
              )}
            </div>
            <hr />
            <div>
              <div className={"d-flex justify-content-end my-3"}>
                {this.state.step > 1 && (
                  <button
                    className="new-decision-button btn-success-custom btn btn-success mx-2"
                    onClick={this.props.previousStep}
                  >
                    Go Back
                  </button>
                )}
                {this.state.step < this.props.totalSteps ? (
                  this._renderContinueBtn()
                ) : (
                  <button
                    className="new-decision-button btn-success-custom btn btn-success mx-2"
                    onClick={this.props.nextStep}
                  >
                    Finish
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedDecision: state.selectedDecision,
    FirstStep: state.votingStep.FirstStep
  };
};

export default withRouter(connect(mapStateToProps, null)(FirstStep));
