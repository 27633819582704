import * as Constants from "../constants/index";

const initialState = {
    data: {},
    alternatives: [],
    isFetching: false,
    getError: "false",
    textError: "",
    alterGotError: false,
    alterTextError: "",
    votingForm: {}
};

export const selectedDecision = (state = initialState, action) => {
    switch (action.type) {

        case(Constants.REQUEST_DECISION_ALTERNATIVES_BY_ID):
            return {
                ...state,
                isFetching: true,
                alterGotError: false,
                alterTextError: "",
            };
        case(Constants.RECEIVE_DECISION_ALTERNATIVES_BY_ID):
            return {
                ...state,
                alternatives: action.data,
                isFetching: false,
                alterGotError: false,
                alterTextError: "",
            };
        case (Constants.FAILED_DECISION_ALTERNATIVES_BY_ID):
            return {
                ...state,
                isFetching: false,
                alterGotError: true,
                alterTextError: action.err,
            };
        case (Constants.RECEIVE_DECISION_BY_ID):
            return {
                ...state,
                data: {...action.decision},
                isFetching: false,
                getError: false,
                textError: ""
            };
        case (Constants.REQUEST_DECISION_BY_ID):
            return {
                ...state,
                isFetching: true,
                getError: false,
                textError: ""
            };
        case (Constants.FAILED_FETCH_DECISION_BY_ID):
            return {
                ...state,
                isFetching: false,
                getError: true,
                textError: action.err
            };
        default:
            return state
    }
};
