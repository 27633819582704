export const REQUEST_ALL_DECISION = "REQUEST_ALL_DECISION";
export const RECEIVE_ALL_DECISION = "RECEIVE_ALL_DECISION";
export const FAILED_FETCH_DECISION = "FAILED_FETCH_DECISION";

export const SET_FORM_DATA = "SET_FORM_DATA";

export const RECEIVE_DECISION_BY_ID = "RECEIVE_DECISION_BY_ID";
export const REQUEST_DECISION_BY_ID = "REQUEST_DECISION_BY_ID";
export const FAILED_FETCH_DECISION_BY_ID = "FAILED_FETCH_DECISION_BY_ID";

export const RECEIVE_DECISION_ALTERNATIVES_BY_ID = "RECEIVE_DECISION_ALTERNATIVES_BY_ID";
export const REQUEST_DECISION_ALTERNATIVES_BY_ID = "REQUEST_DECISION_ALTERNATIVES_BY_ID";
export const FAILED_DECISION_ALTERNATIVES_BY_ID = "FAILED_DECISION_ALTERNATIVES_BY_ID";

export const REQUEST_VOTING_INFO_STEP_1 = "REQUEST_VOTING_INFO_STEP_1";
export const RECEIVE_VOTING_INTO_STEP_1 = "RECEIVE_VOTING_INTO_STEP_1";
export const FAILED_FETCH_VOTING_INFO_STEP_1 = "FAILED_FETCH_VOTING_INFO_STEP_1";

export const REQUEST_VOTING_INFO_STEP_2 = "REQUEST_VOTING_INFO_STEP_2";
export const RECEIVE_VOTING_INFO_STEP_2 = "RECEIVE_VOTING_INFO_STEP_2";
export const FAILED_FETCH_VOTING_INFO_STEP_2 = "FAILED_FETCH_VOTING_INFO_STEP_2";

export const REQUEST_VOTING_INFO_STEP_3 = "REQUEST_VOTING_INFO_STEP_3";
export const RECEIVE_VOTING_INFO_STEP_3 = "RECEIVE_VOTING_INFO_STEP_3";
export const FAILED_FETCH_VOTING_INFO_STEP_3 = "FAILED_FETCH_VOTING_INFO_STEP_3";

export const UPDATE_VOTING_STEP_1 = "UPDATE_VOTING_STEP_1";

export const REQUEST_USER_PROFILE = "REQUEST_USER_PROFILE";
export const RECEIVE_USER_PROFILE = "RECEIVE_USER_PROFILE";
export const FAILED_FETCH_USER_PROFILE = "FAILED_FETCH_USER_PROFILE";

export const REQUEST_ALL_USERS = "GET_ALL_USERS";
export const RECEIVE_ALL_USERS = "RECEIVE_ALL_USERS";
export const FAILED_FETCH_ALL_USERS = "FAILED_FETCH_ALL_USERS";

export const REQUEST_ALL_ATTRIBUTES = "REQUEST_ALL_ATTRIBUTES";
export const RECEIVE_ALL_ATTRIBUTES = "RECEIVE_ALL_ATTRIBUTES";
export const FAILED_FETCH_ALL_ATTRIBUTES = "FAILED_FETCH_ALL_ATTRIBUTES";

export const REQUEST_USER_ATTRIBUTES = "REQUEST_USER_ATTRIBUTES";
export const RECEIVE_USER_ATTRIBUTES = "RECEIVE_USER_ATTRIBUTES";
export const FAILED_FETCH_USER_ATTRIBUTES = "FAILED_FETCH_USER_ATTRIBUTES";

export const REQUEST_USER_FOLLOWINGS = "REQUEST_USER_FOLLOWINGS";
export const RECEIVE_USER_FOLLOWINGS = "RECEIVE_USER_FOLLOWINGS";
export const FAILED_FETCH_USER_FOLLOWINGS = "FAILED_FETCH_USER_FOLLOWINGS";

export const REQUEST_USER_FOLLOWERS = "REQUEST_USER_FOLLOWERS";
export const RECEIVE_USER_FOLLOWERS = "RECEIVE_USER_FOLLOWERS";
export const FAILED_FETCH_USER_FOLLOWERS = "FAILED_FETCH_USER_FOLLOWERS";

export const REQUEST_USER_SESSION = "REQUEST_USER_SESSION";
export const RECEIVE_USER_SESSION = "RECEIVE_USER_SESSION";
export const FAILED_FETCH_USER_SESSION = "FAILED_FETCH_USER_SESSION";

export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE";
export const CREATE_ATTRIBUTE = "CRATE_ATTRIBUTE";

export const CHANGE_CURRENT_USER_EMAIL = "CHANGE_CURRENT_USER_EMAIL";

export const REQUEST_USER_NOTIFICATION = "REQUEST_USER_NOTIFICATION";
export const RECEIVE_USER_NOTIFICATION = "RECEIVE_USER_NOTIFICATION";
export const FAILED_FETCH_USER_NOTIFICATION = "FAILED_FETCH_USER_NOTIFICATION";