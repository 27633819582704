import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, Tooltip, FormSelect } from "shards-react";
import { ic_info } from "react-icons-kit/md/ic_info";
import { Icon } from "react-icons-kit";
import {
  IoIosAddCircleOutline,
  IoMdCheckmarkCircle,
  IoMdCloseCircle,
} from "react-icons/io";
import { css } from "@emotion/core";
import { PulseLoader, ScaleLoader } from "react-spinners";
import { Link } from "react-router-dom";

import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../../constants";
import { authHeader } from "../../actions/AuthHeader";
import MaterialTable, { MTableToolbar } from "material-table";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { IconContext } from "react-icons/lib";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const tableRef = React.createRef();

/**
 * Attr class fetches all attributes and renders.
 */
const OrgAttr = (props) => {
  const [OrgAttr, setOrgAttr] = useState();
  const [SortValue, setSortValue] = useState("default");
  const [ChangeAttr, setChangeAttr] = useState();
  const [CreacteAttributeTooltip, setCreacteAttributeTooltip] = useState(false);

  const getOrgAttr = useAxios({
    url: `${API_URL}/users/${props.sessionID}/get_org_attrs/`,
    options: {
      headers: {
        Authorization: authHeader(),
      },
    },
    trigger: [],
    customHandler: (err, res) => {
      if (res) {
        console.log("test",res.data);
        setOrgAttr(res.data);
        changeSort();
      }
    },
  });


  const changeUserAttr = useAxios({
    url: `${API_URL}/users/${props.sessionID}/change_user_favorite_attr/`,
    method: "POST",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: {
        attr: ChangeAttr,
        org:true
      },
    },
    // trigger: [],
    customHandler: (err, res) => {
      if (res) {
        console.log("res", res);
        setOrgAttr(res.data);
        // changeSort()

        getOrgAttr.reFetch();
      }
    },
  });
  const changeSort = () => {
    if (OrgAttr) {
      let newData = [...OrgAttr];
      if (SortValue === "default") {
        newData = newData.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        setOrgAttr(newData);
      } 
      else if (SortValue === "mostUsed") {
        newData = newData.sort((a, b) => {
          if (a.polls_count > b.polls_count) {
            return -1;
          }
          if (a.polls_count < b.polls_count) {
            return 1;
          }
          return 0;
        });
        setOrgAttr(newData);
      }
      else {
        newData = newData.sort((a, b) => {
          if (a.popularity_count < b.popularity_count) {
            return 1;
          }
          if (a.popularity_count > b.popularity_count) {
            return -1;
          }
          return 0;
        });
        setOrgAttr(newData);
      }
    }
  };

  const changeUserAttrFavorite = (attr) => {
    setChangeAttr(attr);
    changeUserAttr.reFetch();
  };

  useEffect(() => {
    changeSort();
  }, [SortValue]);

  const attr_table_rows_name = [
    {
      title: "ID",
      field:"uniqe_id"

      // render: (rowData) => rowData.tableData.id + 1,
    },
    { title: "Attribute Name", field: "name" },
    {
      title: "More",
      render: (rowData) => {
        // console.log(rowData)
        if (
          rowData.favorite_attr.filter((item) => item.user === props.sessionID)
            .length > 0
        ) {
          return (
            <span
              className={"mr-2 font-size-19"}
              style={{ verticalAlign: "1px" }}
              onClick={() => changeUserAttrFavorite(rowData.name)}
            >
              {/* <IoMdCloseCircle style={{ color: "#f5222d" }} /> */}
              
              <IconContext.Provider value={{ color: "#FE5A2D" }}>
                <MdFavorite />
              </IconContext.Provider>            </span>
          );
        } else {
          return (
            <span
              className={"mr-2 font-size-19"}
              style={{ verticalAlign: "1px" }}
              onClick={() => changeUserAttrFavorite(rowData.name)}
            >
              {/* <IoMdCloseCircle style={{ color: "#f5222d" }} /> */}
              <MdFavoriteBorder />
            </span>
          );
        }
      },
    },
  ];
  return (
    <div
      className={"container"}
      style={{
        backgroundColor: "white",
        marginTop: "70px",
        paddingBottom: "50px",
        border: "1px solid rgba(0, 0, 0, 0.1)",
      }}
    >
      <div className={""}>
        <div
          className={
            "py-5 d-flex ml-sm-2 ml-md-5 justify-content-between decision-wrapper"
          }
        >
          <div className={"d-flex ml-3 ml-md-0"}>
            <div className={"attribute-icon-ui-title "}></div>
            <h2 className={"decisions-header-text"}>
              Attributes{" "}
              {OrgAttr ? (
                <span style={{ fontSize: 16 }}>({OrgAttr.length})</span>
              ) : (
                ""
              )}
            </h2>
          </div>
          {props.user ? (
            props.user.org ? (
              props.user.org.is_admin ? (
                <div
                  className={"py-2 mr-sm-2 mr-md-5 new-decision-button-wrapper"}
                >
                  <Link to="/setting/admin/attr">
                    <Button
                      theme="success"
                      className={"new-decision-button btn-success-custom"}
                    >
                      <IoIosAddCircleOutline className={"mr-2 font-size-18"} />
                      Create New Attribute
                      <Icon
                        id="TooltipCreateAttribute"
                        className={"ml-2"}
                        size={20}
                        icon={ic_info}
                      />
                      <Tooltip
                        open={CreacteAttributeTooltip}
                        target="#TooltipCreateAttribute"
                        toggle={() =>
                          setCreacteAttributeTooltip((before) => !before)
                        }
                      >
                        You can create new attributes to use in your decisions.
                        You also can use any of the attributes already defined.
                      </Tooltip>
                    </Button>
                  </Link>
                </div>
              ) : undefined
            ) : undefined
          ) : undefined}
        </div>
      </div>
      <h5 className={"mt-5 mb-5 font-size-16"}>
        These are atttibutes proved useful within your organization. If you have
        suggested attributes to be added to this list, please contact your
        organizational admin:
        {props.user ? (
          props.user.org ? (
            <a
              className={"blue_text"}
              href={`/people/users/${props.user.org.org_admin.username}`}
            >
              Profile
            </a>
          ) : undefined
        ) : undefined}
      </h5>

      <MaterialTable
                  tableRef={tableRef}

        title="All Attributes"
        columns={attr_table_rows_name}
        data={OrgAttr}
        localization={{
          header: {
            actions: "More",
          },
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div className={"row mb-3"}>
                <div className={"col-12 col-md-4 ml-2 my-1"}>
                  <label className={"form-ui-label font-size-13 fw-600"}>
                    Sort
                  </label>
                  <FormSelect
                    onChange={(e) => {
                      console.log(e.target.value);
                      setSortValue(e.target.value);
                    }}
                    value={SortValue}
                  >
                    <option selected value="default">
                      Default(Alphabetic)
                    </option>
                    <option value="popular">Popular</option>
                    <option value="mostUsed">Most used</option>

                  </FormSelect>
                </div>
              </div>
            </div>
          ),
        }}
        actions={[]}
        options={{
          actionsColumnIndex: -1,
          //   sorting: true,
          //   filtering: true,
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { data } = state.allAttributes;
  console.log("org", state.session.data);
  return {
    data: data.results,
    user: state.session.data,
    sessionID: state.session.data.username,
  };
};
export default withRouter(connect(mapStateToProps, {})(OrgAttr));
