import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Switch, Route } from "react-router";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers/index";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import SignForm from "./containers/SignForm";
import PrivateRoute from "./containers/PrivateRoute";
import LoginRouter from "./containers/LoginRoute";
import Privacy from "./containers/Privacy";
import Terms from "./containers/Terms";
import Pricing from "./containers/Pricing";
import ActivateEnterprise from "./containers/ActivateEnterprise";

import Landing from "./components/Landing/Landing";
import ResetPassword from "./components/Sign/ResetPassword";
import ConfirmAccount from "./components/Sign/ConfirmAccount";
import ReactGA from "react-ga";
import SocialConfirm from "./containers/SocialConfim";
ReactGA.initialize("AW-671129096");

const loggerMiddleware = createLogger();
const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware, loggerMiddleware)
);
const injectGA = () => {
  if (typeof window == "undefined") {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", "UA-153806775-1");
  gtag("config", "AW-671129096");
};

ReactDOM.render(
  <Provider store={store}>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=UA-153806775-1"
    />
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-671129096"
    ></script>
    <script>{injectGA()}</script>
    <Router>
      <Switch>
        <LoginRouter exact path={"/login"} component={SignForm} />
        <Route path={"/social-confirm/:token?"} component={SocialConfirm} />

        <Route exact path="/" component={Landing} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/pricing" component={Pricing} />
        <Route
          path={"/activateEnterprise/:token"}
          component={ActivateEnterprise}
        />

        <Route
          exact
          path={"/rest-auth/password-reset/confirm/:uid/:token"}
          component={ResetPassword}
        />
        <Route exact path={"/confirm/email/:key"} component={ConfirmAccount} />
        <PrivateRoute path={"/"} component={App} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
