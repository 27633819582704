import React, {
  useRef,
  useLayoutEffect,
  Fragment,
  useState,
  useEffect,
} from "react";
import { Badge, Slider } from "shards-react";

import { css } from "@emotion/core";
import { authHeader } from "../../../actions/AuthHeader";
import { API_URL } from "../../../constants";
import useAxios from "@use-hooks/axios";

/**
 * SecondStep class renders sliders for voting on people.
 */
const CopySeccondStep = (props) => {
  const [attributes, setAttributes] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [value, setValue] = useState({});
  const [step, setStep] = useState(2);
  const [isVoted, setIsVoted] = useState(false);
  const [votedData, setVotedData] = useState(false);
  const [a, setA] = useState({});
  const [postValues, setPostValues] = useState("");
  const [putIdData, setPutIdData] = useState();
  const [putValues, setPutValues] = useState("");

  const checkIsVote = useAxios({
    url: `${API_URL}/pollCopy/${props.poll.id}/check_status_skills/`,
    method: "POST",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: {
        user: props.user,
      },
    },
    trigger: [],
    customHandler: (err, res) => {
      if (res) {
        if (res.data.length > 0) {
          console.log("status skills", res.data);
          setIsVoted(true);
          setVotedData(res.data);
        }
      }
    },
  });

  const addPollCopyVote = useAxios({
    url: `${API_URL}/userskillvotescopy/`,
    method: "POST",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: postValues,
    },
    trigger: postValues,

    forceDispatchEffect: () => !!postValues,
    customHandler: (err, res) => {
      if (res) {
        console.log("res", res);
        props.nextStep();
      }
    },
  });

  const putCopyVote = useAxios({
    url: `${API_URL}/userskillvotescopy/${putIdData}/`,
    method: "PUT",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: putValues,
    },
    // trigger: putIdData,

    // forceDispatchEffect: () => !!putIdData,
    customHandler: (err, res) => {
      if (res) {
        console.log("Put res", res);
        props.nextStep();
      }
    },
  });

  const submitVote = async () => {
    if (!isVoted) {
      let postData;
      postData = Object.keys(value).map((item) => {
        return Object.keys(value[item]).map((subItem) => {
          return {
            rate: value[item][subItem],
            attr: item,
            user: subItem,
            poll: parseInt(props.poll.id),
            voted_by: props.user,
          };
        });
      });
      let newPostData = [];
      for (let i = 0; i < postData.length; i++) {
        newPostData.push(...postData[i]);
      }

      setPostValues(newPostData);
      // props.nextStep();
    } else {
      console.log("val", value);
      let putData = votedData.map((item) => {
        console.log(item);
        return {
          attr: item["attr"],
          user: item["user"],
          rate: value[item["attr"]][item["user"]],
          poll: parseInt(props.poll.id),
          voted_by: props.user,
        };
      });
      for (let i = 0; i < votedData.length; i++) {
        let id = votedData[i]["id"];
        console.log(id);
        setPutValues(putData[i]);
        // setPutIndex(i)
        await setPutIdData(id);
        await putCopyVote.reFetch();
      }
    }
  };

  const _renderContinueBtn = () => {
    return (
      <button
        className="new-decision-button btn-success-custom btn btn-success mx-2"
        onClick={submitVote}
      >
        Continue
      </button>
    );
  };

  useEffect(() => {
    console.log(props, props.poll.participants[0]["username"]);

    // let a = {};

    props.poll.attributes.map((item) => (a[item] = {}));
    for (let i = 0; i < props.poll.attributes.length; i++) {
      for (let j = 0; j < props.poll.participants.length; j++) {
        if (props.poll.participants[j]["username"] !== props.user) {
          a[props.poll.attributes[i]][
            props.poll.participants[j]["username"]
          ] = 5;
        }
      }
    }

    setValue(a);
    setAttributes(props.poll.attributes);
    setParticipants(props.poll.participants);
  }, []);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    for (let i = 0; i < votedData.length; i++) {
      if (votedData[i]["rate"]) {
        a[votedData[i]["attr"]][votedData[i]["user"]] = votedData[i]["rate"];
      }
    }

    setValue(a);
  }, [votedData]);

  return (
    <Fragment>
      {attributes !== undefined && participants !== undefined ? (
        <div className={"mx-3 mx-md-5"}>
          <h3 className={"fw-600"}>Merit Matrix</h3>
          <h6 className={"fw-600"}>
            Expertise level of participants in each attribute. If you don’t know
            a participant leave the rating at 5. <br />
            <pre style={{ color: "red" }}>
              (1:Low expertise 5:Average expertise 9:High Expertise)
            </pre>
          </h6>

          <hr />

          {attributes.map((item, index) => {
            if (value[item] !== undefined) {
              return (
                <div key={index}>
                  <h5 className={"fw-600 m-0 p-0"}>
                    Rate participants in attribute:
                    <Badge pill className={"ml-2"}>
                      {item}
                    </Badge>
                  </h5>
                  {participants.map((subItem, index) => {
                    if (props.user !== subItem["username"]) {
                      return (
                        <div className={"row align-items-center"} key={index}>
                          <div className={"col-12 col-md-3"}>
                            <h6 className={"fw-600 font-size-13 m-0 p-0"}>
                              {subItem["username"]}
                            </h6>
                          </div>
                          <div className={"col-12 col-md-8"}>
                            <Slider
                              onSlide={(e) => {
                                let newValue = { ...value };
                                newValue[item][
                                  subItem["username"]
                                ] = parseFloat(e[0]);
                                setValue(newValue);
                              }}
                              connect={[true, false]}
                              step={1}
                              start={[value[item][subItem["username"]]]}
                              range={{ min: 1, max: 9 }}
                            />
                          </div>
                          <div className={"col-12 col-md-1"}>
                            <h6 className={"fw-600 font-size-13 m-0 p-0"}>
                              {value[item][subItem["username"]]} / 9
                            </h6>
                          </div>
                        </div>
                      );
                    }
                  })}
                  <hr />
                </div>
              );
            }
            return null;
          })}

          <div>
            <div className={"d-flex justify-content-end my-3"}>
              {step > 1 && (
                <button
                  className="new-decision-button btn-success-custom btn btn-success mx-2"
                  onClick={props.previousStep}
                >
                  Go Back
                </button>
              )}
              {step < props.totalSteps ? (
                _renderContinueBtn()
              ) : (
                <button
                  className="new-decision-button btn-success-custom btn btn-success mx-2"
                  onClick={props.nextStep}
                >
                  Finish
                </button>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default CopySeccondStep;
