import React, { useState, useEffect } from "react";

import StepWizard from "react-step-wizard";
import Nav from "../../../layout/UI/StepNav";

import { Container, Button } from "shards-react";
import Modal from "react-responsive-modal";

import "../../../layout/UI/StepNav/transition.css";
// import CopyFirstStep from "./CopyFirstStep";
// import SecondStep from "./VotingStep/SecondStep";
// import ThirdStep from "./VotingStep/ThirdStep";

import { API_URL } from "../../../constants";
import { authHeader } from "../../../actions/AuthHeader";
import useAxios from "@use-hooks/axios";
import MaterialTable from "material-table";

const CopyVotingList = (props) => {
  const poll_copy_table_rows_name = [
    {
      title: "ID",
      render: (rowData) => rowData.tableData.id + 1,
    },

    { title: "Name", field: "name" },
    {
      title: "State",
      render: (rowData) => {
        // console.log('s',rowData)
        switch (rowData.state) {
          case "dr":
            return "Draft";
          case "cl":
            return "Closed";
          case "fi":
            return "Finalized";
          case "vt":
            return "Voting";

          default:
            break;
        }
      },
    },
  ];

  const [activeStep, setActiveStep] = useState(null);
  const [allPollCopy, setAllPollCopy] = useState(null);
  const [pollCopy, setPollCopy] = useState("");
  const [showRemoveToggle, setshowRemoveToggle] = useState(false);
  const [OnremovePoll, setOnremovePoll] = useState("");

  const getAllPollCopy = useAxios({
    url: `${API_URL}/pollCopy/`,
    options: {
      headers: {
        Authorization: authHeader(),
      },
    },
    trigger: [],
    customHandler: (err, res) => {
      if (res) {
        console.log("copies", res.data);
        setAllPollCopy(res.data.results);
      }
    },
  });

  const deleteRes = useAxios({
    url: `${API_URL}/pollCopy/${OnremovePoll}`,
    method: "DELETE",
    options: {
      headers: {
        Authorization: authHeader(),
      },
    },
    // trigger: OnremovePoll,

    // forceDispatchEffect: () => !!OnremovePoll,
    customHandler: (err, res) => {
      if (res) {
        getAllPollCopy.reFetch();
        setshowRemoveToggle(false);
      }
    },
  });
  const onChangePollCopy = (event, { newValue }) => {
    setPollCopy(newValue);
  };

  const removePoll = () => {
    deleteRes.reFetch();
  };

  return (
    <>
      <Modal
        animationDuration={100}
        open={showRemoveToggle}
        onClose={() => setshowRemoveToggle((before) => !before)}
        top
      >
        <h5>
          Your poll will be remove.
          <br />
          Are you sure? ‬
        </h5>
        <hr />

        <div
          style={{ width: "500px" }}
          className={"d-flex justify-content-end"}
        >
          <Button onClick={removePoll} className={"mr-1"}>
            Yes
          </Button>
          <Button
            onClick={() => {
              setshowRemoveToggle((before) => !before);
              setOnremovePoll("");
            }}
            className={"ml-1"}
          >
            No
          </Button>
        </div>
      </Modal>
      <div
        className={"form-ui p-3"}
        style={{
          backgroundColor: "white",
          marginTop: "70px",
          paddingBottom: "50px",
          border: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Container>
          {getAllPollCopy.response?.data && (
            <MaterialTable
              title="Poll Copy List"
              columns={poll_copy_table_rows_name}
              data={allPollCopy}
              localization={{
                header: {
                  actions: "More",
                },
              }}
              actions={[
                {
                  icon: "settings",
                  // iconProps: { style: {  color: "green" } },
                  tooltip: "Poll Detail",
                  onClick: (event, rowData) =>
                    props.history.push(
                      `/setting/pollCopy/voting/${rowData.id}`
                    ),
                },
                ,
                (rowData) => ({
                  icon: "delete",
                  iconProps: { style: { color: "red" } },

                  tooltip: "Remove Poll",
                  onClick: (event, rowData) => {
                    setshowRemoveToggle((before) => !before);
                    setOnremovePoll(rowData.id);
                  },
                }),
                ,
                (rowData) => ({
                  icon: "edit",
                  iconProps: { style: { color: "" } },

                  tooltip: "Edit Poll",
                  onClick: (event, rowData) => {
                    props.history.push(`/setting/pollcopy/edit/${rowData.id}`)
                    
                  },
                }),
              ]}
              options={{
                actionsColumnIndex: -1,
                //   sorting: true,
                //   filtering: true,
              }}
            />
          )}
        </Container>
      </div>
    </>
  );
};

export default CopyVotingList;
