import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import {
  IoIosArrowDropdown,
  IoIosArrowDropleftCircle,
  IoIosArrowDropup,
  IoMdCheckmarkCircle
} from "react-icons/io";
import DropUI from "../../layout/UI/DropUI";
import { animateScroll as scroll } from "react-scroll";
import { connect } from "react-redux";
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/core";
import { authHeader } from "../../actions/AuthHeader";
import { setPollAttachment } from "../../actions/index";
import { API_URL } from "../../constants";
import { Link } from "react-router-dom";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

/**
 * StepThree class renders all entered details of decision.
 */
class StepThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gotError: false,
      pollId: ""
    };
  }
  refactorProfileUrl = url => {
    if (url && String(url).substring(0, 4) === "http") {
      return url;
    } else {
      console.log(url);
      return `${API_URL}/uploads/${url["profile_pic"]}`;
    }
  };
  componentDidMount() {
    scroll.scrollToTop();
  }

  onSubmitDecision = async () => {
    this.setState({
      gotError: false
    });
    // let formData = new FormData();
    // formData.append("created_by", "dx");
    // formData.append("name", this.props.decisionName);
    // formData.append("question", this.props.decisionName);
    // formData.append("problem_opportunity", this.props.PODetails);
    // formData.append("problem_objective", this.props.DODetails);
    // formData.append("active_state", this.props.activeState);
    // formData.append("finish_date", this.props.finishDataAndTime);
    // for (let i = 0; i < this.props.Participants.length; i++) {
    //     formData.append("participants", this.props.Participants[i]);
    // }
    // for (let i = 0; i < this.props.requiredAttributes.length; i++) {
    //     formData.append("attributes", this.props.requiredAttributes[i]);
    // }
    // for (let i = 0; i < this.props.Alternatives.length; i++) {
    //     formData.append("alternatives", this.props.Alternatives[i]);
    // }
    // formData.append("attachment", this.props.Attachments[0]);

    let decisionData = {
      created_by: this.props.sessionID,
      name: this.props.decisionName,
      question: this.props.decisionName,
      problem_opportunity: this.props.PODetails,
      problem_objective: this.props.DODetails,
      active_state: this.props.activeState,
      finish_date: this.props.finishDataAndTime,
      participants: this.props.Participants,
      attributes: this.props.requiredAttributes,
      alternatives: this.props.Alternatives
      // "attachment": this.props.Attachments[0]
    };
    if (this.props.dataId) {
      let options = {
        method: "PUT",
        body: JSON.stringify(decisionData),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: authHeader()
        }
      };
      this.setState({
        isPosting: true
      });
      await fetch(`${API_URL}/polls/${this.props.dataId}/`, options)
        .then(res => {
          if (res.status === 400) {
            this.setState({
              gotError: true
            });
          }
          return res.json();
        })
        .then(data => this.setState({ pollId: data["id"] }));

      this.setState({
        isPosting: false
      });
      if (this.state.gotError) {
        return null;
      }

      return this.props.history.push("/decision/polls");
    }
    let options = {
      method: "POST",
      body: JSON.stringify(decisionData),
      headers: {
        // "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundaryIn312MOjBWdkffIM",
        "Content-type": "application/json",
        Authorization: authHeader()
      }
    };
    this.setState({
      isPosting: true
    });
    await fetch(`${API_URL}/polls/`, options)
      .then(res => {
        if (res.status === 400) {
          this.setState({
            gotError: true
          });
        }
        return res.json();
      })
      .then(data => this.setState({ pollId: data["id"] }));

    // let formData = new FormData();
    // console.log(this.state.pollId);
    // formData.append("poll", parseInt(this.state.pollId));
    // formData.append("attachment", this.props.Attachments[0]);
    // options = {
    //     method: 'POST',
    //     body: formData,
    //     headers: {
    //         Accept: 'application/json',
    //         'Content-Type': 'multipart/form-data',
    //         'Authorization': 'JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmlnX2lhdCI6MTU2MDQ5NjE1NSwidXNlcm5hbWUiOiJkeCIsImVtYWlsIjoiZHhAZHguZHgiLCJ1c2VyX2lkIjoiZHgiLCJleHAiOjE1NjkxMzYxNTV9.U21udhvFmFTG8kuPY2nYZq1HdSuko0E2532F87Xux-8'
    //     }
    // };
    //
    // await fetch(`${API_URL}/attachments/`, options)
    //     .then(res => {
    //         if (res.status === 400) {
    //             this.setState({
    //                 gotError: true
    //             });
    //         }
    //         return res.json()
    //     })
    //     .then(data => console.log(data));
    // console.log(this.state.pollId);
    console.log(this.props.Attachments[0], this.state.pollId);
    // await this.props.setPollAttachment(this.state.pollId, this.props.Attachments[0])
    //     .then(data => console.log(data));
    const endpoint = `polls/${this.state.pollId}/set_attachments/`;
    let formData = new FormData();
    formData.set("poll", "test");
    for (let index = 0; index < this.props.Attachments.length; index++) {
      const element = this.props.Attachments[index];
      console.log(element);
      formData.append("attachment", element[0]);
    }
    console.log("formdata", formData);
    const options1 = {
      method: "POST",
      body: formData
    };

    fetch(`${API_URL}/${endpoint}`, {
      ...options1,
      headers: {
        // 'Accept': 'application/json',
        // 'Content-Type': 'multipart/form-data',
        Authorization: authHeader()
      }
    })
      .then(data => data.json())
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.log("asdasd", error);
      });

    this.setState({
      isPosting: false
    });
    if (this.state.gotError) {
      return null;
    }

    return this.props.history.push("/decision/polls");
  };

  render() {
    return (
      <Fragment>
        {this.props.activeKey === this.props.tabKey ? (
          <Fragment>
            <div className={"colored-bar-step d-flex flex-column"}>
              <div className={"mt-3 text-white"}>
                <IoIosArrowDropup
                  className={"form-ui-icon"}
                  onClick={this.props.goToPrevSlide}
                />
              </div>
              <div className={"d-flex flex-column align-items-center"}>
                {this.props.dotClass.map((item, index) => {
                  return (
                    <DropUI isActive={index === this.props.index} key={index} />
                  );
                })}
              </div>
              <div className={"mb-3 text-white"}>
                <IoIosArrowDropdown
                  className={"form-ui-icon"}
                  onClick={this.props.goToNextSlide}
                />
              </div>
            </div>
            <div style={{ flexGrow: "1" }} className={"mb-3"}>
              <div className={"form-ui-header p-3"}>
                <h3 className={"m-0 p-0"}>Review before Sending Invite</h3>
              </div>
              <form className={"p-3"}>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label col-form-label-ui text-blue">
                    {/*<IoMdCard className={"mr-2 font-size-20"}/>*/}
                    Decision Name
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      value={this.props.decisionName}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label col-form-label-ui text-blue">
                    {/*<IoIosCalendar className={"mr-2 font-size-20"}/>*/}
                    Decision Deadline
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      value={this.props.finishDataAndTime.substring(0, 10)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label col-form-label-ui text-blue">
                    {/*<IoIosCalendar className={"mr-2 font-size-20"}/>*/}
                    Active State
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      value={this.props.activeState}
                    />
                  </div>
                </div>
                <hr />
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label col-form-label-ui text-blue">
                    {/*<GoBook className={"font-size-20 mr-2"}/>*/}
                    Problem/ Opportunity details
                  </label>
                  <div className="col-sm-9">
                    <textarea
                      type="text"
                      readOnly
                      rows={3}
                      className="form-control-plaintext"
                      value={this.props.PODetails}
                    />
                  </div>
                </div>
                <hr />
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label col-form-label-ui text-blue">
                    Decision Goal/ Objective Details
                  </label>
                  <div className="col-sm-9">
                    <textarea
                      type="text"
                      readOnly
                      rows={3}
                      className="form-control-plaintext"
                      value={this.props.DODetails}
                    />
                  </div>
                </div>
                <hr />
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label col-form-label-ui text-blue">
                    Alternative
                  </label>
                  <div className="col-sm-9">
                    {this.props.Alternatives.map((item, index) => {
                      return (
                        <input
                          type="text"
                          readOnly
                          key={index}
                          className="form-control-plaintext"
                          value={
                            item["name"].length > 30
                              ? item["name"].substring(0, 30) + "..."
                              : item["name"]
                          }
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label col-form-label-ui text-blue">
                    Required Attributes
                  </label>
                  <div className="col-sm-9">
                    {this.props.requiredAttributes.map((item, index) => {
                      return (
                        <input
                          type="text"
                          readOnly
                          key={index}
                          className="form-control-plaintext"
                          value={item}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label col-form-label-ui text-blue">
                    Participants
                  </label>
                  <div className="col-sm-9">
                    {this.props.all_participants.map((item, index) => {
                      console.log("parti", this.props);
                      return (
                        <Link
                          to={`/people/users/${item["username"]}`}
                          key={index}
                          className={"m-0 poll-data-header-creator fw-300"}
                        >
                          <span
                            className={
                              "nav-profile-avatar d-flex align-items-center mr-4"
                            }
                            style={{ fontWeight: "Normal" }}
                          >
                            <div
                              style={{
                                backgroundImage: `url(${this.refactorProfileUrl(
                                  item["pic"]
                                )})`
                              }}
                              className={"avater-img mr-1"}
                            ></div>
                            {item["first_name"] + " " + item["last_name"]}
                          </span>
                        </Link>
                      );
                    })}
                    <Link
                      to={`/people/users/${this.props.owner_info[0]["username"]}`}
                      className={"m-0 poll-data-header-creator fw-300"}
                    >
                      <span
                        className={
                          "nav-profile-avatar d-flex align-items-center mr-4"
                        }
                        style={{ fontWeight: "Normal" }}
                      >
                        <div
                          style={{
                            backgroundImage: `url(${this.refactorProfileUrl(
                              this.props.owner_info[0]["pic"]
                            )})`
                          }}
                          className={"avater-img mr-1"}
                        ></div>
                        {this.props.owner_info[0]["first_name"] +
                          " " +
                          this.props.owner_info[0]["last_name"]}
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label col-form-label-ui text-blue text-blue">
                    Attachments
                  </label>
                  <div className="col-sm-9">
                    {this.props.Attachments.map((item, index) => {
                      console.log("name", item[0]["name"]);
                      return (
                        <input
                          type="text"
                          readOnly
                          key={index}
                          className="form-control-plaintext"
                          value={item[0]["name"]}
                        />
                      );
                    })}
                  </div>
                </div>
              </form>
              <div className={"btn-wrapper d-flex justify-content-between p-3"}>
                <button
                  type="submit"
                  className="btn btn-success-custom align-self-end"
                  onClick={this.props.goToPrevSlide}
                >
                  <IoIosArrowDropleftCircle
                    className={"mr-2 font-size-19"}
                    style={{ verticalAlign: "-4px" }}
                  />
                  <span>Back</span>
                </button>
                <button
                  type="submit"
                  className="btn btn-success-custom align-self-end"
                  onClick={this.onSubmitDecision}
                >
                  {this.state.isPosting ? (
                    <div className="sweet-loading">
                      <PulseLoader
                        css={override}
                        sizeUnit={"px"}
                        color={"white"}
                        size={7}
                        loading={true}
                      />
                    </div>
                  ) : (
                    "Save Draft Decision"
                  )}
                  <IoMdCheckmarkCircle className={"ml-2 font-size-19"} />
                </button>
              </div>
            </div>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    Alternatives,
    Attachments,
    DODetails,
    PODetails,
    Participants,
    decisionName,
    finishDataAndTime,
    requiredAttributes,
    activeState,
    all_participants,
    owner_info
  } = state.MakeDecisionForm;

  return {
    Alternatives,
    Attachments,
    DODetails,
    PODetails,
    Participants,
    all_participants,
    decisionName,
    finishDataAndTime,
    requiredAttributes,
    activeState,
    owner_info,
    sessionID: state.session.data.username
  };
};

export default withRouter(
  connect(mapStateToProps, { setPollAttachment })(StepThree)
);
