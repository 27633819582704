import React, { useRef, useLayoutEffect, useState, useEffect } from "react";

import { Badge, Slider, Button } from "shards-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { css } from "@emotion/core";

import { authHeader } from "../../../actions/AuthHeader";
import toast from "toasted-notes";
import {withRouter,} from 'react-router';
import {Redirect} from "react-router";
import {connect} from "react-redux";

import "toasted-notes/src/styles.css";

import { API_URL } from "../../../constants";
import Modal from "react-responsive-modal";
import useAxios from "@use-hooks/axios";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
/**
 * SecondStep class renders sliders for voting on alternatives.
 * also has a text editor for leave a comment.
 */

const CopyThirdStep = (props) => {
  const [step, setStep] = useState(3);
  const [showAlternativeDescription, setShowAlternativeDescription] = useState(
    false
  );
  const [value, setValue] = useState({});
  const [selectedAlternative, setSelectedAlternative] = useState("");
  const [toggle, setToggle] = useState(false);

  const [postValues, setPostValues] = useState("");
  const [isVoted, setIsVoted] = useState(false);
  const [votedData, setVotedData] = useState(false);
  const [a, setA] = useState({});
  const [putIdData, setPutIdData] = useState();
  const [redirect, setRedirect] = useState(false);
  const [putValues, setPutValues] = useState("");

  const checkIsVote = useAxios({
    url: `${API_URL}/pollCopy/${props.poll.id}/check_status_alternatives/`,
    method: "POST",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: {
        user: props.user,
      },
    },
    trigger: [],
    customHandler: (err, res) => {
      if (res) {
        if (res.data.length > 0) {
          console.log("status alter", res.data);
          setIsVoted(true);
          setVotedData(res.data);
        }
      }
    },
  });

  const addPollCopyVote = useAxios({
    url: `${API_URL}/alternativevotescopy/`,
    method: "POST",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: postValues,
    },
    trigger: postValues,

    forceDispatchEffect: () => !!postValues,
    customHandler: (err, res) => {
      if (res) {
        console.log("res", res);
        //   props.nextStep();
        setRedirect(true);

        // return props.history.push(`/setting/pollCopy/voting/${props.poll.id}`);
      }
    },
  });

  const putCopyVote = useAxios({
    url: `${API_URL}/alternativevotescopy/${putIdData}/`,
    method: "PUT",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: putValues,
    },
    // trigger: putIdData,

    // forceDispatchEffect: () => !!putIdData,
    customHandler: (err, res) => {
      if (res) {
        console.log("Put res", res);
        setRedirect(true);

        return props.history.push(`/setting/pollCopy/voting/${props.poll.id}`);
      }
    },
  });

  //   constructor(props) {
  //     super(props);
  //     this.state = {
  //       step: 3,
  //       value: {},
  //       isPosting: false,
  //       isVoted: false,
  //       gotError: false,
  //       loadingSlider: false,
  //       commentText: "",
  //       loadingForSubmitComment: false,
  //       is_voted: false,
  //       toggle: false,
  //       selectedAlternative: "",
  //     };
  //     this.modules = {
  //       toolbar: [
  //         [{ header: [1, 2, 3, 4, 5, 6, false] }],
  //         ["bold", "italic", "underline", "strike", "blockquote"],
  //         [{ script: "super" }, { script: "sub" }],
  //         [
  //           { list: "ordered" },
  //           { list: "bullet" },
  //           { indent: "-1" },
  //           { indent: "+1" }
  //         ],
  //         [{ align: [] }, "direction"],
  //         ["link"],
  //         ["clean"]
  //       ]
  //     };
  //     this.formats = [
  //       "header",
  //       "align",
  //       "script",
  //       "bold",
  //       "italic",
  //       "underline",
  //       "strike",
  //       "blockquote",
  //       "list",
  //       "bullet",
  //       "indent",
  //       "link",
  //       "image"
  //     ];
  //   }

  const createMarkup = (text) => {
    return { __html: text };
  };
  const toggleButton = () => {
    setToggle((before) => !before);
  };
  //   showSuccessMessage = text =>
  //     toast.notify(
  //       <p className={"m-0 p-0 font-size-14"}>
  //         <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
  //           <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
  //         </span>
  //         {text}
  //       </p>,
  //       {
  //         duration: 2000
  //       }
  //     );

  //   showErrorMessage = text =>
  //     toast.notify(
  //       <p className={"m-0 p-0 font-size-14"}>
  //         <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
  //           <IoMdCloseCircle style={{ color: "#f5222d" }} />
  //         </span>
  //         {text}
  //       </p>,
  //       {
  //         duration: 2000
  //       }
  //     );

  //   addComment = async () => {
  //     let that = this;
  //     this.setState({
  //       loadingForSubmitComment: true
  //     });
  //     let data = {
  //       poll: this.props.match.params["id"],
  //       comment: this.state.commentText
  //     };
  //     await this.props
  //       .dispatch(addCommentToPoll(data))
  //       .then(data => {
  //         this.setState({
  //           loadingForSubmitComment: false
  //         });
  //         return that.showSuccessMessage("Successfully Added!");
  //       })
  //       .catch(err => {
  //         return that.showErrorMessage("Please try again !");
  //       });
  //   };

  const _renderContinueBtn = () => {
    return (
      <div>
        {redirect ? (
          <Redirect to={`/setting/pollCopy/voting/${props.poll.id}`} />
        ) : undefined}
        <Modal animationDuration={100} open={toggle} onClose={toggleButton} top>
          <h5>
            Your votes will be submitted.
            <br />
            Are you sure? ‬
          </h5>
          <hr />

          <div
            style={{ width: "500px" }}
            className={"d-flex justify-content-end"}
          >
            <Button onClick={submitVote} className={"mr-1"}>
              Yes
            </Button>
            <Button onClick={toggleButton} className={"ml-1"}>
              No
            </Button>
          </div>
        </Modal>
        <div>
          {/* {!this.state.is_voted ? ( */}
          <button
            className="new-decision-button btn-success-custom btn btn-success mx-2"
            onClick={toggleButton}
          >
            submit vote
          </button>
          {/* ) : null} */}
        </div>
      </div>
    );
  };

  const submitVote = async () => {
    if (!isVoted) {
      let postData;
      postData = props.poll.alternatives.map((item) => {
        return {
          rate: value[item["name"]],
          alternative: item["id"],
          poll: props.poll.id,
          voted_by: props.user,
        };
      });
      console.log(postData);
      setPostValues(postData);
    } else {
      console.log("val", value);

      let putData;
      putData = props.poll.alternatives.map((item) => {
        return {
          poll: props.poll.id,
          rate: value[item["name"]],
          alternative: item["id"],
          voted_by: props.user,
        };
      });
      for (let i = 0; i < putData.length; i++) {
        let id = votedData[i]["id"];
        console.log(id);
        setPutValues(putData[i]);
        // setPutIndex(i)
        await setPutIdData(id);
        await putCopyVote.reFetch();
      }
    }
  };

  const toggleShowAlternativeDescription = (alter) => {
    // let {selectedAlternative}=this.state;
    // selectedAlternative['name']=alter;
    // selectedAlternative['description']=desc;
    setShowAlternativeDescription((before) => !before);
    setSelectedAlternative(alter);
  };

  const closeAlternativeDescription = () => {
    setShowAlternativeDescription((before) => !before);
  };

  useEffect(() => {
    console.log("third step", props);

    props.poll.alternatives.map((item) => {
      return (a[item["name"]] = 0);
    });
    setValue(a);
  }, []);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    console.log("here", votedData);
    props.poll.alternatives.map((item, index) => {
      return (a[item["name"]] = votedData[index]["rate"]);
    });

    setValue(a);
  }, [votedData]);

  return (
    <div className={"mx-3 mx-md-5"}>
      <Modal
        open={showAlternativeDescription}
        onClose={closeAlternativeDescription}
        animationDuration={100}
        center
      >
        <div className={"modal-responsive"}>
          <h4>
            {selectedAlternative["name"]
              ? selectedAlternative["name"].length > 30
                ? selectedAlternative["name"].substring(0, 30) + "..."
                : selectedAlternative["name"]
              : ""}
          </h4>
          <hr />
          <div
            className={"alter-description-area p-0 m-0"}
            dangerouslySetInnerHTML={createMarkup(
              selectedAlternative["description"] ||
                "This alternative does not have description."
            )}
          ></div>
        </div>
      </Modal>
      <h3 className={"fw-600"}>Alternatives</h3>
      <h6 className={"fw-600"}>
        Vote for your overall evaluation of each alternatives
      </h6>
      <hr />
      <div className={"row align-items-center"}>
        <div className={"col-12 col-md-3"}>
          <h6 className={"text-muted fw-600 font-size-13 m-0 p-0"}>
            Alternative
          </h6>
        </div>
        <div className={"col-12 col-md-8"}>
          <h6 className={"text-muted fw-600 font-size-13 m-0 p-0"}>
            Desirability of each alternative
            <br />
            <pre style={{ color: "red" }}>
              (-9:Not desirable 0:Neutral 9:Very desirable)
            </pre>
          </h6>
        </div>
      </div>

      {props.poll.alternatives.length > 0
        ? props.poll.alternatives.map((item, index) => {
            return (
              <div className={"row align-items-center"} key={index}>
                <div
                  className={"col-12 col-md-12"}
                  onClick={() => toggleShowAlternativeDescription(item)}
                >
                  <h6 className={"fw-600 font-size-18 m-0 p-0"}>
                    <Badge pill>{item["name"]}</Badge>
                  </h6>
                </div>
                <div className={"col-12 col-md-11 p-0 m-0"}>
                  <Slider
                    onSlide={(e) => {
                      let newValue = { ...value };
                      newValue[item["name"]] = parseFloat(e[0]);
                      setValue(newValue);
                    }}
                    connect={[true, false]}
                    step={1}
                    start={[value[item["name"]]]}
                    range={{ min: -9, max: 9 }}
                  />
                </div>
                <div className={"col-12 col-md-1"}>
                  <h6 className={"fw-600 font-size-13 m-0 p-0"}>
                    {value[item["name"]]} / 9
                  </h6>
                </div>
              </div>
            );
          })
        : null}

      <hr />
      <div>
        <div className={"d-flex justify-content-end my-3"}>
          {step > 1 && (
            <button
              className="new-decision-button btn-success-custom btn btn-success mx-2"
              onClick={props.previousStep}
            >
              Go Back
            </button>
          )}
          {_renderContinueBtn()}
        </div>
      </div>
    </div>
  );
};


export default withRouter(CopyThirdStep);