import React, { Component, Fragment } from "react";
import { FaRegEdit, FaCalendar } from "react-icons/fa";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import DropUI from "../../layout/UI/DropUI";
import { animateScroll as scroll } from "react-scroll";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import {
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  FormInput,
  FormTextarea,
  Fade,
  FormCheckbox
} from "shards-react";
import { connect } from "react-redux";
import "react-day-picker/lib/style.css";
import "input-moment/dist/input-moment.css";
import moment from "moment";
import InputMoment from "input-moment";
import { setFormData } from "../../actions/index";

/**
 * StepOne class render some inputs for decision details.
 */
class StepOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDay: "",
      showCalender: false,
      m: moment(),
      decisionName: "",
      finishDate: "",
      problemDetails: "",
      objectiveDetails: "",
      invalidDecisionName: false,
      invalidProblemDetails: false,
      invalidObjectiveDetails: false,
      activeState: false
    };
  }

  componentDidMount() {
    if (this.props.dataId) {
      if (this.props.selectedDecision) {
        this.setState({
          decisionName: this.props.selectedDecision.data.name,
          finishDate: this.props.selectedDecision.data.finish_date,
          problemDetails: this.props.selectedDecision.data.problem_objective,
          objectiveDetails: this.props.selectedDecision.data
            .problem_opportunity,
          activeState: this.props.selectedDecision.data.active_state
        });
      }
    }
    scroll.scrollToTop();
  }

  onFocusInput = (e, labelElement) => {
    labelElement.style.color = "#008AFF";
  };

  onBlurInput = (e, labelElement) => {
    labelElement.style.color = "#5a6169";
  };

  handleDateInput = e => {
    // this.setState({
    //     selectedDay: e.value
    // })
  };

  toggleDisplay = () => {
    this.setState({
      showCalender: !this.state.showCalender
    });
  };

  handleChange = m => {
    this.setState({ m });
  };

  handleChangeCheckbox = () => {
    this.setState({
      activeState: !this.state.activeState
    });
  };

  handleSave = () => {
    this.setState({
      showCalender: !this.state.showCalender
    });
  };

  checkFormValidation = () => {
    if (this.state.decisionName.length > 0) {
      this.setState({
        invalidDecisionName: false
      });
    } else {
      this.setState({
        invalidDecisionName: true
      });
    }

    if (this.state.problemDetails.length > 0) {
      this.setState({
        invalidProblemDetails: false
      });
    } else {
      this.setState({
        invalidProblemDetails: true
      });
    }
    if (this.state.objectiveDetails.length > 0) {
      this.setState({
        invalidObjectiveDetails: false
      });
    } else {
      this.setState({
        invalidObjectiveDetails: true
      });
    }
    return (
      this.state.decisionName.length > 0 &&
      this.state.objectiveDetails.length > 0 &&
      this.state.problemDetails.length > 0
    );
  };

  checkValidationStepOne = () => {
    if (!this.checkFormValidation()) {
      return;
    }
    this.props.dispatch(
      setFormData({
        decisionName: this.state.decisionName,
        finishDataAndTime: this.state.m.format("YYYY-MM-DD HH:mm"),
        PODetails: this.state.problemDetails,
        DODetails: this.state.objectiveDetails,
        activeState: this.state.activeState
      })
    );
    this.props.goToNextSlide();
  };

  handleEnterKey = e => {
    // if (e.keyCode === 13) {
    //     return this.checkValidationStepOne()
    // }
    // return null;
  };

  render() {
    return (
      <Fragment>
        {this.props.activeKey === this.props.tabKey ? (
          <Fragment>
            <div className={"colored-bar-step d-flex flex-column"}>
              <div className={"mt-3 text-white"}>
                <IoIosArrowDropup className={"form-ui-icon"} />
              </div>
              <div className={"d-flex flex-column align-items-center"}>
                {this.props.dotClass.map((item, index) => {
                  return (
                    <DropUI isActive={index === this.props.index} key={index} />
                  );
                })}
              </div>
              <div className={"mb-3 text-white"}>
                <IoIosArrowDropdown
                  className={"form-ui-icon"}
                  onClick={this.checkValidationStepOne}
                />
              </div>
            </div>
            <div
              style={{ flexGrow: "1" }}
              onKeyDown={this.handleEnterKey}
              className={"mb-3"}
            >
              <div className={"form-ui-header p-3"}>
                <h3 className={"m-0 p-0"}> Decision Information</h3>
              </div>
              <div className={"form-ui p-3"}>
                <form onSubmit={e => e.preventDefault()}>
                  <div className={"row"}>
                    <div className={"col-12 col-md-6 my-1"}>
                      <label
                        className={"form-ui-label font-size-15 fw-600"}
                        ref={element => (this.elementDecisionName = element)}
                      >
                        Decision Name {" "}<span style={{ color: "red", fontSize: 12 }}>
                        maximum 60 character{" "}
                        {this.state.decisionName.length > 0
                          ? "(now " + this.state.decisionName.length+")"
                          : ""}
                      </span>
                      </label>
                      <InputGroup seamless>
                        <InputGroupAddon type="prepend">
                          <InputGroupText>
                            <FaRegEdit />
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          invalid={this.state.invalidDecisionName}
                          onChange={e => {
                            if (this.state.decisionName.length < 60 ||e.target.value.length<60)
                              this.setState({
                                decisionName: e.target.value,
                                invalidDecisionName: false
                              });
                          }}
                          value={this.state.decisionName}
                          onFocus={e =>
                            this.onFocusInput(e, this.elementDecisionName)
                          }
                          onBlur={e =>
                            this.onBlurInput(e, this.elementDecisionName)
                          }
                        />
                      </InputGroup>
                      
                    </div>
                    <div
                      className="col-12 col-md-6 my-1"
                      style={{ position: "relative" }}
                    >
                      <label
                        className={"form-ui-label font-size-15 fw-600"}
                        ref={element => (this.elementFinishDate = element)}
                      >
                        Decision Deadline
                      </label>
                      <InputGroup seamless>
                        <InputGroupAddon
                          type="prepend"
                          onClick={this.toggleDisplay}
                          className={"calender-btn"}
                        >
                          <InputGroupText>
                            <FaCalendar />
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          value={this.state.m.format("YYYY-MM-DD")}
                          onChange={this.handleDateInput}
                          onFocus={e =>
                            this.onFocusInput(e, this.elementFinishDate)
                          }
                          onBlur={e =>
                            this.onBlurInput(e, this.elementFinishDate)
                          }
                        />
                      </InputGroup>
                      <Fade
                        in={this.state.showCalender}
                        className={"fade-ui-custom"}
                        style={{
                          display: this.state.showCalender ? "block" : "none"
                        }}
                      >
                        <InputMoment
                          moment={this.state.m}
                          onChange={this.handleChange}
                          minStep={5}
                          onSave={this.handleSave}
                        />
                      </Fade>
                    </div>
                  </div>
                </form>
              </div>
              <hr className={"m-0"} />
              <div className={"form-ui px-3 pb-3"}>
                <form onSubmit={e => e.preventDefault()}>
                  {/*<div className={"row my-3"}>*/}
                  {/*<div className={"col"}>*/}
                  {/*<label className={"form-ui-label"}*/}
                  {/*ref={(element) => this.elementProblemOpportunity = element}>Problem*/}
                  {/*Opportunity*/}
                  {/*Abstract</label>*/}
                  {/*<InputGroup seamless>*/}
                  {/*<InputGroupAddon type="prepend">*/}
                  {/*<InputGroupText>*/}
                  {/*<FaRegEdit/>*/}
                  {/*</InputGroupText>*/}
                  {/*</InputGroupAddon>*/}
                  {/*<FormInput onFocus={(e) => this.onFocusInput(e, this.elementProblemOpportunity)}*/}
                  {/*onBlur={(e) => this.onBlurInput(e, this.elementProblemOpportunity)}/>*/}
                  {/*</InputGroup>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  <div className={"row my-3"}>
                    <div className="col">
                      <label
                        className={"form-ui-label font-size-15 fw-600"}
                        ref={element =>
                          (this.elementOpportunityDetails = element)
                        }
                      >
                        Problem/Opportunity {" "}
                        <span style={{ color: "red", fontSize: 12 }}>
                          maximum 1000 character{" "}
                          {this.state.problemDetails.length > 0
                            ? "(now " + this.state.problemDetails.length +")"
                            : ""}
                        </span>
                      </label>
                      <FormTextarea
                        invalid={this.state.invalidProblemDetails}
                        onChange={e => {
                          if (this.state.problemDetails.length < 1000 ||e.target.value.length<1000)
                            this.setState({
                              problemDetails: e.target.value,
                              invalidProblemDetails: false
                            });
                        }}
                        value={this.state.problemDetails}
                        onFocus={e =>
                          this.onFocusInput(e, this.elementOpportunityDetails)
                        }
                        onBlur={e =>
                          this.onBlurInput(e, this.elementOpportunityDetails)
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>
              <hr className={"m-0"} />
              <div className={"form-ui px-3 pb-3 d-flex flex-column"}>
                <form onSubmit={e => e.preventDefault()}>
                  {/*<div className={"row my-3"}>*/}
                  {/*<div className={"col"}>*/}
                  {/*<label className={"form-ui-label"}*/}
                  {/*ref={(element) => this.elementObjectiveOpportunity = element}>Goal/Objective*/}
                  {/*Abstract</label>*/}
                  {/*<InputGroup seamless>*/}
                  {/*<InputGroupAddon type="prepend">*/}
                  {/*<InputGroupText>*/}
                  {/*<FaRegEdit/>*/}
                  {/*</InputGroupText>*/}
                  {/*</InputGroupAddon>*/}
                  {/*<FormInput*/}
                  {/*onFocus={(e) => this.onFocusInput(e, this.elementObjectiveOpportunity)}*/}
                  {/*onBlur={(e) => this.onBlurInput(e, this.elementObjectiveOpportunity)}/>*/}
                  {/*</InputGroup>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  <div className={"row my-3"}>
                    <div className="col">
                      <label
                        className={"form-ui-label font-size-15 fw-600"}
                        ref={element =>
                          (this.elementObjectiveDetails = element)
                        }
                      >
                        Decision Goal/Objectives {" "}
                        <span style={{ color: "red", fontSize: 12 }}>
                          maximum 400 character{" "}
                          {this.state.objectiveDetails.length > 0
                            ? "(now " + this.state.objectiveDetails.length +")"
                            : ""}
                        </span>
                      </label>
                      <FormTextarea
                        invalid={this.state.invalidObjectiveDetails}
                        onChange={e =>{
                            if(this.state.objectiveDetails.length<400 ||e.target.value.length<400)
                          this.setState({
                            objectiveDetails: e.target.value,
                            invalidObjectiveDetails: false
                          })
                        }
                        }
                        value={this.state.objectiveDetails}
                        onFocus={e =>
                          this.onFocusInput(e, this.elementObjectiveDetails)
                        }
                        onBlur={e =>
                          this.onBlurInput(e, this.elementObjectiveDetails)
                        }
                      />
                    </div>
                  </div>
                </form>
                <button
                  className="btn btn-success-custom align-self-end font-size-13 fw-600"
                  onClick={this.checkValidationStepOne}
                >
                  Proceed
                  <IoIosArrowDroprightCircle className={"ml-2 font-size-19"} />
                </button>
              </div>
            </div>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ selectedDecision }) => {
  return { selectedDecision };
};

export default connect(mapStateToProps, null)(StepOne);
