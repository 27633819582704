import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Badge, Slider, Button } from "shards-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { PulseLoader, ScaleLoader } from "react-spinners";
import { css } from "@emotion/core";
import {
  fetchDecisionAlternativesById,
  addCommentToPoll
} from "../../../actions";
import { fetchVotingStep3 } from "../../../actions/index";
import { authHeader } from "../../../actions/AuthHeader";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { API_URL } from "../../../constants";
import Modal from "react-responsive-modal";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
/**
 * SecondStep class renders sliders for voting on alternatives.
 * also has a text editor for leave a comment.
 */
class ThirdStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 3,
      value: {},
      isPosting: false,
      isVoted: false,
      gotError: false,
      loadingSlider: false,
      commentText: "",
      loadingForSubmitComment: false,
      is_voted: false,
      toggle: false,
      selectedAlternative: "",
    };
    this.modules = {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ script: "super" }, { script: "sub" }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" }
        ],
        [{ align: [] }, "direction"],
        ["link"],
        ["clean"]
      ]
    };
    this.formats = [
      "header",
      "align",
      "script",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image"
    ];
  }

  createMarkup = text => {
    return { __html: text };
  };
  toggleButton = () => {
    this.setState({
      toggle: !this.state.toggle
    });
  };
  showSuccessMessage = text =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000
      }
    );

  showErrorMessage = text =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000
      }
    );

  addComment = async () => {
    let that = this;
    this.setState({
      loadingForSubmitComment: true
    });
    let data = {
      poll: this.props.match.params["id"],
      comment: this.state.commentText
    };
    await this.props
      .dispatch(addCommentToPoll(data))
      .then(data => {
        this.setState({
          loadingForSubmitComment: false
        });
        return that.showSuccessMessage("Successfully Added!");
      })
      .catch(err => {
        return that.showErrorMessage("Please try again !");
      });
  };

  _renderContinueBtn = () => {
    return (
      <div>
        <Modal
          animationDuration={100}
          open={this.state.toggle}
          onClose={this.toggleButton}
          top
        >
          <h5>
            Your votes will be submitted.
            <br />
            Are you sure? ‬
          </h5>
          <hr />

          <div
            style={{ width: "500px" }}
            className={"d-flex justify-content-end"}
          >
            <Button onClick={this.submitVote} className={"mr-1"}>
              {this.state.isPosting ? (
                <div className="sweet-loading">
                  <PulseLoader
                    css={override}
                    sizeUnit={"px"}
                    color={"white"}
                    size={7}
                    loading={true}
                  />
                </div>
              ) : (
                "Yes"
              )}
            </Button>
            <Button onClick={this.toggleButton} className={"ml-1"}>
              No
            </Button>
          </div>
        </Modal>
        <div>
          {/* {!this.state.is_voted ? ( */}
          <button
            className="new-decision-button btn-success-custom btn btn-success mx-2"
            onClick={this.toggleButton}
          >
            {this.state.isPosting ? (
              <div className="sweet-loading">
                <PulseLoader
                  css={override}
                  sizeUnit={"px"}
                  color={"white"}
                  size={7}
                  loading={true}
                />
              </div>
            ) : (
              "submit vote"
            )}
          </button>
          {/* ) : null} */}
        </div>
      </div>
    );
  };

  async componentDidMount() {
    this.setState({
      loadingSlider: true
    });
    const { dispatch, match } = this.props;
    await dispatch(
      fetchDecisionAlternativesById(this.props.match.params["id"])
    );
    await dispatch(fetchVotingStep3(match.params["id"]));

    const pollId = this.props.match.params["id"];

    const endpoint = `polls/${pollId}/check_participate/`;
    const options = {
      method: "GET"
      //   body: JSON.stringify({
      //     "user":this.props.sessionID

      // })
    };
    fetch(`${API_URL}/${endpoint}`, {
      ...options,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader()
      }
    })
      .then(data => data.json())
      .then(res => {
        console.log("asdasd", res);
        if (res.data === "voted") {
          this.setState({
            is_voted: true
          });
        }
      })
      .catch(error => {
        console.log(error);
      });

    const { alternatives } = this.props;
    const votedList = this.props.ThirdStep["data"];

    if (votedList.length > 0) {
      this.setState({
        isVoted: true
      });
    }

    let a = {};
    if (this.state.isVoted) {
      alternatives.map((item, index) => {
        return (a[item["name"]] = votedList[index]["rate"]);
      });
    } else {
      alternatives.map(item => {
        return (a[item["name"]] = 0);
      });
    }
    this.setState({
      value: a,
      loadingSlider: false
    });
  }

  submitVote = async () => {
    let pollId = this.props.match.params["id"];
    this.setState({
      gotError: false
    });

    if (this.state.isVoted) {
      this.setState({
        gotError: false
      });
      this.setState({
        isPosting: true
      });
      let postData;
      postData = this.props.alternatives.map(item => {
        return {
          poll: pollId,
          rate: this.state.value[item["name"]],
          alternative: item["id"]
        };
      });

      const votedList = this.props.ThirdStep["data"];

      for (let i = 0; i < postData.length; i++) {
        await fetch(`${API_URL}/alternativevotes/${votedList[i]["id"]}/`, {
          method: "PUT",
          body: JSON.stringify(postData[i]),
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: authHeader()
          })
        }).then(res => {
          if (res.status === 400) {
            this.setState({ gotError: true });
          }
        });
      }

      this.setState({
        isPosting: false
      });
      if (this.state.gotError) {
        return null;
      }
      return this.props.history.push("/decision/polls");
    }

    this.setState({
      gotError: false
    });
    this.setState({
      isPosting: true
    });
    let postData;
    postData = this.props.alternatives.map(item => {
      return {
        rate: this.state.value[item["name"]],
        alternative: item["id"],
        poll: pollId
      };
    });

    await fetch(`${API_URL}/alternativevotes/`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader()
      })
    }).then(res => {
      if (res.status === 400) {
        this.setState({ gotError: true });
      }
    });

    this.setState({
      isPosting: false
    });
    if (this.state.gotError) {
      return null;
    }
    return this.props.history.push("/decision/polls");
  };

  handleCommentTextChange = value => {
    this.setState({
      commentText: value
    });
  };
  toggleShowAlternativeDescription = alter => {
    // let {selectedAlternative}=this.state;
    // selectedAlternative['name']=alter;
    // selectedAlternative['description']=desc;
    this.setState({
      showAlternativeDescription: !this.state.showAlternativeDescription,
      selectedAlternative:alter
    });
  };

  closeAlternativeDescription = () => {
    this.setState({
      showAlternativeDescription: !this.state.showAlternativeDescription
    });
  };
  render() {
    return (
      <div className={"mx-3 mx-md-5"}>
        <Modal
          open={this.state.showAlternativeDescription}
          onClose={this.closeAlternativeDescription}
          animationDuration={100}
          center
        >
          <div className={"modal-responsive"}>
            <h4>
              {this.state.selectedAlternative["name"]
                ? this.state.selectedAlternative["name"].length > 30
                  ? this.state.selectedAlternative["name"].substring(0, 30) +
                    "..."
                  : this.state.selectedAlternative["name"]
                : ""}
            </h4>
            <hr />
            <div
              className={"alter-description-area p-0 m-0"}
              dangerouslySetInnerHTML={this.createMarkup(
                this.state.selectedAlternative["description"] ||
                  "This alternative does not have description."
              )}
            ></div>
          </div>
        </Modal>
        <h3 className={"fw-600"}>Alternatives</h3>
        <h6 className={"fw-600"}>
          Vote for your overall evaluation of each alternatives
        </h6>
        <hr />
        <div className={"row align-items-center"}>
          <div className={"col-12 col-md-3"}>
            <h6 className={"text-muted fw-600 font-size-13 m-0 p-0"}>
              Alternative
            </h6>
          </div>
          <div className={"col-12 col-md-8"}>
            <h6 className={"text-muted fw-600 font-size-13 m-0 p-0"}>
              Desirability of each alternative
              <br />
              <pre style={{ color: "red" }}>
                (-9:Not desirable   0:Neutral   9:Very desirable)
              </pre>
            </h6>
          </div>
        </div>
        {!this.state.loadingSlider ? (
          this.props.alternatives.length > 0 ? (
            this.props.alternatives.map((item, index) => {
              return (
                <div className={"row align-items-center"} key={index}>
                  <div className={"col-12 col-md-12"} onClick={() =>
                        this.toggleShowAlternativeDescription(item)} >
                    <h6 className={"fw-600 font-size-18 m-0 p-0"}>
                      <Badge pill>{item["name"]}</Badge>
                    </h6>
                  </div>
                  <div className={"col-12 col-md-11 p-0 m-0"}>
                    <Slider
                      onSlide={e => {
                        let newValue = { ...this.state.value };
                        newValue[item["name"]] = parseFloat(e[0]);
                        this.setState({
                          value: newValue
                        });
                      }}
                      connect={[true, false]}
                      step={1}
                      start={[this.state.value[item["name"]]]}
                      range={{ min: -9, max: 9 }}
                    />
                  </div>
                  <div className={"col-12 col-md-1"}>
                    <h6 className={"fw-600 font-size-13 m-0 p-0"}>
                      {this.state.value[item["name"]]} / 9
                    </h6>
                  </div>
                </div>
              );
            })
          ) : null
        ) : (
          <div className={"d-flex justify-content-center"}>
            <div className="sweet-loading">
              <ScaleLoader
                css={override}
                sizeUnit={"px"}
                size={150}
                color={"#FF8260"}
                loading={true}
              />
            </div>
          </div>
        )}
        {/* <hr />
        <h4>Your comment about this decision (optional):</h4>
        <div className={"editor"}>
          <ReactQuill
            theme="snow"
            value={this.state.commentText}
            onChange={this.handleCommentTextChange}
            modules={this.modules}
            formats={this.formats}
          ></ReactQuill>
          <div className={"d-flex justify-content-end mt-3"}>
            <Button onClick={this.addComment}>
              {this.state.loadingForSubmitComment ? (
                <div className="sweet-loading">
                  <PulseLoader
                    css={override}
                    sizeUnit={"px"}
                    color={"white"}
                    size={7}
                    loading={true}
                  />
                </div>
              ) : (
                "Submit comment"
              )}
            </Button>
          </div>
        </div> */}
        <hr />
        <div>
          <div className={"d-flex justify-content-end my-3"}>
            {this.state.step > 1 && (
              <button
                className="new-decision-button btn-success-custom btn btn-success mx-2"
                onClick={this.props.previousStep}
              >
                Go Back
              </button>
            )}
            {this._renderContinueBtn()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ThirdStep: state.votingStep.ThirdStep,
    alternatives: state.selectedDecision["alternatives"]
  };
};

export default withRouter(connect(mapStateToProps, null)(ThirdStep));
