import React, { Component, Fragment, useState, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import { MdEmail } from "react-icons/md";

import {
  Row,
  Col,
  Container,
  Button,
  Form,
  FormInput,
  FormGroup,
} from "shards-react";
import AutoCompleteAttribute from "../../layout/UI/AutoCompleteAttribute";

import { connect } from "react-redux";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import { API_URL } from "../../constants";
import { authHeader } from "../../actions/AuthHeader";
import useAxios from "@use-hooks/axios";
import {
  IoMdCheckmarkCircle,
  IoMdCloseCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import MaterialTable from "material-table";
import { PulseLoader, ScaleLoader } from "react-spinners";
import Modal from "react-responsive-modal";
import { css } from "@emotion/core";
import OrganizationAttrs from "./adminAccount/OrganizationAttrs";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const AdminAccountAttrs = (props) => {


  const [OrgAttr, setOrgAttr] = useState(null);
  const [showRemoveToggle, setshowRemoveToggle] = useState(false);
  const [AllAttr, setAllAttr] = useState(null);

  const [showAddToggle, setshowAddToggle] = useState(false);

  const [isPosting, setisPosting] = useState(false);
  const [OnRemoveAttr, setOnRemoveAttr] = useState("");
  const [OnAddAttr, setOnAddAttr] = useState("");

  const getOrganizationAttr = useAxios({
    url: `${API_URL}/users/${props.sessionID}/org_attr/`,
    options: {
      headers: {
        Authorization: authHeader(),
      },
    },
    trigger: [],
    customHandler: (err, res) => res && setOrgAttr(res.data.data),
  });


    
  const getAllAttr = useAxios({
    url: `${API_URL}/attributes/`,
    options: {
      headers: {
        Authorization: authHeader(),
      },
    },
    trigger: [],
    customHandler: (err, res) => res && setAllAttr(res.data),
  });
  // if (getAllAttr.response){
  //   console.log("all attr",getAllAttr.response.data.results)
  // }


  const createOrgAttr = useAxios({
    url: `${API_URL}/users/${props.sessionID}/org_add_attribute/`,
    method: "POST",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: {
        attr: OnAddAttr,
      },
    },
    // trigger: [],
    customHandler: (err, res) => {
      if (res) {
        setisPosting(false);

        setshowAddToggle((before) => !before);
        setOnAddAttr("");
        console.log("res", res);
        const message = res.data.data;
        console.log(message);
        if (message == "attribute_added") {
          console.log("there");
          showSuccessMessage("attribute is added");
        }
        getOrganizationAttr.reFetch();
        // } else if (message === "user_is_in_org") {
        //   showErrorMessage("user is in organization");
        // } else if (message === "invitation_is_send") {
        //   showSuccessMessage("Invitaion is sent");
        // }
      }
    },
  });


  const removeOrgAttr = useAxios({
    url: `${API_URL}/users/${props.sessionID}/org_remove_attribute/`,
    method: "POST",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: {
        attr: OnRemoveAttr,
      },
    },
    // trigger: [],
    customHandler: (err, res) => {
      if (res) {
        setisPosting(false);

        
        console.log("res", res);
        const message = res.data.data;
        console.log(message);
        if (message == "not_found") {
          console.log("there");
          showErrorMessage("user not found");
        } else if (message === "attr_removed") {
          showSuccessMessage("attribute is removed");
        }
        setshowRemoveToggle(before=>!before)
        getOrganizationAttr.reFetch();
      }
    },
  });

  const showErrorMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

  const showSuccessMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );
  useEffect(() => {
    console.log("asd");
    

  }, []);

  const removeAttr = () => {
    // ! add isposting
    // setisPosting(true)
    removeOrgAttr.reFetch();
  };

  const addAttr = () => {
    setisPosting(true);
    createOrgAttr.reFetch();
  };

  const handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      return addAttr();
    }
  };


  const onChangeAttribute = (event, { newValue }) => {
    if (newValue.length <= 20)
      setOnAddAttr(newValue);
  };
  // console.log("onRemove", OnremoveUser);

  return (
    <div>
      <Fragment>
        <Modal
          animationDuration={100}
          open={showRemoveToggle}
          onClose={() => setshowRemoveToggle((before) => !before)}
          top
        >
          <h5>
            Your attr  will be remove.
            <br />
            Are you sure? ‬
          </h5>
          <hr />

          <div
            style={{ width: "500px" }}
            className={"d-flex justify-content-end"}
          >
            <Button onClick={removeAttr} className={"mr-1"}>
              {isPosting ? (
                <div className="sweet-loading">
                  <PulseLoader
                    css={override}
                    sizeUnit={"px"}
                    color={"white"}
                    size={7}
                    loading={true}
                  />
                </div>
              ) : (
                "Yes"
              )}
            </Button>
            <Button
              onClick={() => {
                setshowRemoveToggle((before) => !before);
                setOnRemoveAttr("");
              }}
              className={"ml-1"}
            >
              No
            </Button>
          </div>
        </Modal>

       
         <Modal
        animationDuration={100}
        open={showAddToggle}
        onClose={() => {
          setshowAddToggle((before) => !before);
        }}
        center
      >
        <div className={"modal-responsive"}>
          <h3>Add Attribute</h3>
          <hr />
          <div onKeyDown={handleEnterKey}>
            <Form onSubmit={(e) => e.preventDefault()}>
              <FormGroup>
                <label htmlFor="#AttributeName">
                  Attribute Name{" "}
                  <span style={{ color: "red", fontSize: 12 }}>
                    maximum 20 character{" "}
                    {OnAddAttr.length > 0
                      ? "(now " + OnAddAttr.length + ")"
                      : ""}
                  </span>
                </label>
                <AutoCompleteAttribute
                  inputProps={{
                    placeholder: "Attributes",
                    value: OnAddAttr,
                    onChange: onChangeAttribute,
                  }}
                  data={getAllAttr.response?.data.results}
                />
              </FormGroup>
            </Form>
          </div>
          <hr />
          <div>
            <Button theme={"primary"} onClick={addAttr}>
              OK
            </Button>
          </div>
        </div>
      </Modal>
        <div
          className={"form-ui p-3"}
          style={{
            backgroundColor: "white",
            marginTop: "70px",
            paddingBottom: "50px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          {props.data.org ? (
            <Container>
              <Row>
                <Col xs="12" sm="12" md="12" lg="12">
                  <div className={"  my-1"}>
                    <h3 className={"form-ui-label font-size-13 fw-600"}>
                      My Organization:
                      <span style={{ marginLeft: "10px" }}>
                        {props.data.org.org_name}
                      </span>
                      <br />
                      <h5
                        className={"form-ui-label font-size-13 fw-600"}
                        style={{ marginTop: "5px" }}
                      >
                        No. of Org. attributes:
                        <span style={{ marginLeft: "10px" }}>
                          {getOrganizationAttr.response?.data.data.length}
                        </span>
                      </h5>
                    
                    </h3>
                  </div>
                </Col>
              </Row>
              <button
                className="btn btn-success-custom align-self-end font-size-13 fw-600 mb-4"
                onClick={() => setshowAddToggle((before) => !before)}
              >
                New Org. Attribute
              </button>
              {getOrganizationAttr.response ? (
                <OrganizationAttrs
                  data={OrgAttr}
                  setOnRemoveAttr={setOnRemoveAttr}
                  setshowRemoveToggle={setshowRemoveToggle}
                />
              ) : undefined}
            </Container>
          ) : undefined}
        </div>
      </Fragment>
    </div>
  );
};


export default withRouter(connect(null, {})(AdminAccountAttrs));
