import React, {Component, Fragment} from 'react';
import './App.css';
import {connect} from "react-redux";
import {Route, Switch, Redirect} from "react-router";
import {withRouter} from "react-router-dom";
import Navbar from "./layout/Navbar/NavbarUI";
import Submenu from "./layout/Submenu/SubmenuUI";
import Decisions from "./containers/Decisions";
import Attribute from "./containers/Attribute";
import People from "./containers/People";
import Profile from "./containers/Profile";
import {fetchUserSession, fetchUserNotification, postNotificationToken, putNotificationToken} from "./actions/index";
import {ScaleLoader} from 'react-spinners';
import {css} from '@emotion/core';
import Setting from "./containers/Setting";
import {messaging} from "./init-fcm";
import SocialConfirm from './containers/SocialConfim';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

/**
 * App class renders submenu of navbar.
 */
class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    async componentDidMount() {
        const {dispatch} = this.props;
        this.setState({
            isLoading: true
        });
        if (!this.props.sessionID) {
            await dispatch(fetchUserSession());
        }

        this.setState({
            isLoading: false
        });

        Notification.requestPermission().then(async (permission) => {

            if (permission === 'granted') {
                if (!messaging) return
               
                const token = await messaging.getToken();

                if (localStorage.getItem('registration_token')) {
                    let prevToken = localStorage.getItem('registration_token');
                    if (token !== prevToken) {
                        localStorage.setItem('registration_token', token);
                        dispatch(putNotificationToken(prevToken, token))
                    }
                } else {
                    localStorage.setItem('registration_token', token);
                    dispatch(postNotificationToken(token))
                }
            }
        });


        Notification.close = () => {
            dispatch(fetchUserNotification(this.props.sessionID))
        };

        if (!messaging) return
        messaging.onMessage((payload) => {
            console.log('Message received. ', payload);
            let options = {
                body: "You have a notification message!",
                dir: "ltr"
            };
            let notification = new Notification("Hi there", options);
            dispatch(fetchUserNotification(this.props.sessionID))
        });
    }


    render() {
        return (
            <div className={"main-wrapper"}>
                {this.state.isLoading ?
                    (
                        <div className={"spinner-loader"}>
                            <div className='sweet-loading'>
                                <ScaleLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    size={150}
                                    color={'#FF8260'}
                                    loading={this.state.isLoading}
                                />
                            </div>
                        </div>
                    ) :
                    (
                        <Fragment>
                            <Navbar/>
                            <Submenu/>
                            <Switch>
                                <Route path={"/decision"} component={Decisions}/>
                                <Route path={"/attribute"} component={Attribute}/>
                                <Route path={"/people"} component={People}/>
                                <Route path={"/people/users/:id"} component={Profile}/>
                                <Route path={"/setting"} component={Setting}/>
                            </Switch>
                        </Fragment>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sessionID: state.session.data.username
    }
};

export default withRouter(connect(mapStateToProps, null)(App));
