
import React, { Component, Fragment, useState, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import { MdEmail } from "react-icons/md";

import {
  Row,
  Col,
  Container,
  Button,
  FormInput,
  InputGroup,
  FormSelect,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";
import { connect } from "react-redux";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import { API_URL } from "../../constants";
import { authHeader } from "../../actions/AuthHeader";
import useAxios from "@use-hooks/axios";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/core";
import isEmail from "validator/lib/isEmail";
import MaterialTable from "material-table";

import AutoComplete from "../../layout/UI/AutoComplete";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const AdminEnterpriseUsers = (props) => {
  const [AllUsers, setAllUsers] = useState(null);
  const [AllEnterpriseUsers, setAllEnterpriseUsers] = useState(null);



  const poll_copy_table_rows_name = [
    {
      title: "ID",
      render: (rowData) => rowData.tableData.id + 1,
    },

    { title: "Organization Name", field: "organization_name" },
    { title: "Organization Type", field: "organization_type" },
    { title: "organization Owner", field: "user.username" },
  ];



  const getAllEnterpriseUser = useAxios({
    url: `${API_URL}/users/${props.sessionID}/all_enterprise_users/`,
    options: {
      headers: {
        Authorization: authHeader(),
      },
    },
    trigger: [],
    customHandler: (err, res) => {
      if (res) {
        console.log("enterprise", res.data);
        setAllEnterpriseUsers(res.data);
      }
    },
  });

  

  const showErrorMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

  const showSuccessMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

 
  // console.log("onRemove", OnremoveUser);

  return (
    <div>
      <Fragment>
      
        {getAllEnterpriseUser.response?.data && (
          <div
            className={"form-ui p-3"}
            style={{
              backgroundColor: "white",
              marginTop: "70px",
              paddingBottom: "50px",
              border: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <MaterialTable
              title="Enterprise Organizations Admin list"
              columns={poll_copy_table_rows_name}
              data={AllEnterpriseUsers}
              localization={{
                header: {
                  actions: "More",
                },
              }}
            //   actions={[
            //     {
            //       icon: "settings",
            //       // iconProps: { style: {  color: "green" } },
            //       tooltip: "Poll Detail",
            //       onClick: (event, rowData) =>
            //         props.history.push(
            //           `/setting/pollCopy/voting/${rowData.id}`
            //         ),
            //     },
            //   ]}
              options={{
                actionsColumnIndex: -1,
                //   sorting: true,
                //   filtering: true,
              }}
            />
          </div>
        )}
      </Fragment>
    </div>
  );
};

export default withRouter(connect(null, {})(AdminEnterpriseUsers));
