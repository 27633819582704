import React, { Component, Fragment } from "react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { fetchUserSession } from "../../actions";
import toast from "toasted-notes/lib/index";
import "toasted-notes/src/styles.css";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { API_URL } from "../../constants";
import { authHeader } from "../../actions/AuthHeader";
import CookieConsent, { Cookies } from "react-cookie-consent";

/**
 * Landing class renders landing page.
 */
import ReactGA from "react-ga";
ReactGA.initialize("UA-153873451-1");
ReactGA.pageview(window.location.pathname + window.location.search);

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      className: "header_area",
      isLoggedIn: false    };
  }

  async componentDidMount() {


    if (window.pageYOffset > 100) {
      this.setState({
        className: "header_area  navbar_fixed"
      });
    } else {
      this.setState({
        className: "header_area"
      });
    }
    window.addEventListener("scroll", this.listenToScroll);
    this.goToSection();
    await this.props.dispatch(fetchUserSession());
    this.checkAuth();
  }

  checkAuth = () => {
    const isAuthorized = localStorage.getItem("authToken") ? true : false;
    this.setState({
      isLoggedIn: isAuthorized
    });
  };
  showSuccessMessage = text =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000
      }
    );
  submitEmail = e => {
    e.preventDefault();
    console.log("here");
    this.setState({
      email: ""
    });
    const postData = {
      email: this.state.email
    };
    fetch(`${API_URL}/newsletter/`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader()
      }
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        this.showSuccessMessage("thanks for your submit");
      });
  };
  goToSection = () => {
    switch (this.props.location.hash) {
      case "#Home":
        return window.scrollTo(0, 0);
      case "#Story":
        return window.scrollTo(0, 1300);
      case "#info":
        return window.scrollTo(0, 1808);
      case "#RayDalio":
        return window.scrollTo(0, 4112.72705078125);
      default:
        return null;
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  listenToScroll = async () => {
    if (window.pageYOffset > 100) {
      this.setState({
        className: "header_area  navbar_fixed"
      });
    } else {
      this.setState({
        className: "header_area"
      });
    }
  };

  render() {
    return (
      <Fragment>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
          acceptOnScroll={false}
        >
          This website uses cookies to enhance the user experience.{" "}
          {/* <span style={{ fontSize: "10px" }}>
            This bit of text is smaller :O
          </span> */}
        </CookieConsent>

        <header className={this.state.className} id={"Home"}>
          <div className="main_menu">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div
                className="container box_1620"
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <a className="navbar-brand logo_h">
                  <img
                    src={require("../../assets/icon.png")}
                    height={45}
                    width={140}
                    alt=""
                  />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>

                <div
                  className="collapse navbar-collapse offset"
                  id="navbarSupportedContent"
                >
                  <ul className="nav navbar-nav menu_nav justify-content-center">
                    <li className="nav-item">
                      <a className="nav-link" href="#Home">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#Story">
                        Our Story
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#info">
                        How it works
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" >
                        <Link to={"/pricing"} >
                        Pricing
                        </Link>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#RayDalio">
                        Ray Dalio
                      </a>
                    </li>
                  </ul>
                  <ul className="nav navbar-nav navbar-right">
                    {this.state.isLoggedIn ? (
                      <span
                        className={
                          "nav-profile-avatar d-flex flex-column align-items-center my-2"
                        }
                      >
                        <Link to={"/decision"}>
                          <div
                            className={"avater-img"}
                            style={{
                              backgroundImage:
                                "url(" + this.props.session.data.pic + ")"
                            }}
                          ></div>
                        </Link>
                            <p style={{color:'white'}}>Account</p>
                        
                      </span>
                    ) : (
                      <li className="nav-item">
                        <Link to={"/login"} className="tickets_btn">
                          Login / Sign up
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>

        <section className="home_banner_area">
          <div className="banner_inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="banner_content text-center">
                    <h2 style={{ color: "white " }}>
                      MeriTa: Decision Network
                    </h2>
                    <p>
                      {" "}
                      MeriTa is a decision platform that ensures the best ideas win. Our intelligent decision engine helps you to limit the effect of office politics on your decisions and have efficient meetings.

                      {/* MeriTa is a meritocratic decision Support platform to help managers make
                      decisions in a structured form with a true expert system.
                      Each expert is more believable in his/her areas of
                      expertise. */}
                    </p>
                    <Link to={"/login"} className="banner_btn">
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="work_area p_120">
          <div className="container">
            <div className="main_title">
              {/*<h2>*/}
              {/*Lorem Ipsum*/}
              {/*</h2>*/}
              {/*<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor*/}
              {/*incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud*/}
              {/*exercitation.</p>*/}
            </div>
            <div className="work_inner row">
              <div className="col-md-4 col-lg-4">
                <div className="work_item">
                  <img
                    className={"profile_leverage"}
                    src={require("../../assets/1_Leverage.jpeg")}
                    height={200}
                    width={350}
                  />
                  <h6 className={"mt-2 mb-5"}>
                    Leverage each <br /> expert's areas of strength
                  </h6>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="work_item">
                  <img
                    className={"profile_leverage"}
                    src={require("../../assets/2_Get.jpeg")}
                    height={200}
                    width={350}
                  />
                  <h6 className={" mt-2 mb-5"}>
                    Get more out of meetings by <br /> focusing on decisions
                  </h6>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="work_item">
                  <img
                    className={"profile_leverage"}
                    src={require("../../assets/3_Merit.jpeg")}
                    height={200}
                    width={350}
                  />
                  <h6 className={" mt-2 mb-5"}>
                    Meritocratic & Efficient <br /> decision making
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="made_life_area p_120" id={"Story"}>
          <div className="container">
            <div className="made_life_inner">
              <div className="row made_life_text">
                <div className="col-lg-6">
                  <div className="left_side_text">
                    <h3>Our Story</h3>
                    <p className={"text-black"}>
                      We are passionate about making sure the best ideas win. MeriTa is a Decision Support
                      System(DSS) to limit the effects of office politics on your decisions. It also helps introverts and 
                      minorities to be heard and real expertise to prevail.
                      <br/>
                      MeriTa detects who has more expertise in subject matter and gives more weight to their votes.
                      Sometimes this leads to the majority being overruled by an expert minority; an idea meritocracy.

                      {/* MeriTa is a Decision support system(DSS) to make decisions
                      collaboratively based on each person's strengths. MeriTa
                      allows merit based decision making as opposed to autocracy
                      or democracy. Each person's votes has more validity in
                      his/her areas of expertise. MeriTa intelligently collects
                      and combines these ratings to make decisions in a more
                      meritocratic and reliable manner.
                      <br />
                      • Collaborative decision platform that uses a true expert
                      system to solve problems
                      <br />
                      • Allows managers to analyse problems and design the
                      decision making framework for each specific issue
                      <br />
                      • Determine each person's strong skills and give more
                      weight to his/her views in those areas
                      <br />
                      • Implementing MeriTa in an organization will lead to
                      aggregation of knowledge pools, helping HR to understand
                      workforce, knowledge gaps, learning objectives, Merti
                      Matrix, and a lot more.
                      <br /> */}
                    </p>
                    <Link className="main_btn" to="/login">
                      Get Started Now
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="chart_img">
                    <img
                      className="img-fluid"
                      src={require("../../assets/future_city.jpg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="made_life_area made_white mt-4 " id={"info"}>
          <div className="container">
            <div className="made_life_inner text-center mt-5">
              {/* <h2 className={"landing_h2"}>How it works</h2> */}
              <div className={"Info-graphic mt-5"}></div>
            </div>
          </div>
        </section>

        <section className="made_life_area p_120" id={"RayDalio"}>
          <div className="container">
            <div
              className="made_life_inner text-center"
              className={"text-black"}
            >
              <h2 className={"landing_h2 text-center"}>Tribute to Ray Dalio</h2>
              <center>
                <p>
                  We have great respect for Ray Dalio’s works specially in
                  <a
                    style={{ textDecoration: "underline !important" }}
                    className={"text-a"}
                    href="https://www.principles.com/"
                  >
                    {" "}
                    principles.
                  </a>
                  <br /> He promotes basing decisions on each person’s merits.
                </p>
                <p>
                  His views about ​{" "}
                  <strong>believability weighted decision making </strong>
                  encouraged us to develop MeriTa.
                  <br />
                  While MeriTa offers a different approach to dot collector, it
                  uses the same principle.
                </p>
                <p>
                  Ray dalio is the CEO of most successful hedge fund in history and he attributes his 
                  {/* We strongly encourage all managers and decision makers to read
                  the book: ​{" "} */}
                  {/* <a
                    style={{ textDecoration: "underline !important" }}
                    className={"text-a"}
                    href="https://www.amazon.com/Principles-Life-Work-Ray-Dalio/dp/1501124021/ref=sr_1_1?keywords=principles+ray+dalio&qid=1558405148&s=gateway&sr=8-1"
                  >
                    {" "}
                    principles.
                  </a>{" "} */}
                </p>
                <p>success to his specific way of decision making .</p>
                <p>To see a great example of believability weighted decision making, check this video.</p>
              </center>

              <div>
                <div
                  style={{
                    position: "relative",
                    height: 0,
                    paddingBottom: "56.25%"
                  }}
                >
                  <iframe
                    src="https://www.youtube.com/embed/Q9ZeT8tNliU"
                    width="854"
                    height="480"
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%"
                    }}
                    frameBorder={0}
                    scrolling="no"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="work_area p_120">
          <div className="container">
            <div className="main_title">
              {/*<h2>*/}
              {/*Lorem Ipsum*/}
              {/*</h2>*/}
              {/*<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor*/}
              {/*incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud*/}
              {/*exercitation.</p>*/}
            </div>
            <div className="work_inner row">
              <div className="col-md-4 col-lg-4">
                <div className="work_item">
                  <img
                    className={"profile_leverage"}
                    src={require("../../assets/b1.jpg")}
                    height={200}
                    width={350}
                  />
                  <h6 className={"mt-2 mb-5"}>
                    <br/>
                    Meritocratic decision Platform
                  </h6>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="work_item">
                  <img
                    className={"profile_leverage"}
                    src={require("../../assets/b2.jpg")}
                    height={200}
                    width={350}
                  />
                  <h6 className={" mt-2 mb-5"}>
                    Merit weighted results
                  </h6>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="work_item">
                  <img
                    className={"profile_leverage"}
                    src={require("../../assets/b3.jpg")}
                    height={200}
                    width={350}
                  />
                  <h6 className={" mt-2 mb-5"}>
                    Connect with your network of experts
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="footer_area p_120">
          <div className="container">
            <div className="row footer_inner">
              <div className="col-lg-3 col-sm-6">
                <aside className="f_widget ab_widget">
                  <div className="f_title">
                    <h3>About Us</h3>
                  </div>
                  <p>MeriTa is a meritocratic decision support network</p>
                  <p>
                    Copyright
                    <span className={"mx-1"}>
                      &copy;
                      {new Date().getFullYear()}
                    </span>
                    All rights reserved
                  </p>
                </aside>
              </div>
              <div className="col-lg-3 col-sm-6">
                <aside className="f_widget news_widget">
                  <div className="f_title">
                    <h3>Newsletter</h3>
                  </div>
                  <p>Stay updated with us</p>
                  <div id="mc_embed_signup">
                    <form
                      className="subscribe_form relative"
                      onSubmit={this.submitEmail}
                    >
                      <div className="input-group d-flex flex-row">
                        <input
                          name="EMAIL"
                          placeholder="Enter email address"
                          required=""
                          type="email"
                          value={this.state.email}
                          onChange={e =>
                            this.setState({ email: e.target.value })
                          }
                        />
                        <button className="btn sub-btn">
                          <span className="lnr lnr-arrow-right"></span>
                        </button>
                      </div>
                      <div className="mt-10 info"></div>
                    </form>
                  </div>
                </aside>
              </div>
              <div className="col-lg-3">
                <aside className="f_widget social_widget">
                  <div className="f_title">
                    <h3>Follow Us</h3>
                  </div>
                  <p>Let us be social</p>
                  <ul className="list">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/myMeriTa1"
                      >
                        <FaFacebook />
                      </a>
                    </li>

                    <li>
                      <a
                        target="_blank"
                        href="https://twitter.com/MeriTa48053287"
                      >
                        <FaTwitter />
                      </a>
                    </li>

                    <li>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/merita/"
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                  <br />
                  <a
                    href="https://mymeritablog.wordpress.com/"
                    target="_blank"
                    className={"contact-us-email"}
                  >
                    MeriTa Blog
                  </a>
                </aside>
              </div>
              <div className="col-lg-3">
                <aside className="f_widget social_widget">
                  <div className="f_title">
                    <h3>Contact us</h3>
                  </div>
                  <a
                    className={"contact-us-email"}
                    href={
                      "mailto: info@myMeriTa.com?subject=Feedback&body=Message"
                    }
                  >
                    info@myMeriTa.com
                  </a>
                  <br />

                  <a href="/privacy" className={"contact-us-email"}>
                    Privacy
                  </a>
                  <br />

                  <a href="/terms" className={"contact-us-email"}>
                    Terms
                  </a>
                </aside>
              </div>
            </div>
          </div>
        </footer>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session }) => {
  return { session };
};

export default withRouter(connect(mapStateToProps, null)(Landing));
