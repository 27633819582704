import React, { useState, useEffect } from "react";

import StepWizard from "react-step-wizard";
import Nav from "../../../layout/UI/StepNav";
import { InputGroup, Button } from "shards-react";
import "../../../layout/UI/StepNav/transition.css";
import CopyFirstStep from "./CopyFirstStep";
import CopySeccondStep from "./CopySeccondStep";
import CopyThirdStep from "./CopyThirdStep";
import AutoComplete from "../../../layout/UI/AutoCompleteUser";
import { withRouter } from "react-router";

import { API_URL } from "../../../constants";
import { authHeader } from "../../../actions/AuthHeader";
import useAxios from "@use-hooks/axios";
import { Container } from "@material-ui/core";
import { Link } from "react-router-dom";

const CopyVoting = (props) => {
  const [activeStep, setActiveStep] = useState(1);
  const [allPollCopy, setAllPollCopy] = useState(null);
  const [pollCopy, setPollCopy] = useState(null);
  const [sw, setSW] = useState(null);
  const [NewUser, setNewUser] = useState("");
  const [done, setDone] = useState(false);
  const [votedUser, setVotedUser] = useState();

  const getPollCopy = useAxios({
    url: `${API_URL}/pollCopy/${props.match.params.id}`,
    options: {
      headers: {
        Authorization: authHeader(),
      },
    },
    trigger: [],
    customHandler: (err, res) => {
      if (res) {
        console.log(res.data);
        setPollCopy(res.data);
      }
    },
  });

  const getVotedUser = useAxios({
    url: `${API_URL}/pollCopy/${props.match.params.id}/get_voted_user/`,
    options: {
      headers: {
        Authorization: authHeader(),
      },
    },
    trigger: [],
    customHandler: (err, res) => {
      if (res) {
        console.log(res.data);
        setVotedUser(res.data);
      }
    },
  });

  const onStepChange = (stats) => {
    setActiveStep(stats.activeStep);
  };
  const setInstance = (SW) => setSW({ SW });
  const onAddNewUser = (event, { newValue }) => {
    setNewUser(newValue);
    setDone(false);
  };
  const refactorProfileUrl = (url) => {
    if (url && String(url).substring(0, 4) === "http") {
      return url;
    } else {
      console.log(url);
      return `${API_URL}/uploads/${url["profile_pic"]}`;
    }
  };
  const doneUserSelect = () => {
    // console.log(NewUser)
    if (
      pollCopy.participants.filter((item) => item.username == NewUser).length >
      0
    ) {
      setDone(true);
      // const item = pollCopy.participants.filter(item=>item.email == NewUser)
      // setNewUser(item.username)
    } else console.log("no");
  };
  return (
    <Container>
      <div className={"poll-wrapper"}>
        {getPollCopy.response?.data ? (
          <div className={"poll-data-body mx-3 mx-md-5 my-2"}>
            <div className={"row"}>
              <div className={"col-12 col-md-6"}>
                <h6 className={"fw-600 font-size-13"}>
                  Decision Alternatives:
                </h6>
              </div>
              <div className={"col-12 col-md-9"}>
                {getPollCopy.response.data.alternatives.map((item, index) => {
                  return (
                    <span className={"alternative-badge_size mx-1"} key={index}>
                      {item["name"].length > 30
                        ? item["name"].substring(0, 30) + "..."
                        : item["name"]}
                    </span>
                  );
                  // : (
                  //   <span
                  //     onClick={() =>
                  //       this.toggleShowAlternativeDescription(item)
                  //     }
                  //     className={"alternative-badge mx-1"}
                  //     key={index}
                  //   >
                  //     {item["name"]}
                  //   </span>
                  // );
                })}
              </div>
            </div>
          </div>
        ) : undefined}

        <hr />
        {getPollCopy.response?.data ? (
          <div className={"poll-data-body mx-3 mx-md-5 my-2"}>
            <div className={"row"}>
              <div className={"col-12 col-md-3"}>
                <h6 className={"fw-600 font-size-13"}>Required Attributes:</h6>
              </div>
              <div className={"col-12 col-md-9"}>
                {getPollCopy.response.data["attributes"].map((item, index) => (
                  <Link to={`/attribute/t/`} key={index}>
                    <span className={"attribute-badge"}>{item}</span>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        ) : undefined}
        <hr />
        {getPollCopy.response?.data ? (
          <div className={"poll-data-body mx-3 mx-md-5 my-2"}>
            <div className={"row"}>
              <div className={"col-12 col-md-3"}>
                <h6 className={"fw-600 font-size-13"}>Participants:</h6>
              </div>
              <div className={"col-12 col-md-9 d-flex flex-wrap"}>
                {
                  // console.log("asddsd", this.props.data),
                  getPollCopy.response?.data["participants"].map(
                    (item, index) => (
                      // console.log(item),
                      <Link
                        to={`/people/users/${item["username"]}`}
                        key={index}
                        className={"m-0 poll-data-header-creator fw-300"}
                      >
                        <span
                          className={
                            "nav-profile-avatar d-flex align-items-center mr-4"
                          }
                          style={{ fontWeight: "Normal" }}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${refactorProfileUrl(
                                item["pic"]
                              )})`,
                            }}
                            className={"avater-img mr-1"}
                          ></div>
                          {item["first_name"] + " " + item["last_name"]}
                        </span>
                      </Link>
                    )
                  )
                }
              </div>
            </div>
          </div>
        ) : undefined}
        {getPollCopy.response?.data.state !== "dr" ? (
          <div className={"poll-data-body mx-3 mx-md-5 my-2"}>
            <div className={"row"}>
              <div className={"col-12 col-md-3"}>
                <h6 className={"fw-600 font-size-13"}>Details:</h6>
              </div>
              <div className={"col-12 col-md-9 d-flex flex-wrap"}>
                {/* <h5>
                number of participants{" "}
                {getPollCopy.response?.data.participants.length}
              </h5> */}
                <h5 className={"fw-600 font-size-18  ml-5"}>
                  {" "}
                  {getVotedUser.response?.data.data} /
                  {getPollCopy.response?.data.participants.length} is voted
                </h5>
              </div>
            </div>
          </div>
        ) : undefined}
        <hr />

        {getPollCopy.response?.data.state !== "dr" ? (
          <div className={"col-12 col-md-10 my-1"}>
            <label className={"form-ui-label font-size-13 fw-600"}>
              username
            </label>
            <InputGroup seamless>
              {getPollCopy.response ? (
                <AutoComplete
                  inputProps={{
                    placeholder: "",
                    value: NewUser,
                    onChange: onAddNewUser,
                  }}
                  data={getPollCopy.response?.data.participants}
                />
              ) : undefined}
            </InputGroup>
            <button
              className=" mt-4 new-decision-button btn-success-custom btn btn-success mx-2"
              onClick={doneUserSelect}
            >
              Continue
            </button>
          </div>
        ) : undefined}
      </div>

      {getPollCopy.response?.data.state !== "dr" ? (
        <div
          className={"poll-wrapper mt-1 mb-6"}
          style={{ backgroundColor: "#fafafa" }}
        >
          <div className={"poll-data-wrapper container"}>
            {done && (
              <StepWizard
                onStepChange={onStepChange}
                //   transitions={this.state.transitions}
                //   nav={<Nav />}
                instance={setInstance}
              >
                {activeStep == 1 ? (
                  <CopyFirstStep poll={pollCopy} user={NewUser} />
                ) : (
                  <div>test</div>
                )}
                {activeStep == 2 ? (
                  <CopySeccondStep poll={pollCopy} user={NewUser} />
                ) : (
                  <div>test</div>
                )}
                {activeStep == 3 ? (
                  <CopyThirdStep
                    history={props.history}
                    user={NewUser}
                    poll={pollCopy}
                  />
                ) : (
                  <div>test</div>
                )}
              </StepWizard>
            )}
          </div>
        </div>
      ) : undefined}
    </Container>
  );
};

export default withRouter(CopyVoting);
