import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Modal from "react-responsive-modal";
import { TableFooter } from "@material-ui/core";

export default class EwsTable extends React.Component {
  state = {
    table_sum: undefined,
    selectedAlternative: { name: "", description: "" }
  };

  createMarkup = text => {
    return { __html: text };
  };
  componentDidMount = () => {
    var table_sum = 0;
    this.props.data.map(item => {
      table_sum += Math.abs(Math.floor(item.rate));
    });
    this.setState({
      table_sum: table_sum
    });
  };

  closeAlternativeDescription = () => {
    this.setState({
      showAlternativeDescription: !this.state.showAlternativeDescription
    });
  };
  toggleShowAlternativeDescription = (alter, desc) => {
    let { selectedAlternative } = this.state;
    selectedAlternative["name"] = alter;
    selectedAlternative["description"] = desc;
    this.setState({
      showAlternativeDescription: !this.state.showAlternativeDescription,
      selectedAlternative
    });
  };
  render() {
    const columns = [
      {
        id: "0",
        label: "Rank",
        align: "center"
      },
      {
        id: "1",
        label: "Alternative",
        align: "center"
      },
      {
        id: "2",
        label: "Equal Weighted Score (EWS*)",
        align: "center"
      },
      {
        id: "3",
        label: "Not desirable",
        align: "center"
      },
      {
        id: "4",
        label: "Desirable",
        align: "center"
      }
    ];

    return (
      <Paper className="table_container">
        <Modal
          open={this.state.showAlternativeDescription}
          onClose={this.closeAlternativeDescription}
          animationDuration={100}
          center
        >
          <div className={"modal-responsive"}>
            <h4>
              {this.state.selectedAlternative["name"]
                ? this.state.selectedAlternative["name"].length > 30
                  ? this.state.selectedAlternative["name"].substring(0, 30) +
                    "..."
                  : this.state.selectedAlternative["name"]
                : ""}
            </h4>
            <hr />
            <div
              className={"alter-description-area p-0 m-0"}
              dangerouslySetInnerHTML={this.createMarkup(
                this.state.selectedAlternative["description"] ||
                  "This alternative does not have description."
              )}
            ></div>
          </div>
        </Modal>
        <div className="report-table">
          {this.state.table_sum !== undefined ? (
            <Table stickyHeader aria-label="sticky table" style={{}}>
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={"center"}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.data.map((row, index) => (
                  <TableRow>
                    <TableCell align="center" style={{ width: "5px" }}>
                      {index + 1}
                    </TableCell>
                    <TableCell
                          align="start"
                          style={{ width: "155px", cursor: "pointer" }}
                          onClick={() =>
                            this.toggleShowAlternativeDescription(
                              row["alternative"],
                              row["description"]
                            )
                          }
                        >
                          {row.alternative}
                        </TableCell>
                    {/* {this.props.user.trial.user_type === "Free" ? (
                      index < 1 ? (
                        <TableCell
                          align="start"
                          style={{ width: "155px", cursor: "pointer" }}
                          onClick={() =>
                            this.toggleShowAlternativeDescription(
                              row["alternative"],
                              row["description"]
                            )
                          }
                        >
                          {row.alternative}
                        </TableCell>
                      ) : (
                        <TableCell align="start" style={{ width: "155px" }}>
                          <a
                            href="/pricing"
                            className={"orange"}
                            style={{ color: "orange", fontSize: "15px" }}
                          >
                            Upgrade to Permium
                          </a>
                        </TableCell>
                      )
                    ) : (
                      <TableCell
                        align="start"
                        style={{ width: "155px", cursor: "pointer" }}
                        onClick={() =>
                          this.toggleShowAlternativeDescription(
                            row["alternative"],
                            row["description"]
                          )
                        }
                      >
                        {row.alternative}
                      </TableCell>
                    )} */}

                    <TableCell align="center" style={{ width: "30px" }}>
                      {Math.floor(row.rate)}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ width: "235px", padding: "0" }}
                    >
                      {row.rate < 0 ? (
                        <Box display="flex" justifyContent="flex-end">
                          <div
                            style={{
                              backgroundColor: "red",
                              height: "30px",
                              width:
                                Math.abs(
                                  (Math.floor(row.rate) * 100) /
                                    this.state.table_sum
                                ) * 4
                            }}
                          ></div>
                        </Box>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ width: "235px", padding: "0" }}
                    >
                      {row.rate > 0 ? (
                        <Box display="flex" justifyContent="flex-start">
                          <div
                            style={{
                              backgroundColor: "green",
                              height: "30px",
                              width:
                                Math.abs(
                                  (Math.floor(row.rate) * 100) /
                                    this.state.table_sum
                                ) * 4
                            }}
                          ></div>
                        </Box>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            ""
          )}
          <Box display="flex" justifyContent="flex-end">
            <div className={"font-size-12"}>{this.props.engine.engine}</div>
          </Box>
          {/* <hr/> */}
          {/* <Box display="flex" justifyContent="flex-start"> */}
          {/* </Box> */}
        </div>
        
        {/* <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={this.props.data.length}
            rowsPerPage={this.props.rowsPerPage}
            page={this.props.page}
            backIconButtonProps={{
              "aria-label": "previous page"
            }}
            nextIconButtonProps={{
              "aria-label": "next page"
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            className="table-pagination"
          /> */}
      </Paper>
    );
  }
}
