import * as Constants from "../constants/index";

const initialState = {
    decisionName: "",
    finishDataAndTime: "",
    PODetails: "",
    DODetails: "",
    Alternatives: [],
    requiredAttributes: [],
    Participants: [],
    Attachments: [],
    activeState: ""
};

export const MakeDecisionForm = (state = initialState, action) => {
    switch (action.type) {
        case (Constants.SET_FORM_DATA):
            return {
                ...state,
                ...action.formData
            };
        default:
            return state
    }
};
