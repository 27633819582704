import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDiFY3WD8Py7DVtEa9SwHOCgvJ4s2RGqtE",
  authDomain: "merita-2ee08.firebaseapp.com",
  databaseURL: "https://merita-2ee08.firebaseio.com",
  projectId: "merita-2ee08",
  storageBucket: "",
  messagingSenderId: "140382334714",
  appId: "1:140382334714:web:92da122f356fe12abc2343"
};

firebase.initializeApp(firebaseConfig);
let messaging =null;

if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging();
  messaging.usePublicVapidKey(
    "BPItJoNwMF7fyJ_gsMK7wTOB1XNFwfult2mLSDmpFawxiCZfkfop6rsKfS3VME5-NxFXBZNJd_B8fv-U1Ijvk-0"
  );
}

export { messaging };
