import React, { Component } from "react";
import Modal from "react-responsive-modal";
import {
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput
} from "shards-react";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import { css } from "@emotion/core";
import { PulseLoader } from "react-spinners";
import { resetPassword } from "../../actions";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

/**
 * ConfirmAccount class renders input for sending forget password email
 */
class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: false
    };
  }

  showSuccessMessage = text =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 4000
      }
    );

  showErrorMessage = text =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 5000
      }
    );

  validateEmail = email => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  handleOkButton = async () => {
    this.setState({
      loading: true
    });
    if (!this.validateEmail(this.state.email)) {
      await this.showErrorMessage("Email is not in correct form !");
      setTimeout(
        console.log('asd'),
        3000
    );
      await this.setState({
        // invalidEmail: true ,
        loading:false
      });
      return;
    }
    let res = await resetPassword(this.state.email);
    this.setState({
      loading: false,
      email: ""
    });
    return this.showSuccessMessage("Password reset e-mail has been sent");
  };

  onFocusInput = (e, labelElement) => {
    labelElement.style.color = "#008AFF";
  };

  onBlurInput = (e, labelElement) => {
    labelElement.style.color = "#5a6169";
  };

  handleEnterKey = e => {
    if (e.keyCode === 13) {
      return this.handleOkButton();
    }
  };

  render() {
    return (
      <Modal
        animationDuration={100}
        open={this.props.show}
        onClose={this.props.onCloseModal}
        top
      >
        <div onKeyDown={this.handleEnterKey}>
          <h3>Reset Password</h3>
          <hr />
          <form>
            <div className={"row mb-3"}>
              <div className={"col-12 col-md-12 my-1"}>
                <label
                  className={"form-ui-label font-size-13 fw-600"}
                  ref={element => (this.elementEmail = element)}
                >
                  Email
                </label>
                <InputGroup seamless>
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <FaRegEdit />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    invalid={this.state.invalidEmail}
                    onChange={e =>
                      this.setState({
                        email: e.target.value,
                        invalidEmail: false
                      })
                    }
                    value={this.state.email}
                    onFocus={e => this.onFocusInput(e, this.elementEmail)}
                    onBlur={e => this.onBlurInput(e, this.elementEmail)}
                  />
                </InputGroup>
              </div>
            </div>
          </form>
          <div
            style={{ width: "500px" }}
            className={"d-flex justify-content-end"}
          >
            <Button
              theme={"success"}
              onClick={this.handleOkButton}
              disabled={this.state.loading}
              className={"mr-1"}
            >
              {this.state.loading ? (
                <div className="sweet-loading">
                  <PulseLoader
                    css={override}
                    sizeUnit={"px"}
                    color={"white"}
                    size={7}
                    loading={true}
                  />
                </div>
              ) : (
                "Send"
              )}
            </Button>
            <Button onClick={this.props.onCloseModal} className={"ml-1"}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ForgetPassword;
