import React, { Component, Fragment } from "react";
import Modal from "react-responsive-modal";

import "../App.css";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/core";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { Tooltip, Button } from "shards-react";
import { FaCheck } from "react-icons/fa";

import ForgetPassword from "../components/Sign/ForgetPasswordModal";
import { API_URL } from "../constants";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import { ic_info } from "react-icons-kit/md/ic_info";
import { Icon } from "react-icons-kit";
import Feedback from "../layout/UI/Feedback";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { fetchUserSession } from "../actions";
import { authHeader } from "../actions/AuthHeader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
/**
 * SignForm class renders login and sign up forms.
 */
class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      className: "header_area",
      isLoggedIn: false,
      openPayModal: false,
    };
  }
  tooltip_toggle(name, stat) {
    this.setState({
      [name]: !stat,
    });
  }

  create_checkout = async () => {
    if (this.props.session.data.username) {
      if (this.props.session.data.trial.user_type === "Free" || this.props.session.data.trial.user_type === "Trial") {
        let username = this.props.session.data.username;
        let checkout = {
          type: "Premium",
        };
        let options = {
          method: "POST",
          body: JSON.stringify(checkout),
          headers: {
            // "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundaryIn312MOjBWdkffIM",
            "Content-type": "application/json",
            Authorization: authHeader(),
          },
        };
        this.setState({
          isPosting: true,
        });
        await fetch(`${API_URL}/users/${username}/create_checkout/`, options)
          .then((res) => {
            if (res.status === 400) {
              this.setState({
                gotError: true,
              });
            }
            return res.json();
          })
          .then((data) => window.location.replace(data.url));
      }else{
        this.showErrorMessage("you have active plan");
      }
    } else {
      this.showErrorMessage("please login or signup first");
    }
  };

  async componentDidMount() {
    // window.addEventListener("scroll", this.listenToScroll);
    // this.goToSection();
    window.scrollTo(0, 0);

    await this.props.dispatch(fetchUserSession());
    this.checkAuth();
  }

  checkAuth = () => {
    const isAuthorized = localStorage.getItem("authToken") ? true : false;
    this.setState({
      isLoggedIn: isAuthorized,
    });
  };
  showSuccessMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );
  showErrorMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 4000,
      }
    );

  showSuccessMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 4000,
      }
    );

  togglePayModal = () => {
    return this.setState({
      openPayModal: !this.state.openPayModal,
    });
  };

  toggle = () => {
    this.setState({
      activeTab: !this.state.activeTab,
    });
  };

  validateEmail = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  showRelatedError = (data) => {
    return Object.keys(data).map((item) => {
      data[item].map((subItem) => {
        this.showErrorMessage(subItem);
      });
    });
  };

  handleLoginKey = (e) => {
    if (e.keyCode === 13) {
      return this.loginUser();
    }
  };

  handleSignUpKey = (e) => {
    if (e.keyCode === 13) {
      return this.signUpUser();
    }
  };

  render() {
    // if (this.state.isLoggedIn) {
    //     return <Redirect to={`/`}/>
    // }
    return (
      <div>
        <Modal
          animationDuration={100}
          open={this.state.openPayModal}
          onClose={this.togglePayModal}
          top
        >
          <h4>Close Decision</h4>
          <hr />
        </Modal>
        <Fragment className={'mb-0'}>
          {/* <Navbar /> */}
          <header
            style={{ backgroundColor: "#3bacf0" }}
            className={this.state.className}
            id={"Home"}
          >
            <div className="main_menu">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div
                  className="container box_1620"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <a className="navbar-brand logo_h">
                    <img
                      src={require("../assets/icon.png")}
                      height={45}
                      width={140}
                      alt=""
                    />
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>

                  <div
                    className="collapse navbar-collapse offset"
                    id="navbarSupportedContent"
                  >
                    <ul className="nav navbar-nav menu_nav justify-content-center">
                      <li className="nav-item">
                        <a className="nav-link" href="/">
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/#Story">
                          Our Story
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/#info">
                          How it works
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#info">
                          <Link to={"/pricing"}>Pricing</Link>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/#RayDalio">
                          Ray Dalio
                        </a>
                      </li>
                    </ul>
                    <ul className="nav navbar-nav navbar-right">
                      {this.state.isLoggedIn ? (
                        <span
                          className={
                            "nav-profile-avatar d-flex flex-column align-items-center my-2"
                          }
                        >
                          <Link to={"/decision"}>
                            <div
                              className={"avater-img"}
                              style={{
                                backgroundImage:
                                  "url(" + this.props.session.data.pic + ")",
                              }}
                            ></div>
                          </Link>
                          <p style={{ color: "white" }}>Account</p>

                          {/*<span*/}
                          {/*className="text-white">{this.props.session.data.username}*/}
                          {/*</span>*/}
                        </span>
                      ) : (
                        <li className="nav-item">
                          <Link to={"/login"} className="tickets_btn">
                            Login / Sign up
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </header>

          <div class="pricing wrapper m-0" >
            <div class="pricing-table group">
              {/* <h1 class="heading">Pricing overview</h1> */}

              <div class="block personal fl">
                <h2 class="title">Free</h2>
                <div class="content">
                  <p class="price">
                    <sup>$</sup>
                    <span>0</span>
                    <sub>/mo.</sub>
                  </p>
                  {/* <p class="hint">Perfect for freelancers</p> */}
                </div>

                <ul class="features">
                  {/* <Box display="flex" justifyContent="flex-start"> */}
                  <li>
                    Max 3 alternatives
                    <Icon
                      id="TooltipFree1"
                      className={""}
                      style={{ float: "right" }}
                      size={20}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.free_one_tooltip}
                      target="#TooltipFree1"
                      toggle={() =>
                        this.tooltip_toggle(
                          "free_one_tooltip",
                          this.state.free_one_tooltip
                        )
                      }
                    >
                      Max 3 alternatives per decision
                    </Tooltip>
                  </li>
                  {/* </Box> */}
                  <li>
                    Max 4 participants
                    <Icon
                      id="TooltipFree2"
                      className={"ml-2"}
                      size={20}
                      style={{ float: "right" }}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.free_two_tooltip}
                      target="#TooltipFree2"
                      toggle={() =>
                        this.tooltip_toggle(
                          "free_two_tooltip",
                          this.state.free_two_tooltip
                        )
                      }
                    >
                      Max 4 participants per decision
                    </Tooltip>
                  </li>
                  <li>
                    Max 3 attributes
                    <Icon
                      id="TooltipFree3"
                      className={"ml-2"}
                      style={{ float: "right" }}
                      size={20}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.free_tree_tooltip}
                      target="#TooltipFree3"
                      toggle={() =>
                        this.tooltip_toggle(
                          "free_tree_tooltip",
                          this.state.free_tree_tooltip
                        )
                      }
                    >
                      Max 3 attributes per decision
                    </Tooltip>
                  </li>
                  {/* <li>
                    Only best alternative
                    <Icon
                      id="TooltipFree4"
                      className={"ml-2"}
                      style={{ float: "right" }}
                      size={20}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.free_four_tooltip}
                      target="#TooltipFree4"
                      toggle={() =>
                        this.tooltip_toggle(
                          "free_four_tooltip",
                          this.state.free_four_tooltip
                        )
                      }
                    >
                      Only the top alternative will be shown
                    </Tooltip>
                  </li> */}
                  {/* <li
                    className={"ml-2"}
                    style={{ margin: "auto ", paddingLeft: "5px" }}
                  >
                    {" "}
                    <a href="/login">
                      <Button theme="warning"> Start free trial</Button>
                    </a>
                  </li> */}
                </ul>

                {/* <div class="pt-footer">
                    <p>Host My Website</p>
                </div> */}
              </div>

              <div class="block professional fl">
                <h2 class="title">Premuim</h2>

                <div class="content">
                  <p class="price">
                    <sup>$</sup>
                    <span>20</span>
                    <sub>/mo.</sub>
                  </p>
                  {/* <p class="hint">Suitable for startups</p> */}
                </div>

                <ul class="features">
                  <li>
                    Up to 10 alternatives
                    <Icon
                      id="Tooltippre1"
                      className={"ml-2"}
                      size={20}
                      style={{ float: "right" }}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.pre_one_tooltip}
                      target="#Tooltippre1"
                      toggle={() =>
                        this.tooltip_toggle(
                          "pre_one_tooltip",
                          this.state.pre_one_tooltip
                        )
                      }
                    >
                      You'll be able to add up to 10 altenatives to each
                      decision.
                    </Tooltip>
                  </li>
                  <li>
                    Up to 15 participants
                    <Icon
                      id="Tooltippre2"
                      className={"ml-2"}
                      size={20}
                      style={{ float: "right" }}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.pre_two_tooltip}
                      target="#Tooltippre2"
                      toggle={() =>
                        this.tooltip_toggle(
                          "pre_two_tooltip",
                          this.state.pre_two_tooltip
                        )
                      }
                    >
                      You'll be able to add up to 15 participants to each
                      decision.
                    </Tooltip>
                  </li>
                  <li>
                    Up to 8 attributes
                    <Icon
                      id="Tooltippre3"
                      style={{ float: "right" }}
                      className={"ml-2"}
                      size={20}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.pre_three_tooltip}
                      target="#Tooltippre3"
                      toggle={() =>
                        this.tooltip_toggle(
                          "pre_three_tooltip",
                          this.state.pre_three_tooltip
                        )
                      }
                    >
                      You'll be able to add up to 8 attributes to each decision.
                    </Tooltip>
                  </li>

                  {/* <li>
                    All alternatives ranked
                    <Icon
                      id="Tooltippre4"
                      className={"ml-2"}
                      style={{ float: "right" }}
                      size={20}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.pre_four_tooltip}
                      target="#Tooltippre4"
                      toggle={() =>
                        this.tooltip_toggle(
                          "pre_four_tooltip",
                          this.state.pre_four_tooltip
                        )
                      }
                    >
                      You'll be able to compare all alternatives.
                    </Tooltip>
                  </li>

                  <li>
                    Decision Discussions
                    <Icon
                      id="Tooltippre5"
                      className={"ml-2"}
                      style={{ float: "right" }}
                      size={20}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.pre_five_tooltip}
                      target="#Tooltippre5"
                      toggle={() =>
                        this.tooltip_toggle(
                          "pre_five_tooltip",
                          this.state.pre_five_tooltip
                        )
                      }
                    >
                      You'll be able to comment under each decision and discuss
                      with other participants.
                    </Tooltip>
                  </li>

                  <li>
                    Attachments
                    <Icon
                      id="Tooltippre6"
                      className={"ml-2"}
                      size={20}
                      icon={ic_info}
                      style={{ float: "right" }}
                    />
                    <Tooltip
                      open={this.state.pre_six_tooltip}
                      target="#Tooltippre6"
                      toggle={() =>
                        this.tooltip_toggle(
                          "pre_six_tooltip",
                          this.state.pre_six_tooltip
                        )
                      }
                    >
                      You'll be able to attach files to your decisions.
                    </Tooltip>
                  </li>

                  <li>
                    Decision Analytics
                    <Icon
                      id="Tooltippre7"
                      className={"ml-2"}
                      style={{ float: "right" }}
                      size={20}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.pre_seven_tooltip}
                      target="#Tooltippre7"
                      toggle={() =>
                        this.tooltip_toggle(
                          "pre_seven_tooltip",
                          this.state.pre_seven_tooltip
                        )
                      }
                    >
                      Insights about who influenced the decision
                    </Tooltip>
                  </li>

                  <li>
                    Compare with democratic
                    <Icon
                      id="Tooltippre8"
                      className={"ml-2"}
                      size={20}
                      style={{ float: "right" }}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.pre_eight_tooltip}
                      target="#Tooltippre8"
                      toggle={() =>
                        this.tooltip_toggle(
                          "pre_eight_tooltip",
                          this.state.pre_eight_tooltip
                        )
                      }
                    >
                      Compare meritocratic results with democracy
                    </Tooltip>
                  </li> */}
                  <li
                    style={{}}
                    className={"ml-2"}
                    style={{ margin: "auto ", paddingLeft: "5px" }}
                  >
                    {/* <a href="/signin">
                      <Button theme="success" className={"mr-2"}>
                        Start free trial
                      </Button>
                    </a> */}
                    <Button theme="warning" onClick={this.create_checkout}>
                      Buy Now
                    </Button>
                    <a
                    style={{marginLeft:'5px'}}
                      target="_blank"
                      href="https://calendly.com/merita-decision/40min"
                    >
                      <Button theme="warning">Book a meeting</Button>
                    </a>
                  </li>
                  
                </ul>

                {/* <div class="pt-footer">
                    <p>Host My Website</p>
                </div> */}
              </div>

              <div class="block business fl">
                <h2 class="title">Enterprise</h2>

                <div class="content">
                  <p class="price">
                    <sup>$</sup>
                    <sub>from</sub>
                    <span>500</span>
                    <sub>/mo.</sub>
                  </p>
                  {/* <p class="hint">For established business</p> */}
                </div>

                <ul class="features">
                <li>
                    Up to 10 alternatives
                    <Icon
                      id="Tooltippre1"
                      className={"ml-2"}
                      size={20}
                      style={{ float: "right" }}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.pre_one_tooltip}
                      target="#Tooltippre1"
                      toggle={() =>
                        this.tooltip_toggle(
                          "pre_one_tooltip",
                          this.state.pre_one_tooltip
                        )
                      }
                    >
                      You'll be able to add up to 10 altenatives to each
                      decision.
                    </Tooltip>
                  </li>
                  <li>
                    Up to 15 participants
                    <Icon
                      id="Tooltippre2"
                      className={"ml-2"}
                      size={20}
                      style={{ float: "right" }}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.pre_two_tooltip}
                      target="#Tooltippre2"
                      toggle={() =>
                        this.tooltip_toggle(
                          "pre_two_tooltip",
                          this.state.pre_two_tooltip
                        )
                      }
                    >
                      You'll be able to add up to 15 participants to each
                      decision.
                    </Tooltip>
                  </li>
                  <li>
                    Up to 8 attributes
                    <Icon
                      id="Tooltippre3"
                      style={{ float: "right" }}
                      className={"ml-2"}
                      size={20}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.pre_three_tooltip}
                      target="#Tooltippre3"
                      toggle={() =>
                        this.tooltip_toggle(
                          "pre_three_tooltip",
                          this.state.pre_three_tooltip
                        )
                      }
                    >
                      You'll be able to add up to 8 attributes to each decision.
                    </Tooltip>
                  </li>
                <li>
                   MeriTa within your organisation
                    <Icon
                      id="Tooltipenter0"
                      className={"ml-2"}
                      size={20}
                      icon={ic_info}
                      style={{ float: "right" }}
                    />
                    <Tooltip
                      open={this.state.enter_zero_tooltip}
                      target="#Tooltipenter0"
                      toggle={() =>
                        this.tooltip_toggle(
                          "enter_zero_tooltip",
                          this.state.enter_zero_tooltip
                        )
                      }
                    >
                      Implement MeriTa decision platform within your organisation with admin account and dashboard
                    </Tooltip>
                  </li>
                  <li>
                    Live decisions (meetings)
                    <Icon
                      id="Tooltipenter1"
                      className={"ml-2"}
                      size={20}
                      icon={ic_info}
                      style={{ float: "right" }}
                    />
                    <Tooltip
                      open={this.state.enter_one_tooltip}
                      target="#Tooltipenter1"
                      toggle={() =>
                        this.tooltip_toggle(
                          "enter_one_tooltip",
                          this.state.enter_one_tooltip
                        )
                      }
                    >
                      Time limited decisions for live meetings
                    </Tooltip>
                  </li>
                  <li>
                    Integrate with Outlook & teams
                    <Icon
                      id="Tooltipenter2"
                      className={"ml-2"}
                      size={20}
                      style={{ float: "right" }}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.enter_two_tooltip}
                      target="#Tooltipenter2"
                      toggle={() =>
                        this.tooltip_toggle(
                          "enter_two_tooltip",
                          this.state.enter_two_tooltip
                        )
                      }
                    >
                      Integrate with daily corporate software
                    </Tooltip>
                  </li>
                  
                  <li>
                    Merit Matrix
                    <Icon
                      id="Tooltipenter4"
                      className={"ml-2"}
                      size={20}
                      style={{ float: "right" }}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.enter_four_tooltip}
                      target="#Tooltipenter4"
                      toggle={() =>
                        this.tooltip_toggle(
                          "enter_four_tooltip",
                          this.state.enter_four_tooltip
                        )
                      }
                    >
                      Merit Matrix is a very useful human resource planning
                      dashboard, showing expertise matrix throughout your
                      organization.
                    </Tooltip>
                  </li>
                  <li
                    className={"ml-2"}
                    style={{ margin: "auto ", paddingLeft: "5px" }}
                  >
                    <a
                      target="_blank"
                      href="https://calendly.com/merita-decision/40min"
                    >
                      <Button theme="warning">Book a meeting</Button>
                    </a>
                  </li>
                </ul>
                {/* <div class="pt-footer">
                    <p>Host My Website</p>
                </div> */}
              </div>
            </div>
          </div>
        </Fragment>
        <footer className="footer_area p_120 mt-0">
          <div className="container">
            <div className="row footer_inner">
              <div className="col-lg-3 col-sm-6">
                <aside className="f_widget ab_widget">
                  <div className="f_title">
                    <h3>About Us</h3>
                  </div>
                  <p>MeriTa is a meritocratic decision support network</p>
                  <p>
                    Copyright
                    <span className={"mx-1"}>
                      &copy;
                      {new Date().getFullYear()}
                    </span>
                    All rights reserved
                  </p>
                </aside>
              </div>
              <div className="col-lg-3 col-sm-6">
                <aside className="f_widget news_widget">
                  <div className="f_title">
                    <h3>Newsletter</h3>
                  </div>
                  <p>Stay updated with us</p>
                  <div id="mc_embed_signup">
                    <form
                      className="subscribe_form relative"
                      onSubmit={this.submitEmail}
                    >
                      <div className="input-group d-flex flex-row">
                        <input
                          name="EMAIL"
                          placeholder="Enter email address"
                          required=""
                          type="email"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                        <button className="btn sub-btn">
                          <span className="lnr lnr-arrow-right"></span>
                        </button>
                      </div>
                      <div className="mt-10 info"></div>
                    </form>
                  </div>
                </aside>
              </div>
              <div className="col-lg-3">
                <aside className="f_widget social_widget">
                  <div className="f_title">
                    <h3>Follow Us</h3>
                  </div>
                  <p>Let us be social</p>
                  <ul className="list">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/myMeriTa1"
                      >
                        <FaFacebook />
                      </a>
                    </li>

                    <li>
                      <a
                        target="_blank"
                        href="https://twitter.com/MeriTa48053287"
                      >
                        <FaTwitter />
                      </a>
                    </li>

                    <li>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/merita/"
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                  <br />
                  <a
                    href="https://mymeritablog.wordpress.com/"
                    target="_blank"
                    className={"contact-us-email"}
                  >
                    MeriTa Blog
                  </a>
                </aside>
              </div>
              <div className="col-lg-3">
                <aside className="f_widget social_widget">
                  <div className="f_title">
                    <h3>Contact us</h3>
                  </div>
                  <a
                    className={"contact-us-email"}
                    href={
                      "mailto: info@myMeriTa.com?subject=Feedback&body=Message"
                    }
                  >
                    info@myMeriTa.com
                  </a>
                  <br />

                  <a href="/privacy" className={"contact-us-email"}>
                    Privacy
                  </a>
                  <br />

                  <a href="/terms" className={"contact-us-email"}>
                    Terms
                  </a>
                </aside>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = ({ session }) => {
  return { session };
};

export default withRouter(connect(mapStateToProps, null)(Pricing));
