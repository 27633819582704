import React, { Component, Fragment } from "react";
import { animateScroll as scroll } from "react-scroll";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import DropUI from "../../layout/UI/DropUI";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import "react-dropzone/examples/theme.css";
import {
  IoIosClose,
  IoIosPerson,
  IoIosArrowDroprightCircle,
  IoIosArrowDropleftCircle,
  IoMdAttach,
} from "react-icons/io";
import {
  Badge,
  Button,
  Form,
  FormInput,
  FormGroup,
  Tooltip,
} from "shards-react";
import { ic_info } from "react-icons-kit/md/ic_info";
import { Icon } from "react-icons-kit";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  fetchAllAttribute,
  fetchUserfollowings,
  fetchUserSession,
  setFormData,
} from "../../actions/index";
import Modal from "react-responsive-modal";
import AutoComplete from "../../layout/UI/AutoComplete";
import AutoCompleteAttribute from "../../layout/UI/AutoCompleteAttribute";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import { IoMdCloseCircle, IoMdCheckmarkCircle } from "react-icons/io";
import isEmail from "validator/lib/isEmail";
import { API_URL } from "../../constants";
import { FaLock } from "react-icons/fa";
import { authHeader } from "../../actions/AuthHeader";
/**
 * StepThree class is for adding alternatives, attributes, participants.
 */
class StepTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alternative: [],
      attributes: [],
      participants: [],
      openAlterModal: false,
      openAttrModal: false,
      openPartModal: false,
      openAttachModal: false,
      text: "",
      alterTemText: "",
      attrTempText: "",
      partiTempText: "",
      attachmentTempText: "",
      files: [],
      validAlternative: false,
      validAttributes: false,
      validParticipants: false,
      followings_email: [],
      participant_info: [],
      user_attr: [],
    };
    this.onDrop = (files) => {
      if (files.length >= 2) {
        return null;
      }
      this.setState({
        files: [...this.state.files, ...files],
      });
    };
    this.modules = {
      toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link"],
        ["clean"],
      ],
    };
    this.formats = [
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
    ];
  }

  tooltip_toggle(name, stat) {
    this.setState({
      [name]: !stat,
    });
  }

  toggle = () => {
    this.setState({
      openAlterModal: !this.state.openAlterModal,
    });
  };

  async componentDidMount() {
    scroll.scrollToTop();
    const { dispatch } = this.props;
    if (this.props.sessionID === undefined) {
      await dispatch(fetchUserSession());
    }

    dispatch(fetchUserfollowings(this.props.sessionID));
    dispatch(fetchAllAttribute());

    const endpoint1 = `users/${this.props.sessionID}/get_user_favorite_attr/`;
    const options1 = {
      method: "GET",
    };
    await fetch(`${API_URL}/${endpoint1}`, {
      ...options1,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((data) => data.json())
      .then((res) => {
        console.log("connection_list", res);
        this.setState({
          user_attr: res,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    if (this.props.dataId) {
      if (this.props.selectedDecision) {
        let users = this.props.selectedDecision.data.participants
          .filter(
            (item) =>
              item.username !== this.props.owner.username &&
              this.props.selectedDecision.data.created_by !== item.username
          )
          .map((item) => {
            console.log("******", item, this.props.owner);

            // if (
            //   item.username !== this.props.owner.username &&
            //   this.props.selectedDecision.data.created_by !== item.username
            // )
            return {
              first_name: item.first_name,
              last_name: item.last_name,
              pic: item.pic,
              email: item.email,
              username: item.username,
            };
            // else{
            //   return null
            // }
          });
        let users_pr = this.props.selectedDecision.data.participants.map(
          (item) => item.email
        );
        console.log("userssssss", users);
        let alternative = this.props.selectedDecision.alternatives.map(
          (item) => {
            return {
              name: item.name,
              description: item.description,
            };
          }
        );
        this.setState({
          alternative: alternative,
          attributes: this.props.selectedDecision.data.attributes,
          participants: users_pr,
          participant_info: users,
        });
      }
    }
    let owner = [];
    owner.push(this.props.owner);
   
    this.setState({
      owner_participante: owner,
    });

    scroll.scrollToTop();
    // await this.make_user_followings_email();
  }
  make_user_followings_email = () => {
    console.log("email", this.props.userFollowings["data"]);
    let followings_email = [];

    this.props.userFollowings["data"].map((item, index) => {
      console.log(item);
      followings_email.push(item.email);
    });
    this.setState({
      followings_email: followings_email,
    });
    console.log("email", followings_email);
  };
  showErrorMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 4000,
      }
    );

  showSuccessMessage = (text, type) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 4000,
      }
    );

  handleChange = (value) => {
    this.setState({ text: value });
  };

  removeAlternative = (alterName) => {
    let newAlternative = this.state.alternative.filter((item) => {
      return item["name"] !== alterName;
    });
    this.setState({
      alternative: newAlternative,
    });
  };

  _renderAlternative = () => {
    let items = this.state.alternative.map((item, index) => {
      return (
        <div
          className={
            "select-attribute-item my-2 d-flex justify-content-between"
          }
          key={index}
        >
          <p
            className={"m-0 p-0 d-flex align-items-center"}
            style={{ fontWeight: "300", color: "black" }}
          >
            {item["name"].length > 30
              ? item["name"].substring(0, 30) + "..."
              : item["name"]}
          </p>
          <Button
            outline
            pill
            theme="warning"
            size={"sm"}
            onClick={() => this.removeAlternative(item["name"])}
            className={"btn btn-success-custom p-0 m-0"}
          >
            <IoIosClose className={"font-size-22"} />
          </Button>
        </div>
      );
    });
    return items;
  };

  removeParticipant = (text) => {
    let new_participant_info = this.state.participant_info.filter((item) => {
      return item.email !== text;
    });
    let newParticipant = this.state.participants.filter((item) => {
      return item !== text;
    });
    this.setState({
      participants: newParticipant,
      participant_info: new_participant_info,
    });
  };
  refactorProfileUrl = (url) => {
    console.log("url", url, String(url).substring(0, 4));
    if (url && String(url).substring(0, 4) === "http") {
      return url;
    } else {
      // console.log("url", url);
      return `${API_URL}/uploads/${url}`;
    }
  };

  _renderOwnerParticipant = () => {
    console.log("itemsssssssssssssss", this.state.owner_participante);
    let items = this.state.owner_participante.map((item, index) => {
      return (
        <div
          className={
            "select-attribute-item my-2 d-flex justify-content-between"
          }
          key={index}
        >
          <p
            className={"m-0 p-0 d-flex align-items-center"}
            style={{ fontWeight: "300" }}
          >
            {/* <IoIosPerson className={"mr-2 text-orange font-size-20"} /> */}
            <span
              className={
                "nav-profile-avatar d-flex align-items-center text-orange"
              }
              style={{ fontWeight: "Normal" }}
            >
              <div
                style={{
                  backgroundImage: `url(${this.refactorProfileUrl(item.pic)})`,
                }}
                className={"avater-img mr-1"}
              ></div>
            </span>
            {item.first_name} {item.last_name}
            {/* <span className={"ml-2"} style={{color:"red"}}>(Not possible to remove)</span> */}
          </p>
        </div>
      );
    });
    return items;
  };

  _renderParticipant = () => {
    let items = this.state.participant_info.map((item, index) => {
      return (
        <div
          className={
            "select-attribute-item my-2 d-flex justify-content-between"
          }
          key={index}
        >
          <p
            className={"m-0 p-0 d-flex align-items-center"}
            style={{ fontWeight: "300" }}
          >
            {/* <IoIosPerson className={"mr-2 text-orange font-size-20"} /> */}
            <span
              className={
                "nav-profile-avatar d-flex align-items-center text-orange"
              }
              style={{ fontWeight: "Normal" }}
            >
              <div
                style={{
                  backgroundImage: `url(${this.refactorProfileUrl(item.pic)})`,
                }}
                className={"avater-img mr-1"}
              ></div>
            </span>
            {item.first_name} {item.last_name}
          </p>
          <Button
            outline
            pill
            theme="warning"
            size={"sm"}
            onClick={() => this.removeParticipant(item.email)}
            className={"btn btn-success-custom p-0 m-0"}
          >
            <IoIosClose className={"font-size-22"} />
          </Button>
        </div>
      );
    });
    return items;
  };

  removeAttachment = (text) => {
    let newAttachment = this.state.files.filter((item) => {
      return item[0]["name"] !== text;
    });
    this.setState({
      files: newAttachment,
    });
  };

  _renderAttachments = () => {
    let items = this.state.files.map((item, index) => {
      console.log("name", item);
      return (
        <div
          className={
            "select-attribute-item my-2 d-flex justify-content-between"
          }
          key={index}
        >
          <p
            className={"m-0 p-0 d-flex align-items-center"}
            style={{ fontWeight: "300" }}
          >
            <IoMdAttach className={"mr-2 text-orange font-size-20"} />
            {item[0]["name"]}
          </p>
          <Button
            outline
            pill
            theme="warning"
            size={"sm"}
            onClick={() => this.removeAttachment(item[0]["name"])}
            className={"btn btn-success-custom p-0 m-0"}
          >
            <IoIosClose className={"font-size-22"} />
          </Button>
        </div>
      );
    });
    return items;
  };

  removeAttribute = (attrName) => {
    let newAttribute = this.state.attributes.filter((item) => {
      return item !== attrName;
    });
    this.setState({
      attributes: newAttribute,
    });
  };

  _renderAttributes = () => {
    let items = this.state.attributes.map((item, index) => {
      return (
        <Badge className={"select-attribute-tag my-1"} pill key={index}>
          <span className={"ml-2 my-3"}>{item}</span>
          <Button
            pill
            theme="light"
            size={"sm"}
            onClick={() => this.removeAttribute(item)}
            className={"p-0 m-0 ml-2"}
          >
            <IoIosClose className={"font-size-19 text-orange"} />
          </Button>
        </Badge>
      );
    });
    return items;
  };

  addData = async () => {
    if (
      this.props.owner.trial.user_type === "Free" &&
      this.state.alternative.length === 3
    ) {
      this.showErrorMessage("Max Alternative for Free Accounts is 3");
      return null;
    }
    if (
      (this.props.owner.trial.user_type === "Premium" ||
        this.props.owner.trial.user_type === "Trial") &&
      this.state.alternative.length === 8
    ) {
      this.showErrorMessage("Max Alternative number is 8");
      return null;
    }
    if (this.state.alterTemText === "") {
      this.showErrorMessage("Alternative Name should not be empty");
      return null;
    }
    let alterExist = this.state.alternative.filter(
      (item) => item.name === this.state.alterTemText
    );
    if (alterExist.length > 0) {
      this.showErrorMessage("The Alternative Already added!");
      return null;
    }
    await this.setState({
      alternative: [
        ...this.state.alternative,
        {
          name: this.state.alterTemText,
          description: this.state.text,
        },
      ],
      openAlterModal: !this.state.openAlterModal,
      text: "",
      alterTemText: "",
    });
    this.setState({
      validAlternative:
        this.state.alternative.length >= 2 &&
        this.state.alternative.length <= 5,
    });
  };

  toggleAttr = () => {
    this.setState({
      openAttrModal: !this.state.openAttrModal,
    });
  };

  addDataAttr = async () => {
    if (
      this.props.owner.trial.user_type === "Free" &&
      this.state.attributes.length === 3
    ) {
      this.showErrorMessage("Max Attributes for Free Accounts is 3");
      return null;
    }
    if (
      (this.props.owner.trial.user_type === "Premium" ||
        this.props.owner.trial.user_type === "Trial") &&
      this.state.attributes.length === 8
    ) {
      this.showErrorMessage("Max Attribute number is 8");
      return null;
    }

    if (this.state.attrTempText === "") {
      this.showErrorMessage("The filed should not be empty");
      return null;
    }
    if (this.state.attributes.indexOf(this.state.attrTempText) !== -1) {
      this.showErrorMessage("The Attribute Already added!");
      return null;
    }

    await this.setState({
      attributes: [...this.state.attributes, this.state.attrTempText],
      openAttrModal: !this.state.openAttrModal,
      attrTempText: "",
    });
    this.setState({
      validAttributes:
        this.state.attributes.length >= 3 && this.state.attributes.length <= 5,
    });
  };

  handleKeyAlternatives = (e) => {
    if (e.keyCode === 13) {
      return this.addData();
    }
    return null;
  };

  _renderAlternativeModal = () => {
    return (
      <Modal
        open={this.state.openAlterModal}
        animationDuration={100}
        onClose={this.toggle}
        center
      >
        <div className={"alternatives-modal"}>
          <h3>Add Alternative</h3>
          <hr />
          <div onKeyDown={this.handleKeyAlternatives}>
            <Form onSubmit={(e) => e.preventDefault()}>
              <FormGroup>
                <label htmlFor="#AlternativeName">
                  Alternative Name{" "}
                  <span style={{ color: "red", fontSize: 12 }}>
                    maximum 120 character{" "}
                    {this.state.alterTemText.length > 0
                      ? "(now " + this.state.alterTemText.length + ")"
                      : ""}
                  </span>
                </label>
                <FormInput
                  id="#AlternativeName"
                  onChange={(e) => {
                    if (
                      this.state.alterTemText.length < 120 ||
                      e.target.value.length < 120
                    )
                      this.setState({ alterTemText: e.target.value });
                  }}
                  placeholder="Alternative Name"
                  value={this.state.alterTemText}
                />
              </FormGroup>
            </Form>
          </div>
          <p>Detailed Alternative description :</p>
          <ReactQuill
            value={this.state.text}
            modules={this.modules}
            formats={this.formats}
            onKeyDown={this.handleKeyAlternatives}
            onChange={this.handleChange}
          />
          <hr />
          <div>
            <Button theme={"primary"} onClick={this.addData}>
              OK
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  onChangeAttribute = (event, { newValue }) => {
    if (newValue.length <= 20)
      this.setState({
        attrTempText: newValue,
      });
  };

  handleKeyAttribute = (e) => {
    if (e.keyCode === 13) {
      return this.addDataAttr();
    }
    return null;
  };

  _renderAttributeModal = () => {
    const { openAttrModal } = this.state;
    return (
      <Modal
        animationDuration={100}
        open={openAttrModal}
        onClose={this.toggleAttr}
        center
      >
        <div className={"modal-responsive"}>
          <h3>Add Attribute</h3>
          <hr />
          <div onKeyDown={this.handleKeyAttribute}>
            <Form onSubmit={(e) => e.preventDefault()}>
              <FormGroup>
                <label htmlFor="#AttributeName">
                  Attribute Name{" "}
                  <span style={{ color: "red", fontSize: 12 }}>
                    maximum 20 character{" "}
                    {this.state.attrTempText.length > 0
                      ? "(now " + this.state.attrTempText.length + ")"
                      : ""}
                  </span>
                </label>
                <AutoCompleteAttribute
                  inputProps={{
                    placeholder: "Attributes",
                    value: this.state.attrTempText,
                    onChange: this.onChangeAttribute,
                  }}
                  data={this.state.user_attr}
                />
                <div className="m-0 font-size-12 red" style={{ color: "red" }}>
                  * Favorite attributes will show in above list. Go to
                  attributes tab to add favorites.
                </div>
              </FormGroup>
            </Form>
          </div>
          <hr />
          <div>
            <Button theme={"primary"} onClick={this.addDataAttr}>
              OK
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  toggleParticipant = () => {
    this.setState({
      openPartModal: !this.state.openPartModal,
    });
  };

  toggleAttachment = () => {
    this.setState({
      openAttachModal: !this.state.openAttachModal,
    });
  };

  addDataParticipant = async () => {
    if (
      this.props.owner.trial.user_type === "Free" &&
      this.state.participants.length === 4
    ) {
      this.showErrorMessage("Max Participants for Free Accounts is 4");
      return null;
    } else if (
      (this.props.owner.trial.user_type === "Premium" ||
        this.props.owner.trial.user_type === "Trial") &&
      this.state.participants.length === 14
    ) {
      this.showErrorMessage("Max number of Participants is 15");
      return null;
    }
    var followings_email = [];
    console.log("here", this.props);
    if (this.state.followings_email.length == 0) {
      this.props.userFollowings["data"].map((item, index) => {
        followings_email.push(item.email);
      });
      this.setState({
        followings_email: followings_email,
      });
      console.log("email", followings_email);
    } else {
      followings_email = this.state.followings_email;
    }

    // done here
    if (!followings_email.includes(this.state.partiTempText)) {
      this.showErrorMessage(
        "You're not connected to this person ,Go to pepole page and invite them."
      );
      return null;
    }
    if (this.state.partiTempText === "") {
      this.showErrorMessage("The filed should not be empty");
      return null;
    }

    if (!isEmail(this.state.partiTempText)) {
      // console.log("here");
      this.showErrorMessage("please enter valid email address ");
      return null;
    }

    if (this.state.participants.indexOf(this.state.partiTempText) !== -1) {
      this.showErrorMessage("The user Already added!");
      return null;
    }
    let info = this.state.participant_info;
    this.props.userFollowings["data"].filter((item, index) => {
      if (item.email === this.state.partiTempText) {
        info.push({
          first_name: item.first_name,
          last_name: item.last_name,
          pic: item.pic,
          email: item.email,
          username: item.username,
        });
      }
    });
    console.log("info", info);
    await this.setState({
      participants: [...this.state.participants, this.state.partiTempText],
      openPartModal: !this.state.openPartModal,
      partiTempText: "",
      participant_info: info,
    });
    this.setState({
      validParticipants:
        this.state.participants.length >= 3 &&
        this.state.participants.length <= 5,
    });
  };

  onChangeParticipant = (event, { newValue }) => {
    console.log(newValue);
    this.setState({
      partiTempText: newValue,
    });
  };

  handleKeyParticipant = (e) => {
    if (e.keyCode === 13) {
      return this.addDataParticipant();
    }
    return null;
  };

  _renderParticipantModal = () => {
    const { openPartModal } = this.state;
    return (
      <Modal
        open={openPartModal}
        animationDuration={100}
        onClose={this.toggleParticipant}
        center
      >
        <div className={"modal-responsive"}>
          <h3>Add Participant</h3>
          <hr />
          <div onKeyDown={this.handleKeyParticipant}>
            <Form onSubmit={(e) => e.preventDefault()}>
              <FormGroup>
                <label htmlFor="#ParticipantName">Participant Name</label>
                <AutoComplete
                  inputProps={{
                    placeholder: "Participant",
                    value: this.state.partiTempText,
                    onChange: this.onChangeParticipant,
                  }}
                  data={this.props.userFollowings["data"]}
                />
              </FormGroup>
            </Form>
            <span style={{ color: "red" }}>
              Start typing name/username of participants.
            </span>
            <br />
            <span style={{ color: "red" }}>
              You should first add them to your "People" page.
            </span>
          </div>
          <hr />
          <div>
            <Button theme={"primary"} onClick={this.addDataParticipant}>
              OK
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  addDataAttachment = () => {
    this.setState({
      // attachments: [
      //     ...this.state.attachments,
      //     this.state.attachmentTempText
      // ],
      openAttachModal: !this.state.openAttachModal,
    });
  };

  onDrop = (acceptedFiles) => {
    console.log("asdasd", acceptedFiles);
  };

  _renderAttachmentModal = () => {
    const { openAttachModal } = this.state;
    const files = this.state.files.map((file) => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));
    return (
      <Modal
        open={openAttachModal}
        animationDuration={100}
        onClose={this.toggleAttachment}
        center
      >
        <div className={"modal-responsive"}>
          <h3>
            Add Attachment{" "}
            <span className={"ml-2"} style={{ color: "red", fontSize: "15px" }}>
              {" "}
              (Max:2 , Each up to 2MB)
            </span>
          </h3>
          <hr />
          <div>
            <Dropzone
              onDrop={(acceptedFiles) => {
                if (acceptedFiles.length === 0) {
                  return;
                } else if (this.state.files.length > 1) {
                  // here i am checking on the number of files
                  return this.showErrorMessage("upload maximum two file "); // here i am using react toaster to get some notifications. don't need to use it
                } else {
                  this.setState({
                    files: [...this.state.files, acceptedFiles],
                  });
                }
              }}
              minSize={0}
              maxSize={2097152}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
                rejectedFiles,
              }) => {
                // console.log(getInputProps)
                const isFileTooLarge =
                  rejectedFiles.length > 0 && rejectedFiles[0].size > 2097152;
                return (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {!isDragActive && "Click here or drop a file to upload!"}
                    {isDragActive && !isDragReject && "Drop it like it's hot!"}
                    {isDragReject && "File type not accepted, sorry!"}
                    {isFileTooLarge && (
                      <div className="text-danger mt-2">File is too large.</div>
                    )}
                  </div>
                );
              }}
            </Dropzone>
          </div>
          <hr />
          <div>
            <Button theme={"primary"} onClick={this.addDataAttachment}>
              OK
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  checkFormValidation = async () => {
    if (this.props.owner.trial.user_type === "Free") {
      var validParti =
        this.state.participants.length >= 2 &&
        this.state.participants.length <= 3;
      var validattr =
        this.state.attributes.length >= 2 && this.state.attributes.length <= 3;
      var validAlter =
        this.state.alternative.length >= 2 &&
        this.state.alternative.length <= 3;
    } else {
      var validParti =
        this.state.participants.length >= 2 &&
        this.state.participants.length <= 14;
      var validattr =
        this.state.attributes.length >= 2 && this.state.attributes.length <= 8;
      var validAlter =
        this.state.alternative.length >= 2 &&
        this.state.alternative.length <= 8;
    }

    await this.setState({
      validParticipants: validParti,
      validAttributes: validattr,
      validAlternative: validAlter,
    });

    if (this.props.owner.trial.user_type === "Free") {
      if (!this.state.validParticipants) {
        this.showErrorMessage(
          "Number of Participants should be between 3 and 4 !"
        );
      }
      if (!this.state.validAttributes) {
        this.showErrorMessage(
          "Number of Attributes should be between 2 and 3 !"
        );
      }
      if (!this.state.validAlternative) {
        this.showErrorMessage(
          "Number of Alternatives should be between 2 and 3 !"
        );
      }
    } else {
      if (!this.state.validParticipants) {
        this.showErrorMessage(
          "Number of Participants should be between 3 and 15 !"
        );
      }
      if (!this.state.validAttributes) {
        this.showErrorMessage(
          "Number of Attributes should be between 2 and 8 !"
        );
      }
      if (!this.state.validAlternative) {
        this.showErrorMessage(
          "Number of Alternatives should be between 2 and 8 !"
        );
      }
    }
    if (
      this.state.validParticipants &&
      this.state.validAttributes &&
      this.state.validAlternative
    ) {
      return this.checkValidation();
    }
    return null;
  };

  checkValidation = () => {
    this.props.dispatch(
      setFormData({
        Alternatives: this.state.alternative,
        requiredAttributes: this.state.attributes,
        Participants: this.state.participants,
        Attachments: this.state.files,
        all_participants: this.state.participant_info,
        owner_info: this.state.owner_participante,
      })
    );
    return this.props.goToNextSlide();
  };

  render() {
    // console.log("dadadaadadd", this.state.files);
    return (
      <Fragment>
        {this.props.activeKey === this.props.tabKey ? (
          <Fragment>
            {this._renderAlternativeModal()}
            {this._renderAttributeModal()}
            {this._renderParticipantModal()}
            {this._renderAttachmentModal()}

            <div className={"colored-bar-step d-flex flex-column"}>
              <div className={"mt-3 text-white"}>
                <IoIosArrowDropup
                  className={"form-ui-icon"}
                  onClick={this.props.goToPrevSlide}
                />
              </div>
              <div className={"d-flex flex-column align-items-center"}>
                {this.props.dotClass.map((item, index) => {
                  return (
                    <DropUI isActive={index === this.props.index} key={index} />
                  );
                })}
              </div>
              <div className={"mb-3 text-white"}>
                <IoIosArrowDropdown
                  className={"form-ui-icon"}
                  onClick={this.checkFormValidation}
                />
              </div>
            </div>
            <div style={{ flexGrow: "1" }} className={"mb-3"}>
              <div className={"form-ui-header p-3"}>
                <h3 className={"m-0 p-0"}>
                  Set Your Alternatives, Attributes and Participants
                </h3>
              </div>

              <div className={"form-ui p-3"}>
                <h5 className={"m-0"}>
                  Alternatives{" "}
                  <Icon
                    id="TooltipAlternative"
                    className={"ml-2"}
                    size={20}
                    icon={ic_info}
                  />
                  <Tooltip
                    open={this.state.alternative_tooltip}
                    target="#TooltipAlternative"
                    toggle={() =>
                      this.tooltip_toggle(
                        "alternative_tooltip",
                        this.state.alternative_tooltip
                      )
                    }
                  >
                    These are the decision Alternatives, i.e. different choices
                    you face in this decision.
                  </Tooltip>
                  {this.props.owner.trial.user_type === "Free" ? (
                    <span
                      className={"ml-4"}
                      style={{ color: "black", fontSize: "15px" }}
                    >
                      (Min:2 , Max:3{" "}
                      <a
                        href="/pricing"
                        className={"orange"}
                        style={{ color: "orange", fontSize: "15px" }}
                      >
                        Upgrade to Premium
                      </a>
                      )
                    </span>
                  ) : undefined}
                  {this.props.owner.trial.user_type === "Trial" ? (
                    <span
                      className={"ml-4"}
                      style={{ color: "black", fontSize: "15px" }}
                    >
                      (Min:2 , Max:8{" "}
                      <a
                        href="/pricing"
                        className={"orange"}
                        style={{ color: "orange", fontSize: "15px" }}
                      >
                        Upgrade to Premium
                      </a>
                      )
                    </span>
                  ) : undefined}
                  {this.props.owner.trial.user_type === "Premium" ? (
                    <span
                      className={"ml-4"}
                      style={{ color: "black", fontSize: "15px" }}
                    >
                      (Min:2 , Max:8 )
                    </span>
                  ) : undefined}
                </h5>

                <div className={"select-attribute-wrapper"}>
                  <div className={"select-attribute-body"}>
                    {this.state.alternative.length > 0 ? null : (
                      <div className={"d-flex justify-content-center"}>
                        <p style={{ fontWeight: 100 }} className={"my-4"}>
                          Add your Alternative
                        </p>
                      </div>
                    )}
                    {this._renderAlternative()}
                  </div>
                  <div
                    className={
                      "select-attribute-footer mt-2 d-flex justify-content-end"
                    }
                  >
                    <Button theme="primary" onClick={this.toggle}>
                      Add
                    </Button>
                  </div>
                </div>
              </div>
              <hr className={"m-0"} />
              <div className={"form-ui px-3 mt-3 pb-3"}>
                <h5 className={"m-0"}>
                  Required Attributes{" "}
                  <Icon
                    id="TooltipAttribute"
                    className={"ml-2"}
                    size={20}
                    icon={ic_info}
                  />
                  <Tooltip
                    open={this.state.attribute_tooltip}
                    target="#TooltipAttribute"
                    toggle={() =>
                      this.tooltip_toggle(
                        "attribute_tooltip",
                        this.state.attribute_tooltip
                      )
                    }
                  >
                    These are the skills of participants. Think what skills are
                    required to make this decision.
                  </Tooltip>
                  {this.props.owner.trial.user_type === "Free" ? (
                    <span
                      className={"ml-4"}
                      style={{ color: "black", fontSize: "15px" }}
                    >
                      (Min:2 , Max:3{" "}
                      <a
                        href="/pricing"
                        className={"orange"}
                        style={{ color: "orange", fontSize: "15px" }}
                      >
                        Upgrade to Premium
                      </a>
                      )
                    </span>
                  ) : undefined}
                  {this.props.owner.trial.user_type === "Trial" ? (
                    <span
                      className={"ml-4"}
                      style={{ color: "black", fontSize: "15px" }}
                    >
                      (Min:2 , Max:8{" "}
                      <a
                        href="/pricing"
                        className={"orange"}
                        style={{ color: "orange", fontSize: "15px" }}
                      >
                        Upgrade to Premium
                      </a>
                      )
                    </span>
                  ) : undefined}
                  {this.props.owner.trial.user_type === "Premium" ? (
                    <span
                      className={"ml-4"}
                      style={{ color: "black", fontSize: "15px" }}
                    >
                      (Min:2 , Max:8 )
                    </span>
                  ) : undefined}
                </h5>

                <div className={"select-attribute-wrapper"}>
                  <div className={"select-attribute-body"}>
                    {this.state.attributes.length > 0 ? null : (
                      <div className={"d-flex justify-content-center"}>
                        <p style={{ fontWeight: 100 }} className={"my-4"}>
                          Add your Attributes
                        </p>
                      </div>
                    )}
                    <div className={"d-flex flex-column align-items-start"}>
                      {this._renderAttributes()}
                    </div>
                  </div>
                  <div
                    className={
                      "select-attribute-footer mt-2 d-flex justify-content-end"
                    }
                  >
                    <Button theme="primary" onClick={this.toggleAttr}>
                      Add
                    </Button>
                  </div>
                </div>
              </div>
              <hr className={"m-0"} />
              <div className={"form-ui p-3"}>
                <h5 className={"m-0"}>
                  Participants{" "}
                  <Icon
                    id="TooltipParticipant"
                    className={"ml-2"}
                    size={20}
                    icon={ic_info}
                  />
                  <Tooltip
                    open={this.state.participant_tooltip}
                    target="#TooltipParticipant"
                    toggle={() =>
                      this.tooltip_toggle(
                        "participant_tooltip",
                        this.state.participant_tooltip
                      )
                    }
                  >
                    These are your experts. You need to first connect to them on
                    the "people" page, then you can Invite them to any decision.
                  </Tooltip>
                  {this.props.owner.trial.user_type === "Free" ? (
                    <span
                      className={"ml-4"}
                      style={{ color: "black", fontSize: "15px" }}
                    >
                      (Min:3 , Max:4{" "}
                      <a
                        href="/pricing"
                        className={"orange"}
                        style={{ color: "orange", fontSize: "15px" }}
                      >
                        Upgrade to Premium
                      </a>
                      )
                    </span>
                  ) : undefined}
                  {this.props.owner.trial.user_type === "Trial" ? (
                    <span
                      className={"ml-4"}
                      style={{ color: "black", fontSize: "15px" }}
                    >
                      (Min:3 , Max:15{" "}
                      <a
                        href="/pricing"
                        className={"orange"}
                        style={{ color: "orange", fontSize: "15px" }}
                      >
                        Upgrade to Premium
                      </a>
                      )
                    </span>
                  ) : undefined}
                  {this.props.owner.trial.user_type === "Premium" ? (
                    <span
                      className={"ml-4"}
                      style={{ color: "black", fontSize: "15px" }}
                    >
                      (Min:3 , Max:15 )
                    </span>
                  ) : undefined}
                </h5>

                <div className={"select-attribute-wrapper"}>
                  <div className={"select-attribute-body"}>
                    {this.state.participants.length > 0 ? null : (
                      <div className={"d-flex justify-content-center"}>
                        <p style={{ fontWeight: 100 }} className={"my-4"}>
                          Add your Participants - First you should add users to
                          People page
                        </p>
                      </div>
                    )}
                    {this._renderParticipant()}
                    {this._renderOwnerParticipant()}
                  </div>
                  <div
                    className={
                      "select-attribute-footer mt-2 d-flex justify-content-end"
                    }
                  >
                    <Button theme="primary" onClick={this.toggleParticipant}>
                      Add
                    </Button>
                  </div>
                </div>
              </div>
              <hr className={"m-0"} />
              <div className={"form-ui p-3"}>
                <h5 className={"m-0"}>
                  Attachments:{" "}
                  {/* <span style={{ color: "red", fontSize: "15px" }}>
                    (Max:2 , Each up to 2MB)
                  </span> */}
                  <span
                    className={"ml-4"}
                    style={{ color: "black", fontSize: "15px" }}
                  >
                    (Max:2 , Each up to 2MB)
                  </span>
                </h5>

                <div className={"select-attribute-wrapper"}>
                  <div className={"select-attribute-body"}>
                    {this.state.files.length > 0 ? null : (
                      <div className={"d-flex justify-content-center"}>
                        <p style={{ fontWeight: 100 }} className={"my-4"}>
                          Add your Attachments
                        </p>
                      </div>
                    )}
                    {this._renderAttachments()}
                  </div>
                  <div
                    className={
                      "select-attribute-footer mt-2 d-flex justify-content-end"
                    }
                  >
                    <Button theme="primary" onClick={this.toggleAttachment}>
                      Add
                    </Button>
                    {/* {this.props.owner.trial.user_type === "Free" ? (
                      <>
                        <Button
                          theme="secondary"
                          onClick={this.toggleAttachment}
                          disabled
                          className={"mr-5"}
                        >
                          Add
                        </Button>
                      </>
                    ) : (
                      <Button theme="primary" onClick={this.toggleAttachment}>
                        Add
                      </Button>
                    )} */}
                  </div>
                  {/* {this.props.owner.trial.user_type === "Free" ? (
                    <div className={" mt-2 d-flex justify-content-end"}>
                      <h5 className={"m-0"}>
                        <span
                          className={"ml-4"}
                          style={{ color: "", fontSize: "15px" }}
                        >
                          <a
                            href="/pricing"
                            className={"orange"}
                            style={{ color: "orange", fontSize: "15px" }}
                          >
                            Upgrade to Premium
                          </a>
                        </span>
                      </h5>
                    </div>
                  ) : undefined} */}
                </div>
              </div>
              <hr className={"m-0"} />
              <div className={"btn-wrapper d-flex justify-content-between p-3"}>
                <button
                  type="submit"
                  className="btn btn-success-custom align-self-end"
                  onClick={this.props.goToPrevSlide}
                >
                  <IoIosArrowDropleftCircle
                    className={"mr-2 font-size-19"}
                    style={{ verticalAlign: "-4px" }}
                  />
                  <span>Back</span>
                </button>
                <button
                  type="submit"
                  className="btn btn-success-custom align-self-end"
                  onClick={this.checkFormValidation}
                >
                  <span>Proceed</span>
                  <IoIosArrowDroprightCircle className={"ml-2 font-size-19"} />
                </button>
              </div>
            </div>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("state", state);
  return {
    userFollowings: state.userFollowings,
    allAttributes: state.allAttributes,
    sessionID: state.session.data.username,
    selectedDecision: state.selectedDecision,
    owner: state.session.data,
  };
};
export default connect(mapStateToProps, null)(StepTwo);
