import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter, Switch, Route} from "react-router";
import Profile from "./Profile";
import PeopleComponent from "../components/People/PeopleComponent";

/**
 * Users class renders all users and a user with specific id.
 */
class Users extends Component {
    render() {
        return (
            <div className={"mt-5"}>
                <Switch>
                    <Route exact path={"/people/users/"} render={PeopleComponent}/>
                    <Route exact path={"/people/users/:id"} component={Profile}/>
                </Switch>
            </div>
        )
    }
}

export default withRouter(connect()(Users))