import React, { Component, Fragment } from "react";
import { DropdownMenu, DropdownToggle } from "shards-react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { fetchUserNotification } from "../actions";

import {
  IoIosLogOut,
  IoIosCreate,
  IoIosNotificationsOutline,
  IoIosSettings,
  IoMdContact,
  IoIosInformationCircleOutline,
  IoIosAddCircleOutline,
} from "react-icons/io";
import { API_URL } from "../constants";
import { authHeader } from "../actions/AuthHeader";

import { Button } from "@material-ui/core";
/**
 * Notification class fetches all notifications from the rest-api and renders.
 */
class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifList: [],
      loading: false,
    };
  }

  get_unred_notification = () => {
    if (this.props.session.notifications.length > 0) {
      var unread_notification = 0;
      // console.log("oooof", this.props.notifications);
      this.props.session.notifications.reverse().map((item, index) => {
        if (!item.is_read) {
          console.log("here");
          unread_notification += 1;
        }
      });
      console.log(unread_notification);
      return unread_notification;
    } else {
      return 0;
    }
    // this.setState({
    //   unread_notification: unread_notification,
    //   change_read_notification: true
    // });
  };

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    const { dispatch } = this.props;

    if (this.props.session.data.username) {
      await dispatch(fetchUserNotification(this.props.session.data.username));
    }
    // console.log("ooo", this.props.session.notifications);

    this.setState({
      loading: false,
    });
  }

  decline_connect_request = async (id) => {
    let checkout = {
      notif: id,
      state: "no",
    };
    let options = {
      method: "POST",
      body: JSON.stringify(checkout),
      headers: {
        // "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundaryIn312MOjBWdkffIM",
        "Content-type": "application/json",
        Authorization: authHeader(),
      },
    };
    this.setState({
      isPosting: true,
    });
    await fetch(
      `${API_URL}/users/${this.props.session.data.username}/change_connect_request_state/`,
      options
    )
      .then((res) => {
        if (res.status === 400) {
          this.setState({
            gotError: true,
          });
        }
        return res.json();
      })
      .then((data) => {
        window.location.reload();
        console.log("response ");
      });
  };
  accept_connect_request = async (id) => {
    let checkout = {
      notif: id,
      state: "yes",
    };
    let options = {
      method: "POST",
      body: JSON.stringify(checkout),
      headers: {
        // "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundaryIn312MOjBWdkffIM",
        "Content-type": "application/json",
        Authorization: authHeader(),
      },
    };
    this.setState({
      isPosting: true,
    });
    await fetch(
      `${API_URL}/users/${this.props.session.data.username}/change_connect_request_state/`,
      options
    )
      .then((res) => {
        if (res.status === 400) {
          this.setState({
            gotError: true,
          });
        }
        return res.json();
      })
      .then((data) => {
        window.location.reload();
        console.log("response ");
      });
  };

  clear_notification = async () => {
    let checkout = {
      state: "yes",
    };
    let options = {
      method: "POST",
      body: JSON.stringify(checkout),
      headers: {
        // "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundaryIn312MOjBWdkffIM",
        "Content-type": "application/json",
        Authorization: authHeader(),
      },
    };
    this.setState({
      isPosting: true,
    });
    await fetch(
      `${API_URL}/users/${this.props.session.data.username}/clear_notif/`,
      options
    )
      .then((res) => {
        if (res.status === 400) {
          this.setState({
            gotError: true,
          });
        }
        return res.json();
      })
      .then((data) => {
        window.location.reload();
        console.log("response ");
      });
  };

  _renderNotifItem = (item) => {
    console.log("ittttt", item);
    switch (item.state) {
      case "add_to_poll":
        return (
          <div className={"font-size-13 dropdown-item dropdown-item-custom"}>
            <p className={"m-0 p-0 mb-1 fw-600"}>
              You have been invited to a new decision
            </p>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                {/*<div className="notif-ui-profile-pic mr-2">*/}
                {/*</div>*/}
                <div className="text-muted font-size-12">
                  Hi
                  <Link
                    to={`/people/users/${item["recipient"]}`}
                    className={"notif-user-tag fw-600 mx-1"}
                  >
                    @{item["recipient"]},
                  </Link>
                  <br />
                  <Link
                    to={`/people/users/${item["related_user"]}`}
                    className={"notif-user-tag fw-600 mr-1"}
                  >
                    @{item["related_user"]}
                  </Link>
                  <span className={"mr-1"}>
                    has invited you to participate in a decision to
                    <br /> see details and start voting please click on this
                  </span>
                  <a
                    href={`/decision/polls/${item["related_poll"]}`}
                    className={"notif-user-tag fw-600"}
                  >
                    link.
                  </a>
                </div>
              </div>
              {/*<div className="d-flex align-items-center">*/}
              {/*<span className="text-muted">*/}
              {/*{item["send_time"]}*/}
              {/*</span>*/}
              {/*</div>*/}
            </div>
          </div>
        );
      case "comment_create":
        return (
          <div className={"font-size-13 dropdown-item dropdown-item-custom"}>
            <p className={"m-0 p-0 mb-1 fw-600"}>New comment in a decision</p>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                {/*<div className="notif-ui-profile-pic mr-2">*/}
                {/*</div>*/}
                <div className="text-muted font-size-12">
                  Hi
                  <Link
                    to={`/people/users/${item["recipient"]}`}
                    className={"notif-user-tag fw-600 mx-1"}
                  >
                    @{item["recipient"]},
                  </Link>
                  <br />
                  {/* <Link
                    to={`/people/users/${item["related_user"]}`}
                    className={"notif-user-tag fw-600 mr-1"}
                  >
                    @{item["related_user"]}
                  </Link> */}
                  <span className={"mr-1"}>
                    A new comment has been submitted to a decision
                    <br /> you're contributing
                  </span>
                  <a
                    href={`/decision/polls/${item["related_poll"]}`}
                    className={"notif-user-tag fw-600"}
                  >
                    link.
                  </a>
                </div>
              </div>
              {/*<div className="d-flex align-items-center">*/}
              {/*<span className="text-muted">*/}
              {/*{item["send_time"]}*/}
              {/*</span>*/}
              {/*</div>*/}
            </div>
          </div>
        );
      case "poll_waiting":
        return (
          <div className={"font-size-13 dropdown-item dropdown-item-custom"}>
            <p className={"m-0 p-0 mb-1 fw-600"}>
              A decision is awaiting your vote.
            </p>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                {/*<div className="notif-ui-profile-pic mr-2">*/}
                {/*</div>*/}
                <div className="text-muted font-size-12">
                  Hi
                  <Link
                    to={`/people/users/${item["recipient"]}`}
                    className={"notif-user-tag fw-600 mx-1"}
                  >
                    @{item["recipient"]},
                  </Link>
                  <br />
                  <Link
                    to={`/people/users/${item["related_user"]}`}
                    className={"notif-user-tag fw-600 mr-1"}
                  >
                    @{item["related_user"]}
                  </Link>
                  <span className={"mr-1"}>
                    have invited you to participate in a decision and voting
                    period will finish in a week.To see details and start voting
                    please click on this
                  </span>
                  <a
                    href={`/decision/polls/${item["related_poll"]}`}
                    className={"notif-user-tag fw-600"}
                  >
                    link.
                  </a>
                </div>
              </div>
              {/*<div className="d-flex align-items-center">*/}
              {/*<span className="text-muted">*/}
              {/*{item["send_time"]}*/}
              {/*</span>*/}
              {/*</div>*/}
            </div>
          </div>
        );
      case "poll_finalized":
        return (
          <div className={"font-size-13 dropdown-item dropdown-item-custom"}>
            <p className={"m-0 p-0 mb-1 fw-600"}>
              A decision you participated in is now finalized.
            </p>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                {/*<div className="notif-ui-profile-pic mr-2">*/}
                {/*</div>*/}
                <div className="text-muted font-size-12">
                  Hi
                  <Link
                    to={`/people/users/${item["recipient"]}`}
                    className={"notif-user-tag fw-600 mx-1"}
                  >
                    @{item["recipient"]},
                  </Link>
                  <br />
                  <span className={"mr-1"}>
                    A decision that you voted in is now finalized and results
                    <br /> are published. To see results please click on this
                  </span>
                  <a
                    href={`/decision/polls/${item["related_poll"]}`}
                    className={"notif-user-tag fw-600"}
                  >
                    link.
                  </a>
                </div>
              </div>
              {/*<div className="d-flex align-items-center">*/}
              {/*<span className="text-muted">*/}
              {/*{item["send_time"]}*/}
              {/*</span>*/}
              {/*</div>*/}
            </div>
          </div>
        );

      case "poll_ready":
        return (
          <div className={"font-size-13 dropdown-item dropdown-item-custom"}>
            <p className={"m-0 p-0 mb-1 fw-600"}>
              Your decision can be finalized now.
            </p>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                {/*<div className="notif-ui-profile-pic mr-2">*/}
                {/*</div>*/}
                <div className="text-muted font-size-12">
                  Hi
                  <Link
                    to={`/people/users/${item["recipient"]}`}
                    className={"notif-user-tag fw-600 mx-1"}
                  >
                    @{item["recipient"]},
                  </Link>
                  <br />
                  <span className={"mr-1"}>
                    Enough votes have been submitted to your decision.
                    <br /> Now you can finalize your decision and publish the
                    results
                    <br />
                    or you can wait to receive more votes.
                    <br />
                    To see details please click on this
                  </span>
                  <a
                    href={`/decision/polls/${item["related_poll"]}`}
                    className={"notif-user-tag fw-600"}
                  >
                    link.
                  </a>
                </div>
              </div>
              {/*<div className="d-flex align-items-center">*/}
              {/*<span className="text-muted">*/}
              {/*{item["send_time"]}*/}
              {/*</span>*/}
              {/*</div>*/}
            </div>
          </div>
        );
      case "vote_submited":
        return (
          <div className={"font-size-13 dropdown-item dropdown-item-custom"}>
            <p className={"m-0 p-0 mb-1 fw-600"}>
              A new vote has been submitted to your decision.
            </p>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                {/*<div className="notif-ui-profile-pic mr-2">*/}
                {/*</div>*/}
                <div className="text-muted font-size-12">
                  Hi
                  <Link
                    to={`/people/users/${item["recipient"]}`}
                    className={"notif-user-tag fw-600 mx-1"}
                  >
                    @{item["recipient"]},
                  </Link>
                  <br />
                  <span className={"mr-1"}>
                    A new vote has been received for your decision.
                    <br /> To see details please click on this
                  </span>
                  <a
                    href={`/decision/polls/${item["related_poll"]}`}
                    className={"notif-user-tag fw-600"}
                  >
                    link.
                  </a>
                </div>
              </div>
              {/*<div className="d-flex align-items-center">*/}
              {/*<span className="text-muted">*/}
              {/*{item["send_time"]}*/}
              {/*</span>*/}
              {/*</div>*/}
            </div>
          </div>
        );
      case "connect_request":
        return (
          <div className={"font-size-13 dropdown-item dropdown-item-custom"}>
            <p className={"m-0 p-0 mb-1 fw-600"}>You are popular !</p>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                {/*<div className="notif-ui-profile-pic mr-2">*/}
                {/*</div>*/}
                <div className="text-muted font-size-12">
                  Hi
                  <Link
                    to={`/people/users/${item["recipient"]}`}
                    className={"notif-user-tag fw-600 mx-1"}
                  >
                    @{item["recipient"]},
                  </Link>
                  <br />
                  <span className={"mr-1"}>
                    {item.related_user} has connected to you on MeriTa.
                    <br /> Would you like to add them to your
                    <br /> people's page as well?
                  </span>
                  <br />
                  <button
                    onClick={() => this.accept_connect_request(item.id)}
                    className=" mt-4 new-decision-button btn-success-custom btn btn-success mx-2"
                  >
                    yes
                  </button>
                  <button
                    onClick={() => this.decline_connect_request(item.id)}
                    className=" mt-4 new-decision-button btn-success-custom btn btn-success mx-2"
                  >
                    No
                  </button>
                </div>
              </div>
              {/*<div className="d-flex align-items-center">*/}
              {/*<span className="text-muted">*/}
              {/*{item["send_time"]}*/}
              {/*</span>*/}
              {/*</div>*/}
            </div>
          </div>
        );

      case "invite_accept_inviter":
        return (
          <div className={"font-size-13 dropdown-item dropdown-item-custom"}>
            <p className={"m-0 p-0 mb-1 fw-600"}>New expert connected</p>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                {/*<div className="notif-ui-profile-pic mr-2">*/}
                {/*</div>*/}
                <div className="text-muted font-size-12">
                  Hi
                  <Link
                    to={`/people/users/${item["recipient"]}`}
                    className={"notif-user-tag fw-600 mx-1"}
                  >
                    @{item["recipient"]},
                  </Link>
                  <br />
                  <span className={"mr-1"}>
                    {/* <Link
                      to={`/people/users/${item["recipient"]}`}
                      className={"notif-user-tag fw-600 mx-1"}
                    >
                      @{item["related_user"]},
                    </Link>
                    is add to your people list */}
                    {item.related_first_name + " " + item.related_last_name} has
                    joined MeriTa and activated their account.
                    <br />
                    {item.related_first_name + " " + item.related_last_name} has
                    been automatily added to your People.
                  </span>
                  {/* <a
                    href={`/decision/polls/${item["related_poll"]}`}
                    className={"notif-user-tag fw-600"}
                  >
                    link.
                  </a> */}
                </div>
              </div>
              {/*<div className="d-flex align-items-center">*/}
              {/*<span className="text-muted">*/}
              {/*{item["send_time"]}*/}
              {/*</span>*/}
              {/*</div>*/}
            </div>
          </div>
        );
      case "invite_accept_invited":
        return (
          <div className={"font-size-13 dropdown-item dropdown-item-custom"}>
            <p className={"m-0 p-0 mb-1 fw-600"}>New expert connected</p>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                {/*<div className="notif-ui-profile-pic mr-2">*/}
                {/*</div>*/}
                <div className="text-muted font-size-12">
                  Hi
                  <Link
                    to={`/people/users/${item["recipient"]}`}
                    className={"notif-user-tag fw-600 mx-1"}
                  >
                    @{item["recipient"]},
                  </Link>
                  <br />
                  <span className={"mr-1"}>
                    {/* <Link
                        to={`/people/users/${item["recipient"]}`}
                        className={"notif-user-tag fw-600 mx-1"}
                      >
                        @{item["related_user"]},
                      </Link>
                      is add to your people list */}
                    {item.related_first_name + " " + item.related_last_name} has
                    been automatily added to your People.
                    <br />
                    You can invite them to decisions now.
                  </span>
                  {/* <a
                      href={`/decision/polls/${item["related_poll"]}`}
                      className={"notif-user-tag fw-600"}
                    >
                      link.
                    </a> */}
                </div>
              </div>
              {/*<div className="d-flex align-items-center">*/}
              {/*<span className="text-muted">*/}
              {/*{item["send_time"]}*/}
              {/*</span>*/}
              {/*</div>*/}
            </div>
          </div>
        );
      // case "comment_create":
      // return (
      //   <div className={"font-size-13 dropdown-item dropdown-item-custom"}>
      //     <p className={"m-0 p-0 mb-1 fw-600"}>
      //       You have been submitted new comment on decision.
      //     </p>
      //     <div className="d-flex justify-content-between">
      //       <div className="d-flex align-items-center">
      //         {/*<div className="notif-ui-profile-pic mr-2">*/}
      //         {/*</div>*/}
      //         <div className="text-muted font-size-12">
      //           Hi
      //           <Link
      //             to={`/people/users/${item["recipient"]}`}
      //             className={"notif-user-tag fw-600 mx-1"}
      //           >
      //             @{item["recipient"]},
      //           </Link>
      //           <br />
      //           <span className={"mr-1"}>
      //           You have been submitted new comment on decision.
      //             <br /> To see details please click on this
      //           </span>
      //           <Link
      //             to={`/decision/polls/${item["related_poll"]}`}
      //             className={"notif-user-tag fw-600"}
      //           >
      //             link.
      //           </Link>
      //         </div>
      //       </div>
      //       {/*<div className="d-flex align-items-center">*/}
      //       {/*<span className="text-muted">*/}
      //       {/*{item["send_time"]}*/}
      //       {/*</span>*/}
      //       {/*</div>*/}
      //     </div>
      //   </div>
      // );
      default:
        return null;
    }
  };

  render() {
    if (this.props.session.notifications.length > 0) {
      console.log("notification", this.props);
      const noti = this.props.session.notifications.reverse();
    }
    return (
      <Fragment>
        <DropdownToggle
          nav
          caret
          className={"text-white m-0 p-0 font-size-11 notif-icon-parent"}
        >
          <div className={"mr-2 notif-icon nav-link"}>
            <IoIosNotificationsOutline
              className={"font-size-20"}
              style={{ verticalAlign: "-3px", color: "white" }}
            />
            <span className="notif-icon-badge">
              {/* {this.state.change_read_notification
                      ? this.state.unread_notification
                      : ""} */}

              {this.props.click === "no" ? this.get_unred_notification() : 0}
            </span>
          </div>
        </DropdownToggle>
        <DropdownMenu
          right
          className={"drop-down-notification dropdown-menu-ui-custom-notif"}
        >
          {this.state.loading ? (
            <p>Loading</p>
          ) : this.props.session ? (
            <Fragment>
              {this.props.session.notifications &&
              this.props.session.notifications.length > 0 ? (
                <>
                  <Button
                    theme="success"
                    className={"new-decision-button btn-success-custom"}
                    style={{ marginLeft: "140px" }}
                    onClick={this.clear_notification}
                  >
                    {/* <IoIosAddCircleOutline className={"mr-2 font-size-18"} /> */}
                    Clear
                  </Button>
                  {this.props.session.notifications.reverse().map(
                    (item, index) => (
                      // item.is_read ===
                      // true(
                      <div key={index} className={"p-0 nav-link"}>
                        {this._renderNotifItem(item)}
                      </div>
                    )
                    // )
                  )}
                </>
              ) : (
                <p className={"m-0 p-0 text-center"}>
                  You don't have any notification
                </p>
              )}
            </Fragment>
          ) : null}
        </DropdownMenu>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session }) => {
  return { session };
};

export default withRouter(connect(mapStateToProps, null)(Notification));
