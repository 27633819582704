import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Route, Switch, withRouter } from "react-router";
import Personal from "../components/Setting/Personal";
import Email from "../components/Setting/Email";
import Password from "../components/Setting/Password";
import { connect } from "react-redux";
import { getUserProfileInfo, setUserProfileInfo } from "../actions/index";
import { ScaleLoader } from "react-spinners";
import { css } from "@emotion/core";
import "toasted-notes/src/styles.css";
import EmailNotification from "../components/Setting/EmailNotification";
import MeritaAccount from "../components/Setting/MeritaAccount";
import AdminAccountUsers from "../components/Setting/AdminAccountUsers";
import AdminAccountAttrs from "../components/Setting/AdminAccountAttrs";
import AdminAddEnterpriseUser from "../components/Setting/AdminAddEnterpriseUser";
import AdminAddOrgLogo from "../components/Setting/AdminAddOrgLogo";
import AdminPollCopy from "../components/Setting/AdminPollCopy";
import AdminPollCopyEdit from "../components/Setting/AdminPollCopyEdit";
import CopyVoting from "../components/Setting/pollCopy/CopyVoting";
import CopyVotingList from "../components/Setting/pollCopy/CopyVotingList";
import AdminEnterpriseUsers from "../components/Setting/AdminEnterpriseUsers";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
/**
 * Setting class renders three components <Personal/> , <Email/>, <Password/> by considering tha url of browser.
 */
class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: [],
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    if (this.props.data === undefined) {
      await this.props
        .getUserProfileInfo(this.props.sessionID)
        .then((data) => this.setState({ data: data }));
    }
    // else {
    //     await this.setState({
    //         data: this.props.data
    //     })
    // }
    this.setState({
      isLoading: false,
    });
  }

  render() {
    console.log("setting:", this.props);
    return (
      <div className={"container"} style={{ height: "90%" }}>
        {this.state.isLoading ? (
          <div
            className="sweet-loading d-flex justify-content-center align-items-center"
            style={{ height: "90%" }}
          >
            <ScaleLoader
              css={override}
              sizeUnit={"px"}
              size={150}
              color={"#FF8260"}
              loading={this.state.isLoading}
            />
          </div>
        ) : (
          <div className="row mb-5">
            <div className="col-12 col-md-3">
              <div
                className="d-flex sidebar-container flex-column"
                style={{
                  backgroundColor: "white",
                  marginTop: "70px",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                }}
              >
                <NavLink
                  exact
                  className="sidebar-item p-3"
                  to={"/setting/"}
                  activeClassName={"sidebar-item-active"}
                >
                  <h6 className="font-size-13 fw-600 m-0">
                    Personal Information
                  </h6>
                </NavLink>
                <NavLink
                  exact
                  to={"/setting/email/"}
                  activeClassName={"sidebar-item-active"}
                  className="sidebar-item p-3"
                >
                  <h6 className="font-size-13 fw-600 m-0">Email</h6>
                </NavLink>
                <NavLink
                  exact
                  to={"/setting/password/"}
                  activeClassName={"sidebar-item-active"}
                  className="sidebar-item p-3"
                >
                  <h6 className="font-size-13 fw-600 m-0 sidebar-item-text">
                    Password
                  </h6>
                </NavLink>
                <NavLink
                  exact
                  to={"/setting/notification/"}
                  activeClassName={"sidebar-item-active"}
                  className="sidebar-item p-3"
                >
                  <h6 className="font-size-13 fw-600 m-0 sidebar-item-text">
                    Notifications
                  </h6>
                </NavLink>
                <NavLink
                  exact
                  to={"/setting/account/"}
                  activeClassName={"sidebar-item-active"}
                  className="sidebar-item p-3"
                >
                  <h6 className="font-size-13 fw-600 m-0 sidebar-item-text">
                    MeriTa Account
                  </h6>
                </NavLink>
                {this.props.data.org ? (
                  this.props.data.org.is_admin ? (
                    <NavLink
                      exact
                      to={"/setting/admin/users"}
                      activeClassName={"sidebar-item-active"}
                      className="sidebar-item p-3"
                    >
                      <h6 className="font-size-13 fw-600 m-0 sidebar-item-text">
                        Admin Account - Users
                      </h6>
                    </NavLink>
                  ) : undefined
                ) : undefined}
                {this.props.data.org ? (
                  this.props.data.org.is_admin ? (
                    <NavLink
                      exact
                      to={"/setting/admin/attr"}
                      activeClassName={"sidebar-item-active"}
                      className="sidebar-item p-3"
                    >
                      <h6 className="font-size-13 fw-600 m-0 sidebar-item-text">
                        Admin Account - Org. Attributes
                      </h6>
                    </NavLink>
                  ) : undefined
                ) : undefined}
                {this.props.data ? (
                  this.props.data.is_superuser ? (
                    <>
                      <NavLink
                        exact
                        to={"/setting/admin/addEnterpriseUser"}
                        activeClassName={"sidebar-item-active"}
                        className="sidebar-item p-3"
                      >
                        <h6 className="font-size-13 fw-600 m-0 sidebar-item-text">
                          Admin - Add Enterprise user
                        </h6>
                      </NavLink>
                      <NavLink
                        exact
                        to={"/setting/admin/addOrgLogo"}
                        activeClassName={"sidebar-item-active"}
                        className="sidebar-item p-3"
                      >
                        <h6 className="font-size-13 fw-600 m-0 sidebar-item-text">
                          Admin - Add Organization Logo
                        </h6>
                      </NavLink>
                      <NavLink
                        exact
                        to={"/setting/admin/enterprise-users"}
                        activeClassName={"sidebar-item-active"}
                        className="sidebar-item p-3"
                      >
                        <h6 className="font-size-13 fw-600 m-0 sidebar-item-text">
                          Admin - Enterprise Users
                        </h6>
                      </NavLink>
                      <NavLink
                        exact
                        to={"/setting/pollCopy/create"}
                        activeClassName={"sidebar-item-active"}
                        className="sidebar-item p-3"
                      >
                        <h6 className="font-size-13 fw-600 m-0 sidebar-item-text">
                          Admin - Create Poll Copy
                        </h6>
                      </NavLink>

                      <NavLink
                        exact
                        to={"/setting/pollCopy/"}
                        activeClassName={"sidebar-item-active"}
                        className="sidebar-item p-3"
                      >
                        <h6 className="font-size-13 fw-600 m-0 sidebar-item-text">
                          Admin - Copy Voting List
                        </h6>
                      </NavLink>
                    </>
                  ) : undefined
                ) : undefined}
              </div>
            </div>
            <div className="col-12 col-md-9">
              <Switch>
                <Route
                  exact
                  path={"/setting/"}
                  render={() => (
                    <Personal
                      setUserProfileInfo={this.props.setUserProfileInfo}
                      sessionID={this.props.sessionID}
                      data={this.props.data}
                    />
                  )}
                />
                <Route
                  exact
                  path={"/setting/email"}
                  render={() => (
                    <Email
                      setUserProfileInfo={this.props.setUserProfileInfo}
                      sessionID={this.props.sessionID}
                      data={this.props.data}
                    />
                  )}
                />
                <Route
                  exact
                  path={"/setting/password"}
                  render={() => <Password data={this.props.data} />}
                />

                <Route
                  exact
                  path={"/setting/notification"}
                  render={() => (
                    <EmailNotification
                      sessionID={this.props.sessionID}
                      data={this.props.data}
                    />
                  )}
                />
                <Route
                  exact
                  path={"/setting/account"}
                  render={() => (
                    <MeritaAccount
                      sessionID={this.props.sessionID}
                      data={this.props.data}
                    />
                  )}
                />
                <Route
                  exact
                  path={"/setting/admin/users"}
                  render={() => (
                    <AdminAccountUsers
                      sessionID={this.props.sessionID}
                      data={this.props.data}
                      history={this.props.history}
                    />
                  )}
                />

                <Route
                  exact
                  path={"/setting/admin/enterprise-users"}
                  render={() => (
                    <AdminEnterpriseUsers
                      sessionID={this.props.sessionID}
                      data={this.props.data}
                      history={this.props.history}
                    />
                  )}
                />
                <Route
                  exact
                  path={"/setting/admin/attr"}
                  render={() => (
                    <AdminAccountAttrs
                      sessionID={this.props.sessionID}
                      data={this.props.data}
                      history={this.props.history}
                    />
                  )}
                />

                <Route
                  exact
                  path={"/setting/admin/addEnterpriseUser"}
                  render={() => (
                    <AdminAddEnterpriseUser
                      sessionID={this.props.sessionID}
                      data={this.props.data}
                      history={this.props.history}
                    />
                  )}
                />
                <Route
                  exact
                  path={"/setting/admin/addOrgLogo"}
                  render={() => (
                    <AdminAddOrgLogo
                      sessionID={this.props.sessionID}
                      data={this.props.data}
                      history={this.props.history}
                    />
                  )}
                />

                <Route
                  exact
                  path={"/setting/pollCopy/create"}
                  render={() => (
                    <AdminPollCopy
                      sessionID={this.props.sessionID}
                      data={this.props.data}
                      history={this.props.history}
                    />
                  )}
                />
                <Route
                  exact
                  path={"/setting/pollCopy/edit/:id"}
                  render={(props) => (
                    <AdminPollCopyEdit
                      sessionID={this.props.sessionID}
                      data={this.props.data}
                      history={this.props.history}
                      key={Math.random()}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path={"/setting/pollCopy/"}
                  render={() => (
                    <CopyVotingList
                      sessionID={this.props.sessionID}
                      data={this.props.data}
                      history={this.props.history}
                    />
                  )}
                />
                <Route
                  exact
                  path={"/setting/pollCopy/voting/:id"}
                  render={(props) => (
                    <CopyVoting
                      sessionID={this.props.sessionID}
                      data={this.props.data}
                      history={this.props.history}
                      key={Math.random()}
                      {...props}
                    />
                  )}
                />
              </Switch>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("state", state);
  return {
    sessionID: state.session.data.username,
    data: state.session.data,
  };
};

export default withRouter(
  connect(mapStateToProps, { getUserProfileInfo, setUserProfileInfo })(Setting)
);
