import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
// import toast from "toasted-notes";
// import "toasted-notes/src/styles.css";
// import { ScaleLoader } from "react-spinners";
import { css } from "@emotion/core";
// import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
// import { confirmEmail } from "../../actions";
import { Link } from "react-router-dom";

import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn
} from "react-icons/fa";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

/**
 * ConfirmAccount class confirms account of user.
 */
class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      text: "",
      className: "header_area"
    };
  }

  render() {
    // console.log(this.state);
    return (
      <Fragment>
        <header
          style={{ backgroundColor: "#3bacf0" }}
          className={this.state.className}
          id={"Home"}
        >
          <div className="main_menu">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div
                className="container box_1620"
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <a className="navbar-brand logo_h">
                  <img
                    src={require("../assets/icon.png")}
                    height={45}
                    width={140}
                    alt=""
                  />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>

                <div
                  className="collapse navbar-collapse offset"
                  id="navbarSupportedContent"
                >
                  <ul className="nav navbar-nav menu_nav justify-content-center">
                    <li className="nav-item">
                      <a className="nav-link" href="/">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/#Story">
                        Our Story
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/#info">
                        How it works
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#info">
                        <Link to={"/pricing"} >
                        Pricing
                        </Link>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/#RayDalio">
                        Ray Dalio
                      </a>
                    </li>
                  </ul>
                  <ul className="nav navbar-nav navbar-right">
                    {this.state.isLoggedIn ? (
                      <span
                        className={
                          "nav-profile-avatar d-flex flex-column align-items-center my-2"
                        }
                      >
                        <Link to={"/decision"}>
                          <div
                            className={"avater-img"}
                            style={{
                              backgroundImage:
                                "url(" + this.props.session.data.pic + ")"
                            }}
                          ></div>
                        </Link>

                        {/*<span*/}
                        {/*className="text-white">{this.props.session.data.username}*/}
                        {/*</span>*/}
                      </span>
                    ) : (
                      <li className="nav-item">
                        <Link to={"/login"} className="tickets_btn">
                          Login / Sign up
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>

        {/* <div className={"row justify-content-center"}>
            <div className={"col-12 col-sm-12 col-md-8 col-lg-6"}>
              <div className="card">
                <article className="card-body">
                  <h4 className="card-title text-center mb-4 mt-1">
                    Confirming your email
                  </h4>
                  <hr />
                  {this.state.isLoading ? (
                    <div className="sweet-loading text-center">
                      <ScaleLoader
                        css={override}
                        sizeUnit={"px"}
                        size={150}
                        color={"#FF8260"}
                        loading={this.props.isFetching}
                      />
                    </div>
                  ) : (
                    <div>
                      <p className={"text-center"}>{this.state.text}</p>
                      <p className={"text-center"} style={{ color: "red" }}>
                        Now you can login using the botton on top of screen.
                      </p>
                    </div>
                  )}
                </article>
              </div>
            </div>
          </div> */}
        <Fragment>
          <section className="made_life_area p_120" id={"Story"}>
            <div className="container">
              <div className="made_life_inner">
                <div className="row made_life_text">
                  <div className="col-lg-11">
                    <div className="left_side_text">
                      <h3>
                        MeriTa Terms of Use
                        <br />
                      </h3>
                      <p className={"text-black"}>
                        Last updated: Jan 6, 2020
                        <br />
                        Thanks for using our MeriTa website, web applications,
                        and other products and services (collectively, the
                        “Services”). The Services are provided by MeriTa, Inc.
                        (“MeriTa,” “we,” “our” or “us”), located at 60 Hilltop
                        Cres. Burwood East, VIC 3151 Australia
                        <br />
                        By using our Services, you are agreeing to these terms.
                        Please read them carefully. Our Services are diverse, so
                        sometimes additional terms or product requirements
                        (including age requirements) may apply. Additional terms
                        will be available with the relevant Services, and those
                        additional terms become part of your agreement with us
                        if you use those Services. By accessing MeriTa or using
                        our Services, you intend and agree to be legally bound
                        by this agreement.
                        <br />
                        <br /> 1. Using our Services
                        <br />
                        <br />
                        You must follow any policies made available to you
                        within the Services.
                        <br /> <br />
                        Don’t misuse our Services. For example, don’t interfere
                        with our Services, try to access them using a method
                        other than the interface and the instructions that we
                        provide, or extensively or automatically copy any
                        content from the Services (in other words, no scraping).
                        You may use our Services only as permitted by law,
                        including applicable export and re-export control laws
                        and regulations. We may suspend or stop providing our
                        Services to you if you do not comply with our terms or
                        policies or if we are investigating suspected
                        misconduct.
                        <br />
                        <br /> Using our Services does not give you ownership of
                        any intellectual property rights in our Services or the
                        content you access. You may not use content from our
                        Services unless you obtain permission from its owner or
                        are otherwise permitted by law. These terms do not grant
                        you the right to use any branding or logos used in our
                        Services, including the MeriTa name and logo. Don’t
                        remove, obscure, or alter any legal notices displayed in
                        or along with our Services.
                        <br />
                        <br /> Our Services display some content that is not our
                        own, for example, content belonging to our users. This
                        content is the sole responsibility of the individual or
                        entity that makes it available. We may review content to
                        determine whether it is illegal or violates our
                        policies, and we may remove or refuse to display content
                        that we believe violates our policies or the law. But we
                        do not generally review content beforehand, and we are
                        not obligated to do so.
                        <br />
                        <br /> In connection with your use of the Services, we
                        may send you service announcements, notifications,
                        administrative messages, and other information. You may
                        opt out of some of those communications, for example, by
                        clicking on the “unsubscribe” link in marketing emails.
                        Please be aware that there may be a brief period before
                        we are able to process your opt-out, but we will always
                        respect your legal rights to opt out of receiving
                        unwanted messages.
                        <br />
                        <br />
                        Some of our Services are available on mobile devices.
                        Using some of our Services may cause you to incur data
                        charges with your wireless provider. Please be aware
                        that we have no control over these charges, and if you
                        do not wish to be charged, you should stop using the
                        Services.
                        <br />
                        <br />
                        2.Your MeriTa Account <br />
                        <br />
                        You may need an account in order to use some of our
                        Services. You may create your own account or your
                        account may be assigned to you by an administrator, such
                        as your employer. If you are using an account assigned
                        to you by an administrator, different or additional
                        terms may apply and your administrator may be able to
                        access or disable your account.
                        <br /> <br /> To protect your account, keep your
                        password confidential. You are responsible for the
                        activity that happens on or through your account. If you
                        learn of any unauthorized use of your account and/or
                        password, please contact us at privacy@getMeriTa.com.
                        <br />
                        <br />
                        3. Payment
                        <br />
                        <br />
                        You agree to pay all amounts owed to us for the Services
                        under any terms, policies or other written or electronic
                        agreement we may have in place. We may require you to
                        maintain valid credit card or other payment account
                        information with us in order to receive the Services,
                        and if so, you hereby authorize us to charge your credit
                        card or other payment account for the Services. Any
                        failure to maintain valid, up-to-date payment
                        information with us or to keep your payments current
                        will constitute a material breach of these terms, for
                        which we may suspend or terminate your access to the
                        Services immediately without notice. Interest will be
                        charged on all unpaid delinquent amounts at the rate of
                        1.5% per month or the highest rate permitted by
                        applicable law, whichever is lesser. You agree to
                        reimburse us for all collection agency fees, attorneys’
                        fees and other costs we may incur to collect delinquent
                        amounts you owe to us.
                        <br />
                        <br />
                        4.Privacy and Feedback
                        <br />
                        <br />
                        Our privacy policy explains how we treat your personal
                        information and protect your privacy when you use our
                        Services or when our users interact with us in other
                        ways. By using our Services, you agree that we can
                        collect, use and share data from you as described in our
                        privacy policy. If you submit feedback or suggestions
                        about our Services, we may use your feedback or
                        suggestions without obligation to you.
                        <br />
                        <br />
                        5.Content You Submit
                        <br />
                        <br />
                        Some of our Services allow you to submit content. You
                        retain ownership of any intellectual property rights
                        that you hold in that content. In short, what belongs to
                        you stays yours. <br /> <br /> When you upload or
                        otherwise submit content to our Services, you give
                        MeriTa (and those we work with) a royalty-free,
                        worldwide license to use, host, store, reproduce,
                        modify, create derivative works (such as those resulting
                        from translations, adaptations or other changes we make
                        so that your content works better with our Services),
                        communicate, publish, perform, display and distribute
                        such content solely for the purpose of operating and
                        providing Services to you, improving our Services, or
                        developing new services to better serve you and other
                        customers. For the avoidance of any doubt, MeriTa shall
                        only use your content to provide Services to you and for
                        internal purposes. MeriTa will not make your content
                        publicly available, nor share your content with any
                        third party without your prior written consent. The
                        license contained herein shall continue even if you stop
                        using our Services. Make sure you have the necessary
                        rights to grant us this license for any content that you
                        submit to our Services.
                        <br />
                        <br />
                        If you have an account, we may display your profile
                        information or actions you take on the Services or on
                        third-party applications connected to your account (such
                        as comments you post) in our Services, including
                        providing notifications to you on linked platforms.
                        However, we will respect the choices you make to limit
                        sharing or visibility settings in your account. Any
                        content that the Services permit you to delete is
                        removed from public display and marked for future
                        deletion; however, it may persist in backup or residual
                        copies for a reasonable period of time (but will not be
                        available to other users).
                        <br />
                        <br />
                        You agree that you will not:
                        <br />
                        <br />
                        <ul>
                          <li>
                            {" "}
                            Submit excessive or unsolicited commercial messages
                            or spam any users
                          </li>
                          <li>Submit malicious content or viruses</li>
                          <li>
                            Solicit other people’s login information, credit
                            card numbers, or other sensitive information
                          </li>
                          <li>Harass or bully other users</li>
                          <li>
                            Post content that is hate speech, threatening or
                            pornographic, that incites violence or that contains
                            nudity or graphic or gratuitous violence.
                          </li>
                        </ul>
                        <br />
                        <br />
                        6. Transparency and Choice
                        <br />
                        <br />
                        As we ask others to respect our intellectual property
                        rights, we respect the intellectual property rights of
                        others, and require our users and customers to do so. If
                        you are a copyright owner or its agent and believe that
                        any content residing on or accessible through the
                        Services infringes upon your copyrights, you may submit
                        a notification under the Digital Millennium Copyright
                        Act (“DMCA”) by providing our Copyright Agent (the
                        “Designated Agent”) with the following information in
                        writing:
                        <br />
                        <br />
                        <ul>
                          <li>
                            Identification of the work or material being
                            infringed.
                          </li>
                          <li>
                            {" "}
                            Identification of the work or material being
                            infringed.
                          </li>
                          <li>
                            Identification of the material that is claimed to be
                            infringing, including its location, with sufficient
                            detail so that we are capable of finding it and
                            verifying its existence.
                          </li>
                          <li>
                            Contact information for the notifying party (the
                            "Notifying Party"), including name, address,
                            telephone number, and email address.
                          </li>
                          <li>
                            A statement that the Notifying Party has a good
                            faith belief that the material is not authorized by
                            the copyright owner, its agent or law.
                          </li>
                          <li>
                            A statement made under penalty of perjury that the
                            information provided in the notice is accurate and
                            that the Notifying Party is authorized to make the
                            complaint on behalf of the copyright owner.
                          </li>
                          <li>
                            A physical or electronic signature of a person
                            authorized to act on behalf of the owner of the
                            copyright that has been allegedly infringed.
                          </li>
                        </ul>
                        <br />
                        <br />
                        Please also note that the information provided in a
                        notice of copyright infringement may be forwarded to the
                        user who posted the allegedly infringing content. After
                        removing material in response to a valid DMCA notice, we
                        will notify the user responsible for the allegedly
                        infringing material that we have removed or disabled
                        access to the material. We will terminate, under
                        appropriate circumstances, users who are repeat
                        copyright infringers, and we reserve the right, in our
                        sole discretion, to terminate any user for actual or
                        apparent copyright infringement.
                        <br />
                        <br />
                        If you believe you are the wrongful subject of a DMCA
                        notification, you may file a counter-notification with
                        us by providing the following information to the
                        Designated Agent at the address below:
                        <br />
                        <br />
                        <ul>
                          <li>
                            The specific URLs of material that we have removed
                            or to which we have disabled access.
                          </li>
                          <li>
                            Your name, address, telephone number, and email
                            address. A statement that you consent to the
                            jurisdiction of Victorian Courts, and that you will
                            accept service of process from the person who
                            provided the original DMCA notification or an agent
                            of such person.
                          </li>
                          <li>
                            The following statement: "I swear, under penalty of
                            perjury, that I have a good faith belief that the
                            material was removed or disabled as a result of a
                            mistake or misidentification of the material to be
                            removed or disabled."
                          </li>
                        </ul>
                        <br />
                        <br />
                        Your signature. <br />
                        <br />
                        Upon receipt of a valid counter-notification, we will
                        forward it to Notifying Party who submitted the original
                        DMCA notification. The original Notifying Party (or the
                        copyright holder he or she represents) will then have
                        ten (10) days to notify us that he or she has filed
                        legal action relating to the allegedly infringing
                        material. If we do not receive any such notification
                        within ten (10) days, we may restore the material to the
                        Services. <br />
                        <br />
                        The contact information for our Designated Agent is:{" "}
                        <br />
                        <br />
                        MeriTa, Inc. <br />
                        <br />
                        Attention: Copyright Agent <br />
                        <br />
                        60 Hilltop Cres. Burwood East 3151 VIC Australia <br />
                        <br />
                        Email: info@myMeriTa.com <br />
                        <br />
                        If you believe that any of your intellectual property
                        rights other than copyrights have been infringed, please
                        e-mail us at copyright@getMeriTa.com. We reserve the
                        right, in our sole and absolute discretion, to suspend
                        or terminate any user who infringes the intellectual
                        property rights of MeriTa or others, and/or to remove,
                        delete, edit or disable access to such person’s content.
                        You agree that we have no liability for any action taken
                        under this section.
                        <br />
                        <br />
                        <br />
                        <br />
                        7.About Software in our Services <br />
                        <br />
                        When a Service requires or includes downloadable
                        software (such as a mobile app), this software may
                        update automatically on your device once a new version
                        or feature is available. By continuing to use the
                        Services, you acknowledge and consent to such software
                        updates or implementation of new versions or features of
                        software. Some Services may let you adjust your
                        automatic update settings.
                        <br />
                        <br /> MeriTa gives you a revocable, personal,
                        worldwide, royalty-free, non-assignable and
                        non-exclusive license to use the software we provide to
                        you as part of the Services. This license is for the
                        sole purpose of enabling you to use and enjoy the
                        benefit of the Services as provided by us, in the manner
                        permitted by these terms. You may not copy, modify,
                        distribute, sell, or lease any part of our Services or
                        included software, nor may you reverse engineer or
                        attempt to extract the source code of that software,
                        unless laws prohibit those restrictions or you have our
                        written permission.
                        <br />
                        <br />
                        8.Modifying and Terminating our Services <br />
                        <br />
                        <br />
                        We are constantly changing and improving our Services.
                        We may add or remove functionalities or features, and we
                        may suspend or stop a Service altogether.
                        <br />
                        <br /> You can stop using our Services at any time,
                        although we’ll be sorry to see you go. We may also stop
                        providing Services to you, or add or create new limits
                        to our Services, at any time and at our sole and
                        absolute discretion.
                        <br />
                        <br />
                        9. Our Warranties and Disclaimers
                        <br />
                        <br />
                        We provide our Services using a commercially reasonable
                        level of skill and care and we hope that you will enjoy
                        using them. But there are certain things that we don’t
                        promise about our Services.
                        <br />
                        <br />
                        OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS OR
                        ADDITIONAL TERMS, NEITHER MeriTa NOR ITS LICENSORS,
                        SUPPLIERS OR DISTRIBUTORS MAKE ANY SPECIFIC PROMISES
                        ABOUT THE SERVICES. FOR EXAMPLE, WE DON’T MAKE ANY
                        COMMITMENTS ABOUT THE CONTENT WITHIN THE SERVICES, THE
                        SPECIFIC FUNCTIONS OF THE SERVICES, OR THEIR
                        RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR
                        NEEDS. WE ALSO DO NOT MAKE ANY WARRANTIES OR COMMITMENT
                        RELATING TO NON-INFRINGEMENT, FREEDOM FROM VIRUSES OR
                        OTHER HARMFUL CODE, OR ERROR-FREE OR UNINTERRUPTED
                        OPERATIONS. WE PROVIDE THE SERVICES “AS IS.” <br />
                        <br /> SOME JURISDICTIONS PROVIDE FOR CERTAIN
                        WARRANTIES, LIKE THE IMPLIED WARRANTY OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                        NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE
                        DISCLAIM ALL WARRANTIES.
                        <br />
                        <br />
                        10. Liability for our Services
                        <br />
                        <br />
                        WHEN PERMITTED BY LAW, MeriTa, AND OUR LICENSORS,
                        SUPPLIERS AND DISTRIBUTORS, WILL NOT BE RESPONSIBLE FOR
                        LOST PROFITS, REVENUES, OR DATA, FINANCIAL LOSSES OR
                        INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE
                        DAMAGES.
                        <br />
                        <br /> TO THE EXTENT PERMITTED BY LAW, THE TOTAL
                        LIABILITY OF MeriTa, AND OUR LICENSORS, SUPPLIERS AND
                        DISTRIBUTORS, FOR ANY CLAIMS UNDER THESE TERMS OR
                        RELATING TO YOUR USE OF THE SERVICES, INCLUDING FOR ANY
                        IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US
                        TO USE THE SERVICES (OR, IF WE CHOOSE, TO SUPPLYING YOU
                        THE SERVICES AGAIN).
                        <br />
                        <br /> IN ALL CASES, MeriTa, AND ITS LICENSORS,
                        SUPPLIERS AND DISTRIBUTORS, WILL NOT BE LIABLE FOR ANY
                        LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE OR
                        THAT IS DUE TO EVENTS OUTSIDE OF OUR REASONABLE CONTROL,
                        SUCH AS WARS, CRIMINAL ACTIVITIES, STORMS, NATURAL
                        DISASTERS, ACTS OF GOVERNMENT, SUPPLY INTERRUPTIONS, OR
                        TELECOMMUNICATION OR INTERNET FAILURES.
                        <br />
                        <br />
                        11.Business/Employer Uses of our Services
                        <br />
                        <br />
                        If you are using our Services on behalf of a business or
                        employer, that business or employer accepts these terms.
                        <br />
                        <br />
                        12.Indemnification
                        <br />
                        <br />
                        You hereby agree to indemnify, defend and hold harmless
                        MeriTa its affiliated companies, and their respective
                        directors, officers, employees, agents, representatives,
                        partners, contractors, successors and assigns from and
                        against all claims, losses, expenses, damages and costs
                        (including, but not limited to, reasonable attorneys'
                        fees), resulting from or arising out of your actual or
                        alleged breach of these terms, any content you provide
                        through the Services, or your use or misuse of the
                        Services. However, you will not be responsible for
                        claims, damages and costs which are found by a court of
                        competent jurisdiction to have arisen solely from our
                        wrongful acts or omissions.
                        <br />
                        <br />
                        Subject to this Agreement, MeriTa hereby agrees to (i)
                        defend you (and if applicable, your officers, directors
                        and employees) against any third party suit, claim, or
                        demand (each a “Claim”) that alleges the Services used
                        in accordance with this Agreement infringe any issued
                        patent, copyright, trademark or misappropriation of any
                        trade secret of such third party; and (ii) pay any
                        court-ordered award of damages or settlement amount to
                        the extent arising from such Claims. In addition, MeriTa
                        may, at its expense and in its sole discretion: (i)
                        procure for you the right to continue using the
                        Services; (ii) replace the same with other products of
                        substantially equivalent functions and efficiency that
                        are not subject to any Claims of infringement; or (iii)
                        modify the Services so that there is no longer any
                        infringement, provided that such modification does not
                        materially and adversely affect the functional
                        capabilities of the Services. Alternatively, MeriTa may
                        elect to terminate the affected Services and refund to
                        you any subscription fees prepaid by you, prorated to
                        cover the remaining portion of the applicable
                        subscription term for the affected Services after the
                        date of termination. The foregoing indemnification
                        obligation of MeriTa shall not apply: (1) to Claims
                        arising from any content posted by third parties using
                        the Services, (2) if the Services are modified by any
                        party other than MeriTa, but solely to the extent the
                        alleged infringement is related to such modification;
                        (3) the Services are combined with other non-MeriTa
                        products, applications, or processes not authorized by
                        MeriTa, but solely to the extent the alleged
                        infringement is related to such combination; (4) to the
                        extent the Claim arises in connection with any
                        unauthorized use of the Services, or use that is not in
                        compliance with all applicable laws; or (5) to any third
                        party products, processes or materials that are not
                        provided by MeriTa. THIS SECTION SETS FORTH MeriTa’S
                        SOLE LIABILITY AND YOUR SOLE AND EXCLUSIVE REMEDY WITH
                        RESPECT TO ANY CLAIM OF INTELLECTUAL PROPERTY
                        INFRINGEMENT.
                        <br />
                        <br />
                        13.About these Terms
                        <br />
                        <br />
                        We may modify these terms or any additional terms that
                        apply to a Service to, for example, reflect changes to
                        the law or changes to our Services. You should look at
                        the terms regularly and the “Last Updated” date at the
                        beginning of these terms. We’ll post notice of
                        modifications to these terms on this page or notify you
                        of modifications to these terms by e-mail or through the
                        Services. We’ll post notice of modified additional terms
                        in the applicable Service. Changes will not apply
                        retroactively and will become effective no sooner than
                        ten (10) days after they are posted. However, changes
                        addressing new functions for a Service or changes made
                        for legal reasons will be effective immediately. If you
                        do not agree to the modified terms for a Service, you
                        should discontinue your use of that Service. <br />
                        <br />
                        If there is a conflict between these terms and any
                        additional terms for a Service, the additional terms
                        will control for that conflict.
                        <br />
                        <br /> These terms control the relationship between
                        MeriTa and you. They do not create any third party
                        beneficiary rights. If you do not comply with these
                        terms, and we don’t take action right away, this doesn’t
                        mean that we are giving up any rights that we may have
                        (such as taking action in the future). If it turns out
                        that a particular term is not enforceable, this will not
                        affect any other terms.
                        <br />
                        <br /> The laws of Australia and the State of Victoria,
                        excluding Victoria’s conflict of laws rules, will apply
                        to any disputes arising out of or relating to these
                        terms or the Services. All claims arising out of or
                        relating to these terms or the Services will be
                        litigated exclusively in the courts of Victoria,
                        Australia, and you and MeriTa consent to personal
                        jurisdiction in those courts. <br />
                        <br />
                        You may not assign or delegate your rights or
                        obligations relating to these terms or your account for
                        the Services without our prior written consent. We may
                        assign these terms or assign or delegate any of our
                        rights or obligations at any time. <br />
                        <br /> For information about how to contact MeriTa,
                        please email us:
                        <u style={{ color: "blue" }}>info@myMeriTa.com</u>.
                        <br />
                        <br />
                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Fragment>
        <footer className="footer_area p_120 mt-5">
          <div className="container">
            <div className="row footer_inner">
              <div className="col-lg-3 col-sm-6">
                <aside className="f_widget ab_widget">
                  <div className="f_title">
                    <h3>About Us</h3>
                  </div>
                  <p>MeriTa is a meritocratic decision support network</p>
                  <p>
                    Copyright
                    <span className={"mx-1"}>
                      &copy;
                      {new Date().getFullYear()}
                    </span>
                    All rights reserved
                  </p>
                </aside>
              </div>
              <div className="col-lg-3 col-sm-6">
                <aside className="f_widget news_widget">
                  <div className="f_title">
                    <h3>Newsletter</h3>
                  </div>
                  <p>Stay updated with us</p>
                  <div id="mc_embed_signup">
                    <form
                      className="subscribe_form relative"
                      onSubmit={e => e.preventDefault()}
                    >
                      <div className="input-group d-flex flex-row">
                        <input
                          name="EMAIL"
                          placeholder="Enter email address"
                          required=""
                          type="email"
                        />
                        <button className="btn sub-btn">
                          <span className="lnr lnr-arrow-right"></span>
                        </button>
                      </div>
                      <div className="mt-10 info"></div>
                    </form>
                  </div>
                </aside>
              </div>
              <div className="col-lg-3">
                <aside className="f_widget social_widget">
                  <div className="f_title">
                    <h3>Follow Us</h3>
                  </div>
                  <p>Let us be social</p>
                  <ul className="list">
                    <li>
                      <a href="#">
                        <FaFacebook />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                  <br/>

                  <a href="https://mymeritablog.wordpress.com/" target="_blank" className={"contact-us-email"}>
                    MeriTa Blog
                  </a>
                </aside>
              </div>
              <div className="col-lg-3">
                <aside className="f_widget social_widget">
                  <div className="f_title">
                    <h3>Contact us</h3>
                  </div>
                  <a
                    className={"contact-us-email"}
                    href={
                      "mailto: info@myMeriTa.com?subject=Feedback&body=Message"
                    }
                  >
                    info@myMeriTa.com
                  </a>
                  <br />

                  <a href="/privacy" className={"contact-us-email"}>
                    Privacy
                  </a>
                  <br />

                  <a href="/terms" className={"contact-us-email"}>
                    Terms
                  </a>
                </aside>
              </div>
            </div>
          </div>
        </footer>
      </Fragment>
    );
  }
}

export default withRouter(Terms);
