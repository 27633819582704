import {combineReducers} from "redux";
import {decisions} from "./decisions";
import {MakeDecisionForm} from "./form";
import {selectedDecision} from "./selectedDecision";
import votingStep from "./VotingSteps";
import {profile} from "./profile";
import {allUsers} from "./allUsers";
import {allAttributes} from "./allAttributes";
import {userAttributes} from "./userAttributes";
import {userFollowings} from "./userFollowings";
import {session} from "./session";

export default combineReducers({
    decisions,
    MakeDecisionForm,
    selectedDecision,
    votingStep,
    profile,
    allUsers,
    allAttributes,
    userAttributes,
    userFollowings,
    session
});