import React, { Component } from "react";
import "react-quill/dist/quill.snow.css";
import Modal from "react-responsive-modal";
import { css } from "@emotion/core";
import AvatarEditor from "react-avatar-editor";
import { Button } from "shards-react";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { PulseLoader } from "react-spinners";
import { HOST_URL } from "../../constants";
import { IoIosSearch } from "react-icons/io";
import Resizer from "react-image-file-resizer";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
/**
 * ChangeImage class is used to change current user profile picture.
 */
class ChangeImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photo: "",
      data: "",
      width: 350,
      height: 350,
      isLoading: false,
    };
    this.fileInput = React.createRef();
  }

  onClickSave = async () => {
    if (this.state.isLoading) {
      return null;
    }
    if (this.editor) {
      this.setState({
        isLoading: true,
      });
      const canvas = this.editor.getImage().toDataURL();
      let imageURL;
      await fetch(canvas)
        .then((res) => res.blob())
        .then((blob) => {
          imageURL = window.URL.createObjectURL(blob);
          this.setState({
            data: imageURL,
          });
        });

        // await this.props.uploadImage(this.state.photo, this.props.username);
        // this.setState({ isLoading: false });
        // window.location.replace(HOST_URL + "/setting/admin/addOrgLogo");

      await Resizer.imageFileResizer(
        this.state.photo,
        350,
        350,
        "JPEG",
        100,
        0,
        (uri) => {
          console.log(uri);
          this.setState(
            {
              photo: uri,
            },
            async () => {
              console.log("asdasdsdasd", this.state.photo);
              await this.props.uploadImage(this.state.photo, this.props.username);
              this.setState({ isLoading: false });
              window.location.replace(HOST_URL + "/setting/admin/addOrgLogo");
            }
          );
        },
        "blob"
      );
      console.log("ff");
    }
  };

  setEditorRef = (editor) => (this.editor = editor);

  handleChangeInputFile = (e) => {
    this.setState({
      photo: this.fileInput.current.files[0],
    });
  };

  componentDidMount() {
    if (window.innerWidth <= 515) {
      this.setState({
        width: 200,
        height: 200,
      });
    } else {
      this.setState({
        width: 350,
        height: 350,
      });
    }
    window.addEventListener("resize", this.resize);
  }

  resize = () => {
    if (window.innerWidth <= 515) {
      this.setState({
        width: 200,
        height: 200,
      });
    } else {
      this.setState({
        width: 350,
        height: 350,
      });
    }
  };

  render() {
    return (
      <Modal
        animationDuration={100}
        open={this.props.showModal}
        onClose={this.props.toggle}
        center
      >
        <div className="profile-image-container">
          <h3>Pick Your Profile Image</h3>
          <hr />
          <div style={{ textAlign: "center" }}>
            <AvatarEditor
              ref={this.setEditorRef}
              image={this.state.photo}
              width={this.state.width}
              height={this.state.height}
              border={10}
              scale={1}
            />
          </div>
          {this.state.data.length !== 0 ? (
            <div>
              <h6 className="m-0">Preview</h6>
              <img
                src={this.state.data}
                className="profile-image"
                alt={"is Loading...!"}
              />
            </div>
          ) : null}
          <div className="cp-modal-btn-container mt-3">
            <label
              className="btn btn-primary m-0 mr-2"
              style={{ backgroundColor: "grey" }}
            >
              <input
                ref={this.fileInput}
                onChange={this.handleChangeInputFile}
                type="file"
              />
              Browse <IoIosSearch />
            </label>
            <Button
              onClick={this.onClickSave}
              theme={"primary"}
              className={"mr-1"}
            >
              {this.state.isLoading ? (
                <div className="sweet-loading">
                  <PulseLoader
                    css={override}
                    sizeUnit={"px"}
                    color={"white"}
                    size={7}
                    loading={true}
                  />
                </div>
              ) : (
                <span>
                  Upload
                  <IoMdCheckmarkCircle className={"ml-2 font-size-19"} />
                </span>
              )}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ChangeImage;
