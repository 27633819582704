import React, { Component, Fragment } from "react";
import navbarBrandImg from "../../assets/icon.png";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { MdMenu } from "react-icons/md";
import Modal from "react-responsive-modal";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import 'react-circular-progressbar/dist/styles.css';
import {
  IoIosLogOut,
  IoIosCreate,
  IoIosNotificationsOutline,
  IoIosSettings,
  IoMdContact,
  IoIosInformationCircleOutline,
} from "react-icons/io";
import { NavLink as NavLinkRouter } from "react-router-dom";
import {
  Button,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
} from "shards-react";
import Notification from "../../containers/Notification";
import InvitationEmail from "../../containers/InvitationEmail";
import AboutMerita from "../UI/AboutMerita";
import Feedback from "../UI/Feedback";
import { ScaleLoader, PulseLoader } from "react-spinners";
import {
  fetchUserfollowers,
  fetchUserfollowings,
  fetchUserSession,
  BlockUser,
} from "../../actions/index";
import { authHeader } from "../../actions/AuthHeader";
import AddFriendModal from "../../components/People/AddFriendModal";
import InviteFriendModal from "../../components/People/InviteFriendModal";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { API_URL } from "../../constants";
import photo from "../../assets/tutorial/00.jpg";
import photo1 from "../../assets/tutorial/01.jpg";
import photo2 from "../../assets/tutorial/02.jpg";
import photo3 from "../../assets/tutorial/03.jpg";
import photo4 from "../../assets/tutorial/04.jpg";
import photo5 from "../../assets/tutorial/05.jpg";
import photo6 from "../../assets/tutorial/06.jpg";
import photo7 from "../../assets/tutorial/07.jpg";
import photo8 from "../../assets/tutorial/08.jpg";
import photo9 from "../../assets/tutorial/09.jpg";
import photo10 from "../../assets/tutorial/10.jpg";
import photo11 from "../../assets/tutorial/11.jpg";
import photo12 from "../../assets/tutorial/12.jpg";
import photo13 from "../../assets/tutorial/13.jpg";
import photo14 from "../../assets/tutorial/14.jpg";
import photo15 from "../../assets/tutorial/15.jpg";
import photo16 from "../../assets/tutorial/16.jpg";
import photo17 from "../../assets/tutorial/17.jpg";
import photo18 from "../../assets/tutorial/18.jpg";
import photo19 from "../../assets/tutorial/19.jpg";
import photo20 from "../../assets/tutorial/20.jpg";
import photo21 from "../../assets/tutorial/21.jpg";
import photo22 from "../../assets/tutorial/22.jpg";
import photo23 from "../../assets/tutorial/23.jpg";
/**
 * NavbarUI class renders Navbar.
 */
const images = [
  photo,
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
  photo10,
  photo11,
  photo12,
  photo13,
  photo14,
  photo15,
  photo16,
  photo17,
  photo18,
  photo19,
  photo20,
  photo21,
  photo22,
  photo23,
];
class NavbarUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpenWithoutText: false,
      dropdownOpenWithText: false,
      collapseOpen: false,
      isLoggedOut: false,
      dropdownOpenNotification: false,
      showInvitationModal: false,
      showAboutModal: false,
      showFeedbackModal: false,
      pdf_modal: false,
      change_read_notification: false,
      show_num_notification: -1,
      photoIndex: 0,
      isOpen: false,
    };
  }

  toggleInvitationModal = () => {
    return this.setState({
      showInvitationModal: !this.state.showInvitationModal,
    });
  };

  toggleFeedbackModal = () => {
    return this.setState({
      showFeedbackModal: !this.state.showFeedbackModal,
    });
  };

  toggleAboutModal = () => {
    return this.setState({
      showAboutModal: !this.state.showAboutModal,
    });
  };

  toggleDropdownWithText = () => {
    this.setState({
      ...this.state,
      ...{
        dropdownOpenWithText: !this.state.dropdownOpenWithText,
      },
    });
  };

  toggleDropdownNotification = () => {
    console.log("ass");

    console.log("username", this.props.sessionID);
    fetch(`${API_URL}/users/${this.props.sessionID}/set_notifications_read/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("dat1123a", data);
      });

    this.setState({
      ...this.state,
      ...{
        dropdownOpenNotification: !this.state.dropdownOpenNotification,
        show_num_notification: 0,
      },
    });
  };

  toggleDropdownWithoutText = () => {
    this.setState({
      ...this.state,
      ...{
        dropdownOpenWithoutText: !this.state.dropdownOpenWithoutText,
      },
    });
  };

  toggleNavbar = () => {
    this.setState({
      ...this.state,
      ...{
        collapseOpen: !this.state.collapseOpen,
      },
    });
  };

  show_pdf_modal = () => {
    this.setState({
      pdf_modal: !this.state.pdf_modal,
    });
  };

  logout = () => {
    localStorage.removeItem("authToken");
    this.setState({
      isLoggedOut: true,
    });
    window.location.reload();
  };
  refresh = async () => {
    const { dispatch } = this.props;
    this.setState({
      isLoading: true,
    });
    await dispatch(fetchUserSession());
    await dispatch(fetchUserfollowings(this.props.sessionID));
    await dispatch(fetchUserfollowers(this.props.sessionID));
    const { userFollowings } = this.props;
    let postData = {
      usernames: [],
    };
    userFollowings["data"].map((item) => {
      return postData.usernames.push(item["username"]);
    });

    userFollowings["followers"].map((item) => {
      return postData.usernames.push(item["username"]);
    });

    await fetch(`${API_URL}/users/many_attributes/`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          topAttribute: data,
        });
      });
    this.setState({
      isLoading: false,
    });
  };
  render() {
    console.log("user is ", this.props.user);
    let profileUrl = "";
    let logoUrl = "";
    if (this.props.pic) {
      profileUrl = this.props.pic;
    }
    if (this.props.user) {
      if (this.props.user.org) {
        logoUrl = this.props.user.org.pic;
        console.log("logo url ", logoUrl);
      }
    }
    const { photoIndex, isOpen } = this.state;

    return (
      <Fragment>
        <div>
          {/* <button type="button" onClick={() => this.setState({ isOpen: true })}>
            Open Lightbox
          </button> */}

          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.length - 1) % images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.length,
                })
              }
            />
          )}
        </div>
        {this.props.user !== undefined ? (
          <>
            <Modal
              animationDuration={100}
              open={this.state.pdf_modal}
              onClose={this.show_pdf_modal}
              center
            >
              <h4>MeriTa Tutorial:</h4>
              <hr />
              <div style={{ width: "800px" }}></div>
              <div className="model">
                <div className="modelContent">
                  <iframe
                    src={require("../../assets/tutorial.pdf")}
                    style={{ width: "750px", height: "500px" }}
                    frameBorder="0"
                  ></iframe>
                </div>
              </div>
            </Modal>
            <InvitationEmail
              show={this.state.showInvitationModal}
              onCloseModal={this.toggleInvitationModal}
              sessionID={this.props.sessionID}
            />
            <AboutMerita
              show={this.state.showAboutModal}
              onCloseModal={this.toggleAboutModal}
            />
            <Feedback
              show={this.state.showFeedbackModal}
              onCloseModal={this.toggleFeedbackModal}
            />
            <Navbar
              type="dark"
              theme="primary"
              expand="md"
              className={"navbar-ui-custom"}
            >
              <Button
                onClick={this.toggleNavbar}
                className={"navbar-toggler navbar-toggeler-icon-custom m-0"}
              >
                <MdMenu className={"text-white"} />
              </Button>

              <NavbarBrand href="/" className={"navbar-brand-ui-icon"}>
                <img
                  src={navbarBrandImg}
                  className={"navbar-brand-ui-icon-img"}
                  alt={"is loading.. !"}
                />
              </NavbarBrand>

              <Dropdown
                open={this.state.dropdownOpenWithoutText}
                toggle={this.toggleDropdownWithoutText}
                className={"mr-2 dropdown-menu-right hide-in-768"}
              >
                <DropdownToggle nav>
                  <span className={"nav-profile-avatar d-flex"}>
                    <div
                      style={{ backgroundImage: "url(" + profileUrl + ")" }}
                      className={"avater-img"}
                    ></div>
                    <div className={"avatar-green-spot"}></div>
                  </span>
                </DropdownToggle>
                <DropdownMenu right className={"dropdown-menu-ui-custom"}>
                  <NavLinkRouter
                    to={"/people/users/dx"}
                    className={"profile-item-"}
                    activeClassName={"active"}
                  >
                    <DropdownItem>
                      <IoMdContact
                        className={"mr-2 font-size-18"}
                        style={{ verticalAlign: "-3px" }}
                      />
                      Profile
                    </DropdownItem>
                  </NavLinkRouter>
                  <NavLinkRouter
                    to={"/setting"}
                    className={"profile-item-"}
                    activeClassName={"active"}
                  >
                    <DropdownItem>
                      <IoIosSettings
                        className={"mr-2 font-size-18"}
                        style={{ verticalAlign: "-3px" }}
                      />
                      Setting
                    </DropdownItem>
                  </NavLinkRouter>
                  <NavLink
                    onClick={this.toggleAboutModal}
                    className={"profile-item- p-0 m-0"}
                  >
                    <DropdownItem className={"profile-item-"}>
                      <IoIosInformationCircleOutline
                        className={"mr-2 font-size-18"}
                        style={{ verticalAlign: "-3px" }}
                      />
                      About MeriTa
                    </DropdownItem>
                  </NavLink>
                  <NavLink
                    onClick={this.toggleFeedbackModal}
                    className={"profile-item- p-0 m-0"}
                  >
                    <DropdownItem>
                      <IoIosCreate
                        className={"mr-2 profile-item- font-size-18"}
                        style={{ verticalAlign: "-3px" }}
                      />
                      Feedback to MeriTa
                    </DropdownItem>
                  </NavLink>

                  <DropdownItem onClick={this.logout}>
                    <IoIosLogOut
                      className={"mr-2 font-size-18"}
                      style={{ verticalAlign: "-3px" }}
                    />
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <Collapse open={this.state.collapseOpen} navbar>
                <Nav navbar>
                  <NavItem
                    className={"mx-0 mx-md-1 mt-3 mt-md-0 nav-item-ui-custom"}
                  >
                    <NavLinkRouter
                      className={
                        "py-2 d-block text-white px-1 px-md-4 font-size-13 d-flex"
                      }
                      activeClassName={
                        "navbar-ui-item-active p-2 d-block text-white px-1 px-md-4 font-size-13"
                      }
                      to={"/decision"}
                    >
                      {/*<span className={"mr-2 font-size-14"}>*/}
                      {/*/!*<IoMdListBox className={"navbar-item-icon"}/>*!/*/}

                      {/*</span>*/}
                      <div className="decision-icon-ui mr-1"></div>
                      <span style={{ marginTop: "6px" }}>Decision</span>
                    </NavLinkRouter>
                  </NavItem>
                  <NavItem
                    className={"mx-0 mx-md-1 mt-3 mt-md-0 nav-item-ui-custom"}
                  >
                    <NavLinkRouter
                      className={
                        "py-2 d-block text-white px-1 px-md-4 font-size-13 d-flex"
                      }
                      activeClassName={
                        "navbar-ui-item-active p-2 d-block text-white px-1 px-md-4 font-size-13"
                      }
                      to={"/attribute"}
                    >
                      {/*<span className={"mr-2 font-size-14"}>*/}
                      {/*<IoIosAnalytics className={"navbar-item-icon text-white"}/>*/}
                      {/*</span>*/}
                      <div className="attribute-icon-ui mr-1"></div>
                      <span style={{ marginTop: "6px" }}>Attribute</span>
                    </NavLinkRouter>
                  </NavItem>
                  <NavItem
                    className={"mx-0 mx-md-1 mt-3 mt-md-0 nav-item-ui-custom"}
                  >
                    <NavLinkRouter
                      className={
                        "py-2 d-block text-white px-1 px-md-4 font-size-13 d-flex"
                      }
                      activeClassName={
                        "navbar-ui-item-active p-2 d-block text-white px-1 px-md-4 font-size-13"
                      }
                      to={"/people"}
                    >
                      {/*<span className={"mr-2 font-size-14"}>*/}
                      {/*<FaUserCircle className={"navbar-item-icon text-white"}/>*/}
                      {/*</span>*/}

                      <div className="profile-icon-ui mr-1"></div>
                      <span style={{ marginTop: "6px" }}>People</span>
                    </NavLinkRouter>
                  </NavItem>
                </Nav>
              </Collapse>

              <Nav navbar className="ml-auto hide-under-768 align-items-center">
                <NavItem
                  className={"nav-item-ui-custom navbar-item-right mr-3"}
                >
                  <a
                    // onClick={this.show_pdf_modal}
                    onClick={() => this.setState({ isOpen: true })}
                    className={
                      "py-2 d-block px-1 px-md-4 font-size-13 cursor-p"
                    }
                  >
                    Tutorial
                  </a>
                </NavItem>
                {this.props.user.org ? (
                  this.props.user.org.is_admin ||
                  !this.props.user.is_enterprise ? (
                    <NavItem
                      className={"nav-item-ui-custom navbar-item-right mr-3"}
                    >
                      <NavLink
                        onClick={this.toggleInvitationModal}
                        className={
                          "py-2 d-block px-1 px-md-4 font-size-13 cursor-p"
                        }
                      >
                        Invite Experts
                      </NavLink>
                    </NavItem>
                  ) : undefined
                ) : undefined}
                {!this.props.user.is_enterprise ? (
                  <NavItem
                    className={"nav-item-ui-custom navbar-item-right mr-3"}
                  >
                    <NavLink
                      onClick={this.toggleInvitationModal}
                      className={
                        "py-2 d-block px-1 px-md-4 font-size-13 cursor-p"
                      }
                    >
                      Invite Experts
                    </NavLink>
                  </NavItem>
                ) : undefined}

                <Dropdown
                  open={this.state.dropdownOpenNotification}
                  toggle={this.toggleDropdownNotification}
                  className={"mr-2 dropdown-menu-right"}
                >
                  {this.state.show_num_notification === -1 ? (
                    <Notification click="no" />
                  ) : (
                    <Notification click="yes" />
                  )}
                </Dropdown>
                {this.props.user.trial ? (
                  !this.props.user.is_enterprise &&
                  this.props.user.trial.user_type !== "Free" &&
                  this.props.user.trial.user_type !== "Premium" ? (
                    <div
                      className={
                        "text-center orange_text nav-item-ui-custom  mr-3"
                      }
                    >
                      {/* Free<br/>
             <p>Upgrade to Premium</p> */}
                      <CircularProgressbar
                        value={this.props.user.trial.days}
                        maxValue={30}
                        background={true}
                        counterClockwise={true}
                        text={`${this.props.user.trial.days} days left`}
                        styles={buildStyles({
                          // Rotation of path and trail, in number of turns (0-1)
                          // rotation: 0.25,

                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          // strokeLinecap: "butt",

                          // Text size
                          textSize: "13px",

                          // How long animation takes to go from one percentage to another, in seconds
                          // pathTransitionDuration: 0.5,

                          // // Can specify path transition in more detail, or remove it entirely
                          // // pathTransition: 'none',

                          // // Colors
                          pathColor: `orange`,
                          textColor: "black",
                          // trailColor: "#d6d6d6",
                          backgroundColor: "white",
                        })}
                      />
                    </div>
                  ) : undefined
                ) : (
                  ""
                )}

                {this.props.user.trial ? (
                  this.props.user.trial.user_type === "Free" ? (
                    <div
                      className={
                        "text-center orange_text nav-item-ui-custom navbar-item-right-permium mr-3"
                      }
                    >
                      {/* Free<br/>
             <p>Upgrade to Premium</p> */}
                      {/* <a
                        href="/pricing"
                        className={"text-center"}
                        style={{ fontSize: "13px" }}
                      >
                        {" "}
                        Free
                      </a> */}
                      <a
                        href="/pricing"
                        className={"text-center"}
                        style={{ fontSize: "13px", marginTop: "50px" }}
                      >
                        {" "}
                        Upgrade <br />{" "}
                        <span style={{ fontSize: "13px", marginLeft: "15px" }}>
                          to Premium
                        </span>
                      </a>
                      {/* <br /> */}
                    </div>
                  ) : this.props.user.trial.user_type === "Trial" ? (
                    <div
                      className={
                        "text-center orange_text nav-item-ui-custom navbar-item-right-permium mr-3"
                      }
                    >
                      <a
                        href="/pricing"
                        className={"text-center"}
                        style={{ fontSize: "13px" }}
                      >
                        {" "}
                        Trial
                      </a>
                      <br />
                      <a
                        href="/pricing"
                        className={"text-center"}
                        style={{ fontSize: "13px" }}
                      >
                        {" "}
                        Upgrade to Premium
                      </a>
                    </div>
                  ) : this.props.user.trial.user_type === "Premium" ? (
                    <NavItem
                      className={
                        "nav-item-ui-custom navbar-item-right-permium mr-3"
                      }
                    >
                      <NavLink
                        className={
                          "py-2 d-block px-1 px-md-4 font-size-13 cursor-p"
                        }
                      >
                        Premium
                      </NavLink>
                    </NavItem>
                  ) : (
                    <span className={"nav-logo-avatar d-flex mr-3"}>
                      {/* <div
                        style={{ backgroundImage: "url(" + logoUrl + ")" }}
                        className={"logo-img"}
                      ></div> */}
                      <img
                        src={logoUrl}
                        width={120}
                        height={47}
                        style={{
                          borderRadius: "10px",
                        }}
                      />
                      {/* <div className={"avatar-green-spot"}></div> */}
                    </span>
                  )
                ) : (
                  //   <span className={"nav-profile-avatar d-flex"}>
                  //   <div
                  //     style={{ backgroundImage: "url(" + profileUrl + ")" }}
                  //     className={"avater-img"}
                  //   ></div>
                  //   <div className={"avatar-green-spot"}></div>
                  // </span>
                  "asd"
                )}

                <Dropdown
                  open={this.state.dropdownOpenWithText}
                  toggle={this.toggleDropdownWithText}
                  className={"mr-2 dropdown-menu-right"}
                >
                  <DropdownToggle
                    nav
                    className={"text-white m-0 p-0 font-size-11"}
                  >
                    <span className={"nav-profile-avatar d-flex"}>
                      <div
                        style={{ backgroundImage: "url(" + profileUrl + ")" }}
                        className={"avater-img"}
                      ></div>
                      <div className={"avatar-green-spot"}></div>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu right className={"dropdown-menu-ui-custom"}>
                    <NavLinkRouter
                      to={`/people/users/${this.props.sessionID}`}
                      className={"profile-item-"}
                      activeClassName={"active"}
                    >
                      <DropdownItem>
                        <IoMdContact
                          className={"mr-2 font-size-18"}
                          style={{ verticalAlign: "-3px" }}
                        />
                        Profile
                      </DropdownItem>
                    </NavLinkRouter>
                    <NavLinkRouter
                      to={"/setting"}
                      className={"profile-item-"}
                      activeClassName={"active"}
                    >
                      <DropdownItem>
                        <IoIosSettings
                          className={"mr-2 font-size-18"}
                          style={{ verticalAlign: "-3px" }}
                        />
                        Setting
                      </DropdownItem>
                    </NavLinkRouter>
                    <NavLink
                      onClick={this.toggleAboutModal}
                      className={"profile-item- p-0 m-0"}
                    >
                      <DropdownItem className={"profile-item-"}>
                        <IoIosInformationCircleOutline
                          className={"mr-2 font-size-18"}
                          style={{ verticalAlign: "-3px" }}
                        />
                        About MeriTa
                      </DropdownItem>
                    </NavLink>
                    <NavLink
                      onClick={this.toggleFeedbackModal}
                      className={"profile-item- p-0 m-0"}
                    >
                      <DropdownItem>
                        <IoIosCreate
                          className={"mr-2 profile-item- font-size-18"}
                          style={{ verticalAlign: "-3px" }}
                        />
                        Feedback to MeriTa
                      </DropdownItem>
                    </NavLink>
                    <DropdownItem onClick={this.logout}>
                      <IoIosLogOut
                        className={"mr-2 font-size-18"}
                        style={{ verticalAlign: "-3px" }}
                      />
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Nav>
            </Navbar>
          </>
        ) : undefined}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("na user ", state.session.data);
  return {
    sessionID: state.session.data.username,
    user: state.session.data,
    pic: state.session.data.pic,
    notifications: state.session.notifications,
  };
};

export default withRouter(connect(mapStateToProps, null)(NavbarUI));
