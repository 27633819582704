import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import { API_URL } from "../../constants";
import { authHeader } from "../../actions/AuthHeader";
import useAxios from "@use-hooks/axios";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FaRegEdit, FaCalendar } from "react-icons/fa";
import Modal from "react-responsive-modal";
import ReactQuill from "react-quill";
import toast from "toasted-notes";
import AutoCompleteAttribute from "../../layout/UI/AutoCompleteAttribute";
import isEmail from "validator/lib/isEmail";
import AutoComplete from "../../layout/UI/AutoComplete";

import {
  IoIosClose,
  IoIosPerson,
  IoIosArrowDroprightCircle,
  IoIosArrowDropleftCircle,
  IoMdAttach,
  IoMdCloseCircle,
  IoMdCheckmarkCircle,
} from "react-icons/io";
import {
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  FormInput,
  FormTextarea,
  Button,
  Form,
  FormGroup,
  Badge,
  FormSelect,
} from "shards-react";
import Dropzone from "react-dropzone";

const AdminPollCopy = (props) => {
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };
  const [pollName, setPollName] = useState("");
  const [pollGoal, setPollGoal] = useState("");
  const [pollProblem, setPollProblem] = useState("");
  const [postData, setPostData] = useState({});
  const [alternatives, setAlternatives] = useState([]);
  const [openAlterModal, setOpenAlterModal] = useState(false);
  const [alterTempText, setAlterTempText] = useState("");
  const [text, setText] = useState("");

  const [attributes, setAttribtes] = useState([]);
  const [openAttrModal, setOpenAttrModal] = useState(false);
  const [attrTempText, setAttrTempText] = useState("");
  const [userAttr, setUserAttr] = useState([]);

  const [participants, setParticipants] = useState([]);
  const [participantsInfo, setParticipantsInfo] = useState([]);
  const [openPartModal, setOpenPartModal] = useState(false);
  const [partiTempText, setPartiTempText] = useState("");

  const [attachments, setAttachments] = useState([]);
  const [openAttachModal, setOpenAttachModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [owner, setOwner] = useState([]);
  const [AdminUser, setAdminUser] = useState("");
  const [userFollow, setUserFollow] = useState([]);
  const [PollType, setPollType] = useState("dr");
  const [ newPollId,setNewPollId] = useState()

  useEffect(() => {
    console.log("pros", props);
    setOwner([...owner, props.owner]);
  }, []);
  const getUserAttr = useAxios({
    url: `${API_URL}/users/${props.sessionID}/get_user_favorite_attr/`,
    options: {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    },
    trigger: [],
    customHandler: (err, res) => {
      if (res) {
        console.log("data", res.data);
        setUserAttr(res.data);
      }
    },
  });

  const getUserFollow = useAxios({
    url: `${API_URL}/users/${props.sessionID}/followings/`,
    options: {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    },
    trigger: [],
    customHandler: (err, res) => {
      if (res) {
        console.log("user data", res.data);
        setUserFollow(res.data);
      }
    },
  });

  const createPollCopy = useAxios({
    url: `${API_URL}/pollCopy/`,
    method: "POST",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: postData,
    },
    // trigger: postData,

    // forceDispatchEffect: () => !!postData,
    customHandler:async (err, res) => {
      if (res) {
        // call api for file attach
        console.log("res create",res.data)
        showSuccessMessage("data Saved");
        await setNewPollId(res.data.id)
        setPollName("");
        setPollGoal("");
        setPollProblem("");
        setAttribtes([]);
        setAlternatives([]);
        setParticipants([]);
        setParticipantsInfo([]);
        setFiles([]);
        setAdminUser("");
        setPollType("");
        // if (fileData.length>0)
        createPollCopyFiles.reFetch()
      } else {
        console.log(err);
      }
    },
  });

  const createPollCopyFiles = useAxios({
    url: `${API_URL}/pollCopy/${newPollId}/set_attachments/`,
    method: "POST",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: fileData,
    },
    // trigger: postData,

    // forceDispatchEffect: () => !!postData,
    customHandler: (err, res) => {
      if (res) {
        // call api for file attach
        console.log('here',res)
        props.history.push('/setting/pollCopy/')
      } else {
        console.log(err);
      }
    },
  });

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    console.log("here");
    createPollCopy.reFetch();
    // setLoading(true);
  }, [postData]);

  const showErrorMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 4000,
      }
    );

  const showSuccessMessage = (text, type) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 4000,
      }
    );

  const toggleAlternative = () => {
    setOpenAlterModal((before) => !before);
  };

  const removeAlternative = (alterName) => {
    let newAlternative = alternatives.filter((item) => {
      return item["name"] !== alterName;
    });
    setAlternatives(newAlternative);
  };

  const handleQuilChange = (value) => {
    setText(value);
  };

  const addAlterData = () => {
    if (alterTempText === "") {
      showErrorMessage("Alternative Name should not be empty");
      return null;
    }
    let alterExist = alternatives.filter((item) => item.name === alterTempText);
    if (alterExist.length > 0) {
      showErrorMessage("The Alternative Already added!");
      return null;
    }

    setAlternatives((before) => [
      ...before,
      {
        name: alterTempText,
        description: text,
      },
    ]);
    setAlterTempText("");
    setText("");
    setOpenAlterModal((before) => !before);
  };

  const handleKeyAlternatives = (e) => {
    if (e.keyCode === 13) {
      return addAlterData();
    }
    return null;
  };

  const _renderAlternativeModal = () => {
    return (
      <Modal
        open={openAlterModal}
        animationDuration={100}
        onClose={toggleAlternative}
        center
      >
        <div className={"alternatives-modal"}>
          <h3>Add Alternative</h3>
          <hr />
          <div onKeyDown={handleKeyAlternatives}>
            <Form onSubmit={(e) => e.preventDefault()}>
              <FormGroup>
                <label htmlFor="#AlternativeName">
                  Alternative Name{" "}
                  <span style={{ color: "red", fontSize: 12 }}>
                    maximum 120 character{" "}
                    {alterTempText.length > 0
                      ? "(now " + alterTempText.length + ")"
                      : ""}
                  </span>
                </label>
                <FormInput
                  id="#AlternativeName"
                  onChange={(e) => {
                    if (
                      alterTempText.length < 120 ||
                      e.target.value.length < 120
                    )
                      setAlterTempText(e.target.value);
                  }}
                  placeholder="Alternative Name"
                  value={alterTempText}
                />
              </FormGroup>
            </Form>
          </div>
          <p>Detailed Alternative description :</p>
          <ReactQuill
            value={text}
            modules={modules}
            formats={formats}
            // onKeyDown={this.handleKeyAlternatives}
            onChange={handleQuilChange}
          />
          <hr />
          <div>
            <Button theme={"primary"} onClick={addAlterData}>
              OK
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const _renderAlternative = () => {
    let items = alternatives.map((item, index) => {
      return (
        <div
          className={
            "select-attribute-item my-2 d-flex justify-content-between"
          }
          key={index}
        >
          <p
            className={"m-0 p-0 d-flex align-items-center"}
            style={{ fontWeight: "300", color: "black" }}
          >
            {item["name"].length > 30
              ? item["name"].substring(0, 30) + "..."
              : item["name"]}
          </p>
          <Button
            outline
            pill
            theme="warning"
            size={"sm"}
            onClick={() => removeAlternative(item["name"])}
            className={"btn btn-success-custom p-0 m-0"}
          >
            <IoIosClose className={"font-size-22"} />
          </Button>
        </div>
      );
    });
    return items;
  };

  //   ? ATTRIBUTES

  const onChangeAttribute = (event, { newValue }) => {
    if (newValue.length <= 20) setAttrTempText(newValue);
  };

  const toggleAttr = () => {
    setOpenAttrModal((before) => !before);
  };

  const handleKeyAttribute = (e) => {
    if (e.keyCode === 13) {
      return addDataAttr();
    }
    return null;
  };

  const _renderAttributeModal = () => {
    return (
      <Modal
        animationDuration={100}
        open={openAttrModal}
        onClose={toggleAttr}
        center
      >
        <div className={"modal-responsive"}>
          <h3>Add Attribute</h3>
          <hr />
          <div onKeyDown={handleKeyAttribute}>
            <Form onSubmit={(e) => e.preventDefault()}>
              <FormGroup>
                <label htmlFor="#AttributeName">
                  Attribute Name{" "}
                  <span style={{ color: "red", fontSize: 12 }}>
                    maximum 20 character{" "}
                    {attrTempText.length > 0
                      ? "(now " + attrTempText.length + ")"
                      : ""}
                  </span>
                </label>
                <AutoCompleteAttribute
                  inputProps={{
                    placeholder: "Attributes",
                    value: attrTempText,
                    onChange: onChangeAttribute,
                  }}
                  data={userAttr}
                />
              </FormGroup>
            </Form>
          </div>
          <hr />
          <div>
            <Button theme={"primary"} onClick={addDataAttr}>
              OK
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const removeAttribute = (attrName) => {
    let newAttribute = attributes.filter((item) => {
      return item !== attrName;
    });
    setAttribtes(newAttribute);
  };

  const _renderAttributes = () => {
    let items = attributes.map((item, index) => {
      return (
        <Badge className={"select-attribute-tag my-1"} pill key={index}>
          <span className={"ml-2 my-3"}>{item}</span>
          <Button
            pill
            theme="light"
            size={"sm"}
            onClick={() => removeAttribute(item)}
            className={"p-0 m-0 ml-2"}
          >
            <IoIosClose className={"font-size-19 text-orange"} />
          </Button>
        </Badge>
      );
    });
    return items;
  };

  const addDataAttr = () => {
    if (attrTempText === "") {
      showErrorMessage("The filed should not be empty");
      return null;
    }
    if (attributes.indexOf(attrTempText) !== -1) {
      showErrorMessage("The Attribute Already added!");
      return null;
    }
    setAttribtes([...attributes, attrTempText]);
    setOpenAttrModal((before) => !before);
    setAttrTempText("");
  };

  //? PARTICIPANTS

  const toggleParticipant = () => {
    setOpenPartModal((before) => !before);
  };

  const addDataParticipant = async () => {
    var followings_email = getUserFollow.response?.data.filter(
      (item) => item.email
    );
    // console.log("here", this.props);
    // if (this.state.followings_email.length == 0) {
    //   this.props.userFollowings["data"].map((item, index) => {
    //     followings_email.push(item.email);
    //   });
    //   this.setState({
    //     followings_email: followings_email,
    //   });
    //   console.log("email", followings_email);
    // } else {
    //   followings_email = this.state.followings_email;
    // }

    // done here
    console.log(
      "parti",
      partiTempText,
      props.userFollowings["data"],
      followings_email
    );
    if (
      followings_email.filter((item) => item.email === partiTempText).length < 0
    ) {
      showErrorMessage(
        "You're not connected to this person ,Go to pepole page and invite them."
      );
      return null;
    }
    if (partiTempText === "") {
      showErrorMessage("The filed should not be empty");
      return null;
    }

    if (!isEmail(partiTempText)) {
      // console.log("here");
      showErrorMessage("please enter valid email address ");
      return null;
    }

    if (participants.indexOf(partiTempText) !== -1) {
      showErrorMessage("The user Already added!");
      return null;
    }
    let info = participantsInfo;

    userFollow.filter((item, index) => {
      if (item.email === partiTempText) {
        info.push({
          first_name: item.first_name,
          last_name: item.last_name,
          pic: item.pic,
          email: item.email,
          username: item.username,
        });
      }
    });
    console.log("info", info);

    setParticipants([...participants, partiTempText]);
    setOpenPartModal((before) => !before);
    setPartiTempText("");
    setParticipantsInfo(info);
  };

  const onChangeParticipant = (event, { newValue }) => {
    setPartiTempText(newValue);
  };

  const handleKeyParticipant = (e) => {
    if (e.keyCode === 13) {
      return addDataParticipant();
    }
    return null;
  };

  const removeParticipant = (text) => {
    let new_participant_info = participantsInfo.filter((item) => {
      return item.email !== text;
    });
    let newParticipant = participants.filter((item) => {
      return item !== text;
    });
    setParticipants(newParticipant);
    setParticipantsInfo(new_participant_info);
  };

  const _renderParticipant = () => {
    let items = participantsInfo.map((item, index) => {
      return (
        <div
          className={
            "select-attribute-item my-2 d-flex justify-content-between"
          }
          key={index}
        >
          <p
            className={"m-0 p-0 d-flex align-items-center"}
            style={{ fontWeight: "300" }}
          >
            {/* <IoIosPerson className={"mr-2 text-orange font-size-20"} /> */}
            <span
              className={
                "nav-profile-avatar d-flex align-items-center text-orange"
              }
              style={{ fontWeight: "Normal" }}
            >
              <div
                style={{
                  backgroundImage: `url(${refactorProfileUrl(item.pic)})`,
                }}
                className={"avater-img mr-1"}
              ></div>
            </span>
            {item.first_name} {item.last_name}
          </p>
          <Button
            outline
            pill
            theme="warning"
            size={"sm"}
            onClick={() => removeParticipant(item.email)}
            className={"btn btn-success-custom p-0 m-0"}
          >
            <IoIosClose className={"font-size-22"} />
          </Button>
        </div>
      );
    });
    return items;
  };

  const _renderParticipantModal = () => {
    return (
      <Modal
        open={openPartModal}
        animationDuration={100}
        onClose={toggleParticipant}
        center
      >
        <div className={"modal-responsive"}>
          <h3>Add Participant</h3>
          <hr />
          <div onKeyDown={handleKeyParticipant}>
            <Form onSubmit={(e) => e.preventDefault()}>
              <FormGroup>
                <label htmlFor="#ParticipantName">Participant Name</label>
                <AutoComplete
                  inputProps={{
                    placeholder: "Participant",
                    value: partiTempText,
                    onChange: onChangeParticipant,
                  }}
                  data={getUserFollow.response?.data}
                />
              </FormGroup>
            </Form>
            <span style={{ color: "red" }}>
              Start typing name/username of participants.
            </span>
            <br />
            <span style={{ color: "red" }}>
              You should first add them to your "People" page.
            </span>
          </div>
          <hr />
          <div>
            <Button theme={"primary"} onClick={addDataParticipant}>
              OK
            </Button>
          </div>
        </div>
      </Modal>
    );
    // }
  };

  const _renderOwnerParticipant = () => {
    if (owner) {
      let items = owner.map((item, index) => {
        return (
          <div
            className={
              "select-attribute-item my-2 d-flex justify-content-between"
            }
            key={index}
          >
            <p
              className={"m-0 p-0 d-flex align-items-center"}
              style={{ fontWeight: "300" }}
            >
              {/* <IoIosPerson className={"mr-2 text-orange font-size-20"} /> */}
              <span
                className={
                  "nav-profile-avatar d-flex align-items-center text-orange"
                }
                style={{ fontWeight: "Normal" }}
              >
                <div
                  style={{
                    backgroundImage: `url(${refactorProfileUrl(item.pic)})`,
                  }}
                  className={"avater-img mr-1"}
                ></div>
              </span>
              {item.first_name} {item.last_name}
              {/* <span className={"ml-2"} style={{color:"red"}}>(Not possible to remove)</span> */}
            </p>
          </div>
        );
      });
      return items;
    }
  };

  const refactorProfileUrl = (url) => {
    // console.log("url", url);
    if (url && String(url).substring(0, 4) === "http") {
      return url;
    } else {
      // console.log("url", url);
      return `${API_URL}/uploads/${url}`;
    }
  };

  //? ATTACHMENTS

  const toggleAttachment = () => {
    setOpenAttachModal((before) => !before);
  };

  const addDataAttachment = () => {
    setOpenAttachModal((before) => !before);
  };

  const onDrop = (acceptedFiles) => {
    console.log("files", acceptedFiles);
  };

  const _renderAttachmentModal = () => {
    // const file = files.map((file) => (
    //   <li key={file.name}>
    //     {file.name} - {file.size} bytes
    //   </li>
    // ));
    return (
      <Modal
        open={openAttachModal}
        animationDuration={100}
        onClose={toggleAttachment}
        center
      >
        <div className={"modal-responsive"}>
          <h3>
            Add Attachment{" "}
            <span className={"ml-2"} style={{ color: "red", fontSize: "15px" }}>
              {" "}
              (Max:2 , Each up to 2MB)
            </span>
          </h3>
          <hr />
          <div>
            <Dropzone
              onDrop={(acceptedFiles) => {
                if (acceptedFiles.length === 0) {
                  return;
                } else if (files.length > 1) {
                  // here i am checking on the number of files
                  return showErrorMessage("upload maximum two file "); // here i am using react toaster to get some notifications. don't need to use it
                } else {
                  setFiles([...files, acceptedFiles]);
                }
              }}
              minSize={0}
              maxSize={2097152}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
                rejectedFiles,
              }) => {
                // console.log(getInputProps)
                const isFileTooLarge =
                  rejectedFiles.length > 0 && rejectedFiles[0].size > 2097152;
                return (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {!isDragActive && "Click here or drop a file to upload!"}
                    {isDragActive && !isDragReject && "Drop it like it's hot!"}
                    {isDragReject && "File type not accepted, sorry!"}
                    {isFileTooLarge && (
                      <div className="text-danger mt-2">File is too large.</div>
                    )}
                  </div>
                );
              }}
            </Dropzone>
          </div>
          <hr />
          <div>
            <Button theme={"primary"} onClick={addDataAttachment}>
              OK
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const removeAttachment = (text) => {
    let newAttachment = files.filter((item) => {
      return item[0]["name"] !== text;
    });
    setFiles(newAttachment);
  };

  const _renderAttachments = () => {
    let items = files.map((item, index) => {
      // console.log("name", item);
      return (
        <div
          className={
            "select-attribute-item my-2 d-flex justify-content-between"
          }
          key={index}
        >
          <p
            className={"m-0 p-0 d-flex align-items-center"}
            style={{ fontWeight: "300" }}
          >
            <IoMdAttach className={"mr-2 text-orange font-size-20"} />
            {item[0]["name"]}
          </p>
          <Button
            outline
            pill
            theme="warning"
            size={"sm"}
            onClick={() => removeAttachment(item[0]["name"])}
            className={"btn btn-success-custom p-0 m-0"}
          >
            <IoIosClose className={"font-size-22"} />
          </Button>
        </div>
      );
    });
    return items;
  };

  const onAddAdminUser = (event, { newValue }) => {
    setAdminUser(newValue);
  };

  const submitData = () => {
    console.log(attributes, alternatives, pollName, pollGoal , participants);

    let formData = new FormData();
    // formData.set("poll", "test");
    // formData.append("problem_opportunity", pollGoal);
    // formData.append("problem_objective", pollProblem);
    // formData.append("created_by", AdminUser);
    // formData.append("state", PollType);
    // formData.append("name", pollName);
    // formData.append("attributes", attributes);
    // formData.append("alternatives", alternatives);
    // formData.append("participants", participantsInfo);

    let decisionData = {
      state:PollType,
      problem_opportunity: pollGoal,
      name: pollName,
      problem_objective: pollProblem,
      participants: participants,
      attributes: attributes,
      alternatives: alternatives,
      created_by: AdminUser.length > 0 ? AdminUser : "NotSet",
    };
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      console.log(element);
      formData.append("attachment", element[0],element[0].name);
    }
    setFileData(formData);
    setPostData(decisionData);
  };

  if (props.owner === undefined) {
    return "asd";
  }
  return (
    <Fragment>
      {_renderAlternativeModal()}
      {_renderAttributeModal()}
      {_renderParticipantModal()}
      {files && _renderAttachmentModal()}
      <div
        style={{ flexGrow: "1" }}
        //   onKeyDown={this.handleEnterKey}
        className={"mb-3"}
      >
        <div className={"form-ui-header p-3"}>
          <h3 className={"m-0 p-0"}>Write Your Decision Information</h3>
        </div>
        <div className={"form-ui p-3"}>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className={"row"}>
              <div className={"col-12 col-md-6 my-1"}>
                <label className={"form-ui-label font-size-15 fw-600"}>
                  Decision Name{" "}
                  <span style={{ color: "red", fontSize: 12 }}>
                    maximum 60 character{" "}
                    {pollName.length > 0 ? "(now " + pollName.length + ")" : ""}
                  </span>
                </label>
                <InputGroup seamless>
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <FaRegEdit />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    onChange={(e) => {
                      if (pollName.length < 60 || e.target.value.length < 60)
                        setPollName(e.target.value);
                    }}
                    value={pollName}
                  />
                </InputGroup>
              </div>
            </div>
          </form>
        </div>

        <hr className={"m-0"} />
        <div className={"form-ui px-3 pb-3"}>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className={"row my-3"}>
              <div className="col">
                <label className={"form-ui-label font-size-15 fw-600"}>
                  Problem/Opportunity{" "}
                  <span style={{ color: "red", fontSize: 12 }}>
                    maximum 400 character{" "}
                    {pollProblem.length > 0
                      ? "(now " + pollProblem.length + ")"
                      : ""}
                  </span>
                </label>
                <FormTextarea
                  onChange={(e) => {
                    if (pollProblem.length < 400 || e.target.value.length < 400)
                      setPollProblem(e.target.value);
                  }}
                  // invalid={this.state.invalidProblemDetails}
                  // onChange={e => {
                  //   if (this.state.problemDetails.length < 400 ||e.target.value.length<400)
                  //     this.setState({
                  //       problemDetails: e.target.value,
                  //       invalidProblemDetails: false
                  //     });
                  // }}
                  value={pollProblem}
                  // onFocus={e =>
                  //   this.onFocusInput(e, this.elementOpportunityDetails)
                  // }
                  // onBlur={e =>
                  //   this.onBlurInput(e, this.elementOpportunityDetails)
                  // }
                />
              </div>
            </div>
          </form>
        </div>
        <hr className={"m-0"} />
        <div className={"form-ui px-3 pb-3 d-flex flex-column"}>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className={"row my-3"}>
              <div className="col">
                <label className={"form-ui-label font-size-15 fw-600"}>
                  Decision Goal/Objectives{" "}
                  <span style={{ color: "red", fontSize: 12 }}>
                    maximum 60 character{" "}
                    {pollGoal.length > 0 ? "(now " + pollGoal.length + ")" : ""}
                  </span>
                </label>
                <FormTextarea
                  onChange={(e) => {
                    if (pollGoal.length < 60 || e.target.value.length < 60)
                    setPollGoal(e.target.value);
                  }}
                  value={pollGoal}
                />
              </div>
            </div>
            <hr className={"m-0"} />

            <div className={"select-attribute-wrapper mb-3"}>
              <div className={"select-attribute-body"}>
                {alternatives.length > 0 ? null : (
                  <div className={"d-flex justify-content-center"}>
                    <p style={{ fontWeight: 100 }} className={"my-4"}>
                      Add your Alternative
                    </p>
                  </div>
                )}
                {_renderAlternative()}
              </div>
              <div
                className={
                  "select-attribute-footer mt-2 d-flex justify-content-end"
                }
              >
                <Button theme="primary" onClick={toggleAlternative}>
                  Add
                </Button>
              </div>
            </div>
            <hr className={"m-0"} />

            <div className={" mb-4 select-attribute-wrapper"}>
              <div className={"select-attribute-body"}>
                {attributes.length > 0 ? null : (
                  <div className={"d-flex justify-content-center"}>
                    <p style={{ fontWeight: 100 }} className={"my-4"}>
                      Add your Attributes
                    </p>
                  </div>
                )}
                <div className={"d-flex flex-column align-items-start"}>
                  {_renderAttributes()}
                </div>
              </div>
              <div
                className={
                  "select-attribute-footer mt-2 d-flex justify-content-end"
                }
              >
                <Button theme="primary" onClick={toggleAttr}>
                  Add
                </Button>
              </div>
            </div>
            <hr className={"mb-4"} />

            <div className={"select-attribute-wrapper"}>
              <div className={"select-attribute-body"}>
                {participants.length > 0 ? null : (
                  <div className={"d-flex justify-content-center"}>
                    <p style={{ fontWeight: 100 }} className={"my-4"}>
                      Add your Participants - First you should add users to
                      People page
                    </p>
                  </div>
                )}
                {_renderParticipant()}
                {/* {_renderOwnerParticipant()} */}
              </div>
              <div
                className={
                  "select-attribute-footer mt-2 d-flex justify-content-end"
                }
              >
                <Button theme="primary" onClick={toggleParticipant}>
                  Add
                </Button>
              </div>
            </div>
            <hr className={"mb-4"} />

            <div className={"select-attribute-wrapper"}>
              <div className={"select-attribute-body"}>
                {files.length > 0 ? null : (
                  <div className={"d-flex justify-content-center"}>
                    <p style={{ fontWeight: 100 }} className={"my-4"}>
                      Add your Attachments
                    </p>
                  </div>
                )}
                {_renderAttachments()}
              </div>
              <div
                className={
                  "select-attribute-footer mt-2 d-flex justify-content-end"
                }
              >
                <Button theme="primary" onClick={toggleAttachment}>
                  Add
                </Button>
              </div>
            </div>
            <hr className={"mb-4"} />
            <div className={"row mb-3"}>
              <div className={"col-12 col-md-6 my-1"}>
                <label className={"form-ui-label font-size-13 fw-600"}>
                  admin user
                  
                </label>
                <InputGroup seamless>
                  <AutoComplete
                    inputProps={{
                      placeholder: "",
                      value: AdminUser,
                      onChange: onAddAdminUser,
                    }}
                    data={participantsInfo}
                  />
                </InputGroup>
                <h5 className={`font-size-13`}>leave it empty for set new user as admin</h5>
              </div>
              <div className={"col-12 col-md-6 my-1"}>
                <label className={"form-ui-label font-size-13 fw-600"}>
                  poll type
                </label>
                <InputGroup seamless>
                  <FormSelect
                    onChange={(e) => setPollType(e.target.value)}
                    value={PollType}
                  >
                    <option value="" disabled selected>
                      Select your Type
                    </option>
                    <option value="dr">Draft</option>
                    <option value="cl">Closed</option>
                    <option value="fi">Decided</option>
                    <option value="vt">Voting</option>
                  </FormSelect>
                </InputGroup>
              </div>
            </div>
          </form>

          <button
            className="btn btn-success-custom align-self-end font-size-13 fw-600"
            onClick={submitData}
          >
            Proceed
            <IoIosArrowDroprightCircle className={"ml-2 font-size-19"} />
          </button>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  console.log("state", state);
  return {
    userFollowings: state.userFollowings,
    allAttributes: state.allAttributes,
    sessionID: state.session.data.username,
    selectedDecision: state.selectedDecision,
    owner: state.session.data,
  };
};
export default withRouter(connect(mapStateToProps, null)(AdminPollCopy));
