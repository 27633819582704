import React from "react";
import Modal from 'react-responsive-modal';


export default (props) => {
    return (
        <div>
            <Modal open={props.show}
                   animationDuration={100}
                   onClose={props.onCloseModal} center>
                <h4>About MeriTa</h4>
                <hr/>
                <div>
                    <p className={"text-black"}>
                        MeriTa is a Decision support system(DSS) to make decisions collaboratively based on each
                        person's strengths. MeriTa allows merit based decision making as opposed to autocracy or
                        democracy. Each person's votes has more validity in his/her areas of expertise. MeriTa
                        intelligently collects and combines these ratings to make decisions in a more meritocratic and
                        reliable manner.
                        <br/>
                        • Collaborative decision platform that uses a true expert system to solve problems
                        <br/>
                        • Allows managers to analyse problems and design the decision making framework for each specific
                        issue
                        <br/>
                        • Determine each person's strong skils and give more weight to his/her views in those areas
                        <br/>
                        • Implementing MeriTa in an organization will lead to aggregation of knowledge pools, helping HR
                        to understand workforce, knowledge gaps, learning objectives, Merti Matrix, and a lot more.
                        <br/>
                    </p>
                </div>
            </Modal>
        </div>
    )
};
