import React from 'react';
import {Route, Redirect, withRouter} from "react-router";

/**
 * a functional component which checks if user is authenticated.if user is authenticated render given component else redirect to "/login"
 */
const PrivateRoute = ({component: Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                localStorage.getItem('authToken') ? (
                    <Component {...props} />
                ) : (
                    <Redirect to='/login'/>
                )
            }
        />
    );
};

export default withRouter(PrivateRoute);