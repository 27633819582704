import * as Constants from "../constants/index";

const initialState = {
    data: [],
    isFetching: false,
    gotError: false,
    textError: false
};


export const allAttributes = (state = initialState, action) => {
    switch (action.type) {
        case (Constants.CREATE_ATTRIBUTE):
            return {
                ...state,
                data: {
                    ...state.data,
                    results: [...state.data.results, action.info]
                }
            };
        case (Constants.RECEIVE_ALL_ATTRIBUTES):
            return {
                ...state,
                data: action.data,
                isFetching: false,
                getError: false,
                textError: ""
            };
        case (Constants.REQUEST_ALL_ATTRIBUTES):
            return {
                ...state,
                isFetching: true,
                getError: false,
                textError: ""
            };
        case (Constants.FAILED_FETCH_ALL_ATTRIBUTES):
            return {
                ...state,
                isFetching: false,
                getError: true,
                textError: action.err
            };
        case (Constants.DELETE_ATTRIBUTE):
            let newResults = state.data.results.filter(item => action.attr !== item["name"]);
            return {
                ...state,
                data: {
                    ...state.data,
                    results: newResults
                }
            };
        default:
            return state
    }
};
