import React, {Component} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import VotingStep from "../components/Decisions/VotingStep";


/**
 * Voting class renders all steps and each step has many slider for voting on attribute, people and etc.
 */
class Voting extends Component {

    createMarkup = (text) => {
        return {__html: text};
    };

    render() {
        return (
            <div>
                <div className={"poll-wrapper"} style={{backgroundColor: "#fafafa"}}>
                    <div className={"poll-data-wrapper container"}>
                        <div className={"poll-data-body mx-3 mt-3 mx-md-5 m-md-5"}>
                            <div className={"row"}>
                                <div className={"col-12 col-md-3"}>
                                    <h6 className={"fw-600 font-size-13"}>
                                        Problem/Opportunity:
                                    </h6>
                                </div>
                                <div className={"col-12 col-md-9"}>
                                    <h6 className={"problem-details font-size-14"}>
                                        <div
                                            dangerouslySetInnerHTML={this.createMarkup(this.props.data["problem_opportunity"])}>

                                        </div>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className={"poll-data-body mx-3 mb-3 mx-md-5 mb-md-5"}>
                            <div className={"row"}>
                                <div className={"col-12 col-md-3"}>
                                    <h6 className={"fw-600 font-size-13"}>
                                        Decision goal/Objective:
                                    </h6>
                                </div>
                                <div className={"col-12 col-md-9"}>
                                    <h6 className={"problem-details font-size-14"}>
                                        <div
                                            dangerouslySetInnerHTML={this.createMarkup(this.props.data["problem_objective"])}>

                                        </div>
                                        {/*{this.props.data["problem_objective"]}*/}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <VotingStep/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {data, getError, isFetching, textError} = state.selectedDecision;
    return {
        data, getError, isFetching, textError
    }
};

export default withRouter(connect(mapStateToProps, null)(Voting))