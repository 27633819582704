import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import { API_URL } from "../constants";
import useAxios from "@use-hooks/axios";

import toast from "toasted-notes";

import { IoMdCloseCircle, IoMdCheckmarkCircle } from "react-icons/io";
import { useParams, useHistory } from "react-router";

const SocialConfirm = (props) => {
  const [pollName, setPollName] = useState("");
  const { token } = useParams();
  const history = useHistory()
  const getUserAttr = useAxios({
    url: `${API_URL}/check_token/`,
    method:"POST",
    options: {
      data: { key: token },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    },
    trigger: [],
    customHandler: (err, res) => {
      if (res) {
        console.log("data", res.data);
        localStorage.setItem("authToken", res.data.token);
        localStorage.setItem("user", res.data.user);
        // window.location.reload();
        history.push("/decision")

      }
    },
  });

  // const getUserFollow = useAxios({
  //   url: `${API_URL}/users/${props.sessionID}/followings/`,
  //   options: {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: authHeader(),
  //     },
  //   },
  //   trigger: [],
  //   customHandler: (err, res) => {
  //     if (res) {
  //       console.log("user data", res.data);
  //       setUserFollow(res.data);
  //     }
  //   },
  // });

  // const createPollCopy = useAxios({
  //   url: `${API_URL}/pollCopy/`,
  //   method: "POST",
  //   options: {
  //     headers: {
  //       Authorization: authHeader(),
  //     },
  //     data: postData,
  //   },
  //   // trigger: postData,

  //   // forceDispatchEffect: () => !!postData,
  //   customHandler: async (err, res) => {
  //     if (res) {
  //       // call api for file attach
  //       console.log("res create", res.data);
  //       showSuccessMessage("data Saved");
  //       await setNewPollId(res.data.id);
  //       setPollName("");
  //       setPollGoal("");
  //       setPollProblem("");
  //       setAttribtes([]);
  //       setAlternatives([]);
  //       setParticipants([]);
  //       setParticipantsInfo([]);
  //       setFiles([]);
  //       setAdminUser("");
  //       setPollType("");
  //       // if (fileData.length>0)
  //       createPollCopyFiles.reFetch();
  //     } else {
  //       console.log(err);
  //     }
  //   },
  // });

  // const createPollCopyFiles = useAxios({
  //   url: `${API_URL}/pollCopy/${newPollId}/set_attachments/`,
  //   method: "POST",
  //   options: {
  //     headers: {
  //       Authorization: authHeader(),
  //     },
  //     data: fileData,
  //   },
  //   // trigger: postData,

  //   // forceDispatchEffect: () => !!postData,
  //   customHandler: (err, res) => {
  //     if (res) {
  //       // call api for file attach
  //       console.log("here", res);
  //       props.history.push("/setting/pollCopy/");
  //     } else {
  //       console.log(err);
  //     }
  //   },
  // });

  const showErrorMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 4000,
      }
    );

  const showSuccessMessage = (text, type) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 4000,
      }
    );

  return <Fragment></Fragment>;
};

export default SocialConfirm;
