import React, {Component, Fragment} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {IoMdCheckmarkCircle, IoMdCloseCircle} from "react-icons/io";
import {Button, FormInput, InputGroup, InputGroupAddon, InputGroupText} from "shards-react";
import {FaRegEdit} from "react-icons/fa";
import {changePassword} from "../../actions/index";
import {css} from '@emotion/core';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {PulseLoader} from 'react-spinners';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
/**
 * Password class is used to change current user password.
 */
class Password extends Component {

    constructor(props) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            newPasswordRepeat: "",
            isLoading: false
        }
    }


    showErrorMessage = (text) => toast.notify(
        <p className={"m-0 p-0 font-size-14"}>
            <span className={"mr-2 font-size-19"} style={{verticalAlign: "1px"}}>
                <IoMdCloseCircle style={{color: "#f5222d"}}/>
            </span>
            {text}
        </p>
        , {
            duration: 2000
        });

    showSuccessMessage = (text) => toast.notify(
        <p className={"m-0 p-0 font-size-14"}>
            <span className={"mr-2 font-size-19"} style={{verticalAlign: "1px"}}>
                    <IoMdCheckmarkCircle style={{color: "#52c41a"}}/>
            </span>
            {text}
        </p>
        , {
            duration: 2000
        });


    onFocusInput = (e, labelElement) => {
        labelElement.style.color = "#008AFF"
    };

    onBlurInput = (e, labelElement) => {
        labelElement.style.color = "#5a6169"
    };

    showRelatedError = (data) => {
        return Object.keys(data).map(item => {
            return data[item].map(subItem => {
                return this.showErrorMessage(subItem)
            })
        });
    };

    handleEnterKey = (e) => {
        if (e.keyCode === 13) {
            return this.onClickChangePassword()
        }
    };

    onClickChangePassword = async () => {
        this.setState({
            isLoading: true
        });
        let data = {
            "old_password": this.state.oldPassword,
            "new_password2": this.state.newPasswordRepeat,
            "new_password1": this.state.newPassword
        };
        await this.props.changePassword(data)
            .then(res => {
                if (res.status === 400) {
                    this.setState({
                        gotError: true
                    });
                    res.json().then(data => {
                        return this.showRelatedError(data);
                    });
                } else if (res.status === 200) {
                    this.setState({
                        gotError: false
                    });
                    res.json().then(data => {
                        this.showSuccessMessage("Your password is changed successfully");
                    });
                }
            });
        this.setState({
            isLoading: false
        });
    };

    render() {
        return (
            <Fragment>
                <div className={"p-3"}
                     style={{
                         backgroundColor: "white",
                         marginTop: "70px",
                         paddingBottom: "50px",
                         border: "1px solid rgba(0, 0, 0, 0.1)"
                     }}
                     onKeyDown={this.handleEnterKey}
                >
                    <form>
                        <h3>
                            Password
                        </h3>
                        <hr/>
                        <div className={"row mb-3"}>
                            <div className={"col-12 col-md-6 my-1"}>
                                <label className={"form-ui-label font-size-13 fw-600"}
                                       ref={(element) => this.elementOldPassword = element}>
                                    Old Password
                                </label>
                                <InputGroup seamless>
                                    <InputGroupAddon type="prepend">
                                        <InputGroupText>
                                            <FaRegEdit/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <FormInput
                                        type={"password"}
                                        invalid={this.state.invalidOldPassword}
                                        onChange={(e) => this.setState({
                                            oldPassword: e.target.value,
                                            invalidOldPassword: false
                                        })}
                                        value={this.state.oldPassword}
                                        onFocus={(e) => this.onFocusInput(e, this.elementOldPassword)}
                                        onBlur={(e) => this.onBlurInput(e, this.elementOldPassword)}/>
                                </InputGroup>
                            </div>
                        </div>
                        <div className={"row mb-3"}>
                            <div className={"col-12 col-md-6 my-1"}>
                                <label className={"form-ui-label font-size-13 fw-600"}
                                       ref={(element) => this.elementNewPassword = element}>
                                    New Password
                                </label>
                                <InputGroup seamless>
                                    <InputGroupAddon type="prepend">
                                        <InputGroupText>
                                            <FaRegEdit/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <FormInput
                                        type={"password"}
                                        invalid={this.state.invalidNewPassword}
                                        onChange={(e) => this.setState({
                                            newPassword: e.target.value,
                                            invalidNewPassword: false
                                        })}
                                        value={this.state.newPassword}
                                        onFocus={(e) => this.onFocusInput(e, this.elementNewPassword)}
                                        onBlur={(e) => this.onBlurInput(e, this.elementNewPassword)}/>
                                </InputGroup>
                            </div>
                        </div>
                        <div className={"row mb-3"}>
                            <div className={"col-12 col-md-6 my-1"}>
                                <label className={"form-ui-label font-size-13 fw-600"}
                                       ref={(element) => this.elementNewPasswordRepeat = element}>
                                    New Password Repeat
                                </label>
                                <InputGroup seamless>
                                    <InputGroupAddon type="prepend">
                                        <InputGroupText>
                                            <FaRegEdit/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <FormInput
                                        type={"password"}
                                        invalid={this.state.invalidNewPasswordRepeat}
                                        onChange={(e) => this.setState({
                                            newPasswordRepeat: e.target.value,
                                            invalidNewPasswordRepeat: false
                                        })}
                                        value={this.state.newPasswordRepeat}
                                        onFocus={(e) => this.onFocusInput(e, this.elementNewPasswordRepeat)}
                                        onBlur={(e) => this.onBlurInput(e, this.elementNewPasswordRepeat)}/>
                                </InputGroup>
                            </div>
                        </div>
                    </form>
                    <Button theme={"primary"}
                            onClick={this.onClickChangePassword}>
                        {this.state.isLoading ? (
                            <div className='sweet-loading'>
                                <PulseLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    color={"white"}
                                    size={7}
                                    loading={true}
                                />
                            </div>) : "Save"}
                    </Button>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(connect(null, {changePassword})(Password))