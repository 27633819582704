import React, { Component } from "react";
import {
  FaAt,
  FaEnvelope,
  FaLock,
  FaUser,
  FaUniversity,
  FaUserTie,
} from "react-icons/fa";
import "../App.css";
import ReactGA from "react-ga";

import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/core";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { FormRadio, FormSelect, FormCheckbox } from "shards-react";
import ForgetPassword from "../components/Sign/ForgetPasswordModal";
import { API_URL } from "../constants";
import { Link } from "react-router-dom";
import { FaGoogle, FaLinkedin } from "react-icons/fa";

import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

/**
 * SignForm class renders login and sign up forms.
 */
class SignForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      className: "header_area",
      showModal: false,
      activeTab: true,
      loginUserName: "",
      loginPassword: "",
      loginEmail: "",
      gotError: false,
      isLoading: false,
      isLoggedIn: false,
      signUpUserName: "",
      signUpFirstName: "",
      signUpLastName: "",
      signUpRepeatPassword: "",
      signUpEmail: "",
      signUpPassword: "",
      isLoadingToSignUp: false,
      isActive: false,
      selectedSex: "Other",
      company: "",
      industry: "",
      country: "",
      selected: true,
    };
  }

  componentDidMount() {
    // Service()
    // const script = document.createElement("script");
    // script.async = true;
    // script.src = "https://www.googletagmanager.com/gtag/js?id=AW-671129096";
    // // script.onload = () => this.scriptLoaded();
    // document.head.appendChild(script);

    if (localStorage.getItem("authToken")) {
      let username = localStorage.getItem("user");
      this.setState({
        isLoggedIn: true,
        loginUserName: username,
      });
    }
    // this.injectGA();
  }

  showErrorMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 4000,
      }
    );

  showSuccessMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 4000,
      }
    );

  toggle = () => {
    this.setState({
      activeTab: !this.state.activeTab,
    });
  };

  validateEmail = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  showRelatedError = (data) => {
    return Object.keys(data).map((item) => {
      data[item].map((subItem) => {
        this.showErrorMessage(subItem);
      });
    });
  };
  injectGA = () => {
    // if (typeof window == "undefined") {
    //   return;
    // }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "AW-671129096");
  };

  gtag_report_conversion = (url) => {
    window.gtag("event", "conversion", {
      send_to: "AW-671129096/PjnUCKqC59gBEIi8gsAC",
    });
  };
  handleTermClick = (e) => {
    // console.log(e.target.value);
    this.setState({ selected: !this.state.selected });
  };
  signUpUser = async () => {
    this.setState({
      isLoadingToSignUp: true,
    });
    if (
      this.state.signUpUserName.length === 0 ||
      this.state.signUpRepeatPassword.length === 0 ||
      this.state.signUpEmail.length === 0 ||
      this.state.signUpPassword.length === 0 ||
      this.state.signUpFirstName.length === 0 ||
      this.state.signUpLastName.length === 0
    ) {
      this.setState({
        isLoadingToSignUp: false,
      });
      return this.showErrorMessage("Please fill all required field.");
    }
    if (this.state.signUpPassword !== this.state.signUpRepeatPassword) {
      this.setState({
        isLoadingToSignUp: false,
      });
      return this.showErrorMessage("Password does not match.");
    }

    if (!this.validateEmail(this.state.signUpEmail)) {
      this.setState({
        isLoadingToSignUp: false,
      });
      return this.showErrorMessage("Email is not in correct form !");
    }

    // this.gtag_report_conversion("https://mymerita.com/login");
    ReactGA.pageview(window.location.pathname);

    let requestBody = {
      password1: this.state.signUpPassword,
      password2: this.state.signUpRepeatPassword,
      email: this.state.signUpEmail,
      username: this.state.signUpUserName,
      sex: this.state.selectedSex,
      company: this.state.company,
      industry: this.state.industry,
      country: this.state.country,
      first_name: this.state.signUpFirstName,
      last_name: this.state.signUpLastName,
    };

    Object.keys(requestBody).map((item) => {
      if (requestBody[item].length === 0) {
        return delete requestBody[item];
      }
    });

    let options = {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await fetch(`${API_URL}/rest-auth/registration/`, options).then((res) => {
      if (res.status === 400) {
        this.setState({
          gotError: true,
        });
        res.json().then((data) => {
          return this.showRelatedError(data);
        });
      } else if (res.status === 201) {
        this.setState({
          gotError: false,
        });
        res.json().then((data) => {
          this.setState({
            activeTab: !this.state.activeTab,
          });
          this.showSuccessMessage(
            "You need to activate your account before login."
          );
        });
      }
    });

    this.setState({
      isLoadingToSignUp: false,
    });
  };

  loginUser = async () => {
    if (
      this.state.loginEmail.length === 0 ||
      this.state.loginPassword.length === 0
    ) {
      return this.showErrorMessage("Please fill all field");
    }
    this.setState({
      gotError: false,
      isLoading: true,
    });
    let requestBody = {
      password: this.state.loginPassword,
      username: this.state.loginUserName,
      email: this.state.loginEmail,
    };
    let options = {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await fetch(`${API_URL}/rest-auth/login/`, options)
      .then((res) => {
        if (res.status === 400) {
          res.json().then((data) => {
            console.log(data);
            if (data["non_field_errors"][0] === "E-mail is not verified.") {
              console.log("asdasd");
              this.setState({
                gotError: true,
              });
              this.showErrorMessage(
                "Please activate your account in your email"
              );
            } else {
              this.setState({
                gotError: true,
              });
              this.showErrorMessage("Invalid Username/Password");
            }
          });
        } else if (res.status === 200) {
          this.setState({
            gotError: false,
          });
          res.json().then((data) => {
            localStorage.setItem("authToken", data.token);
            localStorage.setItem("user", this.state.loginUserName);
            this.checkLoginValidating();
            this.showSuccessMessage("Login Successful");
          });
        }
      })
      .catch((err) => {
        if (
          err.response.data["non_field_errors"][0] === "E-mail is not verified."
        ) {
          console.log("asdasd");
        }
      });
    this.setState({
      isLoading: false,
    });
    if (this.state.gotError) {
      return null;
    }
  };

  checkLoginValidating = () => {
    if (localStorage.getItem("authToken")) {
      this.setState({
        isLoggedIn: true,
      });
      window.location.reload();
    }
  };

  handleLoginKey = (e) => {
    if (e.keyCode === 13) {
      return this.loginUser();
    }
  };

  _renderLogin = () => {
    return (
      <div
        onKeyDown={this.handleLoginKey}
        className={"col-12 col-sm-12 col-md-8 col-lg-6 mt-5"}
      >
        <div className="card mt-5">
          <article className="card-body">
            <h4 className="card-title text-center mb-4 mt-1">
              Login to your account
            </h4>
            <hr />
            {/*<p className="text-success text-center">Some message goes here</p>*/}
            <form>
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FaEnvelope />
                    </span>
                  </div>
                  <input
                    value={this.state.loginEmail}
                    onChange={(e) =>
                      this.setState({ loginEmail: e.target.value })
                    }
                    className="form-control"
                    placeholder="Email"
                    type="email"
                  ></input>
                </div>
              </div>
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FaLock />
                    </span>
                  </div>
                  <input
                    value={this.state.loginPassword}
                    onChange={(e) =>
                      this.setState({ loginPassword: e.target.value })
                    }
                    className="form-control"
                    placeholder="Password"
                    type="password"
                  ></input>
                </div>
              </div>

              <div className="form-group">
                <button
                  onClick={this.loginUser}
                  type={"button"}
                  className="btn btn-primary btn-block"
                >
                  {this.state.isLoading ? (
                    <div className="sweet-loading">
                      <PulseLoader
                        css={override}
                        sizeUnit={"px"}
                        color={"white"}
                        size={7}
                        loading={true}
                      />
                    </div>
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
              <hr />
              <h5 className="card-title text-center mb-4 mt-1">
                Login using your social account:
              </h5>

              <div className="btn btn-default btn-block m-0">
                <a href={`${API_URL}/social-auth/login/facebook/`} style={{  margin:"7px" }}>
                  <FaFacebook size={35} color={"#0F93F3"} />
                </a>
                <a href={`${API_URL}/social-auth/login/google-oauth2/`} style={{  margin:"7px" }}>
                  <FaGoogle size={35} color={"#DD4B39"} />
                </a>
                <a href={`${API_URL}/social-auth/login/linkedin-oauth2/`} style={{  margin:"7px" }}>
                  <FaLinkedin size={35} color={"#0B78B7"} />
                </a>
              </div>

              {/* <div class="google-btn">
                <div class="google-icon-wrapper">
                  <img
                    class="google-icon"
                    src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                  />
                </div>
                <p class="btn-text">
                  <b>Sign in with google</b>
                </p>
              </div> */}
              {/* <hr /> */}

              <div className="form-group">
                <button
                  type={"button"}
                  onClick={this.toggle}
                  className="btn btn-default btn-block m-0"
                >
                 Create New Account
                </button>
                <button
                  type={"button"}
                  onClick={this.toggleForgetModal}
                  className="btn btn-default btn-block m-0"
                >
                  Forget Password?
                </button>

                <div className="m-0 font-size-12">
                  * If you have any problems to log-in please contact:
                  info@myMeriTa.com
                </div>
              </div>
            </form>
          </article>
        </div>
      </div>
    );
  };

  handleSignUpKey = (e) => {
    if (e.keyCode === 13) {
      return this.signUpUser();
    }
  };

  _renderSignUp = () => {
    return (
      <div
        onKeyDown={this.handleSignUpKey}
        className={"col-12 col-sm-12 col-md-8 col-lg-6 mt-2"}
      >
        <div className="card mt-5">
          <article className="card-body mt-4">
            <h4 className="card-title text-center mb-4 mt-4">Create account</h4>
            <hr />
            {/*<p className="text-success text-center">Some message goes here</p>*/}
            <form>
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FaUser />
                    </span>
                  </div>
                  <input
                    value={this.state.signUpFirstName}
                    className="form-control"
                    placeholder="First Name *"
                    onChange={(e) =>
                      this.setState({ signUpFirstName: e.target.value })
                    }
                  ></input>
                </div>
              </div>
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FaUser />
                    </span>
                  </div>
                  <input
                    value={this.state.signUpLastName}
                    className="form-control"
                    placeholder="Last Name *"
                    onChange={(e) =>
                      this.setState({ signUpLastName: e.target.value })
                    }
                  ></input>
                </div>
              </div>
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FaUserTie />
                    </span>
                  </div>
                  <input
                    value={this.state.signUpUserName}
                    className="form-control"
                    placeholder="Username *"
                    onChange={(e) =>
                      this.setState({ signUpUserName: e.target.value })
                    }
                    type="text"
                  ></input>
                </div>
              </div>
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FaAt />
                    </span>
                  </div>
                  <input
                    value={this.state.signUpEmail}
                    className="form-control"
                    placeholder="Email *"
                    onChange={(e) =>
                      this.setState({ signUpEmail: e.target.value })
                    }
                    type="email"
                  ></input>
                </div>
              </div>
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FaLock />
                    </span>
                  </div>
                  <input
                    value={this.state.signUpPassword}
                    onChange={(e) =>
                      this.setState({ signUpPassword: e.target.value })
                    }
                    className="form-control"
                    placeholder="Password *"
                    type="password"
                  ></input>
                </div>
              </div>
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FaLock />
                    </span>
                  </div>
                  <input
                    value={this.state.signUpRepeatPassword}
                    onChange={(e) =>
                      this.setState({ signUpRepeatPassword: e.target.value })
                    }
                    className="form-control"
                    placeholder="Password Confirmation *"
                    type="password"
                  ></input>
                </div>
              </div>
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FaUniversity />
                    </span>
                  </div>
                  <input
                    value={this.state.company}
                    onChange={(e) => this.setState({ company: e.target.value })}
                    className="form-control"
                    placeholder="Company"
                    type="text"
                  ></input>
                </div>
              </div>
              <div className="form-group d-flex">
                <span className="m-0 ml-1 mb-2 mt-2 mr-2">Industry:</span>
                <FormSelect
                  onChange={(e) => this.setState({ industry: e.target.value })}
                >
                  <option value="" disabled selected>
                    Select your industry
                  </option>
                  <option value="Aerospace industry">Aerospace industry</option>
                  <option value="Agriculture">Agriculture</option>
                  <option value="Chemical industry">Chemical industry</option>
                  <option value="Computer industry">Computer industry</option>
                  <option value="Construction industry">
                    Construction industry
                  </option>
                  <option value="Defense industry">Defense industry</option>
                  <option value="Education industry">Education industry</option>
                  <option value="Energy industry">Energy industry</option>
                  <option value="Entertainment industry">
                    Entertainment industry
                  </option>
                  <option value="Financial services">
                    Financial services industry
                  </option>
                  <option value="Food industry">Food industry</option>
                  <option value="Government organization">
                    Government organization
                  </option>
                  <option value="Health care">Health care industry</option>
                  <option value="Hospitality industry">
                    Hospitality industry
                  </option>
                  <option value="Information industry">
                    Information industry
                  </option>
                  <option value="Manufacturing">Manufacturing</option>
                  <option value="Mass media">Mass media</option>
                  <option value="Mining">Mining</option>
                  <option value="Telecommunications industry">
                    Telecommunications industry
                  </option>
                  <option value="Transport industry">Transport industry</option>
                  <option value="Water industry">Water industry</option>
                  <option value="Services">Services</option>
                  <option value="Other">Other</option>
                </FormSelect>
              </div>
              <div className="form-group d-flex">
                <span className="m-0 ml-1 mb-2 mt-2 mr-2">Country:</span>
                <FormSelect
                  onChange={(e) => this.setState({ country: e.target.value })}
                >
                  <option value="" disabled selected>
                    Select your country
                  </option>
                  <option value="Afganistan">Afghanistan</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bonaire">Bonaire</option>
                  <option value="Bosnia & Herzegovina">
                    Bosnia & Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Ter">
                    British Indian Ocean Ter
                  </option>
                  <option value="Brunei">Brunei</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Canary Islands">Canary Islands</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Channel Islands">Channel Islands</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos Island">Cocos Island</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote DIvoire">Cote DIvoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Curaco">Curacao</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="East Timor">East Timor</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands">Falkland Islands</option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Ter">
                    French Southern Ter
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Great Britain">Great Britain</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Hawaii">Hawaii</option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="India">India</option>
                  <option value="Iran">Iran</option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea North">Korea North</option>
                  <option value="Korea Sout">Korea South</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Laos">Laos</option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libya">Libya</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macau">Macau</option>
                  <option value="Macedonia">Macedonia</option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Midway Islands">Midway Islands</option>
                  <option value="Moldova">Moldova</option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Nambia">Nambia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherland Antilles">
                    Netherland Antilles
                  </option>
                  <option value="Netherlands">
                    Netherlands (Holland, Europe)
                  </option>
                  <option value="Nevis">Nevis</option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau Island">Palau Island</option>
                  <option value="Palestine">Palestine</option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Phillipines">Philippines</option>
                  <option value="Pitcairn Island">Pitcairn Island</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Republic of Montenegro">
                    Republic of Montenegro
                  </option>
                  <option value="Republic of Serbia">Republic of Serbia</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russia">Russia</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="St Barthelemy">St Barthelemy</option>
                  <option value="St Eustatius">St Eustatius</option>
                  <option value="St Helena">St Helena</option>
                  <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                  <option value="St Lucia">St Lucia</option>
                  <option value="St Maarten">St Maarten</option>
                  <option value="St Pierre & Miquelon">
                    St Pierre & Miquelon
                  </option>
                  <option value="St Vincent & Grenadines">
                    St Vincent & Grenadines
                  </option>
                  <option value="Saipan">Saipan</option>
                  <option value="Samoa">Samoa</option>
                  <option value="Samoa American">Samoa American</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome & Principe">
                    Sao Tome & Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syria">Syria</option>
                  <option value="Tahiti">Tahiti</option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania">Tanzania</option>
                  <option value="Thailand">Thailand</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Erimates">
                    United Arab Emirates
                  </option>
                  <option value="United States of America">
                    United States of America
                  </option>
                  <option value="Uraguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Vatican City State">Vatican City State</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Vietnam">Vietnam</option>
                  <option value="Virgin Islands (Brit)">
                    Virgin Islands (Brit)
                  </option>
                  <option value="Virgin Islands (USA)">
                    Virgin Islands (USA)
                  </option>
                  <option value="Wake Island">Wake Island</option>
                  <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zaire">Zaire</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </FormSelect>
              </div>
              <div className="form-group d-flex">
                <span className="m-0 mb-2 ml-1 mr-2">Sex:</span>
                <FormRadio
                  inline
                  name="Sex"
                  checked={this.state.selectedSex === "Male"}
                  onChange={() => {
                    this.setState({
                      selectedSex: "Male",
                    });
                  }}
                >
                  Male
                </FormRadio>
                <FormRadio
                  inline
                  name="Sex"
                  checked={this.state.selectedSex === "Female"}
                  onChange={() => {
                    this.setState({
                      selectedSex: "Female",
                    });
                  }}
                >
                  Female
                </FormRadio>
                <FormRadio
                  inline
                  name="Sex"
                  checked={this.state.selectedSex === "Other"}
                  onChange={() => {
                    this.setState({
                      selectedSex: "Other",
                    });
                  }}
                >
                  Other
                </FormRadio>
              </div>

              <div
                className="form-group  font-size-12"
                style={{ color: "red" }}
              >
                * You need to activate your account before login.
              </div>
              <div className="m-0 font-size-12 mb-2" style={{ color: "red" }}>
                ** If you have any problems to log-in please contact:
                info@myMeriTa.com
              </div>
              <div
                className="m-0 font-size-14 mb-2"
                style={{ margin: "auto", left: "50%", paddingLeft: "30%" }}
              >
                <FormCheckbox
                  checked={this.state.selected ? true : false}
                  onChange={(e) => this.handleTermClick(e)}
                >
                  Agree to{" "}
                  <span
                    onClick={() => this.props.history.push("/terms")}
                    style={{ color: "blue", textDecoration: "underline" }}
                  >
                    Terms of Use
                  </span>
                </FormCheckbox>
              </div>
              <div className="form-group">
                <button
                  onClick={this.signUpUser}
                  type={"button"}
                  className="btn btn-primary btn-block si"
                  disabled={!this.state.selected ? true : false}
                >
                  {this.state.isLoadingToSignUp ? (
                    <div className="sweet-loading">
                      <PulseLoader
                        css={override}
                        sizeUnit={"px"}
                        color={"white"}
                        size={7}
                        loading={true}
                      />
                    </div>
                  ) : (
                    "Sign Up"
                  )}
                </button>
              </div>
              <div className="form-group">
                <button
                  type={"button"}
                  onClick={this.toggle}
                  className="btn btn-default btn-block"
                >
                  Already have an account ?
                </button>
              </div>
            </form>
          </article>
        </div>
      </div>
    );
  };

  toggleForgetModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  render() {
    // if (this.state.isLoggedIn) {
    //     return <Redirect to={`/`}/>
    // }
    return (
      <div>
        <header
          style={{ backgroundColor: "#3bacf0" }}
          className={this.state.className}
          id={"Home"}
        >
          <div className="main_menu">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div
                className="container box_1620"
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <a className="navbar-brand logo_h">
                  <img
                    src={require("../assets/icon.png")}
                    height={45}
                    width={140}
                    alt=""
                  />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>

                <div
                  className="collapse navbar-collapse offset"
                  id="navbarSupportedContent"
                >
                  <ul className="nav navbar-nav menu_nav justify-content-center">
                    <li className="nav-item">
                      <a className="nav-link" href="/">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/#Story">
                        Our Story
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/#info">
                        How it works
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#info">
                        <Link to={"/pricing"}>Pricing</Link>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/#RayDalio">
                        Ray Dalio
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>
        <div
          className={"container justify-content-center mt-5"}
          style={{ height: "100%" }}
        >
          <ForgetPassword
            show={this.state.showModal}
            onCloseModal={this.toggleForgetModal}
          />
          <div className={"row justify-content-center"}>
            {this.state.activeTab ? this._renderLogin() : this._renderSignUp()}
          </div>
        </div>
        {/* <footer className="footer_area p_120 mt-5">
          <div className="container">
            <div className="row footer_inner">
              <div className="col-lg-3 col-sm-6">
                <aside className="f_widget ab_widget">
                  <div className="f_title">
                    <h3>About Us</h3>
                  </div>
                  <p>MeriTa is a meritocratic decision support network</p>
                  <p>
                    Copyright
                    <span className={"mx-1"}>
                      &copy;
                      {new Date().getFullYear()}
                    </span>
                    All rights reserved
                  </p>
                </aside>
              </div>
              <div className="col-lg-3 col-sm-6">
                <aside className="f_widget news_widget">
                  <div className="f_title">
                    <h3>Newsletter</h3>
                  </div>
                  <p>Stay updated with us</p>
                  <div id="mc_embed_signup">
                    <form
                      className="subscribe_form relative"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <div className="input-group d-flex flex-row">
                        <input
                          name="EMAIL"
                          placeholder="Enter email address"
                          required=""
                          type="email"
                        />
                        <button className="btn sub-btn">
                          <span className="lnr lnr-arrow-right"></span>
                        </button>
                      </div>
                      <div className="mt-10 info"></div>
                    </form>
                  </div>
                </aside>
              </div>
              <div className="col-lg-3">
                <aside className="f_widget social_widget">
                  <div className="f_title">
                    <h3>Follow Us</h3>
                  </div>
                  <p>Let us be social</p>
                  <ul className="list">
                    <li>
                      <a href="#">
                        <FaFacebook />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                  <br />

                  <a
                    href="https://mymeritablog.wordpress.com/"
                    target="_blank"
                    className={"contact-us-email"}
                  >
                    MeriTa Blog
                  </a>
                </aside>
              </div>
              <div className="col-lg-3">
                <aside className="f_widget social_widget">
                  <div className="f_title">
                    <h3>Contact us</h3>
                  </div>
                  <a
                    className={"contact-us-email"}
                    href={
                      "mailto: info@myMeriTa.com?subject=Feedback&body=Message"
                    }
                  >
                    info@myMeriTa.com
                  </a>
                  <br />

                  <a href="/privacy" className={"contact-us-email"}>
                    Privacy
                  </a>
                  <br />

                  <a href="/terms" className={"contact-us-email"}>
                    Terms
                  </a>
                </aside>
              </div>
            </div>
          </div>
        </footer> */}
           <footer className="footer_area p_120" style={{marginTop:"20px"}}>
          <div className="container">
            <div className="row footer_inner">
              <div className="col-lg-3 col-sm-6">
                <aside className="f_widget ab_widget">
                  <div className="f_title">
                    <h3>About Us</h3>
                  </div>
                  <p>MeriTa is a meritocratic decision support network</p>
                  <p>
                    Copyright
                    <span className={"mx-1"}>
                      &copy;
                      {new Date().getFullYear()}
                    </span>
                    All rights reserved
                  </p>
                </aside>
              </div>
              <div className="col-lg-3 col-sm-6">
                <aside className="f_widget news_widget">
                  <div className="f_title">
                    <h3>Newsletter</h3>
                  </div>
                  <p>Stay updated with us</p>
                  <div id="mc_embed_signup">
                    <form
                      className="subscribe_form relative"
                      onSubmit={this.submitEmail}
                    >
                      <div className="input-group d-flex flex-row">
                        <input
                          name="EMAIL"
                          placeholder="Enter email address"
                          required=""
                          type="email"
                          value={this.state.email}
                          onChange={e =>
                            this.setState({ email: e.target.value })
                          }
                        />
                        <button className="btn sub-btn">
                          <span className="lnr lnr-arrow-right"></span>
                        </button>
                      </div>
                      <div className="mt-10 info"></div>
                    </form>
                  </div>
                </aside>
              </div>
              <div className="col-lg-3">
                <aside className="f_widget social_widget">
                  <div className="f_title">
                    <h3>Follow Us</h3>
                  </div>
                  <p>Let us be social</p>
                  <ul className="list">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/myMeriTa1"
                      >
                        <FaFacebook />
                      </a>
                    </li>

                    <li>
                      <a
                        target="_blank"
                        href="https://twitter.com/MeriTa48053287"
                      >
                        <FaTwitter />
                      </a>
                    </li>

                    <li>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/merita/"
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                  <br />
                  <a
                    href="https://mymeritablog.wordpress.com/"
                    target="_blank"
                    className={"contact-us-email"}
                  >
                    MeriTa Blog
                  </a>
                </aside>
              </div>
              <div className="col-lg-3">
                <aside className="f_widget social_widget">
                  <div className="f_title">
                    <h3>Contact us</h3>
                  </div>
                  <a
                    className={"contact-us-email"}
                    href={
                      "mailto: info@myMeriTa.com?subject=Feedback&body=Message"
                    }
                  >
                    info@myMeriTa.com
                  </a>
                  <br />

                  <a href="/privacy" className={"contact-us-email"}>
                    Privacy
                  </a>
                  <br />

                  <a href="/terms" className={"contact-us-email"}>
                    Terms
                  </a>
                </aside>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default SignForm;
