import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "shards-react";
import {
  FaMapMarkerAlt,
  FaEnvelope,
  FaChevronCircleRight,
  FaChevronCircleLeft
} from "react-icons/fa";
import ItemsCarousel from "react-items-carousel";
import { fetchUserAttribute, fetchUserProfile } from "../actions/index";
import { ScaleLoader } from "react-spinners";
import { css } from "@emotion/core";
import { authHeader } from "../actions/AuthHeader";
import { API_URL } from "../constants";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
/**
 * Profile class render details of user's profile.
 */
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItemIndex: 0,
      isLoading: false,
      topAttribute: [],
      attr_num: []
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });
    const { dispatch, match } = this.props;
    await dispatch(fetchUserProfile(match.params["id"]));
    await dispatch(fetchUserAttribute(match.params["id"]));
    await this.getTopAttribute(match.params["id"]);
    await this.getPollMember(match.params["id"]);
    this.setState({
      isLoading: false
    });
  }

  getTopAttribute = username => {
    console.log("username", username);
    return fetch(`${API_URL}/users/${username}/attributes/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader()
      }
    })
      .then(res => res.json())
      .then(data => {
        console.log("data", data);
        if (data !== undefined) {
          this.setState({
            topAttribute: data
          });
        }
      });
  };
  // getTopAttribute = username => {
  //   return fetch(`${API_URL}/users/${username}/attributes/`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: authHeader()
  //     }
  //   })
  //     .then(res => res.json())
  //     .then(data => {
  //       console.log("data", data);

  //       this.setState({
  //         topAttribute: data
  //       });
  //     });
  // };

  getPollMember = username => {
    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader()
      }
    };
    return fetch(`${API_URL}/users/${username}/poll_member/`, options)
      .then(res => {
        if (res.status === 400) {
          this.setState({
            gotError: true
          });
        }
        return res.json();
      })
      .then(data => {
        console.log("asd", data);

       
        this.setState({
          attr_num: data.participant
        });
      });
  };

  //   return fetch(`${API_URL}/users/${username}/attributes/`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: authHeader()
  //     }
  //   })
  //     .then(res => {
  //       res.json();
  //     })
  //     .then(data => {
  //       console.log("data", data);
  //       if (data !== undefined) {
  //         this.setState({
  //           topAttribute: data
  //         });
  //       }
  //     });
  // };

  shouldComponentUpdate = (nextProps, nextState) => {
    const { dispatch, match } = nextProps;
    if (this.props.match.params["id"] !== nextProps.match.params["id"]) {
      this.updateProfile(match, dispatch);
    }

    return true;
  };

  updateProfile = async (match, dispatch) => {
    this.setState({
      isLoading: true
    });
    await dispatch(fetchUserProfile(match.params["id"]));
    await dispatch(fetchUserAttribute(match.params["id"]));
    await this.getTopAttribute(match.params["id"]);
    this.setState({
      isLoading: false
    });
  };

  render() {
    console.log(this.props.profile);
    return (
      <Fragment>
        {this.state.isLoading ? (
          <div className={"profile-wrapper d-flex justify-content-center"}>
            <div className="sweet-loading">
              <ScaleLoader
                css={override}
                sizeUnit={"px"}
                size={150}
                color={"#FF8260"}
                loading={this.props.isFetching}
              />
            </div>
          </div>
        ) : (
          <div className={"profile-wrapper"}>
            <div
              className={"profile-wrapper"}
              style={{ backgroundColor: "#fafafa" }}
            >
              <div className={"profile-data-wrapper container"}>
                <div className={"profile-data-header mb-4"}>
                  <div className={"row m-0 ml-4"}>
                    <div className={"col-12 col-md-3"}>
                      <div
                        className={"profile-header-pic mt-4"}
                        style={{
                          backgroundImage: `url(${this.props.profile["data"]["pic"]})`
                        }}
                      ></div>
                    </div>
                    <div
                      className={
                        "col-12 col-md-9 d-flex flex-column justify-content-center"
                      }
                    >
                      <div className={"d-flex justify-content-between"}>
                        <h4 className={"fw-600 mt-2 mb-0"}>
                          <span>
                            {this.props.profile["data"]["first_name"]}
                          </span>
                          <span className={"ml-1"}>
                            {this.props.profile["data"]["last_name"]}
                          </span>
                        </h4>
                        <div
                          className={"mt-2 mb-0 d-flex align-items-center"}
                        ></div>
                      </div>
                      {/*<div className={"d-flex flex-row user-badge-wrapper"}>*/}
                      {/*{user["attribute"].map((item, index) => {*/}
                      {/*return (*/}
                      {/*<h6 className={"fw-600 user-badge font-size-10 mt-2 mb-0 mr-2"}*/}
                      {/*key={index}>*/}
                      {/*{item}*/}
                      {/*</h6>*/}
                      {/*)*/}
                      {/*})}*/}
                      {/*</div>*/}
                      <h6 className={"fw-600 mt-3 font-size-13 mb-0"}>
                        {this.props.profile["data"]["occupation"]}
                      </h6>
                      <h6 className={"fw-300 font-size-11 mb-0 text-muted"}>
                        <FaMapMarkerAlt />{" "}
                        {this.props.profile["data"]["location"]}
                      </h6>
                    </div>
                  </div>
                </div>
                <hr />
                <div className={"p-0"}>
                  {/* <div className={"poll-data-body mx-3 mx-md-5"}>
                                        <div className={"row"}>
                                            <div className={"col-12 col-md-3"}>
                                                <h6 className={"fw-600 font-size-13"}>
                                                    Core Skill:
                                                </h6>
                                            </div>
                                            <div className={"col-12 col-md-9"}>
                                                <h6 className={"font-size-14"}>
                                                    {this.props.profile["data"]["core_skill"]}
                                                </h6>
                                            </div>
                                        </div>
                                    </div> */}
                  <div className={"poll-data-body mx-3 mx-md-5 my-2"}>
                    <div className={"row"}>
                      <div className={"col-12 col-md-3"}>
                        <h6 className={"fw-600 font-size-13 mb-0"}>Email:</h6>
                      </div>
                      <div className={"col-12 col-md-9"}>
                        <h6 className={"font-size-14 mb-0"}>
                          <FaEnvelope className={"text-muted"} />{" "}
                          {this.props.profile["data"]["email"]}
                        </h6>
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div className={"poll-data-body mx-3 mx-md-5 my-2"}>
                    <div className={"row"}>
                      <div className={"col-12 col-md-12"}>
                        <h6 className={"fw-600 font-size-17 text-center mb-4"}>
                          Top Skills
                        </h6>
                      </div>
                    </div>

                    {this.state.topAttribute.length > 0 ? (
                      <div
                        style={{
                          padding: "0 60px",
                          maxWidth: 700,
                          margin: "0 auto"
                        }}
                      >
                        <ItemsCarousel
                          placeholderItem={<div className={"d-flex"} />}
                          enablePlaceholder={true}
                          numberOfPlaceholderItems={5}
                          numberOfCards={1}
                          numberOfCars={5}
                          gutter={12}
                          slidesToScroll={1}
                          chevronWidth={60}
                          outsideChevron={true}
                          showSlither={false}
                          firstAndLastGutter={false}
                          activeItemIndex={this.state.activeItemIndex}
                          requestToChangeActive={value =>
                            this.setState({ activeItemIndex: value })
                          }
                          rightChevron={<FaChevronCircleRight />}
                          leftChevron={<FaChevronCircleLeft />}
                        >
                          {this.state.topAttribute.map((item, index) => (
                            <div key={index}>
                              <div className={"text-center slider-item"}>
                                <h4>{item["attr"]}</h4>
                                <h6>{parseInt(item["rate"]/this.state.attr_num[index])}/9</h6>
                              </div>
                            </div>
                          ))}
                        </ItemsCarousel>
                      </div>
                    ) : (
                      <div className={"text-center mb-3 text-muted"}>
                        This user does not have any top attributes yet
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return { profile: state.profile, userAttr: state.userAttributes };
};

export default withRouter(connect(mapStateToProps, null)(Profile));
