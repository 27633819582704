import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "shards-react";
import { connect } from "react-redux";
import {
  fetchDecisionAlternativesById,
  fetchDecisionById,
  closeDecision,
} from "../actions/index";
import { ScaleLoader } from "react-spinners";
import { css } from "@emotion/core";
import { IoIosAttach } from "react-icons/io";
import {
  deletePoll,
  rejectInvitation,
  sendInvitation,
  checkFinalizeStatus,
} from "../actions";
import { ic_info } from "react-icons-kit/md/ic_info";
import { Icon } from "react-icons-kit";

import {
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  FormInput,
  FormTextarea,
  Fade,
  FormCheckbox,
  Tooltip,
} from "shards-react";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import { PulseLoader } from "react-spinners";
import Modal from "react-responsive-modal";
import { API_URL } from "../constants";

import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { authHeader } from "../actions/AuthHeader";
import { FaLock } from "react-icons/fa";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

/**
 * Decision class renders decision details.
 */
class Decision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSendingInvitation: false,
      is_voted: false,
      closeInvitation: false,
      rejectInvitation: false,
      showAlternativeDescription: false,
      selectedAlternative: "",
      openAlterModal: false,
      attachments_data: false,
      toggle_reject: false,
      poll_vote_data: false,
      comment: "",
      all_comments: [],
      all_comments_data: false,
    };
    this.status = {
      cl: ["Closed", "grey"],
      vt: ["Voting", "orange"],
      fi: ["Decided", "#32CD32"],
      dr: ["Draft", "#add8e6"],
    };
  }

  tooltip_toggle(name, stat) {
    this.setState({
      [name]: !stat,
    });
  }

  toggle = () => {
    this.setState({
      openAlterModal: !this.state.openAlterModal,
    });
  };
  toggleReject = () => {
    this.setState({
      toggle_reject: !this.state.toggle_reject,
    });
  };
  toggleShowAlternativeDescription = (alter) => {
    this.setState({
      showAlternativeDescription: !this.state.showAlternativeDescription,
      selectedAlternative: alter,
    });
  };

  closeAlternativeDescription = () => {
    this.setState({
      showAlternativeDescription: !this.state.showAlternativeDescription,
    });
  };

  showErrorMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

  showSuccessMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

  refactorProfileUrl = (url) => {
    if (url && String(url).substring(0, 4) === "http") {
      return url;
    } else {
      console.log(url);
      return `${API_URL}/uploads/${url["profile_pic"]}`;
    }
  };

  componentDidMount = () => {
    const { dispatch, match } = this.props;
    dispatch(fetchDecisionAlternativesById(match.params["id"]));
    dispatch(fetchDecisionById(match.params["id"]));
    const pollId = this.props.match.params["id"];

    const endpoint = `polls/${pollId}/check_participate/`;
    const options = {
      method: "GET",
      //   body: JSON.stringify({
      //     "user":this.props.sessionID

      // })
    };
    fetch(`${API_URL}/${endpoint}`, {
      ...options,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((data) => data.json())
      .then((res) => {
        // console.log("asdasd", res);
        if (res.data === "voted") {
          this.setState({
            is_voted: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const endpoint1 = `polls/${pollId}/get_attachments/`;
    const options1 = {
      method: "GET",
      //   body: JSON.stringify({
      //     "user":this.props.sessionID

      // })
    };
    fetch(`${API_URL}/${endpoint1}`, {
      ...options1,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((data) => data.json())
      .then((res) => {
        console.log("attach", res);
        this.setState({
          attachments: res,
          attachments_data: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    this.get_poll_vote();
    if (!this.state.all_comments_data) {
      this.allComments();
    }
  };

  get_poll_vote = () => {
    const pollId = this.props.match.params["id"];

    const endpoint = `polls/${pollId}/poll_vote_data/`;
    const options = {
      method: "GET",
    };
    fetch(`${API_URL}/${endpoint}`, {
      ...options,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((data) => data.json())
      .then((res) => {
        console.log("test", res);

        // if (res.data === "voted") {
        this.setState({
          particpate_vote: res,
          poll_vote_data: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createMarkup = (text) => {
    return { __html: text };
  };

  commentPost = () => {
    // if (this.props.user.trial.user_type === "Free") {
    //   this.showErrorMessage("Upgrade to Premium");
    //   return null;
    // }
    if (this.state.comment.length > 0) {
      const pollId = this.props.match.params["id"];

      const endpoint = `polls/${pollId}/add_poll_comment`;

      fetch(`${API_URL}/${endpoint}/`, {
        method: "POST",
        body: JSON.stringify({
          message: this.state.comment,
          user: this.props.sessionID,
        }),
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: authHeader(),
        }),
      })
        .then((data) => data.json())
        .then((res) => {
          console.log("res", res);
          this.setState(
            {
              all_comments_data: false,
              comment: "",
            },
            () => {
              this.allComments();
            }
          );

          return this.showSuccessMessage(res.data);
        });
    } else {
      return this.showErrorMessage("Please Complete Post Message");
    }
  };

  allComments = () => {
    const pollId = this.props.match.params["id"];

    const endpoint = `polls/${pollId}/all_poll_comments`;
    const options = {
      method: "GET",
    };

    fetch(`${API_URL}/${endpoint}/`, {
      ...options,
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      }),
    })
      .then((data) => data.json())
      .then((res) => {
        console.log("all_comments", res);
        this.setState({
          all_comments_data: true,
          all_comments: res.reverse(),
        });
      });
  };

  handleFinalizePoll = () => {
    const pollId = this.props.match.params["id"];
    checkFinalizeStatus(pollId).then((res) => {
      if (res.status === 400) {
        res.json().then((data) => {
          switch (data) {
            case "Already finalized":
              return this.props.history.push(
                `/decision/finalize/${this.props.match.params["id"]}/`
              );
            case "50% of participants should submit their votes before finalization.":
              return this.showErrorMessage(data);
            default:
              return this.showErrorMessage(data);
          }
        });
      }
      if (res.status === 200) {
        return this.props.history.push(
          `/decision/finalize/${this.props.match.params["id"]}/`
        );
      }
    });
  };

  closeDecision = async () => {
    this.setState({
      closeInvitation: true,
    });
    const { dispatch } = this.props;
    const pollId = this.props.match.params["id"];
    await dispatch(closeDecision(pollId));
    this.setState({
      closeInvitation: false,
    });
    return window.location.reload();
  };

  deleteDecision = async () => {
    await deletePoll(this.props.match.params.id);
    return this.props.history.push("/decision/polls");
  };

  sendInvitation = async () => {
    this.setState({
      isSendingInvitation: true,
    });
    const { dispatch } = this.props;
    const pollId = this.props.match.params["id"];
    await dispatch(sendInvitation(pollId));
    this.setState({
      isSendingInvitation: false,
    });
    return window.location.reload();
  };
  toggleButton = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };

  rejectDecision = async () => {
    this.setState({
      rejectInvitation: true,
    });

    const { dispatch } = this.props;
    const pollId = this.props.match.params["id"];
    await dispatch(rejectInvitation(pollId));

    this.setState({
      rejectInvitation: false,
    });
    return this.props.history.push("/decision/polls");
  };

  renderOperationButton = () => {
    let decisionState = this.props.data["state"]
      ? this.status[this.props.data["state"]][0]
      : null;
    switch (decisionState) {
      case "Draft":
        return (
          <>
            <div
              className={
                "poll-data-body mx-3 mx-md-5 my-3 mb-5 d-flex justify-content-end"
              }
            >
              {/* {this.props.sessionID === this.props.data.created_by ? ( */}
                <Button
                  theme={"primary"}
                  onClick={() =>
                    this.props.history.push(
                      `/decision/make/${this.props.match.params["id"]}`
                    )
                  }
                  className={"btn mr-3 px-5"}
                >
                  Edit Decision
                </Button>
              {/* ) : null} */}

              {/*{this.props.sessionID === this.props.data.created_by ? (*/}
              {/*<Button theme={"primary"}*/}
              {/*onClick={this.closeDecision}*/}
              {/*className={"btn mr-3 px-5"}>*/}
              {/*{this.state.closeInvitation ? (*/}
              {/*<div className='sweet-loading'>*/}
              {/*<PulseLoader*/}
              {/*css={override}*/}
              {/*sizeUnit={"px"}*/}
              {/*color={"white"}*/}
              {/*size={7}*/}
              {/*loading={true}*/}
              {/*/>*/}
              {/*</div>*/}
              {/*) : "Close Decision"}*/}
              {/*</Button>*/}
              {/*) :*/}
              {/*(null)*/}
              {/*}*/}
              {this.props.sessionID === this.props.data.created_by ? (
                <div>
                  <Button
                    theme={"primary"}
                    className={
                      "btn btn-success-custom align-self-end mr-3 px-5"
                    }
                    onClick={this.sendInvitation}
                  >
                    {this.state.isSendingInvitation ? (
                      <div className="sweet-loading">
                        <PulseLoader
                          css={override}
                          sizeUnit={"px"}
                          color={"white"}
                          size={7}
                          loading={true}
                        />
                      </div>
                    ) : (
                      "Send Invites"
                    )}
                    <Icon
                      id="TooltipSendInvites"
                      className={"ml-2"}
                      size={20}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.send_invite_tooltip}
                      target="#TooltipSendInvites"
                      toggle={() =>
                        this.tooltip_toggle(
                          "send_invite_tooltip",
                          this.state.send_invite_tooltip
                        )
                      }
                    >
                      This will send invitations to participants asking them to
                      vote on this decision. After sending invites you won't be
                      able to change the decision details anymore.
                    </Tooltip>
                  </Button>
                </div>
              ) : null}
            </div>
            <div>
              {this.props.sessionID !== this.props.data.created_by ? (
                <h5 className={"fw-600 font-size-13"} style={{ color: "red" }}>
                  This is a draft decision that one of your colleagues have
                  created. You can discuss in below section. After your
                  colleague send invites, you'll be able to vote in this
                  decision.
                </h5>
              ) : undefined}
            </div>
          </>
        );
      case "Voting":
        return (
          <div
            className={
              "poll-data-body mx-3 mx-md-5 my-3 mb-5 d-flex justify-content-end"
            }
          >
            {this.props.sessionID === this.props.data.created_by ? (
              <div>
                <Button onClick={this.toggle} className={"btn mr-3 px-5"}>
                  Close Decision
                  <Icon
                    id="TooltipCloseDeciosion"
                    className={"ml-2"}
                    size={20}
                    icon={ic_info}
                  />
                  <Tooltip
                    open={this.state.close_decision_tooltip}
                    target="#TooltipCloseDeciosion"
                    toggle={() =>
                      this.tooltip_toggle(
                        "close_decision_tooltip",
                        this.state.close_decision_tooltip
                      )
                    }
                  >
                    If you don't need to continue this decision, you can close
                    it. After closing the decision you will be able to delete
                    it.
                  </Tooltip>
                </Button>

                <Modal
                  animationDuration={100}
                  open={this.state.openAlterModal}
                  onClose={this.toggle}
                  top
                >
                  <h4>Close Decision</h4>
                  <hr />
                  <div className={"mb-3"}>
                    This will permanently close this decision. All votes will be
                    lost.
                    <br />
                    Are you sure ?
                    <br />
                  </div>

                  <div
                    style={{ width: "500px" }}
                    className={"d-flex justify-content-end"}
                  >
                    <Button onClick={this.closeDecision} className={"ml-1"}>
                      Yes
                    </Button>
                    <Button onClick={this.toggle} className={"ml-1"}>
                      No
                    </Button>
                  </div>
                </Modal>
              </div>
            ) : null}
            {this.props.data.active_state ? (
              this.props.sessionID === this.props.data.created_by ? (
                <Link
                  to={`/decision/finalize/${this.props.match.params["id"]}/partial`}
                >
                  <Button
                    className={
                      "btn mr-3 px-5 new-decision-button btn-success-custom btn btn-success"
                    }
                  >
                    Partial Result
                    <Icon
                      id="TooltipPartialResult"
                      className={"ml-2"}
                      size={20}
                      icon={ic_info}
                    />
                    <Tooltip
                      open={this.state.partial_result_tooltip}
                      target="#TooltipPartialResult"
                      toggle={() =>
                        this.tooltip_toggle(
                          "partial_result_tooltip",
                          this.state.partial_result_tooltip
                        )
                      }
                    >
                      This is results based on votes submitted so far. Only the
                      decision creator(you) is able to see these results. After
                      the decision is finalized participants will be able to see
                      final results.
                    </Tooltip>
                  </Button>
                </Link>
              ) : null
            ) : null}

            {this.props.sessionID !== this.props.data.created_by ? (
              !this.state.is_voted ? (
                <div>
                  <Modal
                    animationDuration={100}
                    open={this.state.toggle_reject}
                    onClose={this.toggleReject}
                    top
                  >
                    <h5>
                      You wont be able to participate in this decision.
                      <br />
                      Are you sure? ‬
                    </h5>
                    <hr />

                    <div
                      style={{ width: "500px" }}
                      className={"d-flex justify-content-end"}
                    >
                      <Button onClick={this.rejectDecision} className={"mr-1"}>
                        {this.state.isPosting ? (
                          <div className="sweet-loading">
                            <PulseLoader
                              css={override}
                              sizeUnit={"px"}
                              color={"white"}
                              size={7}
                              loading={true}
                            />
                          </div>
                        ) : (
                          "Yes"
                        )}
                      </Button>
                      <Button onClick={this.toggleReject} className={"ml-1"}>
                        No
                      </Button>
                    </div>
                  </Modal>

                  <Button
                    onClick={this.toggleReject}
                    className={"btn mr-3 px-5  "}
                    theme="light"
                  >
                    {this.state.rejectInvitation ? (
                      <div className="sweet-loading">
                        <PulseLoader
                          css={override}
                          sizeUnit={"px"}
                          color={"white"}
                          size={7}
                          loading={true}
                        />
                      </div>
                    ) : (
                      "Reject"
                    )}
                  </Button>
                </div>
              ) : null
            ) : null}

            {this.props.data.active_state ? (
              !this.state.is_voted ? (
                <Link
                  to={`/decision/polls/${this.props.match.params["id"]}/voting`}
                >
                  <Button
                    className={
                      "btn px-5 new-decision-button btn-success-custom btn btn-success"
                    }
                  >
                    Start Voting
                  </Button>
                </Link>
              ) : null
            ) : null}

            {this.props.sessionID !== this.props.data.created_by ? (
              this.state.is_voted ? (
                <div>
                  <p style={{ color: "red" }}>
                    You have submitted your votes for this decision.
                  </p>
                  <p style={{ color: "red" }}>
                    Please wait until the decision is finalized by creator,
                    we'll send you a notification when results are published.
                  </p>
                </div>
              ) : null
            ) : null}

            {this.props.sessionID === this.props.data.created_by ? (
              <Button
                size="lg"
                onClick={this.handleFinalizePoll}
                className={
                  "btn ml-3  new-decision-button btn-success-custom btn btn-success"
                }
              >
                {this.props.data.active_state ? (
                  <span style={{ fontSize: "13px" }}>
                    {" "}
                    Finalize Decision <br />& publish results
                  </span>
                ) : (
                  "Result"
                )}
                <Icon
                  id="TooltipPublishResult"
                  className={"ml-2"}
                  size={20}
                  icon={ic_info}
                />
                <Tooltip
                  open={this.state.publish_result_tooltip}
                  target="#TooltipPublishResult"
                  toggle={() =>
                    this.tooltip_toggle(
                      "publish_result_tooltip",
                      this.state.publish_result_tooltip
                    )
                  }
                >
                  If more than 50% of participants submitted their votes and you
                  are happy with partial results, you can finalize the decision.
                  After the decision is finalized no one will be able to submit
                  therir votes anymore.
                </Tooltip>
              </Button>
            ) : null}
          </div>
        );
      case "Decided":
        return (
          <div
            className={
              "poll-data-body mx-3 mx-md-5 my-3 mb-5 d-flex justify-content-end"
            }
          >
            <Button
              onClick={this.handleFinalizePoll}
              className={
                "btn px-5 new-decision-button btn-success-custom btn btn-success"
              }
            >
              See Result
            </Button>
          </div>
        );
      case "Closed":
        return (
          <div
            className={
              "poll-data-body mx-3 mx-md-5 my-3 mb-5 d-flex justify-content-end"
            }
          >
            {this.props.sessionID === this.props.data.created_by ? (
              <Button
                theme={"primary"}
                onClick={this.deleteDecision}
                className={"btn mr-3 px-5"}
              >
                Delete
              </Button>
            ) : null}
          </div>
        );
      default:
        return null;
    }
  };

  _renderData = () => {
    return (
      <div className={"poll-wrapper"} style={{ backgroundColor: "#fafafa" }}>
        <Modal
          open={this.state.showAlternativeDescription}
          onClose={this.closeAlternativeDescription}
          animationDuration={100}
          center
        >
          <div className={"modal-responsive"}>
            <h4>
              {this.state.selectedAlternative["name"]
                ? this.state.selectedAlternative["name"].length > 30
                  ? this.state.selectedAlternative["name"].substring(0, 30) +
                    "..."
                  : this.state.selectedAlternative["name"]
                : ""}
            </h4>
            <hr />
            <div
              className={"alter-description-area p-0 m-0"}
              dangerouslySetInnerHTML={this.createMarkup(
                this.state.selectedAlternative["description"] ||
                  "This alternative does not have description."
              )}
            ></div>
          </div>
        </Modal>
        <div className={"poll-data-wrapper container"}>
          <div
            className={
              "poll-data-header m-3 m-md-5 d-flex justify-content-between"
            }
          >
            <div>
              {/* <h3 className={"col-12 md-12 poll-data-header-title"}>
                {this.props.data.creator
                  ? this.props.data["name"].length > 40
                    ? this.props.data["name"].substring(0, 40)
                    : this.props.data["name"]
                  : ""}
              </h3> */}
              <div className={"row"}>
                {/* <div className={"col-12 col-md-3"}>
                <h6 className={"fw-600 font-size-13"}>decision name:</h6>
              </div> */}
                <div className={"col-12 col-md-12 "}>
                  <h6 className={" poll-data-header-title font-size-22"}>
                    {this.props.data["question"]}
                  </h6>
                </div>
              </div>
              <small className={"text-muted"}>
                Created by
                {/* <Link
                  to={`/people/users/${this.props.data["created_by"]}`}
                  className={"ml-2 poll-data-header-creator"}
                >
                  @{this.props.data["created_by"]}
                </Link> */}
                {this.props.data.creator ? (
                  <Link
                    to={`/people/users/${this.props.data["created_by"]}`}
                    className={"m-0 poll-data-header-creator fw-300"}
                  >
                    <span
                      className={
                        "nav-profile-avatar d-flex align-items-center mr-4"
                      }
                      style={{ fontWeight: "Normal" }}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${this.refactorProfileUrl(
                            this.props.data.creator["pic"]
                          )})`,
                        }}
                        className={"avater-img mr-1"}
                      ></div>
                      {this.props.data.creator["first_name"] +
                        " " +
                        this.props.data.creator["last_name"]}
                    </span>
                  </Link>
                ) : (
                  ""
                )}
              </small>
            </div>
            <div>
              <small className={"text-muted font-size-10"}>
                {String(this.props.data["pub_date"]).substring(0, 10)}
              </small>
            </div>
          </div>
          <hr />
          <div className={"poll-data-body mx-3 mx-md-5"}>
            <div className={"row"}>
              <div className={"col-12 col-md-3"}>
                <h6 className={"fw-600 font-size-13"}>decision name:</h6>
              </div>
              <div className={"col-12 col-md-9"}>
                <h6 className={"font-size-14"}>
                  {this.props.data["question"]}
                </h6>
              </div>
            </div>
          </div>
          <div className={"poll-data-body mx-3 mx-md-5 my-2"}>
            <div className={"row"}>
              <div className={"col-12 col-md-3"}>
                <h6 className={"fw-600 font-size-13"}>Status:</h6>
              </div>
              <div className={"col-12 col-md-9"}>
                {this.props.data["state"] ? (
                  <h6 className={"status-custom-badge font-size-14 d-flex"}>
                    <div
                      className={"status-circle"}
                      style={{
                        backgroundColor: this.status[
                          this.props.data["state"]
                        ][1],
                      }}
                    ></div>
                    {this.status[this.props.data["state"]][0]}
                  </h6>
                ) : null}
              </div>
            </div>
          </div>
          <div className={"poll-data-body mx-3 mx-md-5 my-2"}>
            <div className={"row"}>
              <div className={"col-12 col-md-3"}>
                <h6 className={"fw-600 font-size-13"}>Publish Date:</h6>
              </div>
              <div className={"col-12 col-md-9"}>
                <h6 className={"font-size-14"}>
                  {/* {this.props.data["pub_date"].format('YYYY-MM-DD')} */}
                  {new Date(this.props.data["pub_date"])
                    .toUTCString()
                    .slice(0, 16)}
                </h6>
              </div>
            </div>
          </div>
          <div className={"poll-data-body mx-3 mx-md-5 my-2"}>
            <div className={"row"}>
              <div className={"col-12 col-md-3"}>
                <h6 className={"fw-600 font-size-13"}>Decision Deadline:</h6>
              </div>
              <div className={"col-12 col-md-9"}>
                <h6 className={"font-size-14"}>
                  {new Date(this.props.data["finish_date"])
                    .toUTCString()
                    .slice(0, 16)}
                </h6>
              </div>
            </div>
          </div>
          <hr />
          <div className={"poll-data-body mx-3 mx-md-5"}>
            <div className={"row"}>
              <div className={"col-12 col-md-3"}>
                <h6 className={"fw-600 font-size-13"}>Problem/Opportunity:</h6>
              </div>
              <div className={"col-12 col-md-9"}>
                <h6 className={"problem-details font-size-14"}>
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      this.props.data["problem_opportunity"]
                    )}
                  ></div>
                </h6>
              </div>
            </div>
          </div>
          <div className={"poll-data-body mx-3 mx-md-5 my-2"}>
            <div className={"row"}>
              <div className={"col-12 col-md-3"}>
                <h6 className={"fw-600 font-size-13"}>
                  Decision Goal/Objectives:
                </h6>
              </div>
              <div className={"col-12 col-md-9"}>
                <h6 className={"problem-details font-size-14"}>
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      this.props.data["problem_objective"]
                    )}
                  ></div>
                  {/*{this.props.data["problem_objective"]}*/}
                </h6>
              </div>
            </div>
          </div>
          <hr />
          <div className={"poll-data-body mx-3 mx-md-5 my-2"}>
            <div className={"row"}>
              <div className={"col-12 col-md-3"}>
                <h6 className={"fw-600 font-size-13"}>
                  Decision Alternatives:
                </h6>
              </div>
              <div className={"col-12 col-md-9"}>
                {this.props.alternatives.map((item, index) => {
                  return (
                    <span
                      onClick={() =>
                        this.toggleShowAlternativeDescription(item)
                      }
                      className={"alternative-badge_size mx-1"}
                      key={index}
                    >
                      {item["name"].length > 30
                        ? item["name"].substring(0, 30) + "..."
                        : item["name"]}
                    </span>
                  );
                  // : (
                  //   <span
                  //     onClick={() =>
                  //       this.toggleShowAlternativeDescription(item)
                  //     }
                  //     className={"alternative-badge mx-1"}
                  //     key={index}
                  //   >
                  //     {item["name"]}
                  //   </span>
                  // );
                })}
              </div>
            </div>
          </div>
          <div className={"poll-data-body mx-3 mx-md-5 my-2"}>
            <div className={"row"}>
              <div className={"col-12 col-md-3"}>
                <h6 className={"fw-600 font-size-13"}>Required Attributes:</h6>
              </div>
              <div className={"col-12 col-md-9"}>
                {Object.keys(this.props.data).length > 0 ? (
                  this.props.data["attributes"].map((item, index) => (
                    <Link to={`/attribute/t/`} key={index}>
                      <span className={"attribute-badge"}>{item}</span>
                    </Link>
                  ))
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
          <div className={"poll-data-body mx-3 mx-md-5 my-2"}>
            <div className={"row"}>
              <div className={"col-12 col-md-3"}>
                <h6 className={"fw-600 font-size-13"}>Participants:</h6>
              </div>
              <div className={"col-12 col-md-9 d-flex flex-wrap"}>
                {Object.keys(this.props.data).length > 0 ? (
                  // console.log("asddsd", this.props.data),
                  this.props.data["participants"].map((item, index) => (
                    // console.log(item),
                    <Link
                      to={`/people/users/${item["username"]}`}
                      key={index}
                      className={"m-0 poll-data-header-creator fw-300"}
                    >
                      <span
                        className={
                          "nav-profile-avatar d-flex align-items-center mr-4"
                        }
                        style={{ fontWeight: "Normal" }}
                      >
                        <div
                          style={{
                            backgroundImage: `url(${this.refactorProfileUrl(
                              item["pic"]
                            )})`,
                          }}
                          className={"avater-img mr-1"}
                        ></div>
                        {item["first_name"] + " " + item["last_name"]}
                      </span>
                    </Link>
                  ))
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
          <div className={"poll-data-body mx-3 mx-md-5 my-2"}>
            <div className={"row"}>
              <div className={"col-12 col-md-3"}>
                <h6 className={"fw-600 font-size-13"}>Attachment:</h6>
              </div>
              <div className={"col-12 col-md-9"}>
                {this.state.attachments_data ? (
                  this.state.attachments.map((item, index) => {
                    return [
                      <div key={index} className={"attachment-ui d-flex"}>
                        <div className={"p-3 attachment-ui-icon"}>
                          <IoIosAttach className={"font-size-20"} />
                        </div>
                        <div className={"p-4"}>
                          <h6 className={"font-size-14"}>
                            <a
                              className={"black"}
                              href={`${API_URL}/uploads/${item.attachment}`}
                            >
                              {item.attachment}
                            </a>
                          </h6>
                        </div>
                      </div>,
                    ];
                  })
                ) : (
                  <p className={"attachment-placeholder font-size-14"}>
                    There is no attachments for this decision.{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
          {this.renderOperationButton()}
          {this.props.sessionID === this.props.data.created_by ? (
            <>
            <div>
              {this.props.data.active_state ? (
                this.state.poll_vote_data ? (
                  this.state.particpate_vote.is_50 ? (
                    <h5 className={"font-size-14"} style={{ color: "red" }}>
                      {this.state.particpate_vote.voted_participant}/
                      {this.state.particpate_vote.all_participants} have
                      submitted their vote. Threshold of 50% has been met, you
                      can finalize the decision now or wait until more votes are
                      collected.
                    </h5>
                  ) : (
                    <h5 className={"font-size-14"} style={{ color: "red" }}>
                      {this.state.particpate_vote.voted_participant} /{" "}
                      {this.state.particpate_vote.all_participants} have
                      submitted their vote. Threshold of 50% has not been met,
                      you should ask participants to submit their vote.
                    </h5>
                  )
                ) : (
                  ""
                )
              ) : undefined}
            </div>
            <div className={"row"}>
            <div className={"col-12 col-md-3"}>
              <h6 className={"fw-600 font-size-13"}>Voted:</h6>
            </div>
            <div className={"col-12 col-md-9 d-flex flex-wrap"}>
              {this.props.data.active_state
                ? this.state.poll_vote_data
                  ? this.state.particpate_vote.voted.map((item, index) => (
                      // console.log(item),
                      <Link
                        to={`/people/users/${item["username"]}`}
                        key={index}
                        className={"m-0 poll-data-header-creator fw-300"}
                      >
                        <span
                          className={
                            "nav-profile-avatar d-flex align-items-center mr-4"
                          }
                          style={{ fontWeight: "Normal" }}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${this.refactorProfileUrl(
                                item["pic"]
                              )})`,
                            }}
                            className={"avater-img mr-1"}
                          ></div>
                          {item["first_name"] + " " + item["last_name"]}
                        </span>
                      </Link>
                    ))
                  : ""
                : undefined}
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-12 col-md-3"}>
              <h6 className={"fw-600 font-size-13"}>Not voted yet:</h6>
            </div>
            <div className={"col-12 col-md-9 d-flex flex-wrap"}>
              {this.props.data.active_state
                ? this.state.poll_vote_data
                  ? this.state.particpate_vote.not_voted.map((item, index) => (
                      // console.log(item),
                      <Link
                        to={`/people/users/${item["username"]}`}
                        key={index}
                        className={"m-0 poll-data-header-creator fw-300"}
                      >
                        <span
                          className={
                            "nav-profile-avatar d-flex align-items-center mr-4"
                          }
                          style={{ fontWeight: "Normal" }}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${this.refactorProfileUrl(
                                item["pic"]
                              )})`,
                            }}
                            className={"avater-img mr-1"}
                          ></div>
                          {item["first_name"] + " " + item["last_name"]}
                        </span>
                      </Link>
                    ))
                  : ""
                : undefined}
            </div>
          </div>
            </>
          ) : null}
    
          <h3
            className={"poll-data-header-title"}
            style={{ textDecoration: "underline" }}
          >
            Decision Discussions
          </h3>
          {this.props.data.creator ? (
            <div>
              <div className={"poll-data-wrapper container"}>
                <div
                  className={
                    "poll-data-header  m-md-2 d-flex justify-content-between"
                  }
                >
                  <div>
                    <small className={"text-muted"}>
                      {/* <Link
                  to={`/people/users/${this.props.data["created_by"]}`}
                  className={"ml-2 poll-data-header-creator"}
                >
                  @{this.props.data["created_by"]}
                </Link> */}
                      {this.props.data.creator ? (
                        <Link
                          to={`/people/users/${this.props.user["username"]}`}
                          className={"m-0 poll-data-header-creator fw-300"}
                        >
                          <span
                            className={
                              "nav-profile-avatar d-flex align-items-center "
                            }
                            style={{ fontWeight: "Normal" }}
                          >
                            <div
                              style={{
                                backgroundImage: `url(${this.refactorProfileUrl(
                                  this.props.user["pic"]
                                )})`,
                              }}
                              className={"avater-img mr-1"}
                            ></div>
                            {this.props.user["first_name"] +
                              " " +
                              this.props.user["last_name"]}
                          </span>
                        </Link>
                      ) : (
                        ""
                      )}
                    </small>
                  </div>
                  <div className={"mt-5 col-9 col-md-9 mr-5"}>
                    <FormTextarea
                      onChange={(e) => {
                        if (
                          this.state.comment.length < 300 ||
                          e.target.value.length < 300
                        )
                          this.setState({
                            comment: e.target.value,
                          });
                      }}
                      value={this.state.comment}
                    />
                    ({this.state.comment.length}/300)
                  </div>
                </div>
                <div
                  className={
                    "poll-data-body mx-3 mx-md-5 my-3 d-flex justify-content-end"
                  }
                >
                  <Button
                    onClick={this.commentPost}
                    className={"btn mr-3 px-5"}
                  >
                    Post Message
                  </Button>
                </div>
                <div className={"mr-5 d-flex justify-content-end"}>
                  {/* <Button
                    onClick={this.commentPost}
                    className={"btn mr-3 px-5"}
                  >
                    Post Message
                  </Button> */}
                </div>
              </div>
              <div className={"poll-data-wrapper container"}>
                {/* show Comments  */}

                {this.state.all_comments_data
                  ? this.state.all_comments.map((item, index) => {
                      // const comments = this.state.all_comments.reverse();
                      return [
                        <div
                          key={index}
                          className={"  m-md-2 d-flex justify-content-between"}
                        >
                          <div>
                            <small className={"text-muted"}>
                              <Link
                                to={`/people/users/${item.user.username}`}
                                className={
                                  "m-0 poll-data-header-creator fw-300"
                                }
                              >
                                <span
                                  className={
                                    "nav-profile-avatar d-flex align-items-center "
                                  }
                                  style={{ fontWeight: "Normal" }}
                                >
                                  <div
                                    style={{
                                      backgroundImage: `url(${this.refactorProfileUrl(
                                        item.user["pic"]
                                      )})`,
                                    }}
                                    className={"avater-img mr-1"}
                                  ></div>
                                  {item.user["first_name"] +
                                    " " +
                                    item.user["last_name"]}
                                </span>
                              </Link>

                              <span className={"ml-5"}>
                                {new Date(item["post_date"])
                                  .toUTCString()
                                  .slice(0, 16)}
                              </span>
                            </small>
                          </div>
                          <div className={" col-9 col-md-9 mr-5"}>
                            <FormTextarea disabled value={item.comment} />
                          </div>
                        </div>,
                      ];
                    })
                  : ""}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  _renderSpinnerOrData = () => {
    if (!this.props.isFetching) {
      return this._renderData();
    }
    if (this.props.isFetching) {
      return (
        <div className={"poll-sppiner-wrapper"}>
          <div className="sweet-loading">
            <ScaleLoader
              css={override}
              sizeUnit={"px"}
              size={150}
              color={"#FF8260"}
              loading={this.props.isFetching}
            />
          </div>
        </div>
      );
    }
  };

  render() {
    // console.log("asd", this.props);
    return <Fragment>{this._renderSpinnerOrData()}</Fragment>;
  }
}

const mapStateToProps = (state) => {
  const {
    data,
    getError,
    isFetching,
    textError,
    alternatives,
  } = state.selectedDecision;
  console.log("test data", state.session.data);
  return {
    data,
    getError,
    isFetching,
    textError,
    alternatives,
    sessionID: state.session.data.username,
    user: state.session.data,
  };
};

export default withRouter(connect(mapStateToProps)(Decision));
