import * as Constants from "../constants/index";

const initialState = {
    decisions: [],
    isFetching: false,
    getError: "false",
    textError: ""
};

export const decisions = (state = initialState, action) => {
    switch (action.type) {
        case (Constants.RECEIVE_ALL_DECISION):
            return {
                ...state,
                decisions: action.decisions,
                isFetching: false,
                getError: false,
                textError: ""
            };
        case (Constants.REQUEST_ALL_DECISION):
            return {
                ...state,
                isFetching: true,
                getError: false,
                textError: ""
            };
        case (Constants.FAILED_FETCH_DECISION):
            return {
                ...state,
                isFetching: false,
                getError: true,
                textError: action.err
            };
        default:
            return state
    }
};
