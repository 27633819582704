import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, Tooltip, FormSelect } from "shards-react";
import { ic_info } from "react-icons-kit/md/ic_info";
import { Icon } from "react-icons-kit";
import {
  IoIosAddCircleOutline,
  IoMdCheckmarkCircle,
  IoMdCloseCircle,
} from "react-icons/io";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";

import { css } from "@emotion/core";
import { PulseLoader, ScaleLoader } from "react-spinners";
import {
  createAttribute,
  deleteAttribute,
  fetchAllAttribute,
} from "../../actions/index";
import AddAttribute from "./AddAttribute";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";

import { API_URL } from "../../constants";
import { authHeader } from "../../actions/AuthHeader";
import MaterialTable, { MTableToolbar } from "material-table";
import MUIDataTable from "mui-datatables";
import { IconContext } from "react-icons";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const override_table = css`
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
  z-index: 10000;
`;
const columns = [
  {
    id: "0",
    label: "ID",
    align: "center",
  },
  {
    id: "1",
    label: "Attribute Name",
    align: "center",
  },
  {
    id: "2",
    label: "More",
    align: "center",
  },
];

const tableRef = React.createRef();

class Attr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isDeleting: false,
      isChangeAttr: false,
      selectedToDelete: "",
      showModal: false,
      modalSpinner: false,
      page: 0,
      rowsPerPage: 10,
      industries: [],
      sortValue: "popular",
      industryValue: "default",
      all_attr_count: 0,
    };
  }

  tooltip_toggle(name, stat) {
    this.setState({
      [name]: !stat,
    });
  }

  handleChangePage = (event, newPage) => {
    // setPage(newPage);
    this.setState({
      page: newPage,
    });
  };
  handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(+event.target.value);
    // setPage(0);

    this.setState((pre) => ({
      rowsPerPage: event.target.value,
      page: 0,
    }));
  };
  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    await this.props.fetchAllAttribute();
    console.log("all", this.props.data);
    await this.getAllAttrCount();
    await this.getIndustries();
    this.setState(
      {
        isLoading: false,
        attrs: this.props.data,
        all_attr: this.props.data,
        showData: true,
      },
      () => this.changeSort()
    );
  }

  deleteAttribute = async (attrName) => {
    this.setState({
      isDeleting: true,
      selectedToDelete: attrName,
    });
    await this.props
      .deleteAttribute(attrName)
      .then((data) => this.showErrorMessage(data));
    this.setState({
      isDeleting: false,
      selectedToDelete: "",
    });
  };

  showErrorMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

  showSuccessMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

  setFavourite = () => {};

  getIndustries = async () => {
    const endpoint1 = `users/${this.props.sessionID}/get_industries/`;
    const options1 = {
      method: "GET",
    };
    await fetch(`${API_URL}/${endpoint1}`, {
      ...options1,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((data) => data.json())
      .then((res) => {
        console.log("industries", res);
        this.setState({
          industries: res,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllAttrCount = async () => {
    const endpoint1 = `users/${this.props.sessionID}/get_all_attr_count/`;
    const options1 = {
      method: "GET",
    };
    await fetch(`${API_URL}/${endpoint1}`, {
      ...options1,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((data) => data.json())
      .then((res) => {
        console.log("all_attr_count", res);
        this.setState({
          all_attr_count: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  changeUserAttrFavorite = async (attr) => {
    console.log(attr);
    // this.setState({
    //   isChangeAttr: true,
    // });
    const endpoint1 = `users/${this.props.sessionID}/change_user_favorite_attr/`;

    const options1 = {
      method: "Post",
      body: JSON.stringify({
        attr: attr,
        all: true,
      }),
    };

    await fetch(`${API_URL}/${endpoint1}`, {
      ...options1,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((data) => data.json())
      .then((res) => {
        // let before_change_attr;
        // const { attrs } = this.state;
        // const all_attributes = attrs;
        // const filters = all_attributes.filter((el) => el.name === attr);
        // console.log("shit1", all_attributes, filters);
        // let change_attr;
        // console.log(
        //   "res",
        //   res,
        //   res.attributes.filter((el) => el === attr)
        // );
        // const status = res.attributes.filter((el) => el === attr);
        // if (status.length === 0) console.log("asd");
        // if (status.length === 0) {
        //   before_change_attr = all_attributes.find((el) => el.name === attr);
        //   before_change_attr.favorite_attr.filter((att) => {
        //     if (att.user === this.props.sessionID) {
        //       console.log("here1");
        //       if (att.attributes.find((all) => all === attr)) {
        //         console.log("here2");
        //         before_change_attr.favorite_attr.splice(
        //           before_change_attr.favorite_attr.findIndex(
        //             (el) => el.user === this.props.sessionID
        //           ),
        //           1
        //         );
        //         return att;
        //       }
        //     }
        //     return att;
        //   });
        //   console.log("here", before_change_attr);
        //   all_attributes[
        //     all_attributes.findIndex((el) => el.name === attr)
        //   ] = before_change_attr;
        //   console.log("after change ", all_attributes);
        // } else {
        //   change_attr = all_attributes.find((el) => el.name === attr);
        //   console.log("omid", change_attr);
        //   change_attr.favorite_attr.push(res);
        //   all_attributes[
        //     all_attributes.findIndex((el) => el.name === attr)
        //   ].favorite_attr = change_attr.favorite_attr;
        //   console.log("update", all_attributes);
        // }
        // this.setState({
        //   // attrs: all_attributes,
        //   // all_attr: all_attributes,
        //   isChangeAttr: false,
        // });
      })
      .catch((error) => {
        console.log(error);
      });

    // await this.props.fetchAllAttribute();
  };

  changeSort = () => {
    tableRef.current && tableRef.current.onQueryChange();

    // if (this.state.attrs) {
    //   console.log("heeee", this.state.attrs);
    //   let newData = [...this.state.attrs];

    //   if (this.state.sortValue === "default") {
    //     newData.sort((a, b) => {
    //       if (a.name.toLowerCase() < b.name.toLowerCase()) {
    //         return -1;
    //       }
    //       if (a.name.toLowerCase() > b.name.toLowerCase()) {
    //         return 1;
    //       }
    //       return 0;
    //     });
    //     this.setState({
    //       attrs: newData,
    //     });
    //   } else {
    //     newData.sort((a, b) => {
    //       if (a.popularity_count < b.popularity_count) {
    //         return 1;
    //       }
    //       if (a.popularity_count > b.popularity_count) {
    //         return -1;
    //       }
    //       return 0;
    //     });
    //     this.setState({
    //       attrs: newData,
    //     });
    //   }
    // }
  };

  changeIndustry = () => {
    tableRef.current && tableRef.current.onQueryChange();

    // let newData = [...this.state.attrs];
    // if (this.state.industryValue === "default") {
    //   let backupData = [...this.state.all_attr];
    //   this.setState({
    //     attrs: backupData,
    //   });
    // } else {
    //   newData = newData.filter(
    //     (item) => item.industry === this.state.industryValue
    //   );
    //   // console.log("newData", newData);
    //   this.setState({
    //     attrs: newData,
    //   });
    // }
  };

  _renderTableRow = () => {
    let data;
    if (this.props.data && this.props.data.length > 0) {
      data = this.props.data.map((item, index) => {
        return (
          <tr key={index}>
            <th scope="row" className={"text-center text-black"}>
              {index + 1}
            </th>
            <td>{item["name"]}</td>
            {/*<td>{item["field"]}</td>*/}
            <td className={"text-center"}>
              <Button
                onClick={() => this.deleteAttribute(item["name"])}
                theme={"success"}
                size={"sm"}
                className={"btn-success-custom"}
              >
                {this.state.isDeleting &&
                this.state.selectedToDelete === item["name"] ? (
                  <div className="sweet-loading">
                    <PulseLoader
                      css={override}
                      sizeUnit={"px"}
                      color={"white"}
                      size={7}
                      loading={true}
                    />
                  </div>
                ) : (
                  "remove"
                )}
              </Button>
            </td>
          </tr>
        );
      });
    }
    return data;
  };

  _renderTableOrSpinner = () => {
    if (this.state.isLoading) {
      return (
        <div className={"spinner-loader mt-5"}>
          <div className="sweet-loading">
            <ScaleLoader
              css={override}
              sizeUnit={"px"}
              size={150}
              color={"#FF8260"}
              loading={this.state.isLoading}
            />
          </div>
        </div>
      );
    } else {
      const { page, rowsPerPage, attrs } = this.state;
      // console.log("data", this.props.data, "attr", this.state.attrs);
      const options = {
        filterType: "dropdown",
        selectableRows: false,
      };

      const mui_attr_table_rows_name = [
        {
          name: "id",
          label: "ID",
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              // console.log(value,tableMeta)
              tableMeta.rowIndex + 1,
          },
        },
        {
          name: "name",
          label: "Attribute Name",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "favorite_attr",
          label: "Actions",

          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              // console.log(tableMeta.rowData[2].filter(item=> item.user === this.props.sessionID).length>0)
              if (
                tableMeta.rowData[2].filter(
                  (item) => item.user === this.props.sessionID
                ).length > 0
              ) {
                return (
                  <span
                    className={"mr-2 font-size-19"}
                    style={{ verticalAlign: "1px" }}
                    onClick={() =>
                      this.changeUserAttrFavorite(tableMeta.rowData[1])
                    }
                  >
                    {/* <IoMdCloseCircle style={{ color: "#f5222d" }} /> */}
                    <IconContext.Provider
                      value={{ color: "blue", size: "50px" }}
                    >
                      <div>
                        <MdFavorite />
                      </div>
                    </IconContext.Provider>
                  </span>
                );
              } else {
                return (
                  <span
                    className={"mr-2 font-size-19"}
                    style={{ verticalAlign: "1px" }}
                    onClick={() =>
                      this.changeUserAttrFavorite(tableMeta.rowData[1])
                    }
                  >
                    {/* <IoMdCloseCircle style={{ color: "#f5222d" }} /> */}
                    <MdFavoriteBorder />
                  </span>
                );
              }
            },
          },
        },
      ];

      const attr_table_rows_name = [
        {
          title: "ID",
          // render: (rowData) => rowData.tableData.id + 1,
          field: "uniqe_id",
        },
        { title: "Attribute Name", field: "name" },
        {
          title: "More",
          render: (rowData) => {
            // console.log(rowData)
            if (
              rowData.favorite_attr.filter(
                (item) => item.user === this.props.sessionID
              ).length > 0
            ) {
              return (
                <span
                  className={"mr-2 font-size-19"}
                  style={{ verticalAlign: "1px" }}
                  onClick={async () => {
                    await this.changeUserAttrFavorite(rowData.name);
                    tableRef.current && tableRef.current.onQueryChange();
                  }}
                >
                  {/* <IoMdCloseCircle style={{ color: "#f5222d" }} /> */}
                  <IconContext.Provider value={{ color: "#FE5A2D" }}>
                    {/* <div> */}
                      <MdFavorite />
                    {/* </div> */}
                  </IconContext.Provider>{" "}
                </span>
              );
            } else {
              return (
                <span
                  className={"mr-2 font-size-19"}
                  style={{ verticalAlign: "1px" }}
                  onClick={async () => {
                    await this.changeUserAttrFavorite(rowData.name);
                    tableRef.current && tableRef.current.onQueryChange();
                  }}
                >
                  {/* <IoMdCloseCircle style={{ color: "#f5222d" }} /> */}
                  <MdFavoriteBorder />
                </span>
              );
            }
          },
        },
      ];
      // if (this.props.data) {
      //   let all_attributes = this.state.attrs;
      //   console.log("shit", all_attributes);
      // }

      // this.changeSort()
      return this.props.data ? (
        <>
          {/* <ScaleLoader
            css={override_table}
            sizeUnit={"px"}
            size={15}
            color={"#FF8260"}
            loading={this.state.isChangeAttr}
          /> */}

          <MaterialTable
            title="All Attributes"
            tableRef={tableRef}
            columns={attr_table_rows_name}
            // data={this.state.attrs}
            data={(query) =>
              new Promise((resolve, reject) => {
                // console.log(query)
                let url = `${API_URL}/attributes/`;

                url += "?page=" + (query.page + 1);
                url += "&search=" + query.search;
                url += "&page_size=" + query.pageSize;
                url += "&sort=" + this.state.sortValue;
                url += "&industry=" + this.state.industryValue;

                // if(this.state.sortValue === 'popular'){

                //   tableRef.current && tableRef.current.onQueryChange()

                // }

                // data: result.data.map(row => selectedRows.find(selected => selected.id === row.id) ? { ...row, tableData: { checked: true } } : row)

                fetch(url)
                  .then((response) => response.json())
                  .then((result) => {
                    console.log("test", result, query);
                    // console.log('search',result.results.filter(attr=>attr.name.includes('12')))
                    // this.setState({
                    //   attrs:result.results
                    // })
                    // result.results.map(row=>this.state.attrs.find(selected=>selected.name === row.name)?this.state.attrs.filter(selected=>selected.name === row.name)[0]:row)
                    // query.search? result.results.filter(attr=>attr.name.includes(query.search)):result.results
                    resolve({
                      data: result.results,
                      page: query.page,
                      totalCount: result.count,
                    });
                  });
              })
            }
            localization={{
              header: {
                actions: "More",
              },
            }}
            components={{
              Toolbar: (props) => (
                <div>
                  <MTableToolbar {...props} />
                  <div className={"row mb-3"}>
                    <div className={"col-12 col-md-4 ml-2 my-1"}>
                      <label className={"form-ui-label font-size-13 fw-600"}>
                        Sort
                      </label>
                      <FormSelect
                        onChange={(e) =>
                          this.setState({ sortValue: e.target.value }, () =>
                            this.changeSort()
                          )
                        }
                        value={this.state.sortValue}
                      >
                        <option selected value="default">
                          Default(Alphabetic)
                        </option>
                        <option value="popular">Popular</option>
                      </FormSelect>
                    </div>
                    <div className={"col-12 col-md-4 ml-2 my-1"}>
                      <label className={"form-ui-label font-size-13 fw-600"}>
                        Industry
                      </label>
                      <FormSelect
                        onChange={(e) => {
                          this.setState({ industryValue: e.target.value }, () =>
                            this.changeIndustry()
                          );
                        }}
                        value={this.state.industryValue}
                      >
                        <option selected value={"default"}>
                          Default(All)
                        </option>
                        ]
                        {this.state.industries.map((item, index) => {
                          return [
                            <option
                              key={item.industry_name}
                              value={item.industry_name}
                            >
                              {item.industry_name}
                            </option>,
                          ];
                        })}
                      </FormSelect>
                    </div>
                  </div>
                </div>
              ),
            }}
            actions={[
              {
                icon: "refresh",
                tooltip: "Refresh Data",
                isFreeAction: true,
                onClick: () =>
                  tableRef.current && tableRef.current.onQueryChange(),
              },
              // {
              //   icon: () => (
              //     <FormSelect
              //       onChange={(e) => console.log(e.target.value)}
              //       value={'EnterpriseType'}
              //     >
              //       <option value="" disabled selected>
              //         Select your Type
              //       </option>
              //       <option value="Enterprise30">Enterprise30</option>
              //       <option value="Enterprise100">Enterprise100</option>
              //       <option value="Enterprise500">Enterprise500</option>
              //     </FormSelect>
              //   ),
              //   onClick: (event, rowData) => {
              //     console.log("Hello World!");
              //   },
              //   isFreeAction: true,
              //   tooltip: "Add Button",
              // },
              // (rowData) => ({
              //   icon: "favorite_border",
              //   tooltip: "Add to Favourite",
              //   onClick: (event, rowData) => {
              //     this.changeUserAttrFavorite(rowData.name);
              //   },
              // }),
            ]}
            options={{
              actionsColumnIndex: -1,
              sorting: false,
              //   filtering: true,
            }}
          />
          {/* <MUIDataTable
            title={"Employee List"}
            data={attrs}
            columns={mui_attr_table_rows_name}
            options={options}
          /> */}
        </>
      ) : null;
    }
  };

  showAddAttributeModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  createAttributeModal = async (data) => {
    this.setState({
      modalSpinner: true,
    });
    await this.props
      .createAttribute(data)
      .then((data) => {
        this.showSuccessMessage("Attribute created successfully");

        tableRef.current && tableRef.current.onQueryChange();
      })
      .catch((err) => {
        console.log("create attr error", err);
        this.showErrorMessage("The Attribute has already added !");
      });
    this.setState({
      showModal: !this.state.showModal,
      modalSpinner: false,
    });
  };

  render() {
    return (
      <div
        className={"container"}
        style={{
          backgroundColor: "white",
          marginTop: "70px",
          paddingBottom: "50px",
          border: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <AddAttribute
          showSpinner={this.state.modalSpinner}
          showModal={this.state.showModal}
          createAttribute={this.createAttributeModal}
          toggle={this.showAddAttributeModal}
        />
        <div className={""}>
          <div
            className={
              "py-5 d-flex ml-sm-2 ml-md-5 justify-content-between decision-wrapper"
            }
          >
            <div className={"d-flex ml-3 ml-md-0"}>
              <div className={"attribute-icon-ui-title "}></div>
              <h2 className={"decisions-header-text"}>
                Attributes{" "}
                {/* {this.props.data && this.props.data.length > 0 ? (
                  <span style={{ fontSize: 16 }}>
                    ({this.props.data.length})
                  </span>
                ) : (
                  ""
                )} */}
                <span style={{ fontSize: 16 }}>
                  ({this.state.all_attr_count})
                </span>
              </h2>
            </div>
            <div className={"py-2 mr-sm-2 mr-md-5 new-decision-button-wrapper"}>
              <Button
                theme="success"
                onClick={this.showAddAttributeModal}
                className={"new-decision-button btn-success-custom"}
              >
                <IoIosAddCircleOutline className={"mr-2 font-size-18"} />
                Create New Attribute
                <Icon
                  id="TooltipCreateAttribute"
                  className={"ml-2"}
                  size={20}
                  icon={ic_info}
                />
                <Tooltip
                  open={this.state.create_attribute_tooltip}
                  target="#TooltipCreateAttribute"
                  toggle={() =>
                    this.tooltip_toggle(
                      "create_attribute_tooltip",
                      this.state.create_attribute_tooltip
                    )
                  }
                >
                  You can create new attributes to use in your decisions. You
                  also can use any of the attributes already defined.
                </Tooltip>
              </Button>
            </div>
          </div>
        </div>
        {/* <h5 className={"mt-5 mb-5 font-size-16"}>
          Attributes are skills. Each expert can be proficient in several
          attributes.
        </h5> */}
        {this.props.data ? this._renderTableOrSpinner() : undefined}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { data } = state.allAttributes;
  return {
    data: data.results,
    sessionID: state.session.data.username,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    deleteAttribute,
    fetchAllAttribute,
    createAttribute,
  })(Attr)
);
