import React, {Component} from "react";


class DropUI extends Component {
    render() {
        return (
            <div className={"drop-ui-container"}>
                <div className={this.props.isActive ? "ui-dot my-1 ui-dot-active" : "ui-dot my-1 "}>

                </div>
            </div>
        )
    }
}

export default DropUI;