import React, {Component} from "react";
import {withRouter} from "react-router";
import {NavLink} from "react-router-dom";

class SubmenuDecision extends Component {
    render() {
        return (
            <div className={"submenu-items font-size-13"}>
                <NavLink className={"submenu-item hvr-sweep-to-top"} activeClassName={"submenu-item-active"}
                         to={"/decision/polls"}

                         >
                    All Decisions
                </NavLink>
                <NavLink className={"submenu-item hvr-sweep-to-top"} activeClassName={"submenu-item-active"}
                         to={"/decision/make"} exact>
                    Make New Decision
                </NavLink>
            </div>
        )
    }
}


export default withRouter(SubmenuDecision);