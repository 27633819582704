import React, {Component} from "react";
import Autosuggest from 'react-autosuggest';

export default class AutoComplete extends Component {
    constructor() {
        super();
        this.state = {
            suggestions: []
        };
    }

    shouldRenderSuggestions = (value) => {
        return value.trim().length >= 1;
    };

    componentDidMount() {
        this.setState({
            suggestions: this.props.data
        })
    }

    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 ? this.props.data : this.props.data.filter(lang =>
            (lang.first_name + " " + lang.last_name+ " "+ lang.username).toLowerCase().includes(inputValue) === true
        );
    };

    getSuggestionValue = suggestion => suggestion.email;

    renderSuggestion = suggestion => (
        <div className={"react-auto-suggest-item d-flex align-items-center"}>
            <div
                className={"react-auto-suggest-item-icon"}
                style={{
                    backgroundImage: `url(${suggestion.pic})`,
                }}>
            </div>
            <span className={"ml-2"}>

                {suggestion.first_name}
                <span className={"mx-1"}>

                </span>
                {suggestion.last_name}
            </span>
            {/*<span className={"ml-2"}>*/}
            {/*</span>*/}
            <span className={"ml-1 text-muted font-size-10"}>@{suggestion.username}</span>
        </div>
    );

    onSuggestionsFetchRequested = ({value}) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const {suggestions} = this.state;
        const inputProps = this.props.inputProps;
        return (
            <Autosuggest
                suggestions={suggestions}
                className={"form-control"}
                shouldRenderSuggestions={this.shouldRenderSuggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
            />
        );
    }
}