import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import {
  Button,
  FormInput,
  FormTextarea,
  FormSelect,
  FormRadio,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import { FaRegEdit } from "react-icons/fa";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/core";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import ChangeImage from "./ChangeImage";
import { setProfilePic } from "../../actions/index";
import { connect } from "react-redux";
import { API_URL } from "../../constants";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

/**
 * Personal class is used to change current user profile details.
 */
class Personal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      firstName: "",
      lastName: "",
      bio: "",
      occupation: "",
      coreSkill: "",
      location: "",
      sendingData: false,
      showModal: false,
      modalSpinner: false,
      selectedSex: ""
    };
  }

  showErrorMessage = text =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000
      }
    );

  showSuccessMessage = text =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000
      }
    );

  refactorProfileUrl = url => {
    console.log("url", url);
    if (url && String(url).substring(0, 4) === "http") {
      return url;
    } else {
      console.log("url", url);
      return `${API_URL}/uploads/${url}`;
    }
  };
  componentDidMount() {
    this.setState({
      userName: this.props.data["username"],
      firstName: this.props.data["first_name"] || "",
      lastName: this.props.data["last_name"] || "",
      bio: this.props.data["bio"] || "",
      location: this.props.data["location"] || "",
      occupation: this.props.data["occupation"] || "",
      coreSkill: this.props.data["core_skill"] || "",
      selectedSex: this.props.data.sex
    });
  }

  applyChange = () => {
    if (this.state.userName.length === 0) {
      this.showErrorMessage("Username may not be empty");
      return;
    }
    this.setState({
      sendingData: true
    });
    if (this.state.firstName !== "" && this.state.lastName !== ""){
      let data = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        username: this.state.userName,
        bio: this.state.bio,
        location: this.state.location,
        occupation: this.state.occupation,
        core_skill: this.state.coreSkill,
        sex: this.state.selectedSex
      };
      this.props.setUserProfileInfo(this.props.sessionID, data).then(data => {
        this.setState({
          sendingData: false
        });
        this.showSuccessMessage("Updated !");
      });
    }
    else{
      this.setState({
        sendingData:false
      })
      this.showErrorMessage('please complete first Name and last Name field')
    }
    
  };

  onFocusInput = (e, labelElement) => {
    labelElement.style.color = "#008AFF";
  };

  onBlurInput = (e, labelElement) => {
    labelElement.style.color = "#5a6169";
  };

  showChangePictureModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  refactorUrl = url => {
    console.log("url", url);

    if (url) {
      return url["profile_pic"];
    }
    return null;
  };

  render() {
    console.log(this.state);
    return (
      <Fragment>
        <ChangeImage
          showModal={this.state.showModal}
          uploadImage={this.props.setProfilePic}
          username={this.state.userName}
          toggle={this.showChangePictureModal}
        />

        <div
          className={"form-ui p-3"}
          style={{
            backgroundColor: "white",
            marginTop: "70px",
            paddingBottom: "50px",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          }}
        >
          <form>
            <h3>Profile Picture</h3>
            <hr />
            {console.log("thisasdsdasdasd", this.props.data.pic)}
            <div className="nav-profile-avatar d-flex align-items-center">
              {/* <div className="profile-image"
                                 style={{backgroundImage: `url(${this.refactorProfileUrl(
                                    this.props.data.pic
                                  )})`}}>
                            </div> */}
              <span
                className={"nav-profile-avatar d-flex align-items-center"}
                style={{ fontWeight: "Normal" }}
              >
                <div
                  style={{
                    backgroundImage: `url(${this.refactorProfileUrl(
                      this.props.data.pic
                    )})`
                  }}
                  className={"avater-img mr-1"}
                ></div>
              </span>
              <Button
                onClick={this.showChangePictureModal}
                theme={"primary"}
                className={"ml-2"}
                size={"sm"}
              >
                Change Image
              </Button>
              {/*<AvatarEditor*/}
              {/*ref={this.setEditorRef}*/}
              {/*image="https://i.pinimg.com/736x/ed/ac/e1/edace1e0aa1fbc9942516580560a8463--happy-dance-just-dance.jpg"*/}
              {/*width={250}*/}
              {/*height={250}*/}
              {/*border={50}*/}
              {/*color={[255, 255, 255, 0.6]} // RGBA*/}
              {/*scale={1.2}*/}
              {/*rotate={0}*/}
              {/*/>*/}
            </div>
            <hr />
            <h3>Personal Information</h3>
            <hr />
            <div className={"row mb-3"}>
              <div className={"col-12 col-md-6 my-1"}>
                <label
                  className={"form-ui-label font-size-13 fw-600"}
                  ref={element => (this.elementUsername = element)}
                >
                  Username
                </label>
                <InputGroup seamless>
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <FaRegEdit />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    disabled
                    invalid={this.state.invalidUserName}
                    onChange={e =>
                      this.setState({
                        userName: e.target.value,
                        invalidUserName: false
                      })
                    }
                    value={this.state.userName}
                    onFocus={e => this.onFocusInput(e, this.elementUsername)}
                    onBlur={e => this.onBlurInput(e, this.elementUsername)}
                  />
                </InputGroup>
              </div>
            </div>

            <div className={"row"}>
              <div className={"col-12 col-md-6 my-1"}>
                <label
                  className={"form-ui-label font-size-13 fw-600"}
                  ref={element => (this.elementFirstName = element)}
                >
                  First Name
                </label>
                <InputGroup seamless>
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <FaRegEdit />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    invalid={this.state.invalidFirstName}
                    onChange={e =>
                      this.setState({
                        firstName: e.target.value,
                        invalidFirstName: false
                      })
                    }
                    value={this.state.firstName}
                    onFocus={e => this.onFocusInput(e, this.elementFirstName)}
                    onBlur={e => this.onBlurInput(e, this.elementFirstName)}
                  />
                </InputGroup>
              </div>
              <div className={"col-12 col-md-6 my-1"}>
                <label
                  className={"form-ui-label font-size-13 fw-600"}
                  ref={element => (this.elementLastName = element)}
                >
                  Last name
                </label>
                <InputGroup seamless>
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <FaRegEdit />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    invalid={this.state.invalidLastName}
                    onChange={e =>
                      this.setState({
                        lastName: e.target.value,
                        invalidLastName: false
                      })
                    }
                    value={this.state.lastName}
                    onFocus={e => this.onFocusInput(e, this.elementLastName)}
                    onBlur={e => this.onBlurInput(e, this.elementLastName)}
                  />
                </InputGroup>
              </div>
            </div>
            <div className={"row mb-3"}>
              <div className={"col-12 col-md-6 m-0 mt-4"}>
                <div className="form-group d-flex m-0">
                  <span className="m-0 ml-1 mr-2">Sex:</span>
                  <FormRadio
                    inline
                    name="Sex"
                    checked={this.state.selectedSex === "Male"}
                    onChange={() => {
                      this.setState({
                        selectedSex: "Male"
                      });
                    }}
                  >
                    Male
                  </FormRadio>
                  <FormRadio
                    inline
                    name="Sex"
                    checked={this.state.selectedSex === "Female"}
                    onChange={() => {
                      this.setState({
                        selectedSex: "Female"
                      });
                    }}
                  >
                    Female
                  </FormRadio>
                  <FormRadio
                    inline
                    name="Sex"
                    checked={this.state.selectedSex === "Other"}
                    onChange={() => {
                      this.setState({
                        selectedSex: "Other"
                      });
                    }}
                  >
                    Other
                  </FormRadio>
                </div>
              </div>
              {/*<div className={"col-12 col-md-6 my-1"}>*/}
              {/*<label className={"form-ui-label font-size-13 fw-600"}*/}
              {/*ref={(element) => this.elementBio = element}>*/}
              {/*Bio*/}
              {/*</label>*/}
              {/*<InputGroup seamless>*/}
              {/*<InputGroupAddon type="prepend">*/}
              {/*<InputGroupText>*/}
              {/*<FaRegEdit/>*/}
              {/*</InputGroupText>*/}
              {/*</InputGroupAddon>*/}
              {/*<FormTextarea*/}
              {/*invalid={this.state.invalidBio}*/}
              {/*onChange={(e) => this.setState({*/}
              {/*bio: e.target.value,*/}
              {/*invalidBio: false*/}
              {/*})}*/}
              {/*value={this.state.bio}*/}
              {/*onFocus={(e) => this.onFocusInput(e, this.elementBio)}*/}
              {/*onBlur={(e) => this.onBlurInput(e, this.elementBio)}/>*/}
              {/*</InputGroup>*/}
              {/*</div>*/}
            </div>
            <div className={"row mb-3"}>
              <div className={"col-12 col-md-6 my-1"}>
                <label
                  className={"form-ui-label font-size-13 fw-600"}
                  ref={element => (this.elementOccupation = element)}
                >
                  Industry
                </label>
                <InputGroup seamless>
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <FaRegEdit />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormSelect
                    invalid={this.state.invalidOccupation}
                    onChange={e =>
                      this.setState({
                        occupation: e.target.value,
                        invalidOccupation: false
                      })
                    }
                    value={this.state.occupation}
                    onFocus={e => this.onFocusInput(e, this.elementOccupation)}
                    onBlur={e => this.onBlurInput(e, this.elementOccupation)}
                  >
                    <option value="" disabled selected>
                      Select your industry
                    </option>
                    <option value="Aerospace industry">
                      Aerospace industry
                    </option>
                    <option value="Agriculture">Agriculture</option>
                    <option value="Chemical industry">Chemical industry</option>
                    <option value="Computer industry">Computer industry</option>
                    <option value="Construction industry">
                      Construction industry
                    </option>
                    <option value="Defense industry">Defense industry</option>
                    <option value="Education industry">
                      Education industry
                    </option>
                    <option value="Energy industry">Energy industry</option>
                    <option value="Entertainment industry">
                      Entertainment industry
                    </option>
                    <option value="Financial services">
                      Financial services industry
                    </option>
                    <option value="Food industry">Food industry</option>
                    <option value="Government organization">
                      Government organization
                    </option>
                    <option value="Health care">Health care industry</option>
                    <option value="Hospitality industry">
                      Hospitality industry
                    </option>
                    <option value="Information industry">
                      Information industry
                    </option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Mass media">Mass media</option>
                    <option value="Mining">Mining</option>
                    <option value="Telecommunications industry">
                      Telecommunications industry
                    </option>
                    <option value="Transport industry">
                      Transport industry
                    </option>
                    <option value="Water industry">Water industry</option>
                    <option value="Services">Services</option>
                    <option value="Other">Other</option>
                  </FormSelect>

                  {/* </FormSelect>
                                    <FormInput
                                        invalid={this.state.invalidOccupation}
                                        onChange={(e) => this.setState({
                                            occupation: e.target.value,
                                            invalidOccupation: false
                                        })}
                                        value={this.state.occupation}
                                        onFocus={(e) => this.onFocusInput(e, this.elementOccupation)}
                                        onBlur={(e) => this.onBlurInput(e, this.elementOccupation)}
                                        /> */}
                </InputGroup>
              </div>
              <div className={"col-12 col-md-6 my-1"}>
                <label
                  className={"form-ui-label font-size-13 fw-600"}
                  ref={element => (this.elementLocation = element)}
                >
                  Country
                </label>
                <InputGroup seamless>
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <FaRegEdit />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormSelect
                    onChange={e =>
                      this.setState({
                        location: e.target.value,
                        invalidLocation: false
                      })
                    }
                    value={this.state.location}
                  >
                    <option value="" disabled selected>
                      Select your country
                    </option>
                    <option value="Afganistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bonaire">Bonaire</option>
                    <option value="Bosnia & Herzegovina">
                      Bosnia & Herzegovina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Ter">
                      British Indian Ocean Ter
                    </option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Canary Islands">Canary Islands</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Channel Islands">Channel Islands</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos Island">Cocos Island</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote DIvoire">Cote DIvoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curaco">Curacao</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands">Falkland Islands</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Ter">
                      French Southern Ter
                    </option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Great Britain">Great Britain</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="India">India</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea North">Korea North</option>
                    <option value="Korea Sout">Korea South</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macau">Macau</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Midway Islands">Midway Islands</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Nambia">Nambia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherland Antilles">
                      Netherland Antilles
                    </option>
                    <option value="Netherlands">
                      Netherlands (Holland, Europe)
                    </option>
                    <option value="Nevis">Nevis</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau Island">Palau Island</option>
                    <option value="Palestine">Palestine</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Phillipines">Philippines</option>
                    <option value="Pitcairn Island">Pitcairn Island</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Republic of Montenegro">
                      Republic of Montenegro
                    </option>
                    <option value="Republic of Serbia">
                      Republic of Serbia
                    </option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="St Barthelemy">St Barthelemy</option>
                    <option value="St Eustatius">St Eustatius</option>
                    <option value="St Helena">St Helena</option>
                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                    <option value="St Lucia">St Lucia</option>
                    <option value="St Maarten">St Maarten</option>
                    <option value="St Pierre & Miquelon">
                      St Pierre & Miquelon
                    </option>
                    <option value="St Vincent & Grenadines">
                      St Vincent & Grenadines
                    </option>
                    <option value="Saipan">Saipan</option>
                    <option value="Samoa">Samoa</option>
                    <option value="Samoa American">Samoa American</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome & Principe">
                      Sao Tome & Principe
                    </option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syria</option>
                    <option value="Tahiti">Tahiti</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Erimates">
                      United Arab Emirates
                    </option>
                    <option value="United States of America">
                      United States of America
                    </option>
                    <option value="Uraguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican City State">
                      Vatican City State
                    </option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Virgin Islands (Brit)">
                      Virgin Islands (Brit)
                    </option>
                    <option value="Virgin Islands (USA)">
                      Virgin Islands (USA)
                    </option>
                    <option value="Wake Island">Wake Island</option>
                    <option value="Wallis & Futana Is">
                      Wallis & Futana Is
                    </option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zaire">Zaire</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </FormSelect>
                  {/* <FormInput
                                        invalid={this.state.invalidLocation}
                                        onChange={(e) => this.setState({
                                            location: e.target.value,
                                            invalidLocation: false
                                        })}
                                        value={this.state.location}
                                        onFocus={(e) => this.onFocusInput(e, this.elementLocation)}
                                        onBlur={(e) => this.onBlurInput(e, this.elementLocation)}/> */}
                </InputGroup>
              </div>
            </div>
          </form>
          <Button theme={"primary"} onClick={this.applyChange}>
            {this.state.sendingData ? (
              <div className="sweet-loading">
                <PulseLoader
                  css={override}
                  sizeUnit={"px"}
                  color={"white"}
                  size={7}
                  loading={true}
                />
              </div>
            ) : (
              "Save"
            )}
            <IoMdCheckmarkCircle className={"ml-2 font-size-19"} />
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(connect(null, { setProfilePic })(Personal));
