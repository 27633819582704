import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  finalizeDecision,
  getFinalizedPollAlternative,
  getCommentById,
  fetchUserSession
} from "../actions/index";


import toast from "toasted-notes";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";

import CanvasJSReact from "../canvasjs-2.3.2/canvasjs.react";
import { css } from "@emotion/core";
import { ScaleLoader } from "react-spinners";
import { API_URL } from "../constants";
import { authHeader } from "../actions/AuthHeader";
import {
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  FormInput,
  FormTextarea,
  Fade,
  FormCheckbox,
  Container,
  Row,
  Col,
  Tooltip,
} from "shards-react";
import { FaLock } from "react-icons/fa";

import Modal from "react-responsive-modal";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MwsTable from "./MwsTable";
import EwsTable from "./EwsTable";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { ic_info } from "react-icons-kit/md/ic_info";
import { Icon } from "react-icons-kit";
import { Button } from "shards-react";

const columns = [
  {
    id: "0",
    label: "Rank",
    align: "center",
  },
  {
    id: "1",
    label: "Alternative",
    align: "center",
  },
  {
    id: "2",
    label: "Merit Weighted Score (MWS*)",
    align: "center",
  },
  {
    id: "3",
    label: "Not desirable",
    align: "center",
  },
  {
    id: "4",
    label: "Desirable",
    align: "center",
  },
];

const rows = [
  { id: "services", numeric: "false" },
  { id: "requests", numeric: "true" },
  { id: "fat", numeric: "true" },
  { id: "carbs", numeric: "true" },
  { id: "protein", numeric: "true" },
];

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
/**
 * Finalize class renders the result of Finalizing a poll.
 */
class Finalize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      finalize: "",
      data: [],
      comment: [],
      all_comments_data: false,
      all_comments: [],
      page: 0,
      rowsPerPage: 10,
      selectedAlternative: { name: "", description: "" },
      poll: [],
      poll_data: false,
      final_user_weight_data: false,
      attribute_importance_data: false,
    };
  }

  tooltip_toggle(name, stat) {
    this.setState({
      [name]: !stat,
    });
  }

  refactorProfileUrl = (url) => {
    if (url && String(url).substring(0, 4) === "http") {
      return url;
    } else {
      console.log(url);
      return `${API_URL}/uploads/${url["profile_pic"]}`;
    }
  };
  async componentDidMount() {
    let pollId = this.props.match.params["id"];
    // const { dispatch } = this.props;
    // await dispatch(fetchUserSession());
    console.log("finalizze user ", this.props.user);
    this.setState({
      isLoading: true,
    });

    await fetch(`${API_URL}/polls/${pollId}/`)
      .then((res) => {
        if (res.status === 400) {
          this.setState({
            gotError: true,
          });
        }
        return res.json();
      })
      .then((data) => {
        console.log("data", data);
        if (this.props.match.params.partial === undefined) {
          this.setState({
            poll: data,
            poll_data: true,
          });
          return this.get_final_data(pollId);
        } else {
          // call partial
          console.log("here");
          this.setState({
            poll: data,
            poll_data: true,
          });
          return this.get_partial(pollId);
        }
      });

    await this.get_poll_vote();
    await this.get_engine();
    await this.get_ews_data();
    await this.get_chart_user_Weights_believability();
    await this.get_chart_attribute_importance();

    await this.allComments();
  }

  get_poll_vote = () => {
    const pollId = this.props.match.params["id"];

    const endpoint = `polls/${pollId}/poll_vote_data/`;
    const options = {
      method: "GET",
    };
    fetch(`${API_URL}/${endpoint}`, {
      ...options,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((data) => data.json())
      .then((res) => {
        console.log("test", res);

        // if (res.data === "voted") {
        this.setState({
          particpate_vote: res,
          poll_vote_data: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  get_chart_user_Weights_believability = () => {
    const pollId = this.props.match.params["id"];

    const endpoint = `polls/${pollId}/final_UserWeight_believability/`;
    const options = {
      method: "GET",
    };
    fetch(`${API_URL}/${endpoint}`, {
      ...options,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log("final_user_weight", data);
        this.setState({
          final_user_weight: data,
          final_user_weight_data: true,
        });
      });
  };

  get_ews_data = () => {
    const pollId = this.props.match.params["id"];

    const endpoint = `polls/${pollId}/final_EqualWeightedScore/`;
    const options = {
      method: "GET",
    };
    fetch(`${API_URL}/${endpoint}`, {
      ...options,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log("final_ews", data);
        data.sort((a, b) => (a.rate < b.rate ? 1 : -1));

        this.setState({
          ews_table: data,
          ews_table_data: true,
        });
      });
  };

  get_engine = () => {
    const pollId = this.props.match.params["id"];

    const endpoint = `polls/${pollId}/final_Engine/`;
    const options = {
      method: "GET",
    };
    fetch(`${API_URL}/${endpoint}`, {
      ...options,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log("engine", data.engine);

        this.setState({
          engine: data,
          engine_data: true,
        });
      });
  };

  get_chart_attribute_importance = () => {
    const pollId = this.props.match.params["id"];

    const endpoint = `polls/${pollId}/final_AttributeImportance/`;
    const options = {
      method: "GET",
    };
    fetch(`${API_URL}/${endpoint}`, {
      ...options,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log("final_attribute_importance", data);
        this.setState({
          attribute_importance: data,
          attribute_importance_data: true,
        });
      });
  };

  allComments = () => {
    const pollId = this.props.match.params["id"];

    const endpoint = `polls/${pollId}/all_poll_comments`;
    const options = {
      method: "GET",
    };

    fetch(`${API_URL}/${endpoint}/`, {
      ...options,
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      }),
    })
      .then((data) => data.json())
      .then((res) => {
        // console.log("all_comments", res);
        this.setState({
          all_comments_data: true,
          all_comments: res,
        });
      });
  };

  get_partial = async (pollId) => {
    const options = {
      method: "GET",
    };
    await fetch(`${API_URL}/polls/${pollId}/partial/`, {
      ...options,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    })
      .then((res) => {
        if (res.status === 400) {
          this.setState({
            gotError: true,
          });
        }
        return res.json();
      })
      .then((data) => {
        console.log("data is ", data);
      });
    await this.get_ews_data();

    await this.props.getFinalizedPollAlternative(pollId).then((data) => {
      console.log("alter is ", data);
      data.sort((a, b) => (a.rate < b.rate ? 1 : -1));
      this.setState({
        data: data,
      });
    });

    this.setState({
      isLoading: false,
    });
  };

  get_final_data = async (pollId) => {
    // await this.props.finalizeDecision(pollId)
    //   .then(data =>
    //     this.setState({
    //       finalize: data
    //     })
    //   )
    //   .catch(err => console.log(err));
    await this.get_ews_data();
    await this.props
      .getCommentById(pollId)
      .then((data) => this.setState({ comment: data }))
      .catch((err) => console.log(err));

    await this.props.getFinalizedPollAlternative(pollId).then((data) => {
      data.sort((a, b) => (a.rate < b.rate ? 1 : -1));
      console.log("final is ", data);
      this.setState({
        data: data,
      });
    });

    this.setState({
      isLoading: false,
    });
  };

  _renderTableOrSpinner = () => {
    if (this.state.isLoading) {
      return (
        <div className={"spinner-loader mt-5"}>
          <div className="sweet-loading">
            <ScaleLoader
              css={override}
              sizeUnit={"px"}
              size={150}
              color={"#FF8260"}
              loading={this.state.isLoading}
            />
          </div>
        </div>
      );
    } else {
      if (this.props.user) return this._renderChart();
    }
  };

  createMarkup = (text) => {
    return { __html: text };
  };

  toggleShowAlternativeDescription = (alter, desc) => {
    let { selectedAlternative } = this.state;
    selectedAlternative["name"] = alter;
    selectedAlternative["description"] = desc;
    this.setState({
      showAlternativeDescription: !this.state.showAlternativeDescription,
      selectedAlternative,
    });
  };

  closeAlternativeDescription = () => {
    this.setState({
      showAlternativeDescription: !this.state.showAlternativeDescription,
    });
  };

  make_attribute_importance_chart = () => {
    if (this.state.attribute_importance_data) {
      var attribute_importance_sum = 0;
      this.state.attribute_importance.map((item, index) => {
        attribute_importance_sum += item.rate;
      });
      let attribute_importance_dataPoints = this.state.attribute_importance.map(
        (item, index) => {
          return {
            label:
              item["attr"] +
              `, ${Math.floor(
                (item["rate"] * 100) / attribute_importance_sum
              )}%`,
            y: (item["rate"] * 100) / attribute_importance_sum,
          };
        }
      );
      var attribute_importance_options = {
        animationEnabled: true,
        exportEnabled: true,
        theme: "light1",
        data: [
          {
            type: "pie",
            dataPoints: attribute_importance_dataPoints,
            toolTipContent: "{label}:",
            // indexLabel: "{y}%",
            indexLabelPlacement: "inside",
          },
        ],
      };
    }

    return (
      <CanvasJSReact.CanvasJSChart options={attribute_importance_options} />
    );
  };
  showErrorMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 4000,
      }
    );
  show_ews_modal = () => {
    // if (this.props.user.trial.user_type !== "Free") {
      this.setState({
        ews_modal: !this.state.ews_modal,
      });
    // } else {
    //   return this.showErrorMessage("Upgrade to Premium");
    // }
  };

  make_user_weight_chart = () => {
    if (this.state.final_user_weight_data) {
      var final_user_weight_sum = 0;
      this.state.final_user_weight.map((item, index) => {
        final_user_weight_sum += item.rate;
      });
      let attribute_importance_dataPoints = this.state.final_user_weight.map(
        (item, index) => {
          if (item.rate > 0) {
            console.log("weight");
            return {
              label:
                item["user"] +
                `, ${Math.floor(
                  (item["rate"] * 100) / final_user_weight_sum
                )}%`,
              y: (item["rate"] * 100) / final_user_weight_sum,
            };
          }
        }
      );
      attribute_importance_dataPoints = attribute_importance_dataPoints.filter(
        function (element) {
          return element !== undefined;
        }
      );
      var final_user_weight_options = {
        animationEnabled: true,
        exportEnabled: true,
        // theme: "dark2",
        data: [
          {
            type: "pie",
            dataPoints: attribute_importance_dataPoints,
            //   showInLegend: true,
            // legendText: "{label}",
            toolTipContent: "{label}:",
            // indexLabel: "{y}%",
            indexLabelPlacement: "inside",
          },
        ],
      };
    }

    return <CanvasJSReact.CanvasJSChart options={final_user_weight_options} />;
  };

  _renderChart = () => {
    const bg = {};
    return (
      <Fragment>
        {this.props.user.trial ? (
          <>
            <Modal
              animationDuration={100}
              open={this.state.ews_modal}
              onClose={this.show_ews_modal}
              center
              styles={bg}
            >
              {this.state.engine_data ? (
                <EwsTable
                  data={this.state.ews_table}
                  user={this.props.user}
                  engine={this.state.engine}
                />
              ) : undefined}
              <div className="row">
                <div className="col-12 col-md-12 font-size-12">
                  <p>
                    * Equal weighted score(EWS) assumes same weight for all
                    voters. This is a democratic result.{" "}
                  </p>
                </div>
              </div>
            </Modal>

            <Modal
              open={this.state.showAlternativeDescription}
              onClose={this.closeAlternativeDescription}
              animationDuration={100}
              center
            >
              <div className={"modal-responsive"}>
                <h4>
                  {this.state.selectedAlternative["name"]
                    ? this.state.selectedAlternative["name"].length > 30
                      ? this.state.selectedAlternative["name"].substring(
                          0,
                          30
                        ) + "..."
                      : this.state.selectedAlternative["name"]
                    : ""}
                </h4>
                <hr />
                <div
                  className={"alter-description-area p-0 m-0"}
                  dangerouslySetInnerHTML={this.createMarkup(
                    this.state.selectedAlternative["description"] ||
                      "This alternative does not have description."
                  )}
                ></div>
              </div>
            </Modal>
            <h2 className="mt-4 decision-result" style={{ color: "#0786FE" }}>
              Decision Results
            </h2>
            <hr />
            {this.state.engine_data ? (
              <MwsTable
                data={this.state.data}
                user={this.props.user}
                engine={this.state.engine}
              />
            ) : undefined}

            <div className="row">
              <div className="col-12 col-md-12 font-size-12">
                <p>
                  * Merit Weighted Score(MWS) is final score of each alternative
                  where believability of exprts have been applied. A positive
                  number is more favorable.{" "}
                </p>
              </div>
            </div>

            {/* <Container>
          <Row style={{margin:"auto"}}>
            <Col
              sm={{ size: 8, order: 2, offset: 2 }}
              md={{ size: 8, order: 2, offset: 2 }}
              lg={{ size: 6, offset: 4 }}
            >
              See equal weighted results
            </Col>
          </Row>
        </Container> */}
            <div className={"row"}>
              <div
                className={"col-12 col-md-3 mt-2 "}
                style={{ margin: "auto" }}
              >
                {/* See equal weighted results */}

                <Button
                  theme="secondary"
                  className={"btn mr-3 px-5 new-decision-button  btn "}
                  onClick={this.show_ews_modal}
                >
                  See equal weighted results
                  <Icon
                    id="TooltipPartialResult"
                    className={"ml-2"}
                    size={20}
                    icon={ic_info}
                  />
                  <Tooltip
                    open={this.state.partial_result_tooltip}
                    target="#TooltipPartialResult"
                    toggle={() =>
                      this.tooltip_toggle(
                        "partial_result_tooltip",
                        this.state.partial_result_tooltip
                      )
                    }
                  >
                    This is results without applying the believability weight of
                    experts. This shows a democratic result where all
                    participants get the same weight.
                  </Tooltip>
                </Button>
              </div>
            </div>
            {/* {this.props.user.trial.user_type === "Free" ? (
              <div className={"row"}>
                <div
                  className={"col-12 col-md-4 mt-2 pl-5"}
                  style={{ margin: "auto" }}
                >
                  <span
                    className={"ml-4 mb-4 deco px-5 text-center"}
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    <FaLock />{" "}
                    <a
                      href="/pricing"
                      className={"orange"}
                      style={{ color: "orange", fontSize: "12px" }}
                    >
                      Upgrade to Premium
                    </a>
                  </span>
                </div>
              </div>
            ) : (
              ""
            )} */}

            <div
              className={"poll-wrapper"}
              style={{ backgroundColor: "#fafafa" }}
            >
              <div className={"poll-data-wrapper container "}>
                <div className={"poll-data-body mx-3 mx-md-5 my-2"}>
                  <div className={"row"}>
                    <div className={"col-12 col-md-3 mt-2"}>
                      <h6 className={"fw-600 font-size-13"}>
                        No of Participants:
                      </h6>
                    </div>
                    <div className={"col-12 col-md-1 mt-2"}>
                      {this.state.poll_data
                        ? this.state.poll.participants.length
                        : undefined}
                    </div>
                    <div className={"col-12 col-md-8 d-flex flex-wrap"}>
                      {this.state.poll_data ? (
                        // console.log("asddsd", this.props.data),
                        this.state.poll["participants"].map((item, index) => (
                          // console.log(item),
                          <Link
                            to={`/people/users/${item["username"]}`}
                            key={index}
                            className={"m-0 poll-data-header-creator fw-300"}
                          >
                            <span
                              className={
                                "nav-profile-avatar d-flex align-items-center mr-4"
                              }
                              style={{ fontWeight: "Normal" }}
                            >
                              <div
                                style={{
                                  backgroundImage: `url(${this.refactorProfileUrl(
                                    item["pic"]
                                  )})`,
                                }}
                                className={"avater-img mr-1"}
                              ></div>
                              {item["first_name"] + " " + item["last_name"]}
                            </span>
                          </Link>
                        ))
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={"poll-data-body mx-3 mx-md-5 my-2"}>
                  <div className={"row"}>
                    <div className={"col-12 col-md-3 mt-2"}>
                      <h6 className={"fw-600 font-size-13"}>
                        Votes submitted:
                      </h6>
                    </div>
                    {/* <div className={"col-12 col-md-1 mt-2"}>
                {this.state.poll_data
                    ? this.state.poll.attributes.length:""}
                </div> */}
                    {/* <p className={'mx-md-2'}>{columns.length}</p> */}

                    {this.state.poll_vote_data ? (
                      <div className={"col-12 col-md-8 mt-2"}>
                        <p>
                          {this.state.particpate_vote.voted_participant}/
                          {this.state.particpate_vote.all_participants}{" "}
                          <span className={"ml-2"}>
                            (
                            {parseInt(
                              (this.state.particpate_vote.voted_participant /
                                this.state.particpate_vote.all_participants) *
                                100
                            )}
                            %)
                          </span>
                        </p>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* (
                      {this.state.particpate_vote.voted_participant /
                        this.state.particpate_vote.all_participants}
                      %) */}
                  </div>
                </div>

                <div className={"poll-data-body mx-3 mx-md-5 my-2"}>
                  <div className={"row"}>
                    <div className={"col-12 col-md-3 "}>
                      <h6 className={"fw-600 font-size-13"}>
                        No of attributes:
                      </h6>
                    </div>
                    <div className={"col-12 col-md-1 "}>
                      {this.state.poll_data
                        ? this.state.poll.attributes.length
                        : ""}
                    </div>
                    {/* <p className={'mx-md-2'}>{columns.length}</p> */}
                    <div className={"col-12 col-md-8 d-flex flex-wrap"}>
                      {this.state.poll_data
                        ? this.state.poll.attributes.map((item, index) => {
                            return [
                              <Link to={`/attribute/t/`} key={index}>
                                <span className={"attribute-badge"}>
                                  {item}
                                </span>
                              </Link>,
                            ];
                          })
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
          <div className="col-12 col-md-8">
            <div style={{backgroundColor:'gray' ,margin:0,}}>
                See equal weighted results
            </div>
          </div>
        </div> */}

            <h2 className="mt-4 decision-result" style={{ color: "#0786FE" }}>
              Decision Analytics
            </h2>
            <hr />
            <div className={"row"}>
              <div className={"col-12 col-md-6"}>
                {/* {this.props.user.trial.user_type === "Free" ? (
                  <p
                    className={"mt-5 mb-5 fw-600 font-size-16 text-center"}
                    style={{ color: "blue" }}
                  >
                    Importance of Attributes in this Decision
                    <br />
                    <span
                      className={"ml-4 mb-4 deco"}
                      style={{ color: "red", fontSize: "15px" }}
                    >
                      <FaLock />{" "}
                      <a
                        href="/pricing"
                        className={"orange"}
                        style={{ color: "orange", fontSize: "15px" }}
                      >
                        Upgrade to Premium
                      </a>
                    </span>
                  </p>
                ) : ( */}
                  <div>
                    <div
                      className={"mt-4 row text-center mb-3"}
                      style={{ margin: "auto", color: "blue" }}
                    >
                      IMPORTANCE OF ATTRIBUTES IN THIS DECISION
                    </div>
                    {this.state.attribute_importance_data
                      ? this.make_attribute_importance_chart()
                      : ""}
                  </div>
                {/* )} */}
              </div>
              {/* <p className={'mx-md-2'}>{columns.length}</p> */}
              <div className={"col-12 col-md-6"}>
                {/* {this.props.user.trial.user_type === "Free" ? (
                  <p
                    className={"mt-5 mb-5 fw-600 font-size-16 text-center"}
                    style={{ color: "blue" }}
                  >
                    Believability of Experts In this Decision
                    <br />
                    <span
                      className={"ml-4 mb-4 deco"}
                      style={{ color: "red", fontSize: "15px" }}
                    >
                      <FaLock />{" "}
                      <a
                        href="/pricing"
                        className={"orange text-center"}
                        style={{ color: "orange", fontSize: "15px" }}
                      >
                        Upgrade to Premium
                      </a>
                    </span>
                  </p>
                ) : ( */}
                  <div>
                    <div
                      className={" mt-4 row text-center mb-3"}
                      style={{ margin: "auto", color: "blue" }}
                    >
                      BELIEVABILITY OF EXPERTS IN THIS DECISION
                    </div>
                    {this.state.final_user_weight_data
                      ? this.make_user_weight_chart()
                      : ""}
                  </div>
                {/* )} */}
              </div>
            </div>

            <hr />
            <h5>See comments about decision</h5>
            {this.state.all_comments_data
              ? this.state.all_comments.reverse().map((item, index) => {
                  return [
                    <div
                      key={index}
                      className={"  m-md-2 d-flex justify-content-between"}
                    >
                      <div>
                        <small className={"text-muted"}>
                          {/* <Link
                  to={`/people/users/${this.props.data["created_by"]}`}
                  className={"ml-2 poll-data-header-creator"}
                >
                  @{this.props.data["created_by"]}
                </Link> */}
                          <Link
                            to={`/people/users/${item.user.username}`}
                            className={"m-0 poll-data-header-creator fw-300"}
                          >
                            <span
                              className={
                                "nav-profile-avatar d-flex align-items-center "
                              }
                              style={{ fontWeight: "Normal" }}
                            >
                              <div
                                style={{
                                  backgroundImage: `url(${this.refactorProfileUrl(
                                    item.user["pic"]
                                  )})`,
                                }}
                                className={"avater-img mr-1"}
                              ></div>
                              {item.user["first_name"] +
                                " " +
                                item.user["last_name"]}
                            </span>
                          </Link>

                          <span className={"ml-5"}>
                            {new Date(item["post_date"])
                              .toUTCString()
                              .slice(0, 16)}
                          </span>
                        </small>
                      </div>
                      <div className={" col-9 col-md-9 mr-5"}>
                        <FormTextarea disabled value={item.comment} />
                      </div>
                    </div>,
                  ];
                })
              : ""}
          </>
        ) : undefined}
      </Fragment>
    );
  };

  render() {
    return (
      <div
        className={"container"}
        style={{
          backgroundColor: "white",
          marginTop: "70px",
          paddingBottom: "50px",
          border: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        {this.props.user.trial ? this._renderTableOrSpinner() : undefined}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("finalized state", state);
  return { user: state.session.data };
};

export default withRouter(
  connect(mapStateToProps, {
    // fetchUserSession,
    finalizeDecision,
    getFinalizedPollAlternative,
    getCommentById,
  })(Finalize)
);
