import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { FormTextarea, Button } from "shards-react";
import toast from "toasted-notes/lib/index";
import "toasted-notes/src/styles.css";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { css } from "@emotion/core";
import { PulseLoader } from "react-spinners";
import { API_URL } from "../../constants";
import { authHeader } from "../../actions/AuthHeader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: "",
      invalidProblemDetails: false,
      Sending: false
    };
  }

  showSuccessMessage = text =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000
      }
    );

  showErrorMessage = text =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000
      }
    );

  sendFeedback = () => {
    this.setState({
      feedback: "",
      Sending: true,
      invalidProblemDetails: false
    });
    const postData = {
      feedback: this.state.feedback
    };
    fetch(`${API_URL}/feedback/`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:authHeader()
      }
    })
      .then(res => res.json())
      .then(data => {
        console.log("herer", data);
        this.setState({
          Sending: false,
          
        });
        this.showSuccessMessage('thanks for your feedback')
        this.props.onCloseModal()
      });

    // return this.props.onCloseModal()
  };

  render() {
    return (
      <Modal
        open={this.props.show}
        onClose={this.props.onCloseModal}
        animationDuration={100}
        center
      >
        <div className={"modal-responsive"}>
          <h4>We love to hear your feedback</h4>
          <hr />
          <div>
            <form onSubmit={e => e.preventDefault()}>
              <div className={"row my-3"}>
                <div className="col">
                  <label
                    className={"form-ui-label font-size-13 fw-600"}
                    ref={element => (this.elementOpportunityDetails = element)}
                  >
                    Feedback:
                  </label>
                  <FormTextarea
                    invalid={this.state.invalidProblemDetails}
                    onChange={e =>
                      this.setState({
                        feedback: e.target.value,
                        invalidProblemDetails: false
                      })
                    }
                    value={this.state.feedback}
                  />
                </div>
              </div>
            </form>
            <div className={"d-flex justify-content-end"}>
              <Button onClick={this.sendFeedback}>
                {this.state.Sending ? (
                  <div className="sweet-loading">
                    <PulseLoader
                      css={override}
                      sizeUnit={"px"}
                      color={"white"}
                      size={7}
                      loading={true}
                    />
                  </div>
                ) : (
                  "Send"
                )}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
