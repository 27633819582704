import React, {Component} from "react";
import {withRouter} from "react-router";

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";


/**
 * MakeDecision class renders three step of creating a decision.
 */
class MakeDecision extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            dotClass: [0, 1, 2],
        }
    }

    goToNextSlide = () => {
        if (this.state.index <= 1) {
            this.setState({
                index: this.state.index + 1
            })
        }
    };

    goToPrevSlide = () => {
        if (this.state.index >= 1) {
            this.setState({
                index: this.state.index - 1
            })
        }
    };
    componentDidMount() {
        // window.addEventListener("beforeunload", this.keepOnPage);
        // window.addEventListener("beforeunload", (ev) => {
        //   ev.preventDefault();
        //   return (ev.returnValue = "Are you sure you want to close?");
        // });
        // window.addEventListener("beforeunload", function (e) {
        //     var confirmationMessage = "fuck";
          
        //     (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        //     return confirmationMessage;                            //Webkit, Safari, Chrome
        //   });
        window.onbeforeunload = function () {
            return "Do you really want to close?";
        };
      }
    render() {
        let decisionId = this.props.match.params.id;
        return (
            <div className={"ui-container d-flex p-0 mb-4"}>
                <StepOne dotClass={this.state.dotClass}
                         index={this.state.index}
                         goToNextSlide={this.goToNextSlide}
                         goToPrevSlide={this.goToPrevSlide}
                         activeKey={this.state.index}
                         tabKey={0}
                         dataId={decisionId}
                />
                <StepTwo dotClass={this.state.dotClass}
                         index={this.state.index}
                         goToNextSlide={this.goToNextSlide}
                         goToPrevSlide={this.goToPrevSlide}
                         activeKey={this.state.index}
                         tabKey={1}
                         dataId={decisionId}
                />
                <StepThree dotClass={this.state.dotClass}
                           index={this.state.index}
                           goToNextSlide={this.goToNextSlide}
                           goToPrevSlide={this.goToPrevSlide}
                           activeKey={this.state.index}
                           tabKey={2}
                           dataId={decisionId}
                />
            </div>
        )
    }
};


export default withRouter(MakeDecision);