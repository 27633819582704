import React, { Component, Fragment, useState, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import { MdEmail } from "react-icons/md";

import {
  Row,
  Col,
  Container,
  Button,
  FormInput,
  InputGroup,
  FormSelect,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";
import { connect } from "react-redux";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import { API_URL } from "../../constants";
import { authHeader } from "../../actions/AuthHeader";
import useAxios from "@use-hooks/axios";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/core";
import isEmail from "validator/lib/isEmail";

import AutoComplete from "../../layout/UI/AutoComplete";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const AdminAddEnterpriseUser = (props) => {
  const [AllUsers, setAllUsers] = useState(null);
  const [isPosting, setisPosting] = useState(false);
  const [NewUser, setNewUser] = useState("");
  const [EnterpriseType, setEnterpriseType] = useState("");
  const [OrgName, setOrgName] = useState("");

  const getAllUser = useAxios({
    url: `${API_URL}/users/${props.sessionID}/all_non_enterprise_users/`,
    options: {
      headers: {
        Authorization: authHeader(),
      },
    },
    trigger: [],
    customHandler: (err, res) => res && setAllUsers(res.data.data),
  });

  if (getAllUser.response) console.log(getAllUser.response.data.data);

  const createOrgUser = useAxios({
    url: `${API_URL}/users/${props.sessionID}/create_checkout_enterprise/`,
    method: "POST",
    options: {
      headers: {
        Authorization: authHeader(),
      },
      data: {
        org_user: NewUser,
        org_name: OrgName,
        type: EnterpriseType,
      },
    },
    // trigger: [],
    customHandler: (err, res) => {
      if (res) {
        setisPosting(false);

        setNewUser("");
        setEnterpriseType("");
        setOrgName("");
        console.log("res", res);
        const message = res.data.data;
        console.log(message);
        if (message == "enterprise_user_add") {
          getAllUser.reFetch();
          console.log("there");
          showSuccessMessage("Enterprise user added");
        }

        // } else if (message === "user_is_in_org") {
        //   showErrorMessage("user is in organization");
        // } else if (message === "invitation_is_send") {
        //   showSuccessMessage("Invitaion is sent");
        // }
      }
    },
  });

  // const removeOrgUser = useAxios({
  //   url: `${API_URL}/users/${props.sessionID}/org_remove_users/`,
  //   method: "POST",
  //   options: {
  //     headers: {
  //       Authorization: authHeader(),
  //     },
  //     data: {
  //       email: OnremoveUser,
  //     },
  //   },
  //   // trigger: [],
  //   customHandler: (err, res) => {
  //     if (res) {
  //       setisPosting(false);

  //       console.log("res", res);
  //       const message = res.data.data;
  //       console.log(message);
  //       if (message == "not_found") {
  //         console.log("there");
  //         showErrorMessage("user not found");
  //       } else if (message === "user_removed") {
  //         showSuccessMessage("user is removed");
  //       }
  //       setshowRemoveToggle(before=>!before)
  //       getOrganizationUser.reFetch();
  //     }
  //   },
  // });

  const showErrorMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCloseCircle style={{ color: "#f5222d" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

  const showSuccessMessage = (text) =>
    toast.notify(
      <p className={"m-0 p-0 font-size-14"}>
        <span className={"mr-2 font-size-19"} style={{ verticalAlign: "1px" }}>
          <IoMdCheckmarkCircle style={{ color: "#52c41a" }} />
        </span>
        {text}
      </p>,
      {
        duration: 2000,
      }
    );

  const addUser = () => {
    if (isEmail(NewUser)) {
      if (
        OrgName.length > 0 &&
        EnterpriseType.length > 0 &&
        NewUser.length > 0
      ) {
        // setNewUser(
        //   AllUsers.filter((user) => user.email === NewUser)[0].username
        // );
        createOrgUser.reFetch();
        setisPosting(true);
      } else {
        showErrorMessage("please complete inputs");
      }
    } else {
      showErrorMessage('enter valid email address')
    }
  };
  const onAddNewUser = (event, { newValue }) => {
    setNewUser(newValue);
  };

  // console.log("onRemove", OnremoveUser);

  return (
    <div>
      <Fragment>
        <div
          className={"p-3"}
          style={{
            backgroundColor: "white",
            marginTop: "70px",
            paddingBottom: "50px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <h3>Enterprise Add User</h3>
          <hr />
          <div className={"row mb-3"}>
            <div className={"col-12 col-md-6 my-1"}>
              <label className={"form-ui-label font-size-13 fw-600"}>
                username
              </label>
              <InputGroup seamless>
                {getAllUser.response ? (
                  <AutoComplete
                    inputProps={{
                      placeholder: "",
                      value: NewUser,
                      onChange: onAddNewUser,
                    }}
                    data={getAllUser.response?.data.data}
                  />
                ) : undefined}
              </InputGroup>
            </div>
          </div>
          <div className={"row mb-3"}>
            <div className={"col-12 col-md-6 my-1"}>
              <label className={"form-ui-label font-size-13 fw-600"}>
                Organization name
              </label>
              <InputGroup seamless>
                <FormInput
                  onChange={(e) => setOrgName(e.target.value)}
                  value={OrgName}
                />
              </InputGroup>
            </div>
          </div>
          <div className={"row mb-3"}>
            <div className={"col-12 col-md-6 my-1"}>
              <label className={"form-ui-label font-size-13 fw-600"}>
                user type
              </label>
              <InputGroup seamless>
                <FormSelect
                  onChange={(e) => setEnterpriseType(e.target.value)}
                  value={EnterpriseType}
                >
                  <option value="" disabled selected>
                    Select your Type
                  </option>
                  <option value="Enterprise30">Enterprise30</option>
                  <option value="Enterprise100">Enterprise100</option>
                  <option value="Enterprise500">Enterprise500</option>
                </FormSelect>
              </InputGroup>
            </div>
          </div>

          <Button theme={"primary"} onClick={addUser}>
            {isPosting ? (
              <div className="sweet-loading">
                <PulseLoader
                  css={override}
                  sizeUnit={"px"}
                  color={"white"}
                  size={7}
                  loading={true}
                />
              </div>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Fragment>
    </div>
  );
};

export default withRouter(connect(null, {})(AdminAddEnterpriseUser));
